import { styled } from '@mui/material';

export const StyledListWithFilters = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflow: 'hidden',
  backgroundColor: 'white',
  padding: 0,
  borderRadius: '.25rem',
  border: `1px solid ${theme.palette.divider}`,

  '& .list-filters': {
    display: 'flex',
    flexDirection: 'column',
  },

  '& .list-container': {
    flex: 1,
    overflow: 'auto',
    padding: '1rem',
  },
}));

export const StyledHeaderSection = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '16px',
  borderBottom: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.paper,
  flexWrap: 'wrap',
  gap: '8px',
}));

export const StyledFilterSection = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.grey[200],
}));  

export const StyledFilterHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  cursor: 'pointer',
  padding: '0 1rem 0 0',
  height: 0,
  opacity: 1,

  '&:hover': {
    backgroundColor: theme.palette.cloudburst.main,
  },

  button: {
    height: '1.5rem',
    width: '1.5rem',
    backgroundColor: theme.palette.cloudburst.main,
    color: 'white',
    opacity: 1,

    '&:hover': {
      backgroundColor: theme.palette.cloudburst.main,
    },
  },
}));