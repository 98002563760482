import { useCallback, useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useQueryClient, UseQueryResult } from 'react-query';
import toast from 'react-hot-toast';
import { OrganizationUpcomingMeetings } from '../../QueryNames';
import { ApiClient } from '../../Services/NetworkCommon';
import {
  isGoogleUser,
  getIdentityProvider,
  getDisplayProvider,
} from '../../Helpers/IdentityHelpers';
import { isAxiosErrorResponse } from '../../Helpers/AxiosHelpers';
import { DSButton } from './DS';
import { DEALROOMS_COLORS } from '../../Themes/Themes';

export interface DSIntegrationsCalendarButtonProps {
  calendarLoading: boolean;
  orgIsLoading: boolean;
  eventsError: unknown;
  organizationSlug: string;
  refetchEvents: UseQueryResult['refetch'];
  connectButtonLabel?: string;
  disconnectButtonLabel?: string;
}

export const DSIntegrationsCalendarButton = ({
  calendarLoading,
  orgIsLoading,
  eventsError,
  organizationSlug,
  refetchEvents,
  connectButtonLabel = 'Connect',
  disconnectButtonLabel = 'Disconnect',
}: DSIntegrationsCalendarButtonProps) => {
  const { user, getAccessTokenWithPopup, getAccessTokenSilently } = useAuth0();
  const client = useQueryClient();

  const retryWithBackoff = async (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    operation: () => Promise<any>,
    maxRetries = 3,
  ) => {
    for (let i = 0; i < maxRetries; i++) {
      try {
        const result = await operation();
        if (result instanceof Error || result?.error || !result?.data) {
          console.error(`Attempt ${i + 1} failed:`, {
            error: result instanceof Error ? result.message : result?.error,
            data: result?.data,
          });
          throw new Error('Invalid result');
        }
        return result;
      } catch (error) {
        if (i === maxRetries - 1) throw error;
        const delay = Math.min(1000 * 2 ** i, 30000);
        // console.log(`Retrying in ${delay}ms (attempt ${i + 1}/${maxRetries})`);
        await new Promise((resolve) => setTimeout(resolve, delay));
      }
    }
  };

  const loading = calendarLoading || orgIsLoading;

  const displayProvider = useMemo(() => getDisplayProvider(user?.sub), [user]);
  const identityProvider = useMemo(() => getIdentityProvider(user?.sub), [user]);
  const isGoogleUserMemoized = useMemo(() => user?.sub && isGoogleUser(user?.sub), [user]);

  const invalidateCalendarQueries = useCallback(() => {
    client.invalidateQueries({ queryKey: ['calendar'] });
  }, [client]);

  const disconnectButton = useMemo(() => {
    if (!isGoogleUserMemoized) {
      return null; // can't disconnect MSFT calendar
    }

    return (
      <DSButton
        onClick={async () => {
          try {
            const toastId = 'calendar-disconnect';
            toast.loading(
              `Disconnecting ${displayProvider} Calendar...`,
              { id: toastId },
            );

            const token = await getAccessTokenSilently();

            await ApiClient.delete(`/token/${identityProvider}`, {
              headers: { Authorization: `Bearer ${token}` },
            });

            // Invalidate both the events and the calendar status
            await Promise.all([
              client.invalidateQueries(
                OrganizationUpcomingMeetings(organizationSlug!),
              ),
              invalidateCalendarQueries(),
            ]);

            // Refetch to update UI
            await refetchEvents();
            toast.success(`${displayProvider} Calendar disconnected successfully`, {
              id: toastId,
            });
          } catch (error) {
            console.error('Calendar disconnection error:', error);
            toast.error(`Failed to disconnect ${displayProvider} Calendar`, {
              id: 'calendar-disconnect',
            });
          }
        }}
        color="error"
      >
        {disconnectButtonLabel}
      </DSButton>
    );
  }, [getAccessTokenSilently, isGoogleUserMemoized, displayProvider, identityProvider, organizationSlug, client, invalidateCalendarQueries, refetchEvents, disconnectButtonLabel]);

  const connectButton = useMemo(() => {
    return (
      <DSButton
        color="success"
        onClick={async () => {
          const toastId = 'calendar-connect';

          try {
            // First toast: Waiting for popup
            toast.loading(
              `Waiting for ${displayProvider} Calendar authorization...`,
              {
                id: toastId,
              },
            );

            const authParams: Record<string, string | string[] | undefined> = {
              display: 'popup' as const,
              login_hint: user?.email,
            };

            if (isGoogleUserMemoized) {
              authParams['connection_scope'] =
                'https://www.googleapis.com/auth/calendar.events ' +
                'https://www.googleapis.com/auth/contacts.readonly ' +
                'https://www.googleapis.com/auth/contacts.other.readonly';
              authParams['connection'] = 'google-oauth2';
              authParams['scope'] = authParams['connection_scope'];
            } else {
              authParams['connection_scope'] =
                'openid email profile offline_access ' +
                'https://graph.microsoft.com/Calendars.ReadWrite ' +
                'https://graph.microsoft.com/User.Read ' +
                'https://graph.microsoft.com/User.ReadBasic.All ' +
                'https://graph.microsoft.com/Contacts.Read ' +
                'https://graph.microsoft.com/People.Read ' +
                'https://graph.microsoft.com/Contacts.Read.Shared ';
              authParams['connection'] = 'Microsoft';
              authParams['scope'] = authParams['connection_scope'];
              authParams['prompt'] = 'consent';
            }

            let token: string | undefined;
            try {
              token = await getAccessTokenWithPopup({
                authorizationParams: authParams
              });
              if (!token) {
                throw new Error('Failed to get access token');
              }
            } catch (err: unknown) {
              const error = err as Error;
              console.error('Detailed auth error:', {
                error,
                errorMessage: error.message,
                errorStack: error.stack,
                errorType: error.constructor.name,
                authParams,
              });
              toast.error(
                `${displayProvider} Calendar permissions were not granted. Please make sure to select all ${displayProvider} Calendar permissions when authorizing.`,
                { id: toastId },
              );
              invalidateCalendarQueries();
              return;
            }

            // Update toast while we verify access
            toast.loading(
              `Verifying ${displayProvider} Calendar access...`,
              { id: toastId },
            );

            // Set up token
            await ApiClient.delete(`/token/${identityProvider}`, {
              headers: { Authorization: `Bearer ${token}` },
            });

            // Add a small delay to ensure token processing
            await new Promise((resolve) => setTimeout(resolve, 1000));

            // Try to get calendar data with retries
            await retryWithBackoff(() => refetchEvents());

            // If we get here, we have calendar access
            toast.success(
              `${displayProvider} Calendar connected successfully`,
              {
                id: toastId,
              },
            );
          } catch (error) {
            console.error('Calendar connection error:', {
              error,
              provider: displayProvider,
              identityProvider,
            });
            toast.error(
              `${displayProvider} Calendar permissions were not granted. Please make sure to select all ${displayProvider} Calendar permissions when authorizing.`,
              { id: toastId },
            );
            invalidateCalendarQueries();
          }
        }}
      >
        {connectButtonLabel}
      </DSButton>
    );
  }, [getAccessTokenWithPopup, isGoogleUserMemoized, displayProvider, user, identityProvider, invalidateCalendarQueries, refetchEvents, connectButtonLabel]);

  if (eventsError) {
    if (isAxiosErrorResponse(eventsError)) {
      return <>{connectButton}</>
    }
    return null;
  }

  if (loading) {
    return <DSButton disabled>Checking Calendar status...</DSButton>;
  }

  return (
    <>{disconnectButton}</>
  );
};
