import { styled, Typography, SxProps, Theme, CSSObject } from '@mui/material';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';

export interface StyledPhoneInputProps {
  error?: boolean;
  customstyles?: {
    container?: SxProps<Theme>;
    input?: SxProps<Theme>;
  };
}

export interface StyledLabelProps {
  customstyles?: SxProps<Theme>;
  halfbackgroundcolor?: string;
}

export const StyledLabel = styled(Typography)<StyledLabelProps>(({
  theme,
  customstyles,
  halfbackgroundcolor,
}) => {
  const containerStyles: CSSObject = (customstyles as CSSObject) || {};
  return {
    color: 'rgba(0, 0, 0, 0.6)',
    fontSize: '0.8125rem',
    paddingBottom: '.25rem',
    position: 'absolute',
    top: '-0.625rem',
    left: '0.625rem',
    lineHeight: '0.8125rem',
    background: `linear-gradient(to bottom, transparent 65%, ${halfbackgroundcolor || DEALROOMS_COLORS.white} 35%)`,
    padding: '0.125rem 0.25rem 0',
    borderRadius: '0.25rem',
    ...containerStyles,
  };
});

export const StyledPhoneInput = styled('div')<StyledPhoneInputProps>(({
  theme,
  error,
  customstyles,
}) => {
  const containerStyles: CSSObject =
    (customstyles?.container as CSSObject) || {};
  const inputStyles: CSSObject = (customstyles?.input as CSSObject) || {};

  return {
    '& .PhoneInput': {
      width: '100%',
      height: '2.5rem',
      boxSizing: 'border-box',
      border: `1px solid ${error ? DEALROOMS_COLORS.basicErrorMessage : 'rgba(0, 0, 0, 0.23)'}`,
      padding: '0 0 0 0.875rem',
      borderRadius: '.25rem',
      backgroundColor: DEALROOMS_COLORS.white,
      display: 'flex',
      '&:focus': {
        borderColor: error
          ? DEALROOMS_COLORS.basicErrorMessage
          : DEALROOMS_COLORS.themePrimary,
      },
      '&:hover': {
        borderColor: error
          ? DEALROOMS_COLORS.basicErrorMessage
          : DEALROOMS_COLORS.themePrimary,
      },
      ...containerStyles,
    },
    '& .PhoneInputInput': {
      flex: 1,
      borderRadius: '.25rem',
      padding: '.5rem 0.75rem',
      fontSize: '1rem',
      outline: 'none',
      border: 'none',
      backgroundColor: DEALROOMS_COLORS.white,
      '&:focus': {
        borderColor: error
          ? DEALROOMS_COLORS.basicErrorMessage
          : DEALROOMS_COLORS.themePrimary,
      },
      '&:hover': {
        borderColor: error
          ? DEALROOMS_COLORS.basicErrorMessage
          : DEALROOMS_COLORS.themePrimary,
      },
      ...inputStyles,
    },
    '& .PhoneInputCountry': {
      marginRight: 0,
    },
  };
});

export const StyledErrorText = styled(Typography)({
  color: DEALROOMS_COLORS.basicErrorMessage,
  fontSize: '0.75rem',
  marginTop: '.25rem',
});

export const StyledContainer = styled('div')({
  position: 'relative',
});
