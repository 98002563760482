/**
 * DecisionSiteUserCalendar Component
 *
 * This component implements a calendar view for a Decision Site, displaying meetings and events
 * with filtering capabilities for internal/external meetings and specific contacts.
 *
 * Key Features:
 * - Weekly calendar navigation
 * - Meeting filtering (internal/external)
 * - Contact-based filtering
 * - Search functionality
 * - Integration with Google/Microsoft calendars
 */

import { useAuth0 } from '@auth0/auth0-react';
import { useOrganization } from '../../../../Hooks/useOrganization';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';
import { Fragment, useEffect, useMemo, useState, useCallback } from 'react';
import { useUserProfile } from '../../../../Hooks/useProfile';
import { OrganizationMeetingsHappeningSoon } from '../../../../QueryNames';
import { ApiClient } from '../../../../Services/NetworkCommon';
import { CalendarEvent } from '@meetingflow/common/Api/data-contracts';
import { groupEventsByDay } from '../../../../Helpers/CalendarEventHelpers';
import { isGoogleUser } from '../../../../Helpers/IdentityHelpers';
import { isAxiosErrorResponse } from '../../../../Helpers/AxiosHelpers';
import {
  CircularProgress,
  TableBody,
  TableContainer,
  TableRow,
  Typography,
  Paper,
  Table,
  InputAdornment,
  Collapse,
  IconButton,
} from '@mui/material';
import {
  ChevronLeft,
  ChevronRight,
  SearchOutlined,
  ExpandMore,
  ExpandLess,
} from '@mui/icons-material';
import {
  StyledLoadingContainer,
  StyledEmptyStateContainer,
  StyledEmptyStateText,
  StyledClickableText,
  StyledUserCalendarRoot,
  StyledUserCalendarEventsContainer,
  StyledContactsContainer,
  StyledContactChipContainer,
  StyledContactChip,
  StyledWeekNavigationContainer,
  StyledNavigationButton,
  StyledSearchContainer,
  SetupContainer,
  StyledTableCell,
  StyledSearchAndToggleContainer,
  StyledToggleContainer,
  StyledToggleLabel,
  StyledFilterSection,
  StyledFilterHeader,
} from './DecisionSiteUserCalendar.styles';
import { DSButton, DSTextField, DSFormControl, DSSwitch } from '../../DS';
import { useDealRoom } from '../../../../Hooks/useDealRoom';
import DecisionSiteEventCard from '../../../../Components/Organization/Library/DecisionSiteEventCard';
import { useLightOrDarkMode } from '../../../../Hooks/useLightOrDarkMode';
import { useTheme } from '@mui/material/styles';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';
import { DecisionSiteMeetingflowCard } from '../../../../Components/MeetingPlans/DecisionSiteMeetingflowCard';
import { useDebounce } from 'use-debounce';
import { useUserCalendar } from './hooks/useUserCalendar';
import { DSIntegrationsCalendarButton } from '../../DSIntegrationsCalendarButton';

/**
 * Constants for meeting filter preferences in the agenda view
 * SHOW_ALL: Display all meetings
 * EXTERNAL_ONLY: Only show meetings with external participants
 */
const AgendaMeetingFilterPreference = {
  SHOW_ALL: 'SHOW_ALL',
  EXTERNAL_ONLY: 'EXTERNAL_ONLY',
} as const;

/**
 * Constants for attendee display preferences in the agenda
 * COMPANY_AND_ATTENDEES: Show both company and individual attendee information
 * COMPANY_ONLY: Only show company information
 */
const AgendaShowAttendeesPreference = {
  COMPANY_AND_ATTENDEES: 'COMPANY_AND_ATTENDEES',
  COMPANY_ONLY: 'COMPANY_ONLY',
} as const;

interface DecisionSiteUserCalendarProps {
  onSelectEvent?: (eventId: number) => void;
  onSelectMeetingflow?: (meetingflowId: string) => void;
}

export function DecisionSiteUserCalendar({
  onSelectEvent,
  onSelectMeetingflow,
}: DecisionSiteUserCalendarProps) {
  // React Router hooks
  const navigate = useNavigate();

  // Theme hooks
  const theme = useTheme();
  const { isDark } = useLightOrDarkMode();

  // Local state management hooks
  const [showInternal, setShowInternal] = useState(false);
  const [showAttendees, setShowAttendees] = useState(false);
  const [selectedContactId, setSelectedContactId] = useState<number | null>(
    null,
  );
  const [searchString, setSearchString] = useState('');
  const [debouncedSearchString] = useDebounce(searchString, 300);
  const [selectedDate, setSelectedDate] = useState(
    DateTime.now().startOf('week'),
  );
  const [isFilterExpanded, setIsFilterExpanded] = useState(false);

  // Authentication and organization context hooks
  const { user } = useAuth0();
  const { slug: organizationSlug, isLoading: orgIsLoading } = useOrganization();
  const {
    dealRoom,
    isLoading: dealRoomIsLoading,
    dealRoomRole,
  } = useDealRoom();

  // // User profile and preferences hooks
  const { user: userProfile, updateUserProfile } = useUserProfile();

  // // Query client for data management
  const client = useQueryClient();

  const {
    events,
    loading,
    dealRoomContactsError,
    eventsError,
    refetchEvents,
    refetchContacts,
    dealRoomContactsData,
    meetingflowArtifacts,
    ownedMeetingflowsData,
  } = useUserCalendar({
    searchString: debouncedSearchString,
    selectedDate,
    showInternal,
  });

  // Calculate active week start date
  const activeWeekStart = useMemo(
    () => selectedDate.startOf('week'),
    [selectedDate],
  );

  // // Check if a calendar event has a related meetingflow in the decision site
  const isEventInDecisionSite = (event: CalendarEvent) => {
    return meetingflowArtifacts.some((artifact) => {
      return artifact.meetingflowId === event.meetingplanId;
    });
  };

  const filteredEvents = useMemo(() => {
    if (!selectedContactId) {
      return events;
    }
    return events.filter((event) =>
      event.attendees?.some((attendee) => attendee.id === selectedContactId),
    );
  }, [events, selectedContactId]);

  const dayGroups = useMemo(() => {
    return groupEventsByDay(filteredEvents);
  }, [filteredEvents]);
  // All hooks must be at the top level, not inside conditionals
  const handleShowInternalChange = useCallback(async () => {
    const newShowInternal = !showInternal;
    setShowInternal(newShowInternal);
    await updateUserProfile({
      preferenceAgendaMeetingFilter: newShowInternal
        ? AgendaMeetingFilterPreference.EXTERNAL_ONLY
        : AgendaMeetingFilterPreference.SHOW_ALL,
    });
  }, [showInternal, updateUserProfile]);

  const handleContactClick = useCallback(
    (contact: { id?: number; email: string }) => {
      if (selectedContactId === contact.id) {
        setSelectedContactId(null);
        setSearchString('');
      } else {
        setSelectedContactId(contact.id || null);
        setSearchString(contact.email || '');
      }
    },
    [selectedContactId],
  );

  const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchString(event.target.value);
    },
    [],
  );

  useEffect(() => {
    if (refetchEvents && refetchContacts) {
      refetchEvents();
      refetchContacts();
    }
  }, [refetchEvents, refetchContacts]);

  useEffect(() => {
    if (!userProfile) return;
    const preferenceShowInternal =
      userProfile.preferenceAgendaMeetingFilter ===
      AgendaMeetingFilterPreference.EXTERNAL_ONLY;
    const preferenceShowAttendees =
      userProfile.preferenceAgendaShowAttendees ===
      AgendaShowAttendeesPreference.COMPANY_AND_ATTENDEES;

    if (preferenceShowInternal !== showInternal) {
      setShowInternal(preferenceShowInternal);
    }
    if (preferenceShowAttendees !== showAttendees) {
      setShowAttendees(preferenceShowAttendees);
    }
  }, [userProfile, showInternal, showAttendees]);

  if (eventsError) {
    if (isAxiosErrorResponse(eventsError)) {
      return (
        <SetupContainer>
          <div>
            <h3>Let's get your calendar set up.</h3>
            <p>
              Decision Site works best when it has access to your{' '}
              {user?.sub
                ? isGoogleUser(user.sub)
                  ? 'Google'
                  : 'Microsoft'
                : ''}{' '}
              Calendar. We'll show your upcoming events here, and you'll easily
              be able to record them and add them to the Decision Site.
            </p>
            {organizationSlug && (
              <DSIntegrationsCalendarButton
                calendarLoading={loading}
                orgIsLoading={orgIsLoading}
                eventsError={eventsError}
                organizationSlug={organizationSlug}
                refetchEvents={refetchEvents}
                connectButtonLabel={`Connect ${user?.sub && isGoogleUser(user.sub) ? 'Google' : 'Microsoft'} Calendar`}
              />
            )}
            <p>
              Alternatively, add a meeting without connecting your calendar
              using the "New Meeting" button.
            </p>
          </div>
        </SetupContainer>
      );
    }
    return null;
  }

  if (dealRoomContactsError) {
    return <div>Error loading contacts</div>;
  }

  return (
    <StyledUserCalendarRoot>
      <StyledWeekNavigationContainer>
        <Typography
          variant="subtitle1"
          style={{
            fontWeight: 'normal',
          }}
        >
          <span
            style={{
              display: 'block',
              fontSize: '.75rem',
              lineHeight: '.75rem',
              color: DEALROOMS_COLORS.cloudburst,
            }}
          >
            Your calendar for{' '}
          </span>
          {selectedDate.startOf('week').toFormat('MMM d')} -{' '}
          {selectedDate.endOf('week').toFormat('MMM d, yyyy')}
        </Typography>
        <div className="nav-buttons">
          <StyledNavigationButton
            size="small"
            onClick={() => {
              const previousWeekStart = activeWeekStart
                .minus({ weeks: 1 })
                .startOf('week');
              setSelectedDate(previousWeekStart);
            }}
            variant="text"
          >
            <ChevronLeft fontSize="small" />
          </StyledNavigationButton>
          <StyledNavigationButton
            size="small"
            onClick={() => setSelectedDate(DateTime.now())}
            variant="text"
          >
            Today
          </StyledNavigationButton>
          <StyledNavigationButton
            size="small"
            onClick={() => {
              const nextWeekStart = activeWeekStart
                .plus({ weeks: 1 })
                .startOf('week');
              setSelectedDate(nextWeekStart);
            }}
            variant="text"
          >
            <ChevronRight fontSize="small" />
          </StyledNavigationButton>
        </div>
      </StyledWeekNavigationContainer>

      <StyledFilterSection
        sx={{
          borderBottomWidth: isFilterExpanded ? '1px' : '0',
        }}
      >
        <StyledFilterHeader>
          <IconButton
            size="small"
            color="secondary"
            onClick={() => setIsFilterExpanded(!isFilterExpanded)}
          >
            {isFilterExpanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </StyledFilterHeader>

        <Collapse in={isFilterExpanded}>
          <StyledSearchAndToggleContainer>
            <StyledSearchContainer>
              <DSFormControl style={{ width: '100%' }}>
                <DSTextField
                  size="small"
                  label="Search"
                  value={searchString}
                  onChange={(e) => setSearchString(e.target.value)}
                  slotProps={{
                    input: {
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchOutlined fontSize="small" />
                        </InputAdornment>
                      ),
                    },
                  }}
                  fullWidth
                />
              </DSFormControl>
            </StyledSearchContainer>
            <StyledToggleContainer>
              <StyledToggleLabel>Include Internal</StyledToggleLabel>
              <DSSwitch
                checked={showInternal}
                onChange={handleShowInternalChange}
                size="small"
              />
            </StyledToggleContainer>
          </StyledSearchAndToggleContainer>

          <StyledContactsContainer>
            <StyledContactChipContainer isSelected={selectedContactId === null}>
              <StyledContactChip
                label="All Events"
                onClick={() => {
                  setSelectedContactId(null);
                  setSearchString('');
                  setSelectedDate(DateTime.now());
                }}
                variant={selectedContactId === null ? 'filled' : 'outlined'}
                color={selectedContactId === null ? 'primary' : 'default'}
                clickable
                size="small"
              />
            </StyledContactChipContainer>
            {dealRoomContactsData?.data
              ?.filter(
                (contact) =>
                  contact.email !== user?.email && contact.id !== undefined,
              )
              ?.map((contact) => (
                <StyledContactChipContainer
                  key={contact.id}
                  isSelected={selectedContactId === contact.id}
                >
                  <StyledContactChip
                    label={contact.name || contact.email}
                    onClick={() => handleContactClick(contact)}
                    variant={
                      selectedContactId === contact.id ? 'filled' : 'outlined'
                    }
                    color={
                      selectedContactId === contact.id ? 'primary' : 'default'
                    }
                    clickable
                    size="small"
                  />
                </StyledContactChipContainer>
              ))}
          </StyledContactsContainer>
        </Collapse>
      </StyledFilterSection>

      <StyledUserCalendarEventsContainer>
        {loading ? (
          <StyledLoadingContainer>
            <CircularProgress />
          </StyledLoadingContainer>
        ) : filteredEvents.length === 0 ? (
          <StyledEmptyStateContainer>
            <Typography variant="body1">
              No events found{searchString ? ` matching "${searchString}"` : ''}
              .
            </Typography>
            {selectedContactId && (
              <StyledEmptyStateText variant="body2">
                Try{' '}
                <StyledClickableText
                  onClick={() => {
                    setSelectedContactId(null);
                    setSearchString('');
                    setSelectedDate(DateTime.now());
                  }}
                >
                  selecting "All Events"
                </StyledClickableText>{' '}
                or adjusting your search.
              </StyledEmptyStateText>
            )}
            {!showInternal && !selectedContactId && (
              <StyledEmptyStateText variant="body2">
                Try{' '}
                <StyledClickableText onClick={handleShowInternalChange}>
                  including internal meetings
                </StyledClickableText>
                .
              </StyledEmptyStateText>
            )}
          </StyledEmptyStateContainer>
        ) : (
          <TableContainer
            component={Paper}
            sx={{
              maxHeight: '100%',
              borderRadius: 0,
              '& .MuiPaper-root': {
                borderRadius: 0,
              },
            }}
          >
            <Table size="small">
              <TableBody>
                {dayGroups.map((group) => (
                  <Fragment key={group.key}>
                    <TableRow>
                      <StyledTableCell
                        colSpan={1}
                        sx={{
                          marginTop: '2rem',
                          color: DEALROOMS_COLORS.themeSecondary,
                          fontSize: '.75rem',
                          lineHeight: '.75rem',
                          textTransform: 'uppercase',
                          backgroundColor:
                            DEALROOMS_COLORS.financialLighterGray,
                          borderRadius: '0 !important',
                        }}
                      >
                        {DateTime.fromISO(
                          group.items[0].startTime,
                        ).toLocaleString({
                          weekday: 'long',
                          month: 'long',
                          day: 'numeric',
                        })}
                      </StyledTableCell>
                    </TableRow>
                    {filteredEvents
                      .slice(group.startIndex, group.startIndex + group.count)
                      .map((event) => (
                        <TableRow key={event.iCalUid}>
                          <StyledTableCell>
                            {!event.meetingplanId &&
                            organizationSlug &&
                            dealRoom ? (
                              <DecisionSiteEventCard
                                event={event}
                                organizationSlug={organizationSlug!}
                                hideNewMeetingflowButton
                                allowScheduleCallRecording
                                disableCreateViewMeetingflowOnClick
                                showAddToDecisionSiteButton={
                                  !isEventInDecisionSite(event)
                                }
                                showTimesOnly
                              />
                            ) : (
                              <DecisionSiteMeetingflowCard
                                meetingflowId={event.meetingplanId!}
                                hideCRMIcon
                                showAddToDecisionSiteButton={
                                  !isEventInDecisionSite(event)
                                }
                                showTimesOnly
                                noTitleLink
                                onClick={onSelectMeetingflow}
                              />
                            )}
                          </StyledTableCell>
                        </TableRow>
                      ))}
                  </Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </StyledUserCalendarEventsContainer>
    </StyledUserCalendarRoot>
  );
}
