import React, { useState } from 'react';

import { useQuery } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import toast from 'react-hot-toast';

import {
  InviteContainer,
  InviteContent,
  InviteHeaderContainer,
  InviteLabel,
  InviteSubtitle,
} from './UserSettingsInvite.styles';
import { DealRoomContactsQuery } from '../../../../../QueryNames';
import { DealRoomsApiClient } from '../../../../../Services/NetworkCommon';
import { useDealRoom } from '../../../../../Hooks/useDealRoom';
import useDeviceType from '../../../../../Hooks/deviceDetection';
import { UserSettingsInviteForm } from './UserSettingsInviteForm';
import { UserSettingsMembersTable } from './UserSettingsMembersTable';

interface UserSettingsInviteProps {
  isSaving: boolean;
  organizationSlug: string;
  setIsSaving: (isSaving: boolean) => void;
}

export const UserSettingsInvite = ({
  isSaving,
  setIsSaving,
  organizationSlug,
}: UserSettingsInviteProps) => {
  const { isMobile } = useDeviceType(undefined, 1100);

  const { getAccessTokenSilently } = useAuth0();

  const { dealRoomId } = useDealRoom();

  const { data: dealRoomContactsData, refetch: refetchContacts } = useQuery(
    DealRoomContactsQuery(organizationSlug!, dealRoomId!),
    async () => {
      const token = await getAccessTokenSilently();
      return DealRoomsApiClient.listDealRoomContacts(
        organizationSlug,
        dealRoomId!,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
  );

  return (
    <InviteContainer isMobile={isMobile}>
      <InviteContent>
        <InviteHeaderContainer>
          <InviteLabel>Invite Someone</InviteLabel>
          <InviteSubtitle>
            Invite someone to give them access to this Decision Site.
          </InviteSubtitle>
        </InviteHeaderContainer>
        <UserSettingsInviteForm
          setIsSaving={setIsSaving}
          isSaving={isSaving}
          refetchContacts={refetchContacts}
        />
        <UserSettingsMembersTable
          dealRoomContactsData={dealRoomContactsData?.data}
          refetchContactsData={refetchContacts}
        />
      </InviteContent>
    </InviteContainer>
  );
};
