import { DealRoomDocumentArtifact } from '@meetingflow/common/Api/data-contracts';
import { ImageWithFallback } from '../../../Components/Common/ImageWithFallback';
import { ArtifactIconPreview } from './ArtifactIconPreview';
import { BaseArtifactCard, BaseArtifactCardProps } from './BaseArtifactCard';

export type DocumentArtifactCardProps = BaseArtifactCardProps &
  Pick<DealRoomDocumentArtifact, 'fileName' | 'thumbnailUrl' | 'mimeType'>;

export const DocumentArtifactCard = ({
  orgSlug,
  dealRoomId,
  fileName,
  mimeType,
  thumbnailUrl,
  ...rest
}: DocumentArtifactCardProps) => {
  return (
    <BaseArtifactCard
      className="document-artifact"
      orgSlug={orgSlug}
      dealRoomId={dealRoomId}
      mimeType={mimeType}
      {...rest}
    >
      <ImageWithFallback
        src={thumbnailUrl || undefined}
        fallback={<ArtifactIconPreview type="DOCUMENT" mimeType={mimeType} />}
      />
    </BaseArtifactCard>
  );
};
