import { useAuth0 } from '@auth0/auth0-react';
import { Spinner, Text, Icon } from '@fluentui/react';
import {
  DealRoomArtifact,
  DealRoomMeetingflowArtifact,
  DetailedDealRoom,
} from '@meetingflow/common/Api/data-contracts';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { isBadRequest, isForbiddenError } from '../../../Helpers/AxiosHelpers';
import { useAddDealRoomArtifactDialog } from '../../../Hooks/Modals/DealRoom/useAddDealRoomArtifactDialog';

import { useTitle } from '../../../Hooks/useTitle';
import { OrganizationDealRoomArtifactsQuery } from '../../../QueryNames';
import { DealRoomsApiClient } from '../../../Services/NetworkCommon';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';
import { DealRoomArtifactsTable } from './DealRoomArtifactsTable';
import { ArtifactsGridView } from './ArtifactsGridView';
import { DSTooltip } from '../DS/DSTooltip';
import DSButton from '../DS/DSButton';
import toast from 'react-hot-toast';
import { DSMenu, DSMenuHeader, DSMenuItem, DSTab, DSTabs } from '../DS';
import {
  ArtifactsWrapper,
  ViewModeSelectorGroup,
  ArtifactsViewToolsWrapper,
  CompactToggleButton,
} from './DealRoomArtifacts.styles';
import { IconButton, useTheme } from '@mui/material';
import { useNavigate } from 'react-router';
import GridViewIcon from '@mui/icons-material/GridView';
import TableRowsIcon from '@mui/icons-material/TableRows';
import SortIcon from '@mui/icons-material/Sort';
import ListSharpIcon from '@mui/icons-material/ListSharp';
import AppsSharpIcon from '@mui/icons-material/AppsSharp';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useLocalStorageState } from '../../../Hooks/useLocalStorageState';

/** View mode for artifacts display - either table or grid layout */
type ViewMode = 'table' | 'grid';

/** Available fields for sorting artifacts */
export type SortField = 'name' | 'owner' | 'lastModified' | 'upvotes';

/** Sort direction - ascending or descending */
export type SortDirection = 'asc' | 'desc';

/**
 * Props for the DealRoomArtifacts component
 * @property className - Optional CSS class for the root element
 * @property artifactContainerClassName - Optional CSS class for the artifacts container
 * @property organizationSlug - Unique identifier for the organization
 * @property dealRoomId - Numeric identifier for the deal room
 * @property activity - Optional activity data from the deal room
 * @property refreshDealRoom - Function to refresh the deal room data
 * @property onClick - Optional callback when an artifact is clicked
 * @property onAdd - Optional callback when an artifact is added
 * @property onDelete - Optional callback when an artifact is deleted
 */
type DealRoomArtifactsProps = {
  className?: string;
  artifactContainerClassName?: string;
  organizationSlug: string;
  dealRoomId: number;
  activity?: DetailedDealRoom['activity'];
  refreshDealRoom: () => Promise<unknown>;
  onClick?: (id: DealRoomArtifact['id']) => unknown;
  onAdd?: (item: DealRoomArtifact) => Promise<unknown>;
  onDelete?: (id: DealRoomArtifact['id']) => Promise<unknown>;
};

/**
 * DealRoomArtifacts component displays and manages artifacts within a deal room.
 * Features:
 * - Supports both grid and table views
 * - Implements sorting by multiple fields (name, owner, last modified, etc.)
 * - Handles artifact deletion with a trash system
 * - Manages artifact viewing and interaction
 * - Provides artifact filtering and organization
 */
export const DealRoomArtifacts = ({
  className,
  organizationSlug,
  dealRoomId,
  onClick,
  onAdd,
  onDelete,
}: DealRoomArtifactsProps) => {
  const [viewMode, setViewMode] = useLocalStorageState<ViewMode>(
    'artifacts-view-mode',
    'table',
  );
  const [sortField, setSortField] = useState<SortField>('lastModified');
  const [sortDirection, setSortDirection] = useState<SortDirection>('desc');
  const { getAccessTokenSilently } = useAuth0();
  const {
    createDeferred: showAddDealRoomArtifactDialog,
    dialog: addDealRoomArtifactDialog,
  } = useAddDealRoomArtifactDialog({
    organizationSlug,
    dealRoomId,
  });

  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(0);

  useTitle('Artifacts');

  const {
    data: dealRoomArtifacts,
    isLoading: artifactsLoading,
    refetch: refetchArtifacts,
  } = useQuery(
    OrganizationDealRoomArtifactsQuery(organizationSlug, dealRoomId),
    async () => {
      const token = await getAccessTokenSilently();
      const artifacts = await DealRoomsApiClient.listArtifacts(
        { organizationSlug, dealRoomId, includeDeleted: true },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      return artifacts;
    },
    {
      enabled: !!organizationSlug && !!dealRoomId,
      retry: (failureCount, error) => {
        if (isForbiddenError(error)) {
          return false;
        }
        if (isBadRequest(error)) {
          return false;
        }

        return failureCount < 3;
      },
    },
  );

  // Filter out MEETINGFLOW artifacts
  const filteredArtifacts = useMemo(() => {
    return dealRoomArtifacts?.data?.filter((artifact) => artifact.type !== 'MEETINGFLOW');
  }, [dealRoomArtifacts]);

  const undeletedArtifacts = useMemo(
    () => filteredArtifacts?.filter((artifact) => !artifact.deletedAt),
    [filteredArtifacts],
  );

  const handleViewModeChange = (
    event: React.MouseEvent<HTMLElement>,
    newMode: ViewMode | null,
  ) => {
    if (newMode !== null) {
      setViewMode(newMode);
    }
  };

  const handleSortChange = useCallback(
    (field: SortField, direction: SortDirection) => {
      setSortField(field);
      setSortDirection(direction);
    },
    [],
  );

  const sortedArtifacts = useMemo(() => {
    if (!undeletedArtifacts) return [];

    return [...undeletedArtifacts].sort((a, b) => {
      const direction = sortDirection === 'asc' ? 1 : -1;

      switch (sortField) {
        case 'name':
          return direction * a.name.localeCompare(b.name);
        case 'owner':
          return (
            direction *
            (a.creator?.name || '').localeCompare(b.creator?.name || '')
          );
        default:
          return 0;
      }
    });
  }, [undeletedArtifacts, sortField, sortDirection]);
  const deletedArtifacts = useMemo(
    () => filteredArtifacts?.filter((artifact) => artifact.deletedAt),
    [filteredArtifacts],
  );

  useEffect(() => {
    refetchArtifacts();
  }, [refetchArtifacts]);

  const refetch = useCallback(async () => {
    await Promise.all([refetchArtifacts()]);
  }, [refetchArtifacts]);

  const handleOnClick = useCallback(
    (id: DealRoomArtifact['id']) => {
      if (onClick) {
        onClick(id);
      }
    },
    [onClick],
  );

  const handleOnDelete = useCallback(
    async (id: DealRoomArtifact['id']) => {
      await refetch();
      await onDelete?.(id);
    },
    [onDelete, refetch],
  );

  const handlePermanentlyDeleteAll = useCallback(async () => {
    if (!deletedArtifacts?.length) return;

    try {
      const token = await getAccessTokenSilently();

      // Delete all artifacts in parallel
      await Promise.all(
        deletedArtifacts.map((artifact) =>
          DealRoomsApiClient.deleteArtifact(
            {
              organizationSlug,
              dealRoomId,
              artifactId: artifact.id,
              soft: false,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          ),
        ),
      );

      await refetchArtifacts();
      toast.success('Successfully emptied trash');
    } catch (err) {
      console.error(err);
      toast.error('Failed to delete artifacts permanently');
    }
  }, [
    deletedArtifacts,
    dealRoomId,
    organizationSlug,
    getAccessTokenSilently,
    refetchArtifacts,
  ]);

  const theme = useTheme();
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(menuAnchorEl);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleSortFieldChange = (field: SortField) => {
    handleSortChange(field, sortDirection);
    handleMenuClose();
  };

  const handleDirectionChange = (newDirection: SortDirection) => {
    handleSortChange(sortField, newDirection);
    handleMenuClose();
  };

  const getSortLabel = (field: SortField) => {
    switch (field) {
      case 'name':
        return 'Name';
      case 'owner':
        return 'Owner';
      case 'lastModified':
        return 'Last Modified';
      case 'upvotes':
        return 'Votes';
    }
  };

  const panelStyles = useMemo(() => {
    return {
      backgroundColor: DEALROOMS_COLORS.white,
      marginTop: '1rem',
      padding: '0rem',
      borderRadius: '0.25rem',
      border: `1px solid #e8e8ec`,
    };
  }, []);

  const noArtifactsStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2rem',
  } as React.CSSProperties;

  return (
    <ArtifactsWrapper>
      <ArtifactsViewToolsWrapper>
        <IconButton onClick={handleMenuClick} size="small" sx={{ ml: 1 }}>
          <SortIcon />
        </IconButton>
        <DSMenu
          anchorEl={menuAnchorEl}
          open={open}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <DSMenuHeader>Sort by</DSMenuHeader>
          <DSMenuItem
            onClick={() => handleSortFieldChange('name')}
            primaryText="Name"
            selected={sortField === 'name'}
          />
          <DSMenuItem
            onClick={() => handleSortFieldChange('owner')}
            primaryText="Owner"
            selected={sortField === 'owner'}
          />
          <DSMenuItem
            onClick={() => handleSortFieldChange('lastModified')}
            primaryText="Last Modified"
            selected={sortField === 'lastModified'}
          />
          <DSMenuItem
            onClick={() => handleSortFieldChange('upvotes')}
            primaryText="Votes"
            selected={sortField === 'upvotes'}
          />
          <DSMenuHeader>Direction</DSMenuHeader>
          <DSMenuItem
            onClick={() => handleDirectionChange('asc')}
            primaryText="Ascending"
            icon={<ArrowUpwardIcon fontSize="small" />}
            selected={sortDirection === 'asc'}
          />
          <DSMenuItem
            onClick={() => handleDirectionChange('desc')}
            primaryText="Descending"
            icon={<ArrowDownwardIcon fontSize="small" />}
            selected={sortDirection === 'desc'}
          />
        </DSMenu>
        <ViewModeSelectorGroup
          value={viewMode}
          exclusive
          onChange={handleViewModeChange}
          aria-label="view mode"
          size="small"
        >
          <CompactToggleButton value="table" aria-label="table view">
            <ListSharpIcon />
          </CompactToggleButton>
          <CompactToggleButton value="grid" aria-label="grid view">
            <AppsSharpIcon />
          </CompactToggleButton>
        </ViewModeSelectorGroup>
      </ArtifactsViewToolsWrapper>

      <DSTabs>
        <DSTab
          label="All Artifacts"
          count={undeletedArtifacts?.length || 0}
          value="all"
          panelStyles={{
            ...panelStyles,
            padding: viewMode === 'table' ? '1rem' : '0rem',
          }}
          panel={
            <>
              {addDealRoomArtifactDialog}

              {undeletedArtifacts?.length ? (
                <>
                  {viewMode === 'table' ? (
                    <DealRoomArtifactsTable
                      onClick={handleOnClick}
                      onDelete={handleOnDelete}
                      dealRoomId={dealRoomId}
                      organizationSlug={organizationSlug}
                      refreshDealRoom={refetch}
                      artifacts={sortedArtifacts ?? []}
                      refetchArtifacts={refetchArtifacts}
                      sortField={sortField}
                      sortDirection={sortDirection}
                      onSortChange={handleSortChange}
                    />
                  ) : (
                    <ArtifactsGridView
                      artifacts={sortedArtifacts ?? []}
                      onClick={handleOnClick}
                      onDelete={handleOnDelete}
                      organizationSlug={organizationSlug}
                      dealRoomId={dealRoomId}
                      sortField={sortField}
                      sortDirection={sortDirection}
                      onSortChange={handleSortChange}
                    />
                  )}
                </>
              ) : (
                <div style={noArtifactsStyle}>
                  {!artifactsLoading ? <Text>No Artifacts</Text> : <Spinner />}
                </div>
              )}
            </>
          }
        />
        <DSTab
          label="Trash"
          count={deletedArtifacts?.length || 0}
          value="trash"
          panelStyles={{
            ...panelStyles,
            padding: viewMode === 'table' ? '0 1rem' : '0 1rem 0 0',
          }}
          panel={
            <>
              {deletedArtifacts?.length ? (
                <>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      marginTop: '1rem',
                      gap: '1rem',
                    }}
                  >
                    {(deletedArtifacts?.length ?? 0) > 0 && (
                      <DSButton onClick={handlePermanentlyDeleteAll}>
                        Empty Trash
                      </DSButton>
                    )}
                    <DSTooltip title="Artifacts are available in Trash for 30 days before they are permanently deleted.">
                      <Icon
                        iconName="Info"
                        style={{
                          color: DEALROOMS_COLORS.themeSecondary,
                          fontSize: '14px',
                          cursor: 'help',
                        }}
                      />
                    </DSTooltip>
                  </div>
                  {viewMode === 'table' ? (
                    <DealRoomArtifactsTable
                      onClick={handleOnClick}
                      onDelete={handleOnDelete}
                      dealRoomId={dealRoomId}
                      organizationSlug={organizationSlug}
                      refreshDealRoom={refetch}
                      artifacts={deletedArtifacts ?? []}
                      refetchArtifacts={refetchArtifacts}
                      sortField={sortField}
                      sortDirection={sortDirection}
                      onSortChange={handleSortChange}
                    />
                  ) : (
                    <ArtifactsGridView
                      artifacts={deletedArtifacts ?? []}
                      onClick={handleOnClick}
                      onDelete={handleOnDelete}
                      organizationSlug={organizationSlug}
                      dealRoomId={dealRoomId}
                      sortField={sortField}
                      sortDirection={sortDirection}
                      onSortChange={handleSortChange}
                    />
                  )}
                </>
              ) : (
                <div style={noArtifactsStyle}>
                  {!artifactsLoading ? (
                    <Text>No Deleted Artifacts</Text>
                  ) : (
                    <Spinner />
                  )}
                </div>
              )}
            </>
          }
        />
      </DSTabs>

      {addDealRoomArtifactDialog}
    </ArtifactsWrapper>
  );
};
