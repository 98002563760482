import React, { useRef, useState } from 'react';
import {
  ButtonGroup,
  Button,
  Popper,
  Grow,
  Paper,
  MenuList,
  ClickAwayListener,
} from '@mui/material';
import { AddTask, ArrowDropDown } from '@mui/icons-material';
import { DSMenuItem } from '../DS';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';

interface MilestoneOption {
  key: number;
  text: string;
}

interface MilestoneActionItemSelectMilestoneButtonProps {
  milestoneOptions?: MilestoneOption[];
  selectedMilestoneId: number | null;
  onMilestoneSelected: (milestoneId: number) => void;
  onAddToMilestone: () => void | Promise<void>;
}

export const MilestoneActionItemSelectMilestoneButton: React.FC<
  MilestoneActionItemSelectMilestoneButtonProps
> = ({
  milestoneOptions,
  selectedMilestoneId,
  onMilestoneSelected,
  onAddToMilestone,
}) => {
  const [splitButtonOpen, setSplitButtonOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  return (
    <div
      style={{
        position: 'relative',
        top: '.25rem',
      }}
    >
      <ButtonGroup
        variant="outlined"
        sx={{
          height: '24px',
          transition: '.3s ease-in-out all',
          backgroundColor: selectedMilestoneId
            ? DEALROOMS_COLORS.woodsmoke
            : DEALROOMS_COLORS.cloudburst,
          '& .MuiButton-root:last-child': {
            borderTopLeftRadius: '0',
            borderBottomLeftRadius: '0',
            backgroundColor: `${DEALROOMS_COLORS.cloudburst} !important`,
            border: 'none',
            borderLeft: selectedMilestoneId
              ? `1px solid ${DEALROOMS_COLORS.white}`
              : `1px solid ${DEALROOMS_COLORS.white}`,
          },
        }}
      >
        <Button
          onClick={
            selectedMilestoneId
              ? onAddToMilestone
              : () => setSplitButtonOpen(true)
          }
          disabled={false}
          startIcon={selectedMilestoneId ? <AddTask /> : undefined}
          sx={{
            backgroundColor: selectedMilestoneId
              ? `${DEALROOMS_COLORS.successDarkGreen} !important`
              : `${DEALROOMS_COLORS.cloudburst} !important`,
            color: `${DEALROOMS_COLORS.white} !important`,
            fontSize: '12px',
            lineHeight: 1,
            border: 'none',
            '& .MuiSvgIcon-root': {
              color: `${DEALROOMS_COLORS.white} !important`,
              fontSize: '16px',
            },
            '&:hover': {
              backgroundColor: selectedMilestoneId
                ? `${DEALROOMS_COLORS.successLightGreen} !important`
                : undefined,
            },
          }}
        >
          {selectedMilestoneId !== null
            ? `Add to ${
                milestoneOptions?.find((opt) => opt.key === selectedMilestoneId)
                  ?.text || 'Milestone'
              }`
            : 'Select Milestone'}
        </Button>
        <Button
          onClick={() => setSplitButtonOpen((prev) => !prev)}
          ref={anchorRef}
          aria-label="select milestone"
          sx={{
            padding: '0 4px !important',
            minWidth: '24px',
            backgroundColor: selectedMilestoneId
              ? `${DEALROOMS_COLORS.woodsmoke} !important`
              : `${DEALROOMS_COLORS.cloudburst} !important`,
            color: `${DEALROOMS_COLORS.white} !important`,
            '& .MuiSvgIcon-root': {
              color: `${DEALROOMS_COLORS.white} !important`,
              fontSize: '16px',
            },
            '&:hover': {
              backgroundColor: selectedMilestoneId
                ? `${DEALROOMS_COLORS.woodsmoke} !important`
                : undefined,
            },
          }}
        >
          <ArrowDropDown />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{ zIndex: 1300 }}
        className="MuiMilestonePopper-root"
        open={splitButtonOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        placement="bottom-start"
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={() => setSplitButtonOpen(false)}>
                <MenuList autoFocusItem>
                  {milestoneOptions?.map((option) => (
                    <DSMenuItem
                      key={option.key}
                      selected={Number(option.key) === selectedMilestoneId}
                      onClick={() => {
                        onMilestoneSelected(Number(option.key));
                        setSplitButtonOpen(false);
                      }}
                      primaryText={option.text}
                    />
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};
