import React from 'react';
import {
    IconButton,
    Modal,
    Text,
    PrimaryButton,
    DefaultButton,
} from '@fluentui/react';
import { ChromeCloseIconProps } from '../../../../../../utils/iconProps';
import { MutualPlanTemplate } from './DSConfigurationMutualPlanTemplateCard';
import { dsConfigurationMutualPlanStyles } from '../styles';

interface DSConfigurationMutualPlanTemplateConfirmationModalProps {
    template: MutualPlanTemplate;
    hasExistingMilestones: boolean;
    onConfirm: () => void;
    onDismiss: () => void;
    isOpen: boolean;
}

export const DSConfigurationMutualPlanTemplateConfirmationModal: React.FC<DSConfigurationMutualPlanTemplateConfirmationModalProps> = ({
    template,
    hasExistingMilestones,
    onConfirm,
    onDismiss,
    isOpen,
}) => {
    const styles = dsConfigurationMutualPlanStyles;

    return (
        <Modal
            isOpen={isOpen}
            onDismiss={onDismiss}
            className={styles.modal}
        >
            <div className={styles.modalContainer}>
                <div className={styles.modalHeader}>
                    <Text className={styles.modalTitle}>{template.name}</Text>
                    <IconButton
                        className={styles.closeButton}
                        iconProps={ChromeCloseIconProps}
                        onClick={onDismiss}
                        ariaLabel="Close"
                    />
                </div>

                <Text className={styles.modalDescription}>{template.description}</Text>

                <div className={styles.milestonesContainer}>
                    <Text className={styles.milestonesHeader}>Milestones</Text>
                    {template.milestoneTitles.map((title, index) => (
                        <div key={index} className={styles.milestonesItem}>
                            {title}
                        </div>
                    ))}
                </div>

                {hasExistingMilestones && (
                    <div className={styles.warning}>
                        <Text className={styles.warningText}>
                            Warning: This action will replace all existing milestones and their associated tasks.
                            This action cannot be undone.
                        </Text>
                    </div>
                )}

                <div className={styles.buttonsContainer}>
                    <DefaultButton
                        text="Cancel"
                        onClick={onDismiss}
                        className={styles.closeButton}
                    />
                    <PrimaryButton
                        text="Apply Template"
                        onClick={onConfirm}
                    />
                </div>
            </div>
        </Modal>
    );
};
