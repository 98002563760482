import React, { Suspense } from 'react';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
// NOTE: without this PDFs will double render
import '@cyntler/react-doc-viewer/dist/index.css';
import { Spinner } from '@fluentui/react';
import { ImageWithFallback } from '../../../Common/ImageWithFallback';
import { ArtifactIconPreview } from '../ArtifactIconPreview';
import { OfficeArtifactPreview } from '../OfficeArtifactPreview';
import { ArtifactPreviewProps } from './types';
import { getYouTubeVideoId } from '../../utils/youtubeUtils';
import { DSLoomEmbed, DSYouTubeEmbed, DSVimeoEmbed } from '../../DS';
import {
  PreviewContainer,
  YouTubeContainer,
  YouTubeEmbed,
  AudioPreview,
  ImagePreview,
  VideoPreview,
  DocumentPreview,
  LoomContainer,
  VimeoContainer,
} from './ArtifactPreview.styles';
import { getLoomVideoId } from '../../utils/loomUtils';
import { getVimeoVideoId } from '../../utils/vimeoUtils';
import { DocViewerMemoizedWrapper } from './DocViewerMemoizedWrapper';

const OFFICE_MIME_TYPES = [
  'application/vnd.oasis.opendocument.text',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
];

/**
 * ArtifactPreview Component
 *
 * Renders different types of artifact previews based on the artifact type:
 * - Audio files
 * - Images
 * - Videos
 * - Documents (including Office files)
 * - Links
 */
export const ArtifactPreview: React.FC<ArtifactPreviewProps> = ({
  artifact,
  organizationSlug,
  dealRoomId,
}) => {
  const getPreview = () => {
    const youtubeVideoId =
      artifact.type === 'LINK' && artifact.url
        ? getYouTubeVideoId(artifact.url)
        : null;

    const loomVideoId =
      artifact.type === 'LINK' && artifact.url
        ? getLoomVideoId(artifact.url)
        : null;

    const vimeoVideoId =
      artifact.type === 'LINK' && artifact.url
        ? getVimeoVideoId(artifact.url)
        : null;

    switch (artifact.type) {
      case 'AUDIO':
        return (
          <AudioPreview key={artifact.fileUrl} controls preload="metadata">
            <source
              src={artifact.fileUrl}
              type={artifact.mimeType || 'audio/mp3'}
            />
            Your browser does not support the audio element.
          </AudioPreview>
        );
      case 'IMAGE':
        return (
          <ImagePreview>
            <ImageWithFallback
              src={artifact.fileUrl}
              alt={artifact.label || artifact.name}
              fallbackSrc={artifact.thumbnailUrl}
              fallback={<ArtifactIconPreview type="IMAGE" />}
              objectFit="contain"
            />
          </ImagePreview>
        );
      case 'VIDEO':
        return (
          <VideoPreview controls poster={artifact.thumbnailUrl || undefined}>
            <source src={artifact.fileUrl} />
          </VideoPreview>
        );
      case 'DOCUMENT':
        if (OFFICE_MIME_TYPES.includes(artifact.mimeType || '')) {
          return (
            <DocumentPreview>
              <OfficeArtifactPreview
                organizationSlug={organizationSlug}
                dealRoomId={dealRoomId}
                fileName={artifact.fileName!}
                label={artifact.label}
                name={artifact.name}
                fileUrl={artifact.fileUrl!}
                thumbnailUrl={artifact.thumbnailUrl}
              />
            </DocumentPreview>
          );
        }
        return (
          <DocumentPreview>
            <DocViewerMemoizedWrapper fileUrl={artifact.fileUrl!} />
          </DocumentPreview>
        );
      case 'LINK':
        if (youtubeVideoId) {
          return (
            <YouTubeContainer>
              <DSYouTubeEmbed
                videoId={youtubeVideoId}
                title={artifact.label || artifact.name}
              />
            </YouTubeContainer>
          );
        }

        if (loomVideoId) {
          return (
            <LoomContainer>
              <DSLoomEmbed
                videoId={loomVideoId}
                title={artifact.label || artifact.name}
              />
            </LoomContainer>
          );
        }

        if (vimeoVideoId) {
          return (
            <VimeoContainer>
              <DSVimeoEmbed
                videoId={vimeoVideoId}
                title={artifact.label || artifact.name}
              />
            </VimeoContainer>
          );
        }
        return (
          <a href={artifact.url} target="_blank" rel="noopener noreferrer">
            <ImagePreview>
              <ImageWithFallback
                src={
                  artifact.thumbnailUrl ||
                  `https://t0.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${artifact.url}&size=50`
                }
                fallbackSrc={
                  artifact.thumbnailUrl
                    ? `https://t0.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${artifact.url}&size=50`
                    : undefined
                }
                alt={artifact.label || artifact.name}
                fallback={<ArtifactIconPreview type="LINK" />}
                objectFit="contain"
              />
            </ImagePreview>
          </a>
        );
      default:
        return null;
    }
  };

  return (
    <Suspense fallback={<Spinner />}>
      <PreviewContainer>{getPreview()}</PreviewContainer>
    </Suspense>
  );
};
