/**
 * DecisionSiteUpNext Component
 *
 * Displays a carousel of upcoming and recent meetings in a deal room context.
 * Features:
 * - Shows meetings with deal room participants from past week to upcoming week
 * - Auto-scrolls to the most relevant meeting (last past or first upcoming)
 * - Updates position every 15 minutes to maintain time relevance
 * - Shows a "Now" indicator between past and upcoming meetings
 * - Supports both regular calendar events and meetingflow events
 * - Provides navigation to detailed meeting views
 */

import React, { useState, useEffect, useMemo } from 'react';
import { DateTime } from 'luxon';
import { useUserCalendar } from './Journey/hooks/useUserCalendar';
import {
  CalendarEvent,
  DetailedMeetingflow,
} from '@meetingflow/common/Api/data-contracts';
import DecisionSiteEventCard from '../../Organization/Library/DecisionSiteEventCard';
import { useOrganization } from '../../../Hooks/useOrganization';
import { DecisionSiteMeetingflowCard } from '../../MeetingPlans/DecisionSiteMeetingflowCard';
import { useAuth0 } from '@auth0/auth0-react';
import { useDealRoom } from '../../../Hooks/useDealRoom';
import { useNavigate } from 'react-router';
import { DSCarousel, DSTooltip } from '../DS';
import {
  UpNextContainer,
  EventCardContainer,
  Title,
  TitleIcon,
  ContentContainer,
  NowIndicator,
  NowIcon,
} from './DecisionSiteUpNext.styles';
import { Schedule } from '@mui/icons-material';

/**
 * DecisionSiteUpNext Component
 *
 * Displays a carousel of upcoming and recent meetings in a deal room context.
 *
 * @returns JSX.Element
 */
export const DecisionSiteUpNext: React.FC = () => {
  // Tracks current time, updated every 15 minutes to maintain timeline accuracy
  const [now, setNow] = useState(() => DateTime.now());

  // Update current time every 15 minutes
  useEffect(() => {
    const interval = setInterval(() => {
      setNow(DateTime.now());
    }, 900000); // 15 minutes

    return () => clearInterval(interval);
  }, []);

  const { slug: organizationSlug, organization } = useOrganization();
  const { user } = useAuth0();
  const { dealRoom } = useDealRoom();
  const navigate = useNavigate();

  // Query key for caching and identifying this component's calendar data
  const DecisionSiteUpNextQuery = (organizationSlug: string) =>
    `organization-${organizationSlug}-upcoming-meetings-decision-site-up-next`;

  // Fetch calendar events within a 2-week window centered on current time
  const {
    events,
    loading,
    dealRoomContactsData,
    isError,
    eventsError,
    dealRoomContactsError,
    meetingflowArtifacts,
    refetchEvents,
  } = useUserCalendar({
    searchString: '',
    minDate: now.minus({ weeks: 1 }),
    maxDate: now.plus({ weeks: 1 }),
    showInternal: true,
    queryKey: DecisionSiteUpNextQuery,
    hasMeetingflow: true,
  });

  // Refresh events when component mounts
  useEffect(() => {
    refetchEvents();
  }, [refetchEvents]);

  // Create a Set of deal room participant emails for efficient lookup
  // Excludes the current user's email from the set
  const dealRoomContactEmails = useMemo(
    () =>
      new Set(
        dealRoomContactsData?.data
          ?.filter(
            (contact) =>
              contact.email.toLowerCase() !== user?.email?.toLowerCase(),
          )
          .map((contact) => contact.email.toLowerCase()) || [],
      ),
    [dealRoomContactsData?.data, user?.email],
  );

  // Filter and sort events to show only those with deal room participants
  const sortedEvents = useMemo(() => {
    if (!events) return [];
    const dealRoomEvents = events.filter((event) =>
      event.attendees?.some(
        (attendee) =>
          attendee.email &&
          dealRoomContactEmails.has(attendee.email.toLowerCase()),
      ),
    );

    // Sort everything in chronological order and limit to 10 events
    return [...dealRoomEvents]
      .sort((a, b) =>
        DateTime.fromISO(a.startTime) > DateTime.fromISO(b.startTime) ? 1 : -1,
      )
      .slice(0, 10);
  }, [events, dealRoomContactEmails]);

  // Calculate initial scroll position to show most relevant meeting
  const initialScrollIndex = useMemo(() => {
    if (!sortedEvents.length) return 0;

    const firstUpcomingIndex = sortedEvents.findIndex(
      (event) => DateTime.fromISO(event.startTime) > now,
    );

    // If no upcoming events, show the last event
    if (firstUpcomingIndex === -1) return sortedEvents.length - 1;
    // If no past events, show the first event
    if (firstUpcomingIndex === 0) return 0;
    // Otherwise show the last past event
    return firstUpcomingIndex - 1;
  }, [sortedEvents, now]);

  // Handle error states
  if (isError || eventsError || dealRoomContactsError) {
    return null;
  }

  if (!organization?.slug) {
    return null;
  }

  // Navigation handler for meetingflow events
  const onSelectMeetingflow = (meetingflowId: DetailedMeetingflow['id']) => {
    navigate(
      `/organization/${organization.slug}/decisionsite/${dealRoom?.id}/journey?meeting=${meetingflowId}`,
    );
  };

  // Check if an event is already associated with the decision site
  const isEventInDecisionSite = (event: CalendarEvent) => {
    return (
      meetingflowArtifacts?.some(
        (artifact) => artifact.meetingflowId === event.meetingplanId,
      ) ?? false
    );
  };

  if (!sortedEvents.length) {
    return null;
  }

  // Render carousel with events and "Now" indicator
  return (
    <UpNextContainer>
      <Title variant="h6">
        <TitleIcon>
          <Schedule />
        </TitleIcon>
        Meetings
      </Title>
      <ContentContainer>
        <DSCarousel
          loading={loading}
          items={sortedEvents.flatMap((event, index) => {
            const key = event.meetingplanId || event.externalId;
            // Determine if this is the first upcoming event to show "Now" indicator
            const isFirstUpcomingEvent =
              index > 0 &&
              DateTime.fromISO(sortedEvents[index - 1].startTime) <= now &&
              DateTime.fromISO(event.startTime) > now;

            const elements: React.ReactNode[] = [];

            // Insert "Now" indicator before first upcoming event
            if (isFirstUpcomingEvent) {
              elements.push(
                <DSTooltip key="tooltip" title="Now">
                  <NowIndicator key="now">
                    <NowIcon>
                      <Schedule />
                    </NowIcon>
                  </NowIndicator>
                </DSTooltip>,
              );
            }

            // Add event card - either regular calendar event or meetingflow event
            elements.push(
              <EventCardContainer key={key}>
                <div>
                  {!event.meetingplanId && organizationSlug && dealRoom ? (
                    <DecisionSiteEventCard
                      event={event}
                      organizationSlug={organizationSlug}
                      hideNewMeetingflowButton
                      allowScheduleCallRecording
                      disableCreateViewMeetingflowOnClick
                      showAddToDecisionSiteButton={
                        !isEventInDecisionSite(event)
                      }
                    />
                  ) : event.meetingplanId ? (
                    <DecisionSiteMeetingflowCard
                      meetingflowId={event.meetingplanId}
                      hideCRMIcon
                      showAddToDecisionSiteButton={
                        !isEventInDecisionSite(event)
                      }
                      noTitleLink
                      onClick={onSelectMeetingflow}
                    />
                  ) : null}
                </div>
              </EventCardContainer>,
            );

            return elements;
          })}
          initialScrollIndex={Math.max(0, initialScrollIndex)}
        />
      </ContentContainer>
    </UpNextContainer>
  );
};

export default DecisionSiteUpNext;
