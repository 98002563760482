import { useAuth0 } from '@auth0/auth0-react';
import { useQuery, useQueryClient } from 'react-query';
import { isForbiddenError } from '../Helpers/AxiosHelpers';
import { OrganizationNotificationsSettingsQuery } from '../QueryNames';
import { DealRoomsApiClient } from '../Services/NetworkCommon';
import { useOrganizationSlug } from './useOrganizationSlug';
import { useDealRoomId } from './useDealRoomId';
import { useUserProfile } from './useProfile';
import { useCallback } from 'react';

export const useNotificationSettings = (
  orgSlug?: string,
  dealRoomId?: number,
) => {
  const { getAccessTokenSilently } = useAuth0();
  const client = useQueryClient();
  const { user: userProfile } = useUserProfile();

  const slg = useOrganizationSlug();
  const orgSlugFinal = orgSlug || slg;
  const drId = useDealRoomId();
  const dealRoomIdFinal = dealRoomId || drId;

  const {
    data: notificationSettings,
    isLoading: notificationSettingsLoading,
    isFetched: notificationSettingsIsFetched,
    isError: notificationSettingsIsError,
    error: notificationSettingsError,
    refetch: refetchNotificationSettings,
  } = useQuery(
    OrganizationNotificationsSettingsQuery(orgSlugFinal, dealRoomIdFinal),
    async () => {
      const token = await getAccessTokenSilently();
      return DealRoomsApiClient.listUserNotificationPreferences(
        orgSlugFinal!,
        dealRoomIdFinal!,
        userProfile?.email || '',
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      enabled:
        !!orgSlugFinal &&
        !!dealRoomIdFinal &&
        !isNaN(dealRoomIdFinal) &&
        !!userProfile?.email,
      retry: (failureCount, error) => {
        if (isForbiddenError(error)) {
          return false;
        }
        return failureCount < 3;
      },
    },
  );

  const refetchAll = useCallback(async () => {
    await client.invalidateQueries(
      OrganizationNotificationsSettingsQuery(orgSlugFinal, dealRoomIdFinal),
    );
  }, [client, dealRoomIdFinal, orgSlugFinal]);

  return {
    notificationSettings: notificationSettings?.data,
    notificationSettingsLoading,
    notificationSettingsIsFetched,
    notificationSettingsIsError,
    notificationSettingsError,
    refetchNotificationSettings,
    refetchAll,
  };
};
