import { useAuth0 } from '@auth0/auth0-react';
import { useQuery, useQueryClient } from 'react-query';
import { isForbiddenError } from '../Helpers/AxiosHelpers';
import { OrganizationNotificationsDataQuery } from '../QueryNames';
import { DealRoomsApiClient } from '../Services/NetworkCommon';
import { useOrganizationSlug } from './useOrganizationSlug';
import { useDealRoomId } from './useDealRoomId';
import { useUserProfile } from './useProfile';
import { useCallback, useRef, useMemo } from 'react';
import {
  DealRoomNotificationStatusType,
  DealRoomNotification,
} from '@meetingflow/common/Api/data-contracts';
import toast from 'react-hot-toast';
import { getNotificationTitle } from '../Components/DealRoom/Tabs/Inbox/utils';
import { useCollabProvider } from '../Hooks/useCollabProvider';
import { WS_MESSAGE } from '@meetingflow/common/Constants';
import * as Y from 'yjs';
import randomColor from 'randomcolor';
import { useNavigate } from './useNavigate';
import { DEALROOMS_COLORS } from '../Themes/Themes';
import { MarkUnreadChatAlt } from '@mui/icons-material';

export const useNotificationData = (
  orgSlug?: string,
  dealRoomId?: number,
  showNotificationPopups: boolean = false,
) => {
  const { getAccessTokenSilently } = useAuth0();
  const client = useQueryClient();
  const { user: userProfile } = useUserProfile();
  const { user } = useAuth0();
  const navigate = useNavigate();

  const slg = useOrganizationSlug();
  const orgSlugFinal = orgSlug || slg;
  const drId = useDealRoomId();
  const dealRoomIdFinal = dealRoomId || drId;

  // Setup real-time collaboration
  const ydoc = useMemo(() => {
    return new Y.Doc();
  }, []);

  const color = useMemo(
    () =>
      randomColor({
        luminosity: 'dark',
        format: 'rgba',
        alpha: 1,
      }),
    [],
  );

  const onMessage = useCallback(
    (messageType: number) => {
      switch (messageType) {
        case WS_MESSAGE.REFRESH_DEALROOM_NOTIFICATIONS:
          client.invalidateQueries(
            OrganizationNotificationsDataQuery(orgSlugFinal, dealRoomIdFinal),
          );
          return true;
        default:
          return false;
      }
    },
    [client, dealRoomIdFinal, orgSlugFinal],
  );

  // Only initialize collab provider if we have both orgSlug and dealRoomId
  const shouldInitCollab =
    !!orgSlugFinal && !!dealRoomIdFinal && !isNaN(dealRoomIdFinal);

  const { provider: _provider } = useCollabProvider({
    providerName: 'DealRoom',
    documentName:
      orgSlugFinal && dealRoomIdFinal
        ? `DealRoom__${orgSlugFinal}__${dealRoomIdFinal}`
        : '',
    ydoc,
    color,
    email: userProfile?.email || user!.email!,
    name: userProfile?.name || user!.name,
    picture:
      userProfile?.avatarFileUrl || userProfile?.avatarUrl || user?.picture,
    onMessage,
    connect: shouldInitCollab,
  });

  const previousNotificationsRef = useRef<DealRoomNotification[]>([]);
  const isInitialLoadRef = useRef(true);

  const {
    data: notificationData,
    isLoading: notificationDataLoading,
    isFetched: notificationDataIsFetched,
    isError: notificationDataIsError,
    error: notificationDataError,
    refetch: refetchNotificationData,
  } = useQuery(
    OrganizationNotificationsDataQuery(orgSlugFinal, dealRoomIdFinal),
    async () => {
      const token = await getAccessTokenSilently();
      return DealRoomsApiClient.listUserNotifications(
        orgSlugFinal!,
        dealRoomIdFinal!,
        userProfile?.email || '',
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      enabled:
        !!orgSlugFinal &&
        !!dealRoomIdFinal &&
        !isNaN(dealRoomIdFinal) &&
        !!userProfile?.email,
      refetchInterval: 5 * 60 * 1000, // Poll every 5 minutes
      refetchIntervalInBackground: true, // Continue polling even when the tab is in the background
      retry: (failureCount, error) => {
        if (isForbiddenError(error)) {
          return false;
        }
        return failureCount < 3;
      },
      onSuccess: (data) => {
        const currentNotifications = (data?.data?.notifications ||
          []) as DealRoomNotification[];
        const previousNotifications = previousNotificationsRef.current;

        // Only show toasts if this isn't the initial load
        if (!isInitialLoadRef.current) {
          // Find new notifications (any that weren't in the previous list)
          const newNotifications = currentNotifications.filter(
            (current) =>
              !previousNotifications.some((prev) => prev.id === current.id),
          );

          // Show toast for new notifications
          if (newNotifications.length > 0 && showNotificationPopups) {
            const message =
              newNotifications.length === 1
                ? `${getNotificationTitle(newNotifications[0])}`
                : `${newNotifications.length} new messages`;

            const inboxLink = `/organization/${orgSlugFinal}/decisionsite/${dealRoomIdFinal}/inbox`;

            toast(
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => navigate(inboxLink)}
              >
                {message}
              </span>,
              {
                duration: 4000,
                icon: (
                  <MarkUnreadChatAlt
                    style={{ color: DEALROOMS_COLORS.cloudburst }}
                  />
                ),
              },
            );
          }
        }

        // Update previous notifications reference
        previousNotificationsRef.current = currentNotifications;
        isInitialLoadRef.current = false;
      },
    },
  );

  const refetchAll = useCallback(async () => {
    await client.invalidateQueries(
      OrganizationNotificationsDataQuery(orgSlugFinal, dealRoomIdFinal),
    );
  }, [client, dealRoomIdFinal, orgSlugFinal]);

  const bulkUpdateNotifications = useCallback(
    async (
      notificationIds: string[],
      status: DealRoomNotificationStatusType,
    ) => {
      if (!orgSlugFinal || !dealRoomIdFinal || !userProfile?.email) {
        return;
      }

      try {
        const token = await getAccessTokenSilently();

        // Create the payload for bulk update
        const updatePayload = notificationIds.map((id) => ({
          id,
          status,
        }));

        await DealRoomsApiClient.bulkUpdateUserNotifications(
          orgSlugFinal,
          dealRoomIdFinal,
          updatePayload,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );

        // Refetch to get updated data
        await refetchAll();
      } catch (error) {
        toast.error('Failed to update notifications');
      }
    },
    [
      orgSlugFinal,
      dealRoomIdFinal,
      userProfile?.email,
      getAccessTokenSilently,
      refetchAll,
    ],
  );

  return {
    notificationData: (notificationData?.data || { notifications: [] })
      .notifications as DealRoomNotification[],
    unreadCount: (
      (notificationData?.data || { notifications: [] })
        .notifications as DealRoomNotification[]
    ).filter((n) => n.status === 'UNREAD').length,
    notificationDataLoading,
    notificationDataIsFetched,
    notificationDataIsError,
    notificationDataError,
    refetchNotificationData,
    refetchAll,
    bulkUpdateNotifications,
  };
};
