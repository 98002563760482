import useStore from '../../../../Stores/userSettingsStore';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';

import { mergeStyles, Pivot, PivotItem } from '@fluentui/react';
import { TextField, InputAdornment } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import {
  buyerOrientationSurveyOptions,
  optionValuesThatNeedPhoneNumber,
} from '../BuyerOrientationSurvey/buyerOrientationSurveyUtils';
import { SurveyOptionCard } from '../BuyerOrientationSurvey/SurveyOptionCard';
import { UserDetails } from '../../../../types/BuyerOrientationSurveyTypes';
import { useUserProfile } from '../../../../Hooks/useProfile';
import classNames from 'classnames';
import { DecisionSitePhoneNumberField } from '../DecisionSitePhoneNumberField';

interface UserSettingsBuyerPrefProps {
  isSaving: boolean;
  currentUserDetails: UserDetails;
  buyerPrefsState: { [key: string]: string | null };
  refetchDealRoom: () => unknown | Promise<unknown>;
}

export const UserSettingsBuyerPref = ({
  isSaving,
  currentUserDetails,
  buyerPrefsState,
  refetchDealRoom,
}: UserSettingsBuyerPrefProps) => {
  const { user } = useUserProfile();

  return (
    <UserSettingsBuyerPrefComms
      isSaving={isSaving}
      currentUserDetails={currentUserDetails}
      buyerPrefsState={buyerPrefsState}
      refetchDealRoom={refetchDealRoom}
    />
  );
};

const UserSettingsBuyerPrefComms = ({
  isSaving,
  currentUserDetails,
  buyerPrefsState,
}: UserSettingsBuyerPrefProps) => {
  // Styles for the buyer preferences UI
  const buyerPrefStyle = mergeStyles({
    // Section container styles
    '.buyer-pref-section': {
      marginBottom: '1rem',
      containerType: 'inline-size',

      // Section title
      '>div:first-child': {
        color: DEALROOMS_COLORS.themePrimary,
        fontSize: '1rem',
        paddingBottom: '1rem',
        marginBottom: '0.5rem',
      },

      // Options container - holds the preference cards
      '>div:nth-child(2)': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        justifyContent: 'flex-start',
        gap: '1.5rem',
        paddingBottom: '1rem',
        '@container (max-width: 595px)': {
          flexDirection: 'column',
          justifyContent: 'stretch',
        },
      },
    },

    // Remove padding from last section
    '.buyer-pref-section:last-child >div:nth-child(2)': {
      padding: 0,
    },

    // Set max widths for different sections
    '.buyer-pref-section:nth-child(1) >div:nth-child(2) >*': {
      maxWidth: '16rem',
      '@container (max-width: 595px)': {
        maxWidth: 'initial',
      },
    },
    '.buyer-pref-section:nth-child(2) >div:nth-child(2) >*, .buyer-pref-section:nth-child(3) >div:nth-child(2) >*':
      {
        maxWidth: '11.6rem',
        '@container (max-width: 595px)': {
          maxWidth: 'initial',
        },
      },

    // Phone number input field styles
    '.communication-phone-main-container': {
      position: 'relative',
      marginTop: '0.5rem',

      '.communication-phone-icon': {
        color: DEALROOMS_COLORS.themeSecondary,
        position: 'absolute',
        bottom: '.6rem',
        left: '.75rem',
        zIndex: 1,
      },

      '.invalid-phone-number': {
        bottom: '1.8rem',
      },

      '.communication-phone-text-field': {
        maxWidth: '51rem',
        '.ms-Label': {
          fontWeight: '400',
          color: DEALROOMS_COLORS.themePrimary,
        },
        '.ms-TextField-fieldGroup': {
          border: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
          borderRadius: '4px',
        },
        input: {
          minHeight: '1.8rem',
          fontSize: '1rem',
          fontWeight: '400',
          paddingLeft: '0.5rem',
          '::placeholder': {
            color: DEALROOMS_COLORS.userSurveyInputPlaceholder,
          },
        },
      },
    },
  });

  // Get country code from store
  const preferencesPhoneCountryCode = useStore(
    (state) => state.preferencesPhoneCountryCode,
  );
  // Set country code from store
  const setPreferencesPhoneCountryCode = useStore(
    (state) => state.setPreferencesPhoneCountryCode,
  );

  const handleSelectOption = useStore((state) => state.setBuyerPrefs);

  // Helper function to check if an option should be disabled
  const isOptionDisabled = (
    optionValue: string,
    currentPref: string | null,
    buyerPref: string | null,
  ) => {
    return (
      isSaving ||
      (!!buyerPref && optionValue !== buyerPref) ||
      (!!currentPref && !buyerPref && optionValue !== currentPref)
    );
  };

  // Helper function to get current preference value
  const getCurrentPreference = (
    buyerPref: string | null,
    currentPref: string | null,
  ) => {
    return buyerPref || currentPref;
  };

  // Get current phone number value
  const phoneNumber =
    'phoneNumber' in buyerPrefsState
      ? buyerPrefsState.phoneNumber || ''
      : currentUserDetails?.phoneNumber || '';

  // Helper function to render preference section
  const renderPreferenceSection = (
    title: string,
    prefKey:
      | 'assistancePreference'
      | 'consumptionPreference'
      | 'communicationPreference',
    options: (typeof buyerOrientationSurveyOptions)[typeof prefKey]['options'],
  ) => (
    <div className="buyer-pref-section">
      <div>{title}</div>
      <div>
        {options.map((option) => (
          <SurveyOptionCard
            key={option.optionId}
            className="card"
            option={option}
            isSelected={
              option.value ===
              getCurrentPreference(
                buyerPrefsState[prefKey],
                currentUserDetails?.[prefKey] || null,
              )
            }
            handleSelectOption={(value) => {
              if (isSaving) return;
              handleSelectOption(prefKey, value);
              if (
                prefKey === 'communicationPreference' &&
                optionValuesThatNeedPhoneNumber.includes(value) &&
                phoneNumber
              ) {
                handleSelectOption('phoneNumber', phoneNumber);
              }

              if (
                prefKey === 'communicationPreference' &&
                !optionValuesThatNeedPhoneNumber.includes(value)
              ) {
                handleSelectOption(
                  'phoneNumber',
                  currentUserDetails?.phoneNumber || '',
                );
              }
            }}
            isDisabled={isOptionDisabled(
              option.value,
              currentUserDetails?.[prefKey] || null,
              buyerPrefsState[prefKey],
            )}
          />
        ))}
      </div>
    </div>
  );

  return (
    <div className={buyerPrefStyle}>
      {/* Assistance preference section */}
      {renderPreferenceSection(
        'How much help do you want from me?',
        'assistancePreference',
        buyerOrientationSurveyOptions.assistancePreference.options,
      )}

      {/* Content consumption preference section */}
      {renderPreferenceSection(
        'How do you like to consume content?',
        'consumptionPreference',
        buyerOrientationSurveyOptions.consumptionPreference.options,
      )}

      {/* Communication preference section */}
      {renderPreferenceSection(
        "What's your preferred method of communication?",
        'communicationPreference',
        buyerOrientationSurveyOptions.communicationPreference.options,
      )}

      {/* Phone number input field - only shown when needed */}
      <div>
        {optionValuesThatNeedPhoneNumber.includes(
          getCurrentPreference(
            buyerPrefsState.communicationPreference,
            currentUserDetails?.communicationPreference || null,
          ) || '',
        ) && (
          <div className="communication-phone-main-container">
            <DecisionSitePhoneNumberField
              value={phoneNumber || ''}
              onChange={(newPhoneNumber) => {
                handleSelectOption('phoneNumber', newPhoneNumber);
                if (
                  !buyerPrefsState.communicationPreference &&
                  currentUserDetails?.communicationPreference
                ) {
                  handleSelectOption(
                    'communicationPreference',
                    currentUserDetails.communicationPreference,
                  );
                }
              }}
              country={preferencesPhoneCountryCode}
              setCountry={setPreferencesPhoneCountryCode}
              label="Phone Number"
              containerSx={{
                height: '3rem',
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};
