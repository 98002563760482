import React, { useState } from 'react';

import { useQuery } from 'react-query';
import { Box, Chip, CircularProgress, styled } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import toast from 'react-hot-toast';

import { DEALROOMS_COLORS } from '../../../../../Themes/Themes';
import { SendInviteIllustrations } from '../UserSettingsIllustrations';
import { DealRoomContactsQuery } from '../../../../../QueryNames';
import { DealRoomsApiClient } from '../../../../../Services/NetworkCommon';
import { useDealRoom } from '../../../../../Hooks/useDealRoom';
import { useOrganization } from '../../../../../Hooks/useOrganization';
import { useUserProfile } from '../../../../../Hooks/useProfile';
import useDeviceType from '../../../../../Hooks/deviceDetection';
import {
  RemoveCircle,
  RemoveModerator,
  PersonAdd,
  Message,
} from '@mui/icons-material';
import { UserRemoveIcon } from '@fluentui/react-icons-mdl2';
import { useOrganizationSlug } from '../../../../../Hooks/useOrganizationSlug';
import { DSButton, DSTextField } from '../../../../../Components/DealRoom/DS';
import { ITag } from '@fluentui/react';

// List of common non-work email domains
const NON_WORK_DOMAINS = [
  'yahoo.com',
  'hotmail.com',
  'outlook.com',
  'aol.com',
  'icloud.com',
  'mail.com',
  'protonmail.com',
  'zoho.com',
  'yandex.com',
];

const emailPattern = /^[a-zA-Z0-9._%+-]{2,}@[a-zA-Z0-9.-]{2,}\.[a-zA-Z]{2,}$/;

interface UserSettingsInviteFormProps {
  isSaving: boolean;
  setIsSaving: (isSaving: boolean) => void;
  refetchContacts: () => Promise<unknown>;
}

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5rem',
}));

const MessageInput = styled(DSTextField)({
  '& .MuiInputBase-root': {
    alignItems: 'start',
    backgroundColor: DEALROOMS_COLORS.white,
  },
  '& .MuiInputBase-input': {
    height: '32px',
    fontSize: '14px',
    marginLeft: '8px',
    '&::placeholder': {
      color: DEALROOMS_COLORS.themeSecondary,
      opacity: 1,
    },
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
      borderRadius: '4px',
      boxShadow: 'none',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
      borderRadius: '4px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
      borderRadius: '4px',
    },
  },
});

const EmailInputWrapper = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '8px',
  alignItems: 'center',
  padding: '8px 12px',
  minHeight: '32px',
  fontSize: '14px',
  border: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
  borderRadius: '4px',
  backgroundColor: DEALROOMS_COLORS.white,
  '& .MuiSvgIcon-root': {
    width: '16px',
    height: '16px',
    color: DEALROOMS_COLORS.themeSecondary,
  },
});

const EmailChip = styled(Chip)({
  height: '32px',
  fontSize: '14px',
  backgroundColor: DEALROOMS_COLORS.inputLightGray,
  borderRadius: '4px',
  '& .MuiChip-label': {
    color: DEALROOMS_COLORS.themePrimary,
  },
  '& .MuiChip-deleteIcon': {
    backgroundColor: DEALROOMS_COLORS.inputLightGray,
  },
});

const StyledInput = styled('input')({
  flex: '1 0 50px',
  minWidth: '50px',
  border: 'none',
  outline: 'none',
  backgroundColor: 'transparent',
  fontSize: '14px',
  height: '32px',
  padding: '0px 8px',
  '&::placeholder': {
    color: DEALROOMS_COLORS.themeSecondary,
  },
});

export const UserSettingsInviteForm = ({
  isSaving,
  setIsSaving,
  refetchContacts,
}: UserSettingsInviteFormProps) => {
  const [emailsToInvite, setEmailsToInvite] = useState<ITag[]>([]);
  const [currentInput, setCurrentInput] = useState<string>('');
  const [inviteMessage, setInviteMessage] = useState<string>('');
  const { internalDomains, role: orgRole } = useOrganization();
  const { dealRoomId, refetch } = useDealRoom();
  const { getAccessTokenSilently } = useAuth0();
  const organizationSlug = useOrganizationSlug();

  const emailToInviteIsSeller = emailsToInvite.some(
    (email) =>
      internalDomains.includes(email.name.split('@')[1].toLowerCase()) &&
      ['ADMIN', 'CREATOR', 'COLLABORATOR'].includes(orgRole || ''),
  );

  const isWorkEmail = (email: string) => {
    if (!email) return false;
    if (!emailPattern.test(email)) return false;
    const domain = email.split('@')[1].toLowerCase();
    return !NON_WORK_DOMAINS.includes(domain);
  };

  const handleEachInvite = async (inviteEmail: string) => {
    const token = await getAccessTokenSilently();
    try {
      await DealRoomsApiClient.addContactToDealRoom(
        organizationSlug!,
        dealRoomId!,
        inviteEmail,
        {
          role: emailToInviteIsSeller ? 'SELLER' : 'BUYER',
          // @ts-ignore
          message: inviteMessage,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    } catch (e) {
      toast.error(`Something went wrong inviting ${inviteEmail}.`);
    }
  };

  const handleEmailRemove = (emailToRemove: ITag) => {
    setEmailsToInvite(
      emailsToInvite.filter((email) => email.key !== emailToRemove.key),
    );
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' || event.key === ',' || event.key === ' ') {
      event.preventDefault();
      const email = currentInput.trim();
      if (email && isWorkEmail(email)) {
        setEmailsToInvite([...emailsToInvite, { key: email, name: email }]);
        setCurrentInput('');
      } else if (email) {
        toast.error('Please enter a valid work email address');
      }
    }
  };

  const handleInvite = async () => {
    const domains = emailsToInvite.map((email) =>
      email.name.split('@')[1].toLowerCase(),
    );
    if (
      domains.some((emailDomain) => NON_WORK_DOMAINS.includes(emailDomain)) ||
      NON_WORK_DOMAINS.includes(currentInput)
    ) {
      toast.error('Please enter valid work email addresses.');
      return;
    }

    const emails = emailsToInvite.map((email) => email.name);

    if (currentInput.length > 0) {
      emails.push(currentInput);
    }

    setIsSaving(true);
    try {
      await Promise.allSettled(emails.map(handleEachInvite));

      // Refresh the list of contacts
      await refetch();
      await refetchContacts();
    } catch (e) {
      toast.error('Something went wrong inviting the contacts.');
    }
    setIsSaving(false);

    // reset invited emails
    setCurrentInput('');
    setInviteMessage('');
    setEmailsToInvite([]);
  };

  if (!organizationSlug) {
    return;
  }

  return (
    <StyledBox>
      <EmailInputWrapper>
        <PersonAdd />
        {emailsToInvite.map((email) => (
          <EmailChip
            key={email.key}
            label={email.name}
            onDelete={() => handleEmailRemove(email)}
          />
        ))}
        <StyledInput
          value={currentInput}
          onChange={(e) => setCurrentInput(e.target.value.toLowerCase())}
          onKeyDown={handleKeyDown}
          disabled={isSaving}
          placeholder={
            emailsToInvite.length === 0 ? 'Enter work email addresses' : ''
          }
        />
      </EmailInputWrapper>

      <MessageInput
        multiline
        minRows={4}
        placeholder="Add a message to the invite email"
        value={inviteMessage}
        onChange={(e) => setInviteMessage(e.target.value)}
        disabled={isSaving}
        fullWidth
        InputProps={{
          startAdornment: (
            <Message
              sx={{
                width: '16px',
                height: '16px',
                color: DEALROOMS_COLORS.themeSecondary,
                marginRight: '8px',
                marginTop: '4px',
              }}
            />
          ),
        }}
      />

      <DSButton
        variant="contained"
        onClick={handleInvite}
        disabled={
          // if there are no emails in the list and no text in the input, or
          // if there are emails in the list but not all of them are valid, or
          // if there is text in the input but it is not a valid email
          (!emailsToInvite.length && !currentInput) ||
          (!!emailsToInvite.length &&
            !emailsToInvite.every((email) => isWorkEmail(email.name))) ||
          (!!currentInput && !isWorkEmail(currentInput)) ||
          isSaving
        }
        sx={{
          alignSelf: 'flex-start',
          position: 'relative',
          minWidth: '100px',
        }}
      >
        {isSaving ? <CircularProgress size={20} /> : 'Invite'}
      </DSButton>
    </StyledBox>
  );
};
