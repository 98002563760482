import { mergeStyleSets, Spinner } from '@fluentui/react';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Milestone } from '../Milestones/Milestone';
import { MilestoneActionItemPanel } from '../Milestones/MilestoneActionItemPanel';
import { useBoolean } from '@fluentui/react-hooks';
import { useMutualPlan } from '../../../Hooks/useMutualPlan';
import { NoMilestonesAvailable } from '../Milestones/NoMilestonesAvailable';
import { DealRoomMilestone } from '@meetingflow/common/Api/data-contracts';
import { useTitle } from '../../../Hooks/useTitle';
import { DealRoomTaskCommentsContainer } from '../Milestones/TaskComments/DealRoomTaskCommentsContainer';
import { useOrganization } from '../../../Hooks/useOrganization';
import { MilestonesActionItemsFilters } from '../Milestones/MilestonesActionItemsFilters/MilestonesActionItemsFilters';
import { useSearchParams } from 'react-router-dom';
import DSResponsiveDrawer from '../../../Components/Common/DSResponsiveDrawer';
import SuggestedTaskList from '../Components/MutualPlan/SuggestedTasks';
import SuggestedTasksHeader from '../Components/MutualPlan/SuggestedTasksHeader';

type DealRoomMutualProgressTabProps = {
  organizationSlug: string;
  dealRoomId: number;
};

export const DealRoomMutualProgressTab = ({
  organizationSlug,
  dealRoomId,
}: DealRoomMutualProgressTabProps) => {
  useTitle('Mutual Plan');
  const { internalDomains } = useOrganization();

  const {
    mutualPlan,
    refetch: refetchMutualPlan,
    isLoading,
  } = useMutualPlan(organizationSlug, dealRoomId);

  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedMilestone, setSelectedMilestone] =
    useState<DealRoomMilestone | null>(null);
  const [selectedActionItemId, setSelectedActionItem] = useState<number | null>(
    null,
  );
  const [noActionItemPanelKey, setNoActionItemPanelKey] = useState(Date.now());
  const [
    isEditing,
    { setTrue: setIsEditingTask, setFalse: setIsNotEditingTask },
  ] = useBoolean(false);
  const [taskIdForComments, setTaskIdForComments] = useState<number | null>(
    null,
  );

  // Get task ID from URL parameters
  const selectedTaskId = searchParams.get('task')
    ? Number(searchParams.get('task'))
    : null;

  // Get suggested panel state from URL parameters
  const isSuggestedPanelOpen = searchParams.get('suggested') === 'true';

  const handleSuggestedPanelClose = () => {
    const newParams = new URLSearchParams(searchParams);
    newParams.delete('suggested');
    setSearchParams(newParams, { replace: true });
  };

  // Find the milestone containing the task when loading from URL
  useEffect(() => {
    if (selectedTaskId && mutualPlan?.milestones && !selectedMilestone) {
      const milestoneWithTask = mutualPlan.milestones.find((milestone) =>
        milestone.actionItems?.some((item) => item.id === selectedTaskId),
      );
      if (milestoneWithTask) {
        setSelectedMilestone(milestoneWithTask);
      }
    }
  }, [selectedTaskId, mutualPlan?.milestones, selectedMilestone]);

  const handleActionItemClick = (
    actionItemId: number,
    milestone: DealRoomMilestone,
    isEditing: boolean,
  ) => {
    // Update URL with task parameter
    const newParams = new URLSearchParams(searchParams);
    newParams.set('task', actionItemId.toString());
    setSearchParams(newParams, { replace: true });

    handleSelectMilestoneForAddTask(milestone);
    if (isEditing) {
      setIsEditingTask();
    }
  };

  // Used to know which milestone to add the task to and to have correct milestone data in the task panel
  const handleSelectMilestoneForAddTask = (
    currentMilestone: DealRoomMilestone,
  ) => {
    setSelectedMilestone(currentMilestone);
  };

  const handlePanelDismiss = () => {
    // Remove task parameter from URL
    const newParams = new URLSearchParams(searchParams);
    newParams.delete('task');
    setSearchParams(newParams, { replace: true });

    setSelectedMilestone(null);
    setIsNotEditingTask();
  };

  const handleUpdateSelectedMilestone = useCallback(
    (milestoneId: number) => {
      if (!Array.isArray(mutualPlan?.milestones)) return;

      const currentMilestone = mutualPlan.milestones.find(
        (milestone) => milestone.id === milestoneId,
      );

      if (!currentMilestone) return;

      setSelectedMilestone(currentMilestone);
    },
    [mutualPlan?.milestones],
  );

  const visibleMilestones = useMemo(() => {
    return (mutualPlan?.milestones || [])
      .filter((milestone) => milestone.visible)
      .sort((a, b) => {
        if (a.dueDate === null && b.dueDate === null) return 0;
        if (a.dueDate === null) return 1;
        if (b.dueDate === null) return -1;
        return new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime();
      });
  }, [mutualPlan?.milestones]);

  const styles = mergeStyleSets({
    mutualProgressWrapper: {
      border: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
      borderRadius: '.25rem',
      backgroundColor: DEALROOMS_COLORS.white,
      gap: '2rem',
      // Hide empty MS Layer elements that get injected by Fluent UI
      '.ms-layer:empty': {
        display: 'none',
      },
    },
    milestonesList: {
      display: 'flex',
      flexDirection: 'column',
      padding: '1.5rem',
      gap: '1.5rem',
    },
  });

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <div className={styles.mutualProgressWrapper}>
        <MilestonesActionItemsFilters
          organizationSlug={organizationSlug}
          dealRoomId={dealRoomId}
        />

        <div className={styles.milestonesList}>
          {visibleMilestones?.length ? (
            visibleMilestones.map((milestone) => (
              <Milestone
                organizationSlug={organizationSlug}
                dealRoomId={dealRoomId}
                milestone={milestone}
                key={milestone.id}
                onActionItemClick={handleActionItemClick}
                handleSelectMilestoneForAddTask={
                  handleSelectMilestoneForAddTask
                }
                setNoActionItemPanelKey={setNoActionItemPanelKey}
                setTaskIdForComments={setTaskIdForComments}
              />
            ))
          ) : (
            <NoMilestonesAvailable />
          )}
        </div>
        <DSResponsiveDrawer
          anchor="right"
          widthPercentage={70}
          mobileWidthPercentage={90}
          open={Boolean(selectedTaskId) || Boolean(selectedMilestone)}
          padding={'0'}
        >
          <MilestoneActionItemPanel
            key={selectedTaskId ?? noActionItemPanelKey}
            isOpen={selectedTaskId !== null || selectedMilestone !== null}
            onDismiss={handlePanelDismiss}
            currentActionItemId={selectedTaskId}
            organizationSlug={organizationSlug}
            dealRoomId={dealRoomId}
            parentMilestone={selectedMilestone}
            isEditing={isEditing}
            handleUpdateSelectedMilestone={handleUpdateSelectedMilestone}
            // handleUpdateSelectedMilestoneBasedOnType={
            //   handleUpdateSelectedMilestoneBasedOnType
            // }
          />
        </DSResponsiveDrawer>

        <DSResponsiveDrawer
          anchor="right"
          widthPercentage={70}
          mobileWidthPercentage={90}
          open={isSuggestedPanelOpen}
          onClose={handleSuggestedPanelClose}
          padding={'0'}
        >
          <>
            <SuggestedTasksHeader onClose={handleSuggestedPanelClose} />
            <div
              style={{
                padding: '2rem',
              }}
            >
              <SuggestedTaskList />
            </div>
          </>
        </DSResponsiveDrawer>

        <DealRoomTaskCommentsContainer
          key={taskIdForComments}
          currentTaskId={taskIdForComments}
          onDismiss={() => setTaskIdForComments(null)}
          organizationSlug={organizationSlug}
          dealRoomId={dealRoomId}
        />
      </div>
    </>
  );
};
