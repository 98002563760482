import { mergeStyles } from '@fluentui/react';
import {
  DealRoom,
  DealRoomArtifact,
  DetailedDealRoom,
  Organization,
} from '@meetingflow/common/Api/data-contracts';
import classNames from 'classnames';
import { DealRoomArtifacts } from '../Artifacts/DealRoomArtifacts';
import { useSearchParams } from 'react-router-dom';
import { useCallback } from 'react';
import { DSResponsiveDrawer } from '../../Common/DSResponsiveDrawer';
import { DSArtifactPanel } from '../Artifacts/DSArtifactPanel';

type DealRoomBuyersArtifactsTabProps = {
  className?: string;
  organizationSlug: Organization['slug'];
  dealRoomId: DealRoom['id'];
  dealRoom: Pick<DetailedDealRoom, 'activity' | 'artifacts'>;
  refreshDealRoom: () => Promise<unknown>;
};

export const DealRoomBuyersArtifactsTab = ({
  className,
  organizationSlug,
  dealRoomId,
  dealRoom,
  refreshDealRoom,
}: DealRoomBuyersArtifactsTabProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const styles = mergeStyles({
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  });

  const handleClickArtifact = useCallback(
    (id: DealRoomArtifact['id']) => {
      const artifact = dealRoom.artifacts.find((a) => a.id === id);

      if (!artifact) {
        return;
      }

      // Update URL with artifact parameter
      const newParams = new URLSearchParams(searchParams);
      newParams.set('artifact', id.toString());
      setSearchParams(newParams, { replace: true });
    },
    [dealRoom.artifacts, searchParams, setSearchParams],
  );

  const selectedArtifactId = searchParams.get('artifact')
    ? Number(searchParams.get('artifact'))
    : null;
  const selectedArtifact = selectedArtifactId
    ? dealRoom.artifacts.find((a) => a.id === selectedArtifactId)
    : undefined;

  const handleClosePanel = useCallback(() => {
    const newParams = new URLSearchParams(searchParams);
    newParams.delete('artifact');
    setSearchParams(newParams, { replace: true });
  }, [searchParams, setSearchParams]);

  return (
    <div className={classNames(className, styles)}>
      <DealRoomArtifacts
        organizationSlug={organizationSlug}
        dealRoomId={dealRoomId}
        activity={dealRoom.activity}
        refreshDealRoom={refreshDealRoom}
        onClick={handleClickArtifact}
        onAdd={(_) => refreshDealRoom()}
        onDelete={(_) => refreshDealRoom()}
      />
      <DSResponsiveDrawer
        anchor="right"
        open={!!selectedArtifactId}
        onClose={handleClosePanel}
        widthPercentage={70}
        mobileWidthPercentage={90}
        padding={'0'}
      >
        {selectedArtifactId && (
          <>
            <DSArtifactPanel
              artifactId={selectedArtifactId}
              onClickCloseButton={handleClosePanel}
            />
          </>
        )}
      </DSResponsiveDrawer>
    </div>
  );
};
