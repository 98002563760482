import { Box, IconButton, Typography, Divider } from '@mui/material';
import { StyledIconButton } from './DecisionSiteMeetingPanelHeader.styles';
import CloseIcon from '@mui/icons-material/Close';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
  decisionSiteJourneySummaryPanelStyles,
  drawerStyles,
} from './DecisionSiteJourneySummaryPanel.style';
import { AutoAwesomeOutlined } from '@mui/icons-material';
import { DSTooltip } from '../../DS';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';
import DSResponsiveDrawer from '../../../../Components/Common/DSResponsiveDrawer';
import { DecisionSiteJourneySummary } from './DecisionSiteJourneySummary';
import { useSearchParams, useNavigate } from 'react-router-dom';

interface DecisionSiteJourneySummaryPanelProps {
  organizationSlug: string;
  dealRoomId: number;
}

export const DecisionSiteJourneySummaryPanel: React.FC<
  DecisionSiteJourneySummaryPanelProps
> = ({ organizationSlug, dealRoomId }) => {
  const styles = decisionSiteJourneySummaryPanelStyles();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const isOpen = searchParams.has('summary');

  const handleClose = () => {
    const newParams = new URLSearchParams(searchParams);
    newParams.delete('summary');
    navigate({ search: newParams.toString() }, { replace: true });
  };

  return (
    <DSResponsiveDrawer
      anchor="right"
      open={isOpen}
      onClose={handleClose}
      variant="temporary"
      sx={drawerStyles}
    >
      <Box className={styles.container}>
        <div className={styles.header}>
          <div className={styles.title}>
            <AutoAwesomeOutlined sx={{ fontSize: 20 }} />
            <Typography>Journey Summary</Typography>
          </div>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <DSTooltip
              title="These insights are based on the meetings from your Journey."
              placement="left"
            >
              <IconButton component={StyledIconButton} size="small">
                <HelpOutlineIcon sx={{ fontSize: 20 }} />
              </IconButton>
            </DSTooltip>
            <IconButton component={StyledIconButton} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </div>
        <Divider
          sx={{ backgroundColor: DEALROOMS_COLORS.financialLightGray }}
        />
        <Box className={styles.content}>
          <Box className={styles.contentInner}>
            <DecisionSiteJourneySummary
              organizationSlug={organizationSlug}
              dealRoomId={dealRoomId}
            />
          </Box>
        </Box>
      </Box>
    </DSResponsiveDrawer>
  );
};
