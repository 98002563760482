import { styled } from '@mui/material/styles';

export const DSMenuHeaderRoot = styled('div')(({ theme }) => ({
  padding: '8px 16px',
  color: theme.palette.text.secondary,
  fontSize: '0.75rem',
  fontWeight: 500,
  textTransform: 'uppercase',
  letterSpacing: '0.02em',
  backgroundColor: theme.palette.background.paper,
  borderBottom: `1px solid ${theme.palette.divider}`,
}));
