import React, { useState } from 'react';
import { Stack, DatePicker, Icon, IconButton, Text } from '@fluentui/react';
import { MilestoneObject } from '../../../../../types/MilestoneTypes';
import { dsConfigurationMutualPlanStyles as styles } from './styles';
import { DSConfigurationMutualPlanDeleteMilestoneDialog } from './DSConfigurationMutualPlanDeleteMilestoneDialog';
import { DEALROOMS_COLORS } from '../../../../../Themes/Themes';
import {
  EyeOffIconProps,
  EyeOpenIconProps,
} from '../../../../../utils/iconProps';
import { useBoolean } from '@fluentui/react-hooks';
import { StyledTextField } from './DSConfigurationMutualPlan.styles';

interface DSConfigurationMutualPlanMilestoneItemProps {
  milestone: MilestoneObject;
  onClickMilestone: (id: number) => void;
  onChangeDueDate: (
    id: number,
    date: Date | null | undefined,
  ) => void;
  onDeleteMilestone: (id: number) => void;
  onMilestoneTitleChange: (
    milestoneId: number,
    newTitle: string,
    callback?: () => void,
  ) => Promise<void>;
}

export const DSConfigurationMutualPlanMilestoneItem: React.FC<
  DSConfigurationMutualPlanMilestoneItemProps
> = ({ 
  milestone, 
  onClickMilestone, 
  onChangeDueDate, 
  onDeleteMilestone,
  onMilestoneTitleChange,
}) => {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const [
    isEditingTitle,
    { setTrue: startEditingTitle, setFalse: stopEditingTitle },
  ] = useBoolean(false);
  
  const [editedTitle, setEditedTitle] = useState(
    'title' in milestone && milestone.title
      ? (milestone.title as string)
      : '',
  );

  const handleDelete = (e: React.MouseEvent) => {
    setIsDeleteDialogOpen(true);
  };

  const handleConfirmDelete = () => {
    onDeleteMilestone(milestone.id);
    setIsDeleteDialogOpen(false);
  };

  const handleTitleClick = () => {
    startEditingTitle();
  };

  const handleResetTitle = () => {
    setEditedTitle(
      'title' in milestone && milestone.title
        ? (milestone.title as string)
        : '',
    );
  };

  const handleTitleBlur = async () => {
    stopEditingTitle();
    // Get the current title
    const currentTitle =
      'title' in milestone && milestone.title
        ? (milestone.title as string)
        : '';

    // Check if the title has actually changed
    if (editedTitle === currentTitle) {
      // No change or empty title, exit early
      return;
    }

    // Check if the title is empty
    if (editedTitle.trim() === '') {
      // Reset the edited title
      handleResetTitle();
      return;
    }

    // Title has changed, update it and send a callback to reset the local state in case of an error
    await onMilestoneTitleChange(
      milestone.id,
      editedTitle.trim(),
      handleResetTitle,
    );
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditedTitle(e.target.value);
  };

  return (
    <>
      <div
        className={`${styles.milestone} ${milestone.visible ? styles.milestoneVisible : ''}`}
      >
        <IconButton
          className={styles.eyeIconButton}
          iconProps={milestone.visible ? EyeOpenIconProps : EyeOffIconProps}
          onClick={() => onClickMilestone(milestone.id)}
        />
        <div className={styles.milestoneNameAndCount}>
          {/* Editable title */}
          {isEditingTitle ? (
            <StyledTextField
              value={editedTitle}
              onChange={handleTitleChange}
              onBlur={handleTitleBlur}
              fullWidth
              autoFocus
              size="small"
              aria-label="Milestone title"
            />
          ) : (
            <Text
              className={styles.milestoneName}
              onClick={handleTitleClick}
              styles={{ root: { cursor: 'pointer' } }}
            >
              {editedTitle}
            </Text>
          )}
          {/* Display number of tasks if any */}
          {milestone.numberOfTasks > 0 && (
            <Text
              className={styles.tasksNumber}
            >{`${milestone.numberOfTasks} ${milestone.numberOfTasks === 1 ? 'Task' : 'Tasks'}`}</Text>
          )}
          {/* Additional controls for visible milestones */}
        </div>
        <Stack
          horizontal
          horizontalAlign="end"
          styles={{ root: { marginLeft: 'auto' } }}
        >
          {milestone.visible && (
            <>
              {/* Date picker for due date */}
              <div className={styles.datePickerContainer}>
                <DatePicker
                  className={styles.datePicker}
                  placeholder="Due Date..."
                  value={
                    milestone.dueDate ? new Date(milestone.dueDate) : undefined
                  }
                  onSelectDate={(newDate) => {
                    onChangeDueDate(milestone.id, newDate);
                  }}
                />
              </div>
            </>
          )}

          {/* Delete button */}
          <IconButton
            iconProps={{
              iconName: 'Delete',
              styles: {
                root: {
                  color: DEALROOMS_COLORS.darkerRed,
                  fontSize: 16,
                },
              },
            }}
            onClick={(e) => {
              handleDelete(e as React.MouseEvent);
            }}
            ariaLabel="Delete milestone"
          />
        </Stack>
      </div>
      {/* Delete confirmation dialog */}
      <DSConfigurationMutualPlanDeleteMilestoneDialog
        isOpen={isDeleteDialogOpen}
        milestoneName={milestone.title}
        onConfirm={handleConfirmDelete}
        onDismiss={() => setIsDeleteDialogOpen(false)}
      />
    </>
  );
};
