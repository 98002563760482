import React from 'react';
import { Checkbox } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';
import { DEALROOMS_COLORS } from '../../../../../Themes/Themes';
import useStore from '../../../../../Stores/userSettingsStore';
import { DealRoomNotificationDeliveryTimingType } from '@meetingflow/common/Api/data-contracts';

export const FrequencyCheckbox: React.FC<ICellRendererParams> = (props) => {
  // Current frequency value from props
  const value = props?.value as DealRoomNotificationDeliveryTimingType;

  // Get current notification settings from global store
  const notificationsSettings = useStore(
    (state) => state.notificationsSettings,
  );
  // Function to update notification settings in global store
  const setNotificationsSettings = useStore(
    (state) => state.setNotificationsSettings,
  );
  // Check if settings are currently being saved
  const isSaving = useStore((state) => state.isLoading);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    const { node, colDef } = props;
    const rowData = node?.data || {};

    const field = colDef?.field as 'email' | 'inApp';
    const newValue = checked ? 'ASAP' : 'NEVER';

    // Update notification settings based on the checkbox state
    const updatedSettings = notificationsSettings.map((category) => ({
      ...category,
      notificationTypes: category.notificationTypes.map((type) => {
        // Check if the current row matches the category and type
        if (
          type.notificationType === rowData.key &&
          category.notificationCategory === rowData.notificationSectionCategory
        ) {
          return {
            ...type,
            delivery: type.delivery.map((delivery, index) => {
              // Update either email (index 0) or inApp (index 1) based on the field
              if (
                (index === 0 && field === 'email') ||
                (index === 1 && field === 'inApp')
              ) {
                return {
                  ...delivery,
                  timingType:
                    newValue as DealRoomNotificationDeliveryTimingType,
                };
              }
              return delivery;
            }),
          };
        }
        return type;
      }),
    }));

    // Update the global state with the new settings
    setNotificationsSettings(updatedSettings);
  };

  return (
    <Checkbox
      checked={value === 'ASAP'}
      onChange={handleChange}
      disabled={isSaving}
      sx={{
        color: DEALROOMS_COLORS.cloudburst,
        '&.Mui-checked': {
          color: DEALROOMS_COLORS.themePrimary,
        },
      }}
    />
  );
};
