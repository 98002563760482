import { mergeStyles } from '@fluentui/react';
import { DEALROOMS_COLORS } from '../../../../../Themes/Themes';

export const gridContainerStyles = mergeStyles({
  backgroundColor: DEALROOMS_COLORS.white,
  overflow: 'hidden',

  '.ag-root-wrapper': {
    border: 'none !important', // Remove the grid container border
    overflow: 'hidden',
    paddingLeft: '1.75rem',
  },

  '.ag-header': {
    backgroundColor: 'transparent !important',
  },
  '.ag-header-cell-text': {
    fontWeight: 600,
  },
  '.ag-header-cell:first-of-type': {
    paddingLeft: '0 !important',
  },
  '.ag-center-cols-viewport': {
    paddingTop: '.5rem',
    minHeight: 'fit-content',

    '.ag-center-cols-container': {
      minHeight: 'fit-content',
    },
  },
  '.ag-cell': {
    fontSize: '0.875rem',
    lineHeight: '0.9375rem',
    textOverflow: 'ellipsis',
    wordBreak: 'break-word',
    height: 'fit-content',
    overflowWrap: 'break-word',
  },

  '.ag-cell-first-right-pinned, .ag-cell:first-of-type': {
    paddingLeft: '0 !important',
  },

  '.ag-row:hover': {
    '::before': {
      backgroundColor: `transparent !important`,
    },
  },
  '.ag-row': {
    display: 'flex',
    alignItems: 'center',
    color: DEALROOMS_COLORS.themePrimary,
    border: 'none !important',
    ':hover': {
      backgroundColor: `transparent !important`,
    },
  },
  '@media (max-width: 1024px)': {
    '.notification-cell': {
      whiteSpace: 'normal !important',
    },
  },
  '.notification-header': {
    color: 'red'
  },
});
