import { styled, ToggleButtonGroup, Box, ToggleButton } from '@mui/material';

import { DEALROOMS_COLORS } from '../../../Themes/Themes';

export const ViewModeSelectorGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  backgroundColor: DEALROOMS_COLORS.white,
  border: `1px solid ${theme.palette.divider}`,
  '& .MuiToggleButtonGroup-grouped': {
    // margin: theme.spacing(0.5),
    border: 0,
    padding: theme.spacing(0.5),
    '&.Mui-disabled': {
      border: 0,
    },
    '&:first-of-type': {
      borderTopLeftRadius: '0.25rem',
      borderBottomLeftRadius: '0.25rem',
    },
    '&:last-of-type': {
      borderTopRightRadius: '0.25rem',
      borderBottomRightRadius: '0.25rem',
    },
  },
}));

export const ArtifactsWrapper = styled('div')(() => ({
  position: 'relative',
}));

export const ArtifactsViewToolsWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  marginBottom: '16px',
  position: 'absolute',
  top: '0',
  right: '0',
  zIndex: 1,
  gap: '.5rem',
}));

export const CompactToggleButton = styled(ToggleButton)(({ theme }) => ({
  padding: theme.spacing(0.5),
  '& .MuiSvgIcon-root': {
    fontSize: '1.25rem',
    color: DEALROOMS_COLORS.cloudburst,
  },
  '&.Mui-selected': {
    backgroundColor: DEALROOMS_COLORS.themeSecondary,
    '& .MuiSvgIcon-root': {
      color: DEALROOMS_COLORS.white,
    },
    '&:hover': {
      backgroundColor: DEALROOMS_COLORS.themeSecondary,
    },
  },
}));
