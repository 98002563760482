import { DealRoomFileArtifact } from '@meetingflow/common/Api/data-contracts';
import { BaseArtifactCard, BaseArtifactCardProps } from './BaseArtifactCard';
import { ArtifactIconPreview } from './ArtifactIconPreview';
import { ImageWithFallback } from '../../../Components/Common/ImageWithFallback';

export type FileArtifactCardProps = BaseArtifactCardProps &
  Pick<DealRoomFileArtifact, 'fileName' | 'thumbnailUrl' | 'mimeType'>;

export const FileArtifactCard = ({
  orgSlug,
  dealRoomId,
  fileName,
  mimeType,
  thumbnailUrl,
  ...rest
}: FileArtifactCardProps) => {
  return (
    <BaseArtifactCard
      className="file-artifact"
      orgSlug={orgSlug}
      dealRoomId={dealRoomId}
      mimeType={mimeType}
      {...rest}
    >
      <ImageWithFallback
        src={thumbnailUrl || undefined}
        fallback={
          <ArtifactIconPreview
            type="FILE"
            fileExtension={fileName.split('.').pop()}
            mimeType={mimeType}
          />
        }
      />
    </BaseArtifactCard>
  );
};
