import { styled, Select, Typography } from '@mui/material';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';
import { DealRoomActionItemStatus } from '@meetingflow/common/Api/data-contracts';

interface StatusSelectProps {
  sx?: {
    backgroundColor: string;
    '& .MuiSelect-select': {
      color: string;
    };
    '& .MuiOutlinedInput-input': {
      color: string;
    };
    '& .MuiSvgIcon-root': {
      color: string;
    };
  };
}

export const Container = styled('div')<{
  isLastItem?: boolean;
  removeLeftPadding?: boolean;
}>(({ isLastItem, removeLeftPadding }) => ({
  container: 'milestone-item / inline-size',
  transition: 'opacity 0.2s ease-in-out',
  display: 'flex',
  flexDirection: 'column',
  cursor: 'pointer',
  paddingTop: '.25rem',
  paddingBottom: '.25rem',
  paddingLeft: removeLeftPadding ? '0' : '1rem',
  borderBottom: isLastItem
    ? 'none'
    : `1px solid ${DEALROOMS_COLORS.financialLightGray}70`,
  '@media (max-width: 768px)': {
    paddingLeft: '0',
  },
}));

export const Row = styled('div')({
  display: 'flex',
  alignItems: 'flex-start',
  width: '100%',
  padding: '.25rem 0',
  '@media (max-width: 768px)': {
    flexDirection: 'column',
    alignItems: 'stretch',
    paddingLeft: '0',
    '& .title-section': {
      flexDirection: 'row',
    },
    '& .controls-section': {
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
  },
});

export const TitleSection = styled('div')({
  display: 'flex',
  alignItems: 'flex-start',
  flex: 1,
  minWidth: 0,
  marginRight: '1rem',
  cursor: 'pointer',
  '& .more-options': {
    '@media (min-width: 768px)': {
      display: 'none',
    },
  },
  '@media (max-width: 768px)': {
    marginRight: 0,
  },
});

export const ControlsSection = styled('div')({
  display: 'flex',
  alignItems: 'flex-start',
  gap: '.5rem',
  flexWrap: 'wrap',
});

export const MoreOptionsLarge = styled('div')({
  '@media (max-width: 768px)': {
    display: 'none',
  },
});

export const TaskTitle = styled('div')<{ $isPlaceholder?: boolean }>(
  ({ $isPlaceholder }) => ({
    flex: 1,
    minWidth: 0,
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
    lineHeight: '1.25rem',
    color: $isPlaceholder
      ? DEALROOMS_COLORS.cloudburst
      : DEALROOMS_COLORS.woodsmoke,
    cursor: 'pointer',
    marginBottom: '.5rem',
    marginTop: '.25rem',
  }),
);

export const StyledSelect = styled(Select)(({ theme }) => ({
  height: '24px',
  minWidth: '150px',
  fontSize: '0.75rem',
  maxWidth: '130px',
  backgroundColor: 'white',
  '& .MuiSelect-select': {
    fontSize: '0.75rem',
    backgroundColor: DEALROOMS_COLORS.neutralLighter,
    padding: '2px 24px 2px 8px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    height: '24px',
    lineHeight: '20px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiOutlinedInput-input': {
    color: DEALROOMS_COLORS.woodsmoke,
    padding: '2px 24px 2px 8px',
  },
  '& .MuiSvgIcon-root': {
    color: DEALROOMS_COLORS.woodsmoke,
    width: '20px',
    height: '20px',
    top: '2px',
    right: '2px',
  },
  '& .MuiInputBase-input': {
    padding: '2px 24px 2px 8px !important',
    height: 'auto !important',
    minHeight: '0 !important',
  },
}));

export const StatusSelect = styled(
  Select<DealRoomActionItemStatus>,
)<StatusSelectProps>(({ theme, sx = {} }) => ({
  height: '24px',
  minWidth: '100px',
  maxWidth: '100px',
  fontSize: '0.75rem',
  '& .MuiSelect-select': {
    padding: '2px 24px 2px 8px',
    fontSize: '0.75rem',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textAlign: 'left',
    backgroundColor: 'transparent',
    height: '24px',
    lineHeight: '20px',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiOutlinedInput-input': {
    textAlign: 'left',
    padding: '2px 24px 2px 8px',
  },
  '& .MuiSvgIcon-root': {
    width: '20px',
    height: '20px',
    top: '2px',
    right: '2px',
  },
  '& .MuiInputBase-input': {
    padding: '2px 24px 2px 8px !important',
    height: 'auto !important',
    minHeight: '0 !important',
  },
  // Merge in the dynamic color styles from sx prop
  ...(typeof sx === 'function' ? sx(theme) : sx),
}));

export const ButtonContainer = styled('div')({
  display: 'flex',
  gap: '8px',
  marginLeft: '8px',
  alignItems: 'center',
  height: '24px',
  paddingTop: '.25rem',
});

export const AttachmentsContainer = styled('div')<{ hasAttachments?: boolean }>(
  ({ hasAttachments }) => ({
    // marginBottom: '.25rem',
    // marginTop: hasAttachments ? '.5rem' : '0px',
  }),
);
