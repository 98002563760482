import { FC, useEffect } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import { useQuery } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { DealRoomsApiClient } from '../../../../Services/NetworkCommon';
import {
  LightbulbOutlined,
  PlaylistAddCheckOutlined,
  TrendingUpOutlined,
  InsightsOutlined,
} from '@mui/icons-material';
import {
  DecisionSiteJourneySummaryContainer,
  DecisionSiteJourneySummaryStack,
  DecisionSiteJourneySummaryCard,
  DecisionSiteJourneySummaryCardHeader,
  DecisionSiteJourneySummaryCardContent,
  DecisionSiteJourneySummaryStyledMarkdown,
  DecisionSiteJourneySummaryTimestampText,
  DecisionSiteJourneySummaryLoadingContainer,
  DecisionSiteJourneySummaryStateContainer,
  DecisionSiteJourneySummaryIconWrapper,
} from './DecisionSiteJourneySummary.styles';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';

interface SummaryResponse {
  overview?: string;
  key_changes?: string;
  next_steps?: string;
  risks_opportunities?: string;
  generated_at?: string;
}

type SummaryField = keyof Omit<SummaryResponse, 'generated_at'>;

type SummaryLayout = 'horizontal' | 'vertical';

interface DecisionSiteJourneySummaryProps {
  organizationSlug: string;
  dealRoomId: number;
  fields?: SummaryField[];
  layout?: SummaryLayout;
  showTimestamp?: boolean;
  onQueryStateChange?: (hasData: boolean) => void;
}

const SummaryCard = ({
  title,
  content,
  icon,
}: {
  title: string;
  content: string;
  icon: React.ReactNode;
}) => {
  return (
    <DecisionSiteJourneySummaryCard elevation={0}>
      <DecisionSiteJourneySummaryCardHeader>
        <DecisionSiteJourneySummaryIconWrapper>
          {icon}
        </DecisionSiteJourneySummaryIconWrapper>
        <Typography variant="subtitle1" sx={{ ml: 1 }}>
          {title}
        </Typography>
      </DecisionSiteJourneySummaryCardHeader>
      <DecisionSiteJourneySummaryCardContent>
        <DecisionSiteJourneySummaryStyledMarkdown>
          {content}
        </DecisionSiteJourneySummaryStyledMarkdown>
      </DecisionSiteJourneySummaryCardContent>
    </DecisionSiteJourneySummaryCard>
  );
};

export const DecisionSiteJourneySummary: FC<
  DecisionSiteJourneySummaryProps
> = ({
  organizationSlug,
  dealRoomId,
  fields = ['overview', 'key_changes', 'next_steps', 'risks_opportunities'],
  layout = 'vertical',
  showTimestamp = true,
  onQueryStateChange,
}) => {
  const { getAccessTokenSilently } = useAuth0();

  const getSummaryData = async () => {
    const token = await getAccessTokenSilently();
    const response = await DealRoomsApiClient.getDealRoomSummary(
      {
        organizationSlug,
        dealRoomId,
        type: 'journey',
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    if (response.status != 200) {
      throw new Error('Failed to fetch summary');
    }

    return response.data;
  };

  const { data, isLoading, error } = useQuery<SummaryResponse>({
    queryKey: ['decision-site-summary', organizationSlug, dealRoomId],
    queryFn: getSummaryData,
    refetchInterval: 30000,
    staleTime: 0,
  });

  useEffect(() => {
    if (isLoading) return;

    const hasValidData =
      !!data &&
      Object.values(data).some(
        (value) => value !== null && value !== undefined && value !== '',
      );
    onQueryStateChange?.(hasValidData);
  }, [data, isLoading, onQueryStateChange]);

  //   if (isLoading) {
  //     return (
  //       <DecisionSiteJourneySummaryLoadingContainer>
  //         <CircularProgress size={20} />
  //       </DecisionSiteJourneySummaryLoadingContainer>
  //     );
  //   }

  if (error) {
    return (
      <DecisionSiteJourneySummaryStateContainer>
        <Typography align="center">
          Failed to load summary. Please try again later.
        </Typography>
      </DecisionSiteJourneySummaryStateContainer>
    );
  }

  if (!data && !isLoading) {
    return (
      <DecisionSiteJourneySummaryStateContainer>
        <Typography align="center">No summary available.</Typography>
      </DecisionSiteJourneySummaryStateContainer>
    );
  }

  return (
    <DecisionSiteJourneySummaryContainer>
      <DecisionSiteJourneySummaryStack
        layout={layout}
        totalFields={fields.length}
      >
        {fields.map((field) => {
          const content = data?.[field];

          const fieldConfig = {
            overview: {
              title: 'Journey Overview',
              icon: (
                <InsightsOutlined
                  sx={{ fontSize: 20, color: DEALROOMS_COLORS.peacock }}
                />
              ),
            },
            key_changes: {
              title: 'Key Changes',
              icon: (
                <LightbulbOutlined
                  sx={{ fontSize: 20, color: DEALROOMS_COLORS.orchid }}
                />
              ),
            },
            next_steps: {
              title: 'Next Steps',
              icon: (
                <PlaylistAddCheckOutlined
                  sx={{ fontSize: 20, color: DEALROOMS_COLORS.frost }}
                />
              ),
            },
            risks_opportunities: {
              title: 'Risks & Opportunities',
              icon: (
                <TrendingUpOutlined
                  sx={{ fontSize: 20, color: DEALROOMS_COLORS.crimson }}
                />
              ),
            },
          };

          const config = fieldConfig[field];
          if (!content || !config) return null;

          return (
            <SummaryCard
              key={field}
              title={config.title}
              content={content}
              icon={config.icon}
            />
          );
        })}
      </DecisionSiteJourneySummaryStack>
      {showTimestamp && data?.generated_at && (
        <DecisionSiteJourneySummaryTimestampText>
          Generated:{' '}
          {new Date(data.generated_at).toLocaleString(undefined, {
            timeZoneName: 'short',
            hour: 'numeric',
            minute: '2-digit',
            month: 'numeric',
            day: 'numeric',
            year: 'numeric',
          })}
        </DecisionSiteJourneySummaryTimestampText>
      )}
    </DecisionSiteJourneySummaryContainer>
  );
};

export default DecisionSiteJourneySummary;
