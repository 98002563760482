import {
  CommandButton,
  FontSizes,
  FontWeights,
  IButtonStyles,
  Link,
  Text,
  mergeStyles,
} from '@fluentui/react';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { Duration } from 'luxon';
import toast from 'react-hot-toast';
import { VideoPlayerControls } from '../Video/VideoPlayer';
import { useMemo, useState } from 'react';
import classNames from 'classnames';
import { MEETINGFLOW_COLORS } from '../../Themes/Themes';
import { useLightOrDarkMode } from '../../Hooks/useLightOrDarkMode';
import { Utterance } from '@meetingflow/common/Api/data-contracts';
import { AsyncLink } from '../HOC/AsyncLink';

const TIMESTAMP_SHARE_PADDING_SECONDS = 5;

export type Topic = {
  timestamp: number;
  title: string;
  summary: string;
  questions?:
    | {
        timestamp: number;
        speaker?: string | undefined;
        question: string;
      }[]
    | undefined;
  concerns?:
    | {
        timestamp: number;
        speaker?: string | undefined;
        concern: string;
      }[]
    | undefined;
};

interface CallRecordTopicCardProps {
  topic: Topic;
  meetingPlanId: string;
  organizationSlug: string;
  showSummary?: boolean;
  videoPlayerControls?: VideoPlayerControls;
  isExpanded?: boolean;
  transcript?: Utterance[];
  onSelectTopic?: (topic: Topic) => void;
  onClickJumpToTranscript?: (timestamp: number) => void;
  showCollabDialogForTimestamp: (timestamp: number) => void;
}

const CallRecordTopicCard: React.FC<CallRecordTopicCardProps> = ({
  topic,
  meetingPlanId,
  organizationSlug,
  showSummary,
  videoPlayerControls,
  onSelectTopic,
  isExpanded,
  transcript,
  onClickJumpToTranscript,
  showCollabDialogForTimestamp,
}) => {
  const appInsights = useAppInsightsContext();

  const [isCollapsed, setIsCollapsed] = useState(!isExpanded);

  const { isDark } = useLightOrDarkMode();

  const formatSeconds = (seconds: number) =>
    Duration.fromObject({ seconds }).toFormat('h:mm:ss');

  const timestampMenuStyles = {
    root: {
      height: '1rem',
      width: '2rem',
      position: 'absolute',
      right: 0,
    },
  } as IButtonStyles;

  const hasQuestions = topic?.questions?.length && topic?.questions?.length > 0;
  const hasConcerns = topic?.concerns?.length && topic?.concerns?.length > 0;
  const hasQuestionsOrConcerns = hasQuestions || hasConcerns;

  const sectionHeaderClass = mergeStyles({
    display: 'block',
    fontSize: FontSizes.small,
    color: isDark
      ? MEETINGFLOW_COLORS.purpleLight
      : MEETINGFLOW_COLORS.purpleMedium,
    fontWeight: FontWeights.semibold,
    margin: '1rem 0 .5rem 0',

    ':first-child': {
      marginTop: '0',
    },
  });

  const topicCardClass = mergeStyles({});

  return (
    <dl
      className={classNames(
        'topic',
        isCollapsed ? 'collapsed' : 'expanded',
        hasQuestionsOrConcerns && !isExpanded ? 'expandable' : '',
      )}
      key={`${meetingPlanId}-topic-${topic.title}`}
      onClick={
        onSelectTopic
          ? (e) => {
              onSelectTopic?.(topic);
              videoPlayerControls?.seek(topic.timestamp);
              appInsights.trackEvent({
                name: 'CALL_RECORDING_INSIGHTS_SELECT_TOPIC',
                properties: {
                  meetingPlanId,
                  recordingTimestamp: topic.timestamp,
                  topicTitle: topic.title,
                },
              });
            }
          : undefined
      }
    >
      <dt>
        <CommandButton
          onClick={(e) => {
            e.stopPropagation();
          }}
          menuIconProps={{
            iconName: 'More',
          }}
          menuProps={{
            items: [
              {
                key: 'seek',
                text: 'Seek video to this topic',
                iconProps: {
                  iconName: 'FastForward',
                },
                onClick: () => {
                  videoPlayerControls?.seek(
                    topic.timestamp - TIMESTAMP_SHARE_PADDING_SECONDS,
                  );
                  appInsights.trackEvent({
                    name: 'CALL_RECORDING_INSIGHTS_MORE_MENU_SEEK_TO_PHRASE',
                    properties: {
                      meetingPlanId,
                      recordingTimestamp: topic.timestamp,
                    },
                  });
                },
              },
              {
                key: 'share',
                text: 'Share Meetingflow at this timestamp',
                iconProps: { iconName: 'Copy' },
                onClick: (e) => {
                  showCollabDialogForTimestamp(topic.timestamp);
                  appInsights.trackEvent({
                    name: 'CALL_RECORDING_INSIGHTS_MORE_MENU_SHARE_TIMESTAMP',
                    properties: {
                      meetingPlanId,
                      recordingTimestamp:
                        topic.timestamp - TIMESTAMP_SHARE_PADDING_SECONDS,
                    },
                  });
                },
              },
              {
                key: 'copy',
                text: 'Copy link to Meetingflow at this timestamp',
                iconProps: { iconName: 'Share' },
                onClick: (e) => {
                  e?.preventDefault();
                  const externalView = window.location.href.includes('/share/');
                  const url = externalView
                    ? `${window.location.href}?recordingTimestamp=${topic.timestamp}`
                    : `${window.location.origin}/organization/${organizationSlug}/plan/${meetingPlanId}/?recordingTimestamp=${topic.timestamp}`;
                  navigator.clipboard
                    .writeText(url)
                    .then(() =>
                      toast.success(
                        `Copied Meetingflow URL (with timestamp) to the clipboard.`,
                      ),
                    );
                  appInsights.trackEvent({
                    name: 'CALL_RECORDING_INSIGHTS_MORE_MENU_COPY_TIMESTAMP',
                    properties: {
                      meetingPlanId,
                      recordingTimestamp:
                        topic.timestamp - TIMESTAMP_SHARE_PADDING_SECONDS,
                    },
                  });
                },
              },
            ],
          }}
          styles={timestampMenuStyles}
        />
        {topic.title}{' '}
        <span className="timestamp">{formatSeconds(topic.timestamp)}</span>
      </dt>
      {showSummary || !isCollapsed ? (
        <dd
          style={{
            marginTop: '.25rem',
            marginBottom: '1rem',
            paddingRight: '5rem',
          }}
        >
          {topic.summary}{' '}
          <Link
            onClick={(e) => {
              onClickJumpToTranscript?.(topic.timestamp);
              e.stopPropagation();
            }}
            styles={{
              root: {
                display: 'inline-block',
                fontFamily:
                  'Segoe UI, Helvetica Neue, Helvetica, sans-serif !important',
                fontSize: FontSizes.small,
                fontWeight: FontWeights.semibold,
                color: MEETINGFLOW_COLORS.orange,
              },
            }}
          >
            Full transcript
          </Link>
        </dd>
      ) : null}

      {hasQuestionsOrConcerns ? (
        <div className="sub-topics">
          {hasQuestions && !isCollapsed ? (
            <div
              className="sub-topic"
              style={{ flexBasis: hasConcerns ? '50%' : '100%' }}
            >
              <Text className={sectionHeaderClass}>Questions Asked:</Text>
              {topic?.questions?.map(
                (question: {
                  timestamp: number;
                  speaker?: string | undefined;
                  question: string;
                }) => (
                  <dl
                    key={`${meetingPlanId}-topic-${question.question}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      videoPlayerControls?.seek(
                        question.timestamp - TIMESTAMP_SHARE_PADDING_SECONDS,
                      );
                    }}
                    className="question"
                  >
                    <dt>
                      <CommandButton
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        menuIconProps={{
                          iconName: 'More',
                        }}
                        menuProps={{
                          items: [
                            {
                              key: 'seek',
                              text: 'Seek video to this timestamp',
                              iconProps: {
                                iconName: 'FastForward',
                              },
                              onClick: (e) => {
                                videoPlayerControls?.seek(
                                  question.timestamp -
                                    TIMESTAMP_SHARE_PADDING_SECONDS,
                                );
                                appInsights.trackEvent({
                                  name: 'CALL_RECORDING_INSIGHTS_MORE_MENU_SEEK_TO_PHRASE',
                                  properties: {
                                    meetingPlanId,
                                    recordingTimestamp: question.timestamp,
                                  },
                                });
                              },
                            },
                            {
                              key: 'share',
                              text: 'Share Meetingflow at this Timestamp',
                              iconProps: { iconName: 'Copy' },
                              onClick: (e) => {
                                showCollabDialogForTimestamp(
                                  question.timestamp,
                                );
                                appInsights.trackEvent({
                                  name: 'CALL_RECORDING_INSIGHTS_MORE_MENU_SHARE_TIMESTAMP',
                                  properties: {
                                    meetingPlanId,
                                    recordingTimestamp:
                                      question.timestamp -
                                      TIMESTAMP_SHARE_PADDING_SECONDS,
                                  },
                                });
                              },
                            },
                            {
                              key: 'copy',
                              text: 'Copy link to Meetingflow at this timestamp',
                              iconProps: { iconName: 'Share' },
                              onClick: (e) => {
                                e?.preventDefault();
                                const externalView =
                                  window.location.href.includes('/share/');
                                const url = externalView
                                  ? `${window.location.href}?recordingTimestamp=${topic.timestamp}`
                                  : `${window.location.origin}/organization/${organizationSlug}/plan/${meetingPlanId}/?recordingTimestamp=${topic.timestamp}`;
                                navigator.clipboard
                                  .writeText(url)
                                  .then(() =>
                                    toast.success(
                                      `Copied Meetingflow URL (with timestamp) to the clipboard.`,
                                    ),
                                  );
                                appInsights.trackEvent({
                                  name: 'CALL_RECORDING_INSIGHTS_MORE_MENU_COPY_TIMESTAMP',
                                  properties: {
                                    meetingPlanId,
                                    recordingTimestamp:
                                      question.timestamp -
                                      TIMESTAMP_SHARE_PADDING_SECONDS,
                                  },
                                });
                              },
                            },
                          ],
                        }}
                        styles={timestampMenuStyles}
                      />
                      {question.question}
                    </dt>
                    <dd>
                      {question.speaker}{' '}
                      <span className="timestamp">
                        {formatSeconds(question.timestamp)}
                      </span>
                    </dd>
                  </dl>
                ),
              )}
            </div>
          ) : null}

          {hasConcerns && !isCollapsed ? (
            <div
              className="sub-topic"
              style={{ flexBasis: hasQuestions ? '50%' : '100%' }}
            >
              <Text className={sectionHeaderClass}>Concerns Raised:</Text>

              {topic?.concerns?.filter(concern => concern != null)?.map(
                (concern: {
                  timestamp: number;
                  speaker?: string | undefined;
                  concern: string;
                }) => (
                  <dl
                    key={`${meetingPlanId}-topic-${concern.concern}`}
                    onClick={(e) => {
                      e?.stopPropagation();
                      videoPlayerControls?.seek(
                        concern.timestamp - TIMESTAMP_SHARE_PADDING_SECONDS,
                      );
                    }}
                    className="concern"
                  >
                    <dt>
                      <CommandButton
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        menuIconProps={{
                          iconName: 'More',
                        }}
                        menuProps={{
                          items: [
                            {
                              key: 'seek',
                              text: 'Seek video to this timestamp',
                              iconProps: {
                                iconName: 'FastForward',
                              },
                              onClick: () => {
                                videoPlayerControls?.seek(
                                  concern.timestamp -
                                    TIMESTAMP_SHARE_PADDING_SECONDS,
                                );
                                appInsights.trackEvent({
                                  name: 'CALL_RECORDING_INSIGHTS_MORE_MENU_SEEK_TO_PHRASE',
                                  properties: {
                                    meetingPlanId,
                                    recordingTimestamp:
                                      concern.timestamp -
                                      TIMESTAMP_SHARE_PADDING_SECONDS,
                                  },
                                });
                              },
                            },
                            {
                              key: 'share',
                              text: 'Share Meetingflow at this Timestamp',
                              iconProps: { iconName: 'Share' },
                              onClick: (e) => {
                                showCollabDialogForTimestamp(concern.timestamp);
                                appInsights.trackEvent({
                                  name: 'CALL_RECORDING_INSIGHTS_MORE_MENU_SHARE_TIMESTAMP',
                                  properties: {
                                    meetingPlanId,
                                    recordingTimestamp:
                                      concern.timestamp -
                                      TIMESTAMP_SHARE_PADDING_SECONDS,
                                  },
                                });
                              },
                            },
                            {
                              key: 'copy',
                              text: 'Copy link to Meetingflow at this timestamp',
                              iconProps: { iconName: 'Copy' },
                              onClick: (e) => {
                                e?.preventDefault();
                                const externalView =
                                  window.location.href.includes('/share/');
                                const url = externalView
                                  ? `${window.location.href}?recordingTimestamp=${topic.timestamp}`
                                  : `${window.location.origin}/organization/${organizationSlug}/plan/${meetingPlanId}/?recordingTimestamp=${topic.timestamp}`;
                                navigator.clipboard
                                  .writeText(url)
                                  .then(() =>
                                    toast.success(
                                      `Copied Meetingflow URL (with timestamp) to the clipboard.`,
                                    ),
                                  );

                                appInsights.trackEvent({
                                  name: 'CALL_RECORDING_INSIGHTS_MORE_MENU_COPY_TIMESTAMP',
                                  properties: {
                                    meetingPlanId,
                                    recordingTimestamp:
                                      concern.timestamp -
                                      TIMESTAMP_SHARE_PADDING_SECONDS,
                                  },
                                });
                              },
                            },
                          ],
                        }}
                        styles={timestampMenuStyles}
                      />
                      {concern.concern}
                    </dt>
                    <dd>
                      {concern.speaker}{' '}
                      <span className="timestamp">
                        {formatSeconds(concern.timestamp)}
                      </span>
                    </dd>
                  </dl>
                ),
              )}
            </div>
          ) : null}
        </div>
      ) : null}
    </dl>
  );
};

export default CallRecordTopicCard;
