import { IconButton, styled } from '@mui/material';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';

export const PanelWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflow: 'hidden',
});

export const HeaderWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0 1rem',
  position: 'relative',
  borderBottom: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
  backgroundColor: 'transparent',
  zIndex: 1,
  flex: '0 0 auto', // Don't grow or shrink, use auto height
});

export const ContentWrapper = styled('div')({
  // padding: '1.5rem 1rem 1rem 1rem',
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1 auto', // Allow growing and shrinking
  minHeight: '200px',
  overflow: 'auto',
  backgroundColor: 'transparent',
});

interface TabsWrapperProps {
  isCollapsed?: boolean;
}

export const TabsWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isCollapsed',
})<TabsWrapperProps>(({ isCollapsed = false }) => ({
  position: 'relative',
  backgroundColor: 'white',
  borderTop: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
  display: 'flex',
  flexDirection: 'column',
  flex: isCollapsed ? '0 0 48px' : '0 0 33vh',
  minHeight: isCollapsed ? '48px' : 'auto',
  overflow: 'hidden',
  transition: 'all 0.3s ease-in-out',
  paddingTop: '1rem',

  '& .artifact-panel-tabs': {
    flex: '1 1 auto', // Grow to fill remaining space
    minHeight: '100%',
    maxHeight: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',

    '& .MuiTabs-scroller': {
      padding: '0 1rem',
    },
  },
}));

export const CollapseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: '1rem',
  top: '1rem',
  zIndex: 1,
  padding: '4px',
  '& svg': {
    transition: 'transform 0.3s ease-in-out',
  },
}));

export const TabLabelWithIcon = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '8px', // Changed from .5rem to match original
  '& svg': {
    fontSize: '1.2rem',
  },
});
