import { useCallback } from 'react';
import { pickFile } from '../../../../Helpers/FileHelpers';
import toast from 'react-hot-toast';
import {
  getFileWithMimeType,
  hasValidFileExtension,
} from '../../../../utils/buyerDecisionSiteUtils';

interface UseFileUploadProps {
  setSelectedFile: (file: File | null) => void;
}
const validateFile = (file: File): boolean => {
  if (!hasValidFileExtension(file)) {
    toast.error('Please select a file with an extension, like .txt or .pdf');
    return false;
  }
  return true;
};

export const useFileUpload = ({ setSelectedFile }: UseFileUploadProps) => {
  const handleSelectFile = useCallback(async () => {
    try {
      const file = await pickFile({});
      if (!file) return;

      if (validateFile(file)) {
        const fileCopyWithType = getFileWithMimeType(file);
        setSelectedFile(fileCopyWithType);
      }
    } catch (err) {
      toast.error('Error selecting file');
    }
  }, [setSelectedFile]);

  const handleDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      const droppedFiles = event?.dataTransfer?.files;

      if (droppedFiles?.[0] && validateFile(droppedFiles[0])) {
        const fileCopyWithType = getFileWithMimeType(droppedFiles[0]);
        setSelectedFile(fileCopyWithType);
      }
    },
    [setSelectedFile],
  );

  return { handleSelectFile, handleDrop };
};
