import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';

/**
 * Container for the entire suggested tasks section
 */
export const SuggestedTasksContainer = styled(Box)({
  marginBottom: '2rem',
});

/**
 * Container for the meeting info section
 */
export const MeetingInfoContainer = styled(Box)({});

/**
 * Base text style for the meeting info
 */
export const MeetingInfoText = styled(Typography)({
  color: DEALROOMS_COLORS.cloudburst,
  fontWeight: 400,
});

/**
 * Meeting title text
 */
export const MeetingTitle = styled('strong')({
  fontWeight: 400,
  color: DEALROOMS_COLORS.woodsmoke,
  display: 'block',
});

/**
 * Container for the meeting date and loading state
 */
export const MeetingDateContainer = styled('span')({
  color: DEALROOMS_COLORS.cloudburst,
  display: 'flex',
  alignItems: 'center',
  gap: '.5rem',
});

/**
 * Container for individual action items
 */
export const ActionItemContainer = styled(Box)({});

/**
 * Message for when no suggestions are found
 */
export const NoSuggestionsMessage = styled(Typography)({
  color: DEALROOMS_COLORS.cloudburst,
  fontWeight: 400,
});

export const MeetingTitleContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '.5rem',
  cursor: 'pointer',
});
