import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import { DSConfigurationCalloutCards } from './Pages/DSConfigurationCalloutCards/DSConfigurationCalloutCards';
import { DSConfigurationKeyFacts } from './Pages/DSConfigurationKeyFacts/DSConfigurationKeyFacts';
import { DSConfigurationMutualPlan } from './Pages/DSConfigurationMutualPlan/DSConfigurationMutualPlan';
import { DSConfigurationFirstTimeUserExperience } from './Pages/DSConfigurationFirstTimeUserExperience';
import { DSConfigurationCustomization } from './Pages/DSConfigurationCustomization/DSConfigurationCustomization';
import { DSConfigurationOwnership } from './Pages/DSConfigurationOwnership/DSConfigurationOwnership';
import { useUserProfile } from '../../../Hooks/useProfile';
import { useDealRoom } from '../../../Hooks/useDealRoom';
import {
  TextCalloutIcon,
  CalculatorPercentageIcon,
  StepIcon,
  CPlusPlusLanguageIcon,
  BrushIcon,
  GroupObjectIcon,
} from '@fluentui/react-icons-mdl2';
import useDeviceType from '../../../Hooks/deviceDetection';
import { FontWeights, mergeStyles } from '@fluentui/react';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';
import { useOrganization } from '../../../Hooks/useOrganization';

export const DSConfigurationRoot = () => {
  const { isMobile } = useDeviceType();
  const { user } = useUserProfile();
  const { dealRoom } = useDealRoom();
  const { slug: organizationSlug } = useOrganization();

  const [activeSection, setActiveSection] = useState<string>('callouts');

  const configGroups = useMemo(() => {
    const allGroups = [
      { id: 'callouts', label: 'Callout Cards', icon: TextCalloutIcon },
      { id: 'keyfacts', label: 'Key Details', icon: CalculatorPercentageIcon },
      { id: 'milestones', label: 'Mutual Plan', icon: StepIcon },
      {
        id: 'ftue',
        label: 'First Time User Experience',
        icon: CPlusPlusLanguageIcon,
      },
      { id: 'customization', label: 'Customization', icon: BrushIcon },
      {
        id: 'ownership',
        label: 'Ownership',
        icon: GroupObjectIcon,
        hidden:
          !dealRoom?.owner?.id || !user?.id || dealRoom.owner.id !== user.id,
      },
    ];

    return allGroups.filter((group) => !group.hidden);
  }, [dealRoom?.owner?.id, user?.id]);

  const styles = {
    root: mergeStyles({
      display: 'flex',
      border: `1px solid${DEALROOMS_COLORS.neutralLight}`,
      gap: '2rem',
      minHeight: '70vh',
      height: '100%',
      backgroundColor: DEALROOMS_COLORS.neutralGray,
      flexDirection: isMobile ? 'column' : 'row',
    }),
    sidebar: mergeStyles({
      width: isMobile ? 'auto' : '240px',
      borderRight: isMobile
        ? 'none'
        : `1px solid ${DEALROOMS_COLORS.neutralLight}`,
      borderBottom: isMobile
        ? `1px solid ${DEALROOMS_COLORS.neutralLight}`
        : 'none',
      padding: '1rem',
    }),
    sidebarItem: mergeStyles({
      padding: '0.75rem 1rem',
      cursor: 'pointer',
      borderRadius: '4px',
      marginBottom: '0.5rem',
      ':hover': {
        backgroundColor: DEALROOMS_COLORS.neutralLighter,
      },
    }),
    sidebarItemActive: mergeStyles({
      backgroundColor: DEALROOMS_COLORS.neutralLight,
      fontWeight: FontWeights.semibold,
    }),
    content: mergeStyles({
      flex: 1,
      padding: '1rem',
    }),
    section: mergeStyles({
      margin: '1rem 0 2rem 0',
      display: 'flex',
      gap: '1rem',
    }),
    button: mergeStyles({
      backgroundColor: DEALROOMS_COLORS.themePrimary,
      color: DEALROOMS_COLORS.white,
      width: 'content',
      borderRadius: '0.25rem',
      margin: '0 0 0 0',
      fontSize: '0.85rem',
      padding: '0.3rem 1rem 0.3rem 0.7rem',
      ':hover': {
        backgroundColor: DEALROOMS_COLORS.neutralDarker,
      },
      ':disabled': {
        backgroundColor: DEALROOMS_COLORS.sidebarTextSecondary,
      },
      svg: {
        margin: '0 0.3rem 0 0',
      },
    }),
    sidebarItemIcon: mergeStyles({
      marginRight: '0.5rem',
    }),
  };

  const renderContent = () => {
    switch (activeSection) {
      case 'callouts':
        return (
          <div className={styles.section}>
            <DSConfigurationCalloutCards />
          </div>
        );
      case 'keyfacts':
        return (
          <div className={styles.section}>
            <DSConfigurationKeyFacts />
          </div>
        );
      case 'milestones':
        return (
          <div className={styles.section}>
            <DSConfigurationMutualPlan
              organizationSlug={organizationSlug || ''}
              dealRoomId={dealRoom?.id || 0}
            />
          </div>
        );
      case 'ftue':
        return (
          <div className={styles.section}>
            <DSConfigurationFirstTimeUserExperience />
          </div>
        );
      case 'customization':
        return (
          <div className={styles.section}>
            <DSConfigurationCustomization />
          </div>
        );
      case 'ownership':
        return (
          <div className={styles.section}>
            <DSConfigurationOwnership />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.sidebar}>
        {configGroups.map((group) => (
          <div
            key={group.id}
            className={classNames(styles.sidebarItem, {
              [styles.sidebarItemActive]: activeSection === group.id,
            })}
            onClick={() => setActiveSection(group.id)}
          >
            {group.icon && <group.icon className={styles.sidebarItemIcon} />}
            {group.label}
          </div>
        ))}
      </div>
      <div className={styles.content}>{renderContent()}</div>
    </div>
  );
};
