import { PrimaryButton } from '@fluentui/react';
import { useCallback, useEffect, useState } from 'react';
import { DetailedDealRoom } from '@meetingflow/common/Api/data-contracts';
import toast from 'react-hot-toast';
import { DealRoomsApiClient } from '../../../../../Services/NetworkCommon';
import { useAuth0 } from '@auth0/auth0-react';
import { useOrganizationSlug } from '../../../../../Hooks/useOrganizationSlug';
import { useDealRoomId } from '../../../../../Hooks/useDealRoomId';

export const DSConfigurationHideMutualPlanButton: React.FC = () => {
    const [dealRoom, setDealRoom] = useState<DetailedDealRoom | null>(null);
    const { getAccessTokenSilently } = useAuth0();
    const organizationSlug = useOrganizationSlug();
    const dealRoomId = useDealRoomId();

    const loadDealRoom = useCallback(async () => {
        if (!organizationSlug || !dealRoomId) {
            return;
        }

        const token = await getAccessTokenSilently();
        const response = await DealRoomsApiClient.getDealRoom(
            organizationSlug,
            dealRoomId,
            {
                headers: { Authorization: `Bearer ${token}` }
            }
        );

        setDealRoom(response.data);
    }, [organizationSlug, dealRoomId, getAccessTokenSilently]);

    useEffect(() => {
        loadDealRoom();
    }, [loadDealRoom]);

    const toggleMutualPlan = async () => {
        if (!organizationSlug || !dealRoomId || !dealRoom) {
            return;
        }

        const token = await getAccessTokenSilently();

        await toast.promise(
            DealRoomsApiClient.updateDealRoom(
                organizationSlug,
                dealRoomId,
                {
                    hasMutualPlan: !dealRoom.hasMutualPlan
                },
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            ),
            {
                loading: `${dealRoom.hasMutualPlan ? 'Hiding' : 'Showing'} Mutual Plan tab...`,
                success: () => {
                    loadDealRoom();
                    return `Successfully ${dealRoom.hasMutualPlan ? 'hid' : 'showed'} Mutual Plan tab`;
                },
                error: 'Failed to update Mutual Plan visibility'
            }
        );
    };

    if (!dealRoom) {
        return null;
    }

    return (
        <PrimaryButton
            onClick={toggleMutualPlan}
            text={dealRoom.hasMutualPlan ? 'Hide Mutual Plan Tab' : 'Show Mutual Plan Tab'}
        />
    );
};