import { useMemo, useState } from 'react';
import PhoneInput, {
  isValidPhoneNumber,
  Country,
} from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags';
import 'react-phone-number-input/style.css';
import { SxProps, Theme } from '@mui/material';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';
import {
  StyledPhoneInput,
  StyledErrorText,
  StyledLabel,
  StyledContainer,
} from './DecisionSitePhoneNumberField.styles';

interface DecisionSitePhoneNumberFieldProps {
  value: string;
  defaultValue?: string;
  onChange: (value: string) => void;
  country: Country | undefined;
  setCountry: (value: Country) => void;
  onBlur?: () => Promise<void>;
  label?: string;
  containerSx?: SxProps<Theme>;
  inputSx?: SxProps<Theme>;
  labelSx?: SxProps<Theme>;
}

export const DecisionSitePhoneNumberField = ({
  value,
  onChange,
  country = 'US',
  setCountry,
  onBlur,
  label,
  containerSx,
  inputSx,
  labelSx,
}: DecisionSitePhoneNumberFieldProps) => {
  const [touched, setTouched] = useState(false);

  // Check if the phone number is valid based on the selected country when the user leaves the field
  const hasError = useMemo(
    () => touched && value && !isValidPhoneNumber(value, country),
    [touched, value, country],
  );

  return (
    <StyledContainer>
      {label && (
        <StyledLabel
          halfbackgroundcolor={
            containerSx && 'backgroundColor' in containerSx
              ? (containerSx?.backgroundColor as string)
              : DEALROOMS_COLORS.white
          }
          customstyles={labelSx}
        >
          {label}
        </StyledLabel>
      )}
      <StyledPhoneInput
        error={!!hasError}
        customstyles={{
          container: containerSx,
          input: inputSx,
        }}
      >
        <PhoneInput
          international
          countryCallingCodeEditable={false}
          defaultCountry="US"
          value={value}
          onChange={(value) => {
            onChange(value || '');
            setTouched(false);
          }}
          flags={flags}
          country={country}
          onCountryChange={setCountry}
          onBlur={() => {
            setTouched(true);
            onBlur && onBlur();
          }}
        />
      </StyledPhoneInput>
      {hasError && (
        <StyledErrorText>Please enter a valid phone number</StyledErrorText>
      )}
    </StyledContainer>
  );
};
