import { useCallback, useState } from 'react';
import { Text } from '@fluentui/react';
import { dsConfigurationMutualPlanStyles as styles } from '../styles';
import { useAuth0 } from '@auth0/auth0-react';
import { DealRoomsApiClient } from '../../../../../../Services/NetworkCommon';
import { DSConfigurationMutualPlanTemplateConfirmationModal } from './DSConfigurationMutualPlanTemplateConfirmationModal';
import toast from 'react-hot-toast';

export interface MutualPlanTemplate {
    name: string;
    description: string;
    milestoneTitles: string[];
}

interface DSConfigurationMutualPlanTemplateCardProps {
    organizationSlug: string;
    dealRoomId: number;
    template: MutualPlanTemplate;
    refetchMilestones: () => void;
    refetchMutualPlan: () => void;
    hasExistingMilestones: boolean;
}

export const DSConfigurationMutualPlanTemplateCard = ({
    organizationSlug,
    dealRoomId,
    template,
    refetchMilestones,
    refetchMutualPlan,
    hasExistingMilestones,
}: DSConfigurationMutualPlanTemplateCardProps) => {
    const { getAccessTokenSilently } = useAuth0();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleConfirm = useCallback(async () => {
        try {
            const token = await getAccessTokenSilently();

            // Delete existing milestones if any
            if (hasExistingMilestones) {
                const existingMilestonesResponse = await DealRoomsApiClient.listMilestones(
                    { organizationSlug, dealRoomId },
                    {
                        headers: { Authorization: `Bearer ${token}` },
                    }
                );

                await Promise.all(
                    existingMilestonesResponse.data.map(milestone =>
                        DealRoomsApiClient.deleteMilestone(
                            organizationSlug,
                            dealRoomId,
                            milestone.id,
                            {
                                headers: { Authorization: `Bearer ${token}` },
                            }
                        )
                    )
                );
            }

            // Create new milestones from template
            const milestones = template.milestoneTitles.map((title, index) => {
                // Temporary hack: Set due dates based on index to maintain milestone order
                const dueDate = new Date();
                dueDate.setDate(dueDate.getDate() + index);
                return {
                    title,
                    dueDate: dueDate.toISOString(),
                    visible: false
                };
            });

            await toast.promise(
                DealRoomsApiClient.saveMilestones(
                    organizationSlug,
                    dealRoomId,
                    milestones,
                    {
                        headers: { Authorization: `Bearer ${token}` },
                    }
                ),
                {
                    loading: 'Applying template...',
                    success: () => {
                        refetchMilestones();
                        refetchMutualPlan();
                        setIsModalOpen(false);
                        return 'Template applied successfully';
                    },
                    error: 'Failed to apply template',
                }
            );
        } catch (error) {
            console.error('Error applying template:', error);
            toast.error('Failed to apply template');
        }
    }, [dealRoomId, getAccessTokenSilently, hasExistingMilestones, organizationSlug, refetchMilestones, refetchMutualPlan, template.milestoneTitles]);

    return (
        <>
            <div className={styles.templateContainer}>
                <div
                    className={styles.templateCard}
                    onClick={() => setIsModalOpen(true)}
                >
                    <Text className={styles.templateName}>{template.name}</Text>
                    <Text className={styles.templateDescription}>{template.description}</Text>
                </div>
            </div>
            <DSConfigurationMutualPlanTemplateConfirmationModal
                template={template}
                hasExistingMilestones={hasExistingMilestones}
                onConfirm={handleConfirm}
                onDismiss={() => setIsModalOpen(false)}
                isOpen={isModalOpen}
            />
        </>
    );
};