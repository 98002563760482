/**
 * DecisionSiteMeetingPanelTabs is a comprehensive tabbed interface component that
 * organizes and displays various aspects of a meeting, including insights,
 * attendees, AI chat, transcript, tasks, and collaborative notes.
 *
 * Features:
 * - Dynamic tab management based on meeting state
 * - Real-time participant tracking
 * - AI-powered chat integration
 * - Synchronized video transcript
 * - Task management system
 * - Collaborative note-taking
 * - URL-based tab state persistence
 */

import React, { useContext, useMemo, useState, useEffect } from 'react';
import { DSTabs, DSTab } from '../../DS/DSTabs';
import {
  DealRoomActionItem,
  DetailedMeetingflow,
  GetCallRecordingStatusData,
  Utterance,
} from '@meetingflow/common/Api/data-contracts';
import { useOrganization } from '../../../../Hooks/useOrganization';
import { useDealRoom } from '../../../../Hooks/useDealRoom';
import {
  AttendeesTabContentWrapper,
  TabLabelWithIcon,
} from './DecisionSiteMeetingPanel.styles';
import EditorFrame from '../../../Collab/EditorFrame';
import { CustomEditor } from '../../../../types/slate';
import { DecisionSiteMeetingPanelAIChat } from './DecisionSiteMeetingPanelAIChat';
import {
  externalPlanContacts,
  externalPlanDomains,
} from '../../../../Helpers/MeetingPlanHelpers';
import { GPTChatBoxContext } from '../../../../Components/GPT/GPTChatBoxContext';
import { DecisionSiteVideoTranscript } from './DecisionSiteVideoTranscript';
import {
  VideoPlayerControls,
  VideoPlayerState,
} from '../../../../Components/Video/VideoPlayer';
import { DecisionSiteMeetingPanelTasks } from './DecisionSiteMeetingPanelTasks';
import { DecisionSiteMeetingTranscriptInsights } from './DecisionSiteMeetingTranscriptInsights';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';
import {
  InsightsOutlined,
  PeopleOutline,
  SmartToyOutlined,
  TextSnippetOutlined,
  ChecklistOutlined,
  EditNoteOutlined,
} from '@mui/icons-material';
import { useSearchParams } from 'react-router-dom';
import DecisionSiteMeetingPanelAttendees from './DecisionSiteMeetingPanelAttendees';
import { useDecisionSiteMeetingflowActionItems } from './hooks/useDecisionSiteMeetingflowActionItems';

/**
 * Props interface for DecisionSiteMeetingPanelTabs
 * @property meetingflow - Detailed meeting information and configuration
 * @property notesEditor - Optional editor instance for collaborative note-taking
 * @property readOnly - Flag to control edit permissions
 * @property refetchMeetingflow - Function to refresh meeting data
 * @property videoPlayerState - Current state of video playback
 * @property videoPlayerControls - Video player control functions
 * @property callRecorderStatus - Current status of call recording
 * @property scheduleCallRecording - Function to schedule a new recording
 * @property onContactClick - Handler for contact selection
 * @property onCompanyClick - Handler for company selection
 * @property isRealTime - Flag indicating if this is a real-time session
 * @property transcript - Array of meeting utterances
 * @property onScrollToBottom - Handler for scrolling to the bottom of the transcript
 * @property onScrollToTop - Handler for scrolling to the top of the transcript
 */
interface DecisionSiteMeetingPanelTabsProps {
  meetingflow: DetailedMeetingflow;
  notesEditor?: CustomEditor;
  readOnly: boolean;
  refetchMeetingflow: () => Promise<void>;
  videoPlayerState?: VideoPlayerState;
  videoPlayerControls?: VideoPlayerControls | null;
  callRecorderStatus?: GetCallRecordingStatusData | undefined;
  scheduleCallRecording?: (e: React.MouseEvent) => void;
  onContactClick?: (c: number) => void;
  onCompanyClick?: (c: number) => void;
  isRealTime?: boolean;
  transcript?: Utterance[];
  onScrollToBottom?: () => void;
  onScrollToTop?: () => void;
}

/**
 * Type definition for mention suggestions in the notes editor
 * Used for @mentions functionality in collaborative notes
 */
type DealRoomMentionSuggestion = {
  id: number;
  name: string;
  email: string;
};

/**
 * Main tabbed interface component for meeting management
 * Handles:
 * - Tab state and navigation
 * - Meeting participant organization
 * - Real-time updates and synchronization
 * - Content rendering for each tab
 */
export const DecisionSiteMeetingPanelTabs: React.FC<
  DecisionSiteMeetingPanelTabsProps
> = ({
  meetingflow,
  notesEditor,
  readOnly,
  refetchMeetingflow,
  videoPlayerState,
  videoPlayerControls,
  callRecorderStatus,
  scheduleCallRecording,
  onContactClick,
  onCompanyClick,
  isRealTime,
  transcript,
  onScrollToBottom,
  onScrollToTop,
}) => {
  const { slug: organizationSlug, internalDomains } = useOrganization();
  const { dealRoom } = useDealRoom();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedTab, setSelectedTab] = useState<string>('attendees');

  const { totalTaskCount, refetchTasks } =
    useDecisionSiteMeetingflowActionItems(meetingflow, refetchMeetingflow);

  const mentionSuggestions = useMemo(() => {
    if (!dealRoom?.contacts) return [];
    return dealRoom.contacts.map((contact) => ({
      id: contact.id,
      name: contact.name,
      email: contact.email || '',
    }));
  }, [dealRoom?.contacts]);

  /**
   * State management for AI chatbot context
   * Persists conversation history and context between renders
   */
  const [chatbotContext, setChatbotContext] = React.useState<
    GPTChatBoxContext | undefined
  >(undefined);

  /**
   * Memoized calculations for participant organization
   * Separates internal and external participants for proper display
   */
  const externalDomains = useMemo(
    () => externalPlanDomains(meetingflow, internalDomains),
    [internalDomains, meetingflow],
  );

  const externalContactIds = useMemo(
    () => externalPlanContacts(meetingflow, internalDomains).map((c) => c.id),
    [internalDomains, meetingflow],
  );

  const internalAttendees = useMemo(
    () =>
      meetingflow?.attendees.filter((a) =>
        internalDomains.includes(a.emailDomain),
      ),
    [meetingflow, internalDomains],
  );

  const externalAttendees = useMemo(
    () =>
      meetingflow?.attendees.filter(
        (a) => !internalDomains.includes(a.emailDomain),
      ),
    [meetingflow, internalDomains],
  );

  /**
   * Dynamic tab management
   * Determines available tabs based on meeting state and features
   */
  const availableTabs = useMemo(() => {
    const tabs = ['attendees', 'ai-chat', 'tasks'];
    if (transcript?.length) {
      tabs.unshift('insights');
      tabs.push('transcript');
    }
    if (notesEditor) {
      tabs.push('notes');
    }
    return tabs;
  }, [transcript?.length, notesEditor]);

  const selectedTabFromUrl = useMemo(() => {
    const tabParam = searchParams.get('tab');
    return availableTabs.includes(tabParam || '')
      ? tabParam!
      : meetingflow?.callRecording?.transcriptAnalysis
        ? 'insights'
        : 'attendees';
  }, [
    searchParams,
    availableTabs,
    meetingflow?.callRecording?.transcriptAnalysis,
  ]);

  useEffect(() => {
    setSelectedTab(selectedTabFromUrl);
  }, [selectedTabFromUrl]);

  const meetingflowArtifactId = useMemo(() => {
    return (
      dealRoom?.artifacts.find(
        (artifact) =>
          artifact.type === 'MEETINGFLOW' &&
          artifact.meetingflowId === meetingflow.id,
      )?.id ?? null
    );
  }, [meetingflow.id, dealRoom?.artifacts]);

  const handleTabChange = (
    _: React.SyntheticEvent,
    newValue: string | number,
  ) => {
    setSelectedTab(newValue as string);
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev);
      newParams.set('tab', newValue as string);
      return newParams;
    });
  };

  const mppActionItemsForMeetingflow = dealRoom?.mutualActionItems.filter(
    (item) =>
      item.artifacts?.some(
        (artifact) =>
          artifact.type === 'MEETINGFLOW' &&
          artifact.id === meetingflowArtifactId,
      ),
  );

  if (!meetingflow || !organizationSlug) return null;

  const isFullScreen = false;

  /**
   * Common styling for tab panels
   * Ensures consistent layout and scrolling behavior
   */
  const panelStyles = {
    overflow: 'auto',
    maxHeight: '100%',
    padding: '2rem 2rem 0 2rem',
    borderTop: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
  };

  const handleError = (error: Error) => {
    // TODO: Add proper error handling
  };

  return (
    <DSTabs
      value={selectedTab}
      onChange={handleTabChange}
      className="meeting-panel-tabs"
      variant="scrollable"
      scrollButtons="auto"
      sx={{
        '& .MuiTab-root': {
          minWidth: '50px',
          paddingLeft: '.5rem',
          paddingRight: '.5rem',
        },
      }}
    >
      {/* Insights Tab - Shows meeting analytics and key points */}
      {meetingflow?.callRecording?.transcriptAnalysis && (
        <DSTab
          label={
            <TabLabelWithIcon>
              <InsightsOutlined
                sx={{ fontSize: 20, color: DEALROOMS_COLORS.cloudburst }}
              />
              Insights
            </TabLabelWithIcon>
          }
          value="insights"
          panel={
            <DecisionSiteMeetingTranscriptInsights
              meetingflowId={meetingflow.id}
            />
          }
          panelStyles={panelStyles}
        />
      )}

      {/* Attendees Tab - Displays and manages meeting participants */}
      <DSTab
        count={internalAttendees?.length + externalAttendees?.length}
        label={
          <TabLabelWithIcon>
            <PeopleOutline
              sx={{ fontSize: 20, color: DEALROOMS_COLORS.cloudburst }}
            />
            Attendees
          </TabLabelWithIcon>
        }
        value="attendees"
        panelStyles={panelStyles}
        panel={
          <AttendeesTabContentWrapper>
            <DecisionSiteMeetingPanelAttendees
              meetingflow={meetingflow}
              chatboxContext={chatbotContext}
              setChatBoxContext={setChatbotContext}
            />
          </AttendeesTabContentWrapper>
        }
      />

      {/* AI Chat Tab - Provides AI-powered conversation interface */}
      <DSTab
        label={
          <TabLabelWithIcon>
            <SmartToyOutlined
              sx={{ fontSize: 20, color: DEALROOMS_COLORS.cloudburst }}
            />
            AI Chat
          </TabLabelWithIcon>
        }
        value="ai-chat"
        panelStyles={{ ...panelStyles, padding: 0 }}
        panel={
          <>
            <DecisionSiteMeetingPanelAIChat
              key={meetingflow.id}
              chatboxContext={chatbotContext}
              setChatBoxContext={setChatbotContext}
              meetingPlan={meetingflow!}
              organizationSlug={organizationSlug!}
              internalDomains={internalDomains}
              externalDomains={externalDomains}
              externalContactIds={externalContactIds}
              onMeetingflowClick={(meetingflowId: string) => {
                handleError(new Error('Not implemented'));
              }}
              onContactClick={(contactId: number) => {
                onContactClick?.(contactId);
              }}
              onCompanyClick={(id: number) => {
                onCompanyClick?.(id);
              }}
              onClose={function (): void {
                throw new Error('Function not implemented.');
              }}
            />
          </>
        }
      />

      {/* Transcript Tab - Shows meeting transcript with video sync */}
      {transcript?.length && (
        <DSTab
          label={
            <TabLabelWithIcon>
              <TextSnippetOutlined
                sx={{ fontSize: 20, color: DEALROOMS_COLORS.cloudburst }}
              />
              Transcript
            </TabLabelWithIcon>
          }
          value="transcript"
          panelStyles={{
            ...panelStyles,
            padding: '0',
            overflow: 'hidden',
          }}
          panel={
            <>
              <DecisionSiteVideoTranscript
                playerTime={videoPlayerState?.currentTime}
                meetingPlanId={meetingflow.id}
                onSeekTranscriptUtterance={videoPlayerControls?.seek}
                isFullscreen={videoPlayerState?.isFullscreen}
                onContactClick={onContactClick}
                isRealtime={isRealTime}
                onScrollToBottom={onScrollToBottom}
                onScrollToTop={onScrollToTop}
              />
            </>
          }
        />
      )}

      {/* Tasks Tab - Manages meeting-related tasks */}
      <DSTab
        label={
          <TabLabelWithIcon>
            <ChecklistOutlined
              sx={{ fontSize: 20, color: DEALROOMS_COLORS.cloudburst }}
            />
            Tasks
          </TabLabelWithIcon>
        }
        count={totalTaskCount}
        value="tasks"
        panelStyles={panelStyles}
        panel={
          <>
            <DecisionSiteMeetingPanelTasks
              meetingflow={meetingflow}
              refetchMeetingflow={refetchMeetingflow}
            />
          </>
        }
      />

      {/* Notes Tab - Collaborative note-taking interface */}
      {notesEditor && meetingflow && (
        <DSTab
          label={
            <TabLabelWithIcon>
              <EditNoteOutlined
                sx={{ fontSize: 20, color: DEALROOMS_COLORS.cloudburst }}
              />
              Notes
            </TabLabelWithIcon>
          }
          value="notes"
          panelStyles={{
            ...panelStyles,
            padding: 0,
          }}
          panel={
            <EditorFrame
              name="MeetingPlanNotes"
              readonly={readOnly}
              organizationSlug={organizationSlug}
              meetingflowId={meetingflow.id}
              placeholder="Enter your meeting notes here"
              editor={notesEditor}
              additionalEventProps={{
                organizationSlug,
                meetingPlanId: meetingflow.id,
              }}
              allowTags
              allowMentions
              showPlaceholderHint
              editorMaxHeight={'100%'}
              backgroundColor={DEALROOMS_COLORS.white}
              color={DEALROOMS_COLORS.themePrimary}
              defaultMentionSuggestions={mentionSuggestions}
            />
          }
        />
      )}
    </DSTabs>
  );
};
