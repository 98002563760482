import { styled } from '@mui/material';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';

export const MilestoneContainer = styled('div')<{ hasActionItems: boolean }>(
  ({ hasActionItems }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '0rem',
    // Hide empty MS Layer elements that get injected by Fluent UI
    '.ms-layer:empty': {
      display: 'none',
    },
    marginBottom: '.5rem',
    paddingBottom: '.25rem',
  }),
);

export const TasksList = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '0rem',
  padding: '0',
  borderRadius: '4px',
  '@media (max-width: 768px)': {
    padding: 0,
  },
});
