import { Box, styled } from '@mui/material';
import { DealRoomArtifact } from '@meetingflow/common/Api/data-contracts';
import { AudioArtifactCard } from '../Artifacts/AudioArtifactCard';
import { DocumentArtifactCard } from '../Artifacts/DocumentArtifactCard';
import { FileArtifactCard } from '../Artifacts/FileArtifactCard';
import { ImageArtifactCard } from '../Artifacts/ImageArtifactCard';
import { LinkArtifactCard } from '../Artifacts/LinkArtifactCard';
import { MeetingflowArtifactCard } from '../Artifacts/MeetingflowArtifactCard';
import { VideoArtifactCard } from '../Artifacts/VideoArtifactCard';
import { useDealRoom } from '../../../Hooks/useDealRoom';

const GridContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
}));

import { SortField, SortDirection } from './DealRoomArtifacts';

interface ArtifactsGridViewProps {
  artifacts: DealRoomArtifact[];
  onClick: (id: DealRoomArtifact['id']) => void;
  onDelete: (id: DealRoomArtifact['id']) => Promise<void>;
  organizationSlug: string;
  dealRoomId: number;
  sortField: SortField;
  sortDirection: SortDirection;
  onSortChange: (field: SortField, direction: SortDirection) => void;
}

export const ArtifactsGridView = ({
  artifacts,
  onClick,
  onDelete,
  organizationSlug,
  dealRoomId,
  sortField,
  sortDirection,
  onSortChange,
}: ArtifactsGridViewProps) => {
  const { dealRoom, refetch: refetchDealRoom } = useDealRoom();

  return (
    <GridContainer>
      {artifacts.map((artifact) => {
        const viewCount = 0; // We can add view count later if needed
        // Extract base props from artifact
        const commonProps = {
          id: artifact.id,
          createdAt: artifact.createdAt,
          label: artifact.label,
          name: artifact.name,
          description: artifact.description,
          downvoteCount: artifact.downvoteCount,
          downvoted: artifact.downvoted,
          upvoteCount: artifact.upvoteCount,
          upvoted: artifact.upvoted,
          creator: artifact.creator,
          type: artifact.type,
          deletedAt: artifact.deletedAt,
          updatedAt: artifact.updatedAt,
          featuredAt: artifact.featuredAt,
          viewCount,
          orgSlug: organizationSlug,
          dealRoomId,
          onClick: () => onClick(artifact.id),
          refreshDealRoom: refetchDealRoom,
          onDelete
        };

        switch (artifact.type) {
          case 'MEETINGFLOW':
            return (
              <MeetingflowArtifactCard
                key={artifact.id}
                {...artifact}
                {...commonProps}
              />
            );

          case 'AUDIO':
            return (
              <AudioArtifactCard
                key={artifact.id}
                {...artifact}
                {...commonProps}
              />
            );

          case 'DOCUMENT':
            return (
              <DocumentArtifactCard
                key={artifact.id}
                {...artifact}
                {...commonProps}
              />
            );

          case 'FILE':
            return (
              <FileArtifactCard
                key={artifact.id}
                {...artifact}
                {...commonProps}
              />
            );

          case 'IMAGE':
            return (
              <ImageArtifactCard
                key={artifact.id}
                fileName={artifact.fileName || ''}
                fileUrl={artifact.fileUrl || ''}
                thumbnailFileName={artifact.thumbnailFileName || ''}
                thumbnailUrl={artifact.thumbnailUrl || ''}
                mimeType={artifact.mimeType || ''}
                {...commonProps}
              />
            );

          case 'LINK':
            return (
              <LinkArtifactCard
                key={artifact.id}
                {...artifact}
                {...commonProps}
              />
            );

          case 'VIDEO':
            return (
              <VideoArtifactCard
                key={artifact.id}
                {...artifact}
                {...commonProps}
              />
            );
        }
      })}
    </GridContainer>
  );
};
