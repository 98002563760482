import React, { useState, useMemo, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Autocomplete,
} from '@mui/material';
import { Warning as WarningIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useOrganizationSlug } from '../../../../../Hooks/useOrganizationSlug';
import { useDealRoom } from '../../../../../Hooks/useDealRoom';
import { useUserProfile } from '../../../../../Hooks/useProfile';
import { DSConfigurationHeaderRow } from '../../Components/DSConfigurationHeaderRow';
import useDeviceType from '../../../../../Hooks/deviceDetection';
import { OrganizationMember } from '@meetingflow/common/Api/data-contracts';
import {
  ContentContainer,
  PageTitle,
  DangerBox,
  DangerHeader,
  DangerIcon,
  DangerTitle,
  DangerText,
  Section,
  SectionTitle,
  HelperText,
  TransferButton,
} from './DSConfigurationOwnership.styles';
import {
  DealRoomsApiClient,
  MembersApiClient,
} from '../../../../../Services/NetworkCommon';
import toast from 'react-hot-toast';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from 'react-query';

const ALLOW_GUESTS_IN_OWNERSHIP_OPTIONS = false; // useful for testing

export const DSConfigurationOwnership = () => {
  const navigate = useNavigate();
  const organizationSlug = useOrganizationSlug();
  const { dealRoom } = useDealRoom();
  const { user } = useUserProfile();
  const { isMobile } = useDeviceType();
  const { getAccessTokenSilently } = useAuth0();

  const { data: members } = useQuery({
    queryKey: ['members', organizationSlug],
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      return MembersApiClient.listMembers(
        { organizationSlug: organizationSlug! },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    enabled: !!organizationSlug,
  });

  // Find the current owner in the members list
  const currentOwnerMember = useMemo(() => {
    if (!members || !user?.id) return null;
    return members.data.find((member) => member.id === user.id) || null;
  }, [members, user?.id]);

  const eligibleMembers = useMemo(() => {
    if (!members?.data) return [];
    if (ALLOW_GUESTS_IN_OWNERSHIP_OPTIONS) {
      return members.data;
    }
    return members.data.filter((member) => member.role !== 'GUEST');
  }, [members?.data]);

  const [selectedMember, setSelectedMember] =
    useState<OrganizationMember | null>(currentOwnerMember);

  useEffect(() => {
    if (currentOwnerMember && !selectedMember) {
      setSelectedMember(currentOwnerMember);
    }
  }, [currentOwnerMember, selectedMember]);

  const [confirmationText, setConfirmationText] = useState('');
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);

  const isTransferEnabled =
    selectedMember &&
    confirmationText === organizationSlug &&
    selectedMember.id !== user?.id; // Disable if current owner is selected

  const handleTransferClick = () => {
    setIsConfirmationDialogOpen(true);
  };

  const handleConfirmTransfer = async () => {
    if (!selectedMember || !dealRoom || !organizationSlug) return;

    const token = await getAccessTokenSilently();
    const transferPromise = DealRoomsApiClient.updateDealRoom(
      organizationSlug,
      dealRoom.id,
      {
        ownerId: selectedMember.id,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );

    await toast.promise(transferPromise, {
      loading: 'Transferring ownership...',
      success: `Successfully transferred ownership to ${selectedMember.name || selectedMember.email}`,
      error: 'Failed to transfer ownership',
    });

    // After successful transfer, navigate back to the decision site
    navigate(`/organization/${organizationSlug}/decisionsite/${dealRoom.id}`);
  };

  // Only show this component if the current user is the owner
  if (!dealRoom?.owner?.id || !user?.id || dealRoom.owner.id !== user.id) {
    return null;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2.5 }}>
      <DSConfigurationHeaderRow
        title="Decision Site Ownership"
        description="You are the owner of this Decision Site. Only you can access this page."
      >
        {null}
      </DSConfigurationHeaderRow>

      <ContentContainer isMobile={isMobile}>
        <PageTitle variant="h6">Transfer Ownership</PageTitle>

        <DangerBox>
          <DangerHeader>
            <DangerIcon>
              <WarningIcon />
            </DangerIcon>
            <DangerTitle>Danger Zone</DangerTitle>
          </DangerHeader>
          <DangerText>
            Transferring ownership will make the selected member the new owner
            of this Decision Site. You will remain as a member of the Decision
            Site, but will no longer have owner privileges.
          </DangerText>
        </DangerBox>

        <Section>
          <HelperText>
            Select a new owner for this Decision Site. The new owner must be a
            member of your organization.
          </HelperText>
          <Autocomplete
            value={selectedMember}
            onChange={(_, newValue) => setSelectedMember(newValue)}
            options={eligibleMembers}
            getOptionLabel={(option) => option.name || option.email}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select New Owner"
                variant="outlined"
                fullWidth
              />
            )}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                <Typography>{option.name || option.email}</Typography>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  sx={{ ml: 1 }}
                >
                  ({option.email})
                </Typography>
              </Box>
            )}
          />
        </Section>

        {selectedMember && selectedMember.id !== user?.id && (
          <Section>
            <SectionTitle variant="subtitle1" sx={{ mb: 0 }}>
              Confirm Transfer
            </SectionTitle>
            <HelperText variant="caption">
              To confirm, please type <strong>{organizationSlug}</strong> below:
            </HelperText>
            <TextField
              sx={{ mt: 2 }}
              fullWidth
              variant="outlined"
              value={confirmationText}
              onChange={(e) => setConfirmationText(e.target.value)}
              error={
                confirmationText !== '' && confirmationText !== organizationSlug
              }
              helperText={
                confirmationText !== '' && confirmationText !== organizationSlug
                  ? 'Text does not match'
                  : ''
              }
            />
          </Section>
        )}

        {selectedMember && selectedMember.id !== user?.id && (
          <TransferButton
            variant="contained"
            disabled={!isTransferEnabled}
            onClick={handleTransferClick}
          >
            Transfer Ownership
          </TransferButton>
        )}
      </ContentContainer>

      <Dialog
        open={isConfirmationDialogOpen}
        onClose={() => setIsConfirmationDialogOpen(false)}
      >
        <DialogTitle>
          <DangerHeader>
            <DangerIcon>
              <WarningIcon />
            </DangerIcon>
            Confirm Ownership Transfer
          </DangerHeader>
        </DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to transfer ownership to{' '}
            <strong>{selectedMember?.name ?? selectedMember?.email}</strong>?
            You will remain as a member of the Decision Site, but they will
            become the new owner with full administrative privileges.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setIsConfirmationDialogOpen(false)}
            color="inherit"
          >
            Cancel
          </Button>
          <TransferButton onClick={handleConfirmTransfer}>
            Yes, Transfer Ownership
          </TransferButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
