import React from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import {
  MoreVert as MoreVertIcon,
  Star,
  StarOutlined,
  Delete as DeleteIcon,
  Videocam,
} from '@mui/icons-material';
import {
  DetailedMeetingflow,
  OrganizationEntitlement,
} from '@meetingflow/common/Api/data-contracts';
import { toast } from 'react-hot-toast';
import { MeetingflowsApiClient } from '../../../Services/NetworkCommon';
import { sendWSMessage } from '../../../Helpers/WSHelpers';
import { WS_MESSAGE } from '@meetingflow/common/Constants';
import { QueryClient } from 'react-query';
import { MeetingPlanCallRecorderStatusQuery } from '../../../QueryNames';
import { WebsocketProvider } from 'y-websocket';

interface Props {
  meetingflow: DetailedMeetingflow;
  dealRoomRole: string | undefined;
  organizationSlug: string | undefined;
  meetingflowId: string;
  isArtifactFeatured: boolean;
  hasEntitlement: (entitlement: OrganizationEntitlement) => boolean;
  getAccessTokenSilently: () => Promise<string>;
  handleFeature: (event: React.MouseEvent<HTMLElement>) => Promise<void>;
  handleRemoveFromDecisionSite: (event: React.MouseEvent<HTMLElement>) => void;
  handleDelete: (event: React.MouseEvent<HTMLElement>) => void;
  provider?: WebsocketProvider;
  client: QueryClient;
  refetchRecorderStatus: () => void;
  refetchMeetingflow: () => void;
}

export const DecisionSiteMeetingflowCardContextMenu: React.FC<Props> = ({
  meetingflow,
  dealRoomRole,
  organizationSlug,
  meetingflowId,
  isArtifactFeatured,
  hasEntitlement,
  getAccessTokenSilently,
  handleFeature,
  handleRemoveFromDecisionSite,
  handleDelete,
  provider,
  client,
  refetchRecorderStatus,
  refetchMeetingflow,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  return (
    <div
      className="meetingflow-actions-column"
      onClick={(e: React.MouseEvent) => e.stopPropagation()}
    >
      <IconButton
        size="small"
        sx={{
          padding: '4px',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
        }}
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          event.stopPropagation();
          setAnchorEl(event.currentTarget);
        }}
      >
        <MoreVertIcon fontSize="small" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        onClick={(e: React.MouseEvent) => e.stopPropagation()}
        PaperProps={{
          elevation: 2,
          sx: {
            minWidth: '220px',
            '& .MuiMenuItem-root': {
              py: 1,
              px: 2,
              typography: 'body2',
              '& .MuiSvgIcon-root': {
                fontSize: '20px',
                marginRight: 1.5,
                color: 'text.secondary',
              },
            },
          },
        }}
      >
        {dealRoomRole === 'SELLER' && organizationSlug && (
          <MenuItem onClick={handleFeature}>
            {isArtifactFeatured ? (
              <Star fontSize="small" />
            ) : (
              <StarOutlined fontSize="small" />
            )}
            {isArtifactFeatured
              ? 'Remove from Featured Section'
              : 'Add to Featured Section'}
          </MenuItem>
        )}
        <MenuItem onClick={handleRemoveFromDecisionSite}>
          <DeleteIcon fontSize="small" />
          Remove from Decision Site
        </MenuItem>
        {/* <MenuItem
          onClick={handleDelete}
          sx={{
            color: 'error.main',
            '& .MuiSvgIcon-root': {
              color: 'error.main',
            },
          }}
        >
          <DeleteIcon fontSize="small" />
          Delete Meeting
        </MenuItem> */}
        {import.meta.env.DEV &&
        hasEntitlement('CALL_RECORDING') &&
        meetingflow?.callRecording &&
        organizationSlug ? (
          <MenuItem
            onClick={() => {
              getAccessTokenSilently().then((token) =>
                toast.promise(
                  MeetingflowsApiClient.syncRecording(
                    organizationSlug,
                    meetingflowId,
                    {
                      headers: { Authorization: `Bearer ${token}` },
                      validateStatus: (status) => [200, 201].includes(status),
                    },
                  ),
                  {
                    loading: `Syncing Call Recording`,
                    error: (err) => {
                      console.error(err);
                      return `Something went wrong syncing call recording`;
                    },
                    success: (response) => {
                      if (provider) {
                        sendWSMessage(provider.ws, WS_MESSAGE.REFRESH_PLAN);
                        sendWSMessage(
                          provider.ws,
                          WS_MESSAGE.REFRESH_CALL_RECORDING_STATUS,
                        );
                      }

                      // Clean up the scheduleCallRecording parameter from URL if it exists
                      const url = new URL(window.location.href);
                      if (url.searchParams.has('scheduleCallRecording')) {
                        url.searchParams.delete('scheduleCallRecording');
                        window.history.replaceState({}, '', url.toString());
                      }

                      client.removeQueries(
                        MeetingPlanCallRecorderStatusQuery(
                          organizationSlug,
                          meetingflowId,
                        ),
                      );

                      refetchRecorderStatus();
                      refetchMeetingflow();

                      return `Successfully synced call recording`;
                    },
                  },
                ),
              );
            }}
          >
            <Videocam fontSize="small" />
            Sync Call Recording
          </MenuItem>
        ) : null}
      </Menu>
    </div>
  );
};
