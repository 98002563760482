import { mergeStyles, Stack } from '@fluentui/react';
import { useCallback, useMemo, useState } from 'react';
import { BuyerOrientationSurveyStepIndicator } from '../Components/BuyerOrientationSurvey/BuyerOrientationSurveyStepIndicator';
import { BuyerOrientationSurveySecondaryHeader } from '../Components/BuyerOrientationSurvey/BuyerOrientationSurveySecondaryHeader';
import { BuyerOrientationDialogProps } from '../../../types/BuyerOrientationSurveyTypes';
import {
  optionValuesThatNeedPhoneNumber,
  stepNames,
  stepNamesBasedOnIndex,
  surveyStepComponents,
} from '../Components/BuyerOrientationSurvey/buyerOrientationSurveyUtils';
import { useBuyerSurveyReducer } from '../../../Hooks/useBuyerSurveyReducer';
import { DealRoomsApiClient } from '../../../Services/NetworkCommon';
import { useAuth0 } from '@auth0/auth0-react';
import {
  AssistancePreference,
  BuyerPriorities,
  CommunicationPreference,
  ConsumptionPreference,
  UpdateDealRoomContactPayload,
} from '@meetingflow/common/Api/data-contracts';
import toast from 'react-hot-toast';
import useDeviceType from '../../../Hooks/deviceDetection';
import { DSResponsiveModal } from '../DS';
import useUserConfigurationsStore from '../../../Stores/userConfigurationsStore';
import { isValidPhoneNumber } from 'react-phone-number-input';

// Total number of steps in the orientation survey
const numberOfSteps = 4;

/**
 * BuyerOrientationDialog Component
 *
 * A multi-step dialog that guides buyers through setting their preferences and orientation
 * for using the deal room. Handles user preferences like assistance, consumption,
 * communication preferences and priorities.
 */

export const BuyerOrientationDialog = ({
  handleDismiss,
  ownerAvatarUrl,
  ownerName,
  userName,
  currentUserDetails,
  organizationSlug,
  dealRoomId,
  userEmail,
  userRole,
  refetchDealRoomData,
}: BuyerOrientationDialogProps) => {
  const { isMobile } = useDeviceType();

  const { getAccessTokenSilently } = useAuth0();

  // State management
  const [step, setStep] = useState(1);
  const [orientationForm, updateOrientationForm] = useBuyerSurveyReducer();
  const [showDialog, setShowDialog] = useState<boolean>(true);

  // Get the phone country code from the store
  const surveyPhoneCountryCode = useUserConfigurationsStore(
    (state) => state.surveyPhoneCountryCode,
  );

  // Get the component for the current step
  const CurrentStepComponent = useMemo(() => {
    const stepName = stepNamesBasedOnIndex[`${step}`];
    return surveyStepComponents[stepName];
  }, [step]);

  // Get the name of the current step
  const currentStepName = useMemo(
    () => stepNamesBasedOnIndex[`${step}`],
    [step],
  );

  // Styles for the modal dialog
  const modalStyle = mergeStyles({
    '.buyer-orientation-wrapper': {
      width: '100%',
      '.buyer-orientation-content': {
        padding: isMobile ? '2rem 1rem 3rem' : '2rem 4rem 3rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: '2rem',
      },
    },
  });

  // Determine if the Next button should be disabled based on validation rules
  const isNextButtonDisabled = useMemo(() => {
    const {
      assistancePreference,
      consumptionPreference,
      communicationPreference,
      phoneNumber,
      priorities,
      otherPriorityText,
    } = orientationForm;

    // Validation rules for each step
    const validationRules = {
      [stepNames.assistancePreference]: () => !assistancePreference,

      [stepNames.consumptionPreference]: () => !consumptionPreference,

      [stepNames.communicationPreference]: () => {
        const requiresPhone = optionValuesThatNeedPhoneNumber.includes(
          communicationPreference || '',
        );
        return (
          !communicationPreference ||
          (requiresPhone &&
            (!phoneNumber ||
              !isValidPhoneNumber(phoneNumber, surveyPhoneCountryCode)))
        );
      },

      [stepNames.priorities]: () => {
        const hasOtherOption = priorities.includes('OTHER');
        return !priorities.length || (hasOtherOption && !otherPriorityText);
      },
    };

    // Return validation result for current step, or false if step not found
    return validationRules[currentStepName]?.() ?? false;
  }, [currentStepName, orientationForm, surveyPhoneCountryCode]);

  const onDismiss = useCallback(() => {
    handleDismiss();
    setShowDialog(false);
  }, [handleDismiss, setShowDialog]);

  // Handler for updating options in the backend
  const handleUpdateOption = useCallback(
    async (step: number) => {
      if (!currentUserDetails) {
        return;
      }

      const currentStepName = stepNamesBasedOnIndex[step];
      const token = await getAccessTokenSilently();

      // Initialize update payload with required role
      const dataToUpdate: UpdateDealRoomContactPayload = {
        role: userRole,
      };

      // Helper function to check if value has changed
      const hasValueChanged = (
        newVal: string[] | string | null,
        oldVal: string[] | string | null,
      ) => {
        if (Array.isArray(newVal)) {
          return JSON.stringify(newVal) !== JSON.stringify(oldVal || []);
        }
        return newVal !== oldVal;
      };

      let needsPhone = false;
      let phoneChanged = false;

      let hasOtherPriority = false;
      let otherTextChanged = false;

      // Update data based on current step
      switch (currentStepName) {
        case stepNames.assistancePreference:
          if (
            hasValueChanged(
              orientationForm.assistancePreference,
              currentUserDetails.assistancePreference,
            )
          ) {
            dataToUpdate.assistancePreference =
              orientationForm.assistancePreference as AssistancePreference;
          }
          break;

        case stepNames.consumptionPreference:
          if (
            hasValueChanged(
              orientationForm.consumptionPreference,
              currentUserDetails.consumptionPreference,
            )
          ) {
            dataToUpdate.consumptionPreference =
              orientationForm.consumptionPreference as ConsumptionPreference;
          }
          break;

        case stepNames.communicationPreference:
          // Update communication preference if changed
          if (
            hasValueChanged(
              orientationForm.communicationPreference,
              currentUserDetails.communicationPreference,
            )
          ) {
            dataToUpdate.communicationPreference =
              orientationForm.communicationPreference as CommunicationPreference;
          }

          needsPhone = optionValuesThatNeedPhoneNumber.includes(
            orientationForm.communicationPreference || '',
          );
          phoneChanged =
            orientationForm.phoneNumber !== currentUserDetails.phoneNumber;

          // Add phone if needed and changed
          if (needsPhone && phoneChanged) {
            dataToUpdate.phoneNumber = orientationForm.phoneNumber;
          }
          // Remove phone if no longer needed
          else if (!needsPhone && currentUserDetails.phoneNumber) {
            dataToUpdate.phoneNumber = '';
          }
          break;

        case stepNames.priorities:
          // Update priorities if changed
          if (
            hasValueChanged(
              orientationForm.priorities,
              currentUserDetails.priorities,
            )
          ) {
            dataToUpdate.priorities =
              orientationForm.priorities as BuyerPriorities[];
          }

          hasOtherPriority = orientationForm.priorities?.includes('OTHER');
          otherTextChanged =
            orientationForm.otherPriorityText !==
            currentUserDetails.otherPriorityText;

          // Add other priority text if needed and changed
          if (hasOtherPriority && otherTextChanged) {
            dataToUpdate.otherPriorityText = orientationForm.otherPriorityText;
          } else if (
            !hasOtherPriority &&
            currentUserDetails.otherPriorityText
          ) {
            // Remove other priority text if no longer needed
            dataToUpdate.otherPriorityText = '';
          }

          break;
      }

      // Only make API call if we have changes to update
      if (Object.keys(dataToUpdate).length <= 1) {
        // No changes needed, just move to next step
        setStep(step + 1);
        return;
      }

      await toast.promise(
        DealRoomsApiClient.updateDealRoomContact(
          organizationSlug,
          dealRoomId,
          userEmail,
          dataToUpdate,
          { headers: { Authorization: `Bearer ${token}` } },
        ),
        {
          loading: 'Updating the option',
          success: () => {
            if (step < numberOfSteps) {
              setStep(step + 1);
            }
            if (refetchDealRoomData) {
              refetchDealRoomData().then();
            }

            if (step === numberOfSteps) {
              onDismiss();
            }
            return 'The option was successfully updated.';
          },
          error: 'Something went wrong. Please try again later',
        },
      );
    },
    [
      onDismiss,
      currentUserDetails,
      dealRoomId,
      getAccessTokenSilently,
      organizationSlug,
      refetchDealRoomData,
      orientationForm.assistancePreference,
      orientationForm.communicationPreference,
      orientationForm.consumptionPreference,
      orientationForm.otherPriorityText,
      orientationForm.phoneNumber,
      orientationForm.priorities,
      userEmail,
      userRole,
    ],
  );

  // Handler for Next button click
  const handleClickNext = useCallback(async () => {
    await handleUpdateOption(step);
  }, [handleUpdateOption, step]);

  // Handler for Back button click
  const handleClickBack = useCallback(() => {
    if (step > 1) {
      setStep(step - 1);
    } else {
      onDismiss();
    }
  }, [onDismiss, step]);

  return (
    <DSResponsiveModal
      open={showDialog}
      onDismiss={onDismiss}
      fullScreenOnMobile
      customMaxWidth={910}
      title={'Preferences'}
      dismissable={false}
      showKeepOpenToggle={false}
      keepOpenToggleValue={step < numberOfSteps}
      secondaryFooterButtons={[
        {
          children: step === 1 ? "I'll do this later" : 'Back',
          onClick: handleClickBack,
        },
      ]}
      primaryFooterButtons={[
        {
          children: step === numberOfSteps ? 'Done' : 'Next',
          onClick: handleClickNext,
          disabled: isNextButtonDisabled,
        },
      ]}
    >
      <div className={modalStyle}>
        <Stack className="buyer-orientation-wrapper">
          <BuyerOrientationSurveySecondaryHeader
            step={step}
            avatarUrl={ownerAvatarUrl}
            userName={userName}
            ownerName={ownerName}
          />
          <BuyerOrientationSurveyStepIndicator
            step={step}
            numberOfSteps={numberOfSteps}
          />
          <div className="buyer-orientation-content">
            <CurrentStepComponent
              orientationForm={orientationForm}
              updateOrientationForm={updateOrientationForm}
            />
          </div>
        </Stack>
      </div>
    </DSResponsiveModal>
  );
};
