import { useAuth0 } from '@auth0/auth0-react';
import {
  Dropdown,
  FontIcon,
  FontWeights,
  IColumn,
  IGroup,
  IStyle,
  PrimaryButton,
  SelectionMode,
  Text,
  mergeStyles,
} from '@fluentui/react';
import { OrganizationMemberRole } from '@meetingflow/common/Api/data-contracts';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { orderBy } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useQuery, useQueryClient } from 'react-query';
import {
  ROLE_DROPDOWN_OPTIONS,
  ROLE_DROPDOWN_OPTIONS_NO_GUEST,
} from '../../../Helpers/Organizations';
import { useOrganization } from '../../../Hooks/useOrganization';
import { useUserProfile } from '../../../Hooks/useProfile';
import { InvitesQuery, OrganizationMembersQuery } from '../../../QueryNames';
import {
  InvitesApiClient,
  MembersApiClient,
  TextClient,
} from '../../../Services/NetworkCommon';
import { AsyncIconButton } from '../../HOC/AsyncButton';
import StyledDateTime from '../../StyledDateTime';
import { StyledSpinner } from '../../StyledSpinner';
import { SettingsStyledDetailsList } from './SettingsStyledDetailList';
import {
  settingsSectionDescriptionClass,
  settingsSectionDescriptionClassDark,
  settingsSectionDescriptionIconClass,
} from './SettingsPageLayout';
import { useLightOrDarkMode } from '../../../Hooks/useLightOrDarkMode';

export type OrganizationMembersProps = {
  organizationSlug: string;
};
export const OrganizationMembers = ({
  organizationSlug,
}: OrganizationMembersProps) => {
  const { getAccessTokenSilently } = useAuth0();

  const { userId } = useUserProfile();
  const { isAdmin, organization } = useOrganization(organizationSlug);

  const client = useQueryClient();

  const { isDark } = useLightOrDarkMode();

  const [guestInviteAsRoles, setGuestInviteAsRoles] = useState<{
    [key: string | number]: string | number;
  }>({});

  const appInsights = useAppInsightsContext();

  const { data: orgMembersData, isLoading: orgMembersLoading } = useQuery(
    OrganizationMembersQuery(organizationSlug!),
    async () => {
      const token = await getAccessTokenSilently();
      return MembersApiClient.listMembers(
        { organizationSlug },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
  );

  const getInviteButtonDisabledForMember = useCallback(
    (memberId: string | number) => {
      return (
        (guestInviteAsRoles[memberId.toString()] &&
          !['ADMIN', 'CREATOR', 'COLLABORATOR'].includes(
            guestInviteAsRoles[memberId.toString()].toString(),
          )) ||
        guestInviteAsRoles[memberId.toString()] === undefined
      );
    },
    [guestInviteAsRoles],
  );

  const memberColumns: IColumn[] = useMemo(
    () => [
      {
        key: 'name',
        name: 'Name',
        minWidth: 150,
        maxWidth: 300,
        fieldName: 'name',
        onRender: (member) => {
          return (
            <Text style={{ fontWeight: FontWeights.semibold }}>
              {member.name}
            </Text>
          );
        },
      },
      {
        key: 'email',
        name: 'Email',
        minWidth: 200,
        maxWidth: 250,
        fieldName: 'email',
        onRender: (member) => {
          return <Text>{member.email}</Text>;
        },
      },
      {
        key: 'lastSeen',
        name: 'Last Seen',
        minWidth: 200,
        maxWidth: 200,
        fieldName: 'lastSeen',
        onRender: (member) => {
          return (
            <Text>
              <StyledDateTime dateTime={member.lastSeen} useRelativeDates />
            </Text>
          );
        },
      },
      {
        key: 'role',
        name: 'Role',
        minWidth: 450,
        maxWidth: 450,
        fieldName: 'role',
        onRender: (member: {
          id: number;
          role: string;
          name: string;
          email: string;
        }) => {
          if (member?.role === 'GUEST') {
            const inviteDisabled = getInviteButtonDisabledForMember(member.id);
            return (
              <>
                <Dropdown
                  styles={{ root: { display: 'inline-block', width: '362px' } }}
                  options={ROLE_DROPDOWN_OPTIONS}
                  defaultSelectedKey={member?.role}
                  disabled={!isAdmin || member?.id === userId}
                  onChange={(_evt, option) => {
                    if (option) {
                      setGuestInviteAsRoles({
                        ...guestInviteAsRoles,
                        [member.id.toString()]: option?.key,
                      });
                    }
                  }}
                />
                <PrimaryButton
                  disabled={inviteDisabled}
                  onClick={async () => {
                    const token = await getAccessTokenSilently();
                    const result = await InvitesApiClient.putInvite(
                      {
                        organizationSlug,
                        email: member.email,
                        role: guestInviteAsRoles[
                          member?.id
                        ] as OrganizationMemberRole,
                        product: 'MEETINGFLOW',
                      },
                      {
                        headers: { Authorization: `Bearer ${token}` },
                      },
                    );

                    if (result.status === 201) {
                      client.invalidateQueries(InvitesQuery());
                      client.invalidateQueries(InvitesQuery('SENT'));

                      toast.success(
                        `${
                          member.name || member.email
                        } has been invited to become a member of ${
                          organization?.name || organizationSlug
                        }.`,
                      );
                      appInsights.trackEvent({
                        name: 'SEND_INVITE_UPGRADING_GUEST',
                        properties: {
                          organizationSlug,
                          email: member.email,
                          role: member.role,
                        },
                      });
                    } else if (result.status === 200) {
                      client.invalidateQueries(InvitesQuery());
                      client.invalidateQueries(InvitesQuery('SENT'));
                      toast.success(
                        `${
                          member.name || member.email
                        } was sent an invitation reminder`,
                      );
                    }
                  }}
                  styles={{
                    root: {
                      float: 'right',
                      margin: '0 !important',
                      display: 'inline-block',
                      width: '20%',
                    },
                  }}
                >
                  Invite
                </PrimaryButton>
              </>
            );
          } else {
            return (
              <Dropdown
                options={ROLE_DROPDOWN_OPTIONS_NO_GUEST}
                selectedKey={member?.role}
                disabled={!isAdmin || member?.id === userId}
                onChange={async (_evt, option) => {
                  if (!option) {
                    return;
                  }

                  const token = await getAccessTokenSilently();
                  const result = await TextClient.put(
                    `/organization/${organizationSlug}/member/${member?.id}/role`,
                    option.key,
                    {
                      headers: { Authorization: `Bearer ${token}` },
                    },
                  );
                  if (result.status === 200) {
                    appInsights.trackEvent({
                      name: 'CHANGE_MEMBER_ROLE',
                      properties: {
                        organizationSlug,
                        memberId: member.id,
                        previousRole: member.role,
                        newRole: option.key,
                      },
                    });
                    client.invalidateQueries(
                      OrganizationMembersQuery(organizationSlug!),
                    );
                  }
                }}
              />
            );
          }
        },
      },
      {
        key: 'delete',
        name: 'Delete',
        minWidth: 48,
        onRender: (member, _column) => (
          <AsyncIconButton
            iconProps={{ iconName: 'Delete' }}
            disabled={!isAdmin || member?.id === userId}
            onClick={async () => {
              const token = await getAccessTokenSilently();
              await toast.promise(
                MembersApiClient.deleteMember(organizationSlug, member.id, {
                  headers: { Authorization: `Bearer ${token}` },
                }),
                {
                  loading: 'Removing user',
                  success: (result) => {
                    if (result.status === 204) {
                      client.invalidateQueries(
                        OrganizationMembersQuery(organizationSlug!),
                      );
                      return `${
                        member.name || member.email
                      } has been removed from your workspace.`;
                    }
                    return `Unable to remove ${
                      member.name || member.email
                    } from your workspace.`;
                  },
                  error: (err) => {
                    return `Unable to remove ${
                      member.name || member.email
                    } from your workspace.`;
                  },
                },
              );
            }}
          />
        ),
      },
    ],
    [
      appInsights,
      client,
      getAccessTokenSilently,
      getInviteButtonDisabledForMember,
      guestInviteAsRoles,
      isAdmin,
      organization?.name,
      organizationSlug,
      userId,
    ],
  );

  const regularMemberships = useMemo(
    () => orgMembersData?.data?.filter((m) => m.role !== 'GUEST'),
    [orgMembersData?.data],
  );

  const guestMemberships = useMemo(
    () => orgMembersData?.data?.filter((m) => m.role === 'GUEST'),
    [orgMembersData?.data],
  );

  const memberships = useMemo(() => {
    return [
      ...orderBy(regularMemberships, ['role'], ['asc']),
      ...orderBy(guestMemberships, ['role'], ['asc']),
    ];
  }, [guestMemberships, regularMemberships]);

  const groups = useMemo(
    () =>
      [
        {
          key: 'regular',
          name: 'Members',
          startIndex: 0,
          count: regularMemberships?.length,
          level: 0,
          isCollapsed: false,
        },
        {
          key: 'guest',
          name: 'Guests',
          startIndex: regularMemberships?.length,
          count: guestMemberships?.length,
          level: 0,
          isCollapsed: false,
        },
      ] as IGroup[],
    [guestMemberships?.length, regularMemberships?.length],
  );

  if (orgMembersLoading) {
    return <StyledSpinner />;
  }

  return (
    <>
      <SettingsStyledDetailsList
        items={memberships}
        columns={memberColumns}
        selectionMode={SelectionMode.none}
        groups={groups}
        maxRowHeight={'none'}
      />

      <Text
        className={
          isDark
            ? mergeStyles(settingsSectionDescriptionClassDark as IStyle)
            : mergeStyles(settingsSectionDescriptionClass as IStyle)
        }
        style={{ marginTop: '1rem', marginBottom: '0' }}
      >
        <FontIcon
          iconName="Info"
          className={settingsSectionDescriptionIconClass}
        />
        <ul>
          <li>
            Admin, Creator, and Collaborator members have access to all
            Meetingflows.
          </li>
          <li>
            Guest members have access only to Meetingflows they've been
            explicitly invited to collaborate on.
          </li>
          <li>
            Admin, Creator, and Collaborator members may invite others to
            collaborate on Meetingflows.
          </li>
          <li>
            Guest members may not invite others to collaborate on Meetingflows.
          </li>
        </ul>
      </Text>
    </>
  );
};
