import { Typography, Avatar } from '@mui/material';
import {
  DealRoomArtifact,
  DealRoomArtifactBase,
} from '@meetingflow/common/Api/data-contracts';
import { humanizeDateTime } from '@meetingflow/common/DateHelpers';
import React, { PropsWithChildren } from 'react';
import { useShareDealRoomArtifactDialog } from '../../../Hooks/Modals/DealRoom/useShareDealRoomArtifactDialog';
import { useConfirmationDialog } from '../../../Hooks/Modals/useConfirmationDialog';
import { useUserProfile } from '../../../Hooks/useProfile';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';
import { useQueryClient } from 'react-query';
import { ArtifactsTableActions } from './ArtifactsTableActions';
import { useDealRoom } from '../../../Hooks/useDealRoom';
import { useOrganizationSlug } from '../../../Hooks/useOrganizationSlug';
import { avatarPropsFromString } from '../DS/DSUserAvatar';
import { getMUIIconForArtifactType } from '../../../Helpers/IconHelpers';
import { DSTooltip } from '../DS';
import {
  StyledCard,
  Header,
  TitleContainer,
  ActionsButton,
  Title,
  Preview,
  Activity,
} from './BaseArtifactCard.styles';
import { OrganizationDealRoomArtifactsQuery } from '../../../QueryNames';

export type BaseArtifactCardProps = Pick<
  DealRoomArtifactBase,
  | 'id'
  | 'createdAt'
  | 'label'
  | 'name'
  | 'description'
  | 'downvoteCount'
  | 'downvoted'
  | 'upvoteCount'
  | 'upvoted'
  | 'creator'
  | 'type'
  | 'deletedAt'
  | 'updatedAt'
  | 'featuredAt'
> & {
  className?: string;
  orgSlug: string;
  dealRoomId: number;
  viewCount?: number;
  mimeType?: string | null;
  refreshDealRoom: () => Promise<unknown>;
  onClick?: (artifactId: DealRoomArtifact['id']) => void;
  onDelete: (artifactId: number) => Promise<void>;
};

export const BaseArtifactCard = ({
  className,
  id,
  createdAt,
  deletedAt,
  updatedAt,
  featuredAt,
  creator,
  type,
  mimeType,
  name,
  description,
  label,
  downvoted,
  downvoteCount,
  upvoted,
  upvoteCount,
  children,
  dealRoomId,
  orgSlug,
  viewCount,
  refreshDealRoom,
  onClick,
  onDelete,
}: PropsWithChildren<BaseArtifactCardProps>) => {
  const { userId } = useUserProfile();
  const organizationSlug = useOrganizationSlug();
  const { dealRoomRole } = useDealRoom(organizationSlug, dealRoomId);
  const client = useQueryClient();

  const {
    createDeferred: createConfirmDeleteDeferred,
    dialog: confirmDeleteDialog,
  } = useConfirmationDialog({
    title: `Delete Artifact?`,
    subText:
      'Deleting this Artifact will delete it for all users and cannot be undone. Are you sure you want to delete this Artifact?',
    primaryAction: 'CANCEL',
  });

  const {
    createDeferred: createConfirmShareDeferred,
    dialog: confirmShareDialog,
  } = useShareDealRoomArtifactDialog({
    organizationSlug: orgSlug,
    artifactName: name,
  });

  return (
    <>
      <StyledCard
        className={className}
        onClick={onClick ? () => onClick(id) : undefined}
      >
        <Header>
          <TitleContainer>
            {React.createElement(
              getMUIIconForArtifactType(type, mimeType as string),
              {
                sx: {
                  width: 20,
                  height: 20,
                  color: DEALROOMS_COLORS.cloudburst,
                },
              },
            )}
            <Title>{label || name}</Title>
          </TitleContainer>
          <ActionsButton>
            <ArtifactsTableActions
              userId={userId}
              dealRoomRole={dealRoomRole}
              organizationSlug={orgSlug}
              dealRoomId={dealRoomId}
              refreshDealRoom={refreshDealRoom}
              onDelete={onDelete}
              refetchArtifacts={async () => {
                await Promise.allSettled([
                  client.refetchQueries({
                    queryKey: ['artifacts', dealRoomId, orgSlug],
                  }),
                  client.refetchQueries(
                    OrganizationDealRoomArtifactsQuery(orgSlug, dealRoomId),
                  ),
                ]);
              }}
              itemData={{
                artifactId: id,
                sharedOn: createdAt,
                creator,
                description: description,
                owner: creator?.name || '',
                lastModified: updatedAt,
                type,
                name,
                downvoted,
                downvotes: downvoteCount,
                upvoted,
                mimeType: '',
                upvotes: upvoteCount,
                deletedAt,
                featured: !!featuredAt,
                key: id,
                updatedAt,
                featuredAt,
              }}
            />
          </ActionsButton>
        </Header>
        <Preview>{children}</Preview>
        <Activity>
          {creator && (creator.avatarFileUrl || creator.avatarUrl) && (
            <DSTooltip
              title={
                <>
                  Added by {creator?.name || creator?.email} <br />
                  {humanizeDateTime(createdAt, { displayComponents: ['date'] })}
                </>
              }
            >
              <Avatar
                {...avatarPropsFromString(creator.name || creator.email || '')}
                src={creator.avatarFileUrl || creator.avatarUrl || undefined}
                sx={{ width: 24, height: 24 }}
              />
            </DSTooltip>
          )}
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {description}
          </Typography>
        </Activity>
      </StyledCard>
      {confirmDeleteDialog}
      {confirmShareDialog}
    </>
  );
};
