import { useNavigate } from 'react-router-dom';
import { Text, Stack, DefaultButton } from '@fluentui/react';
import { useAuth0 } from '@auth0/auth0-react';
import { DealRoomPrimaryButton } from '../DealRoom/Components/DealRoomButton';
import { NoAccessIllustration } from '../DealRoom/Components/Illustrations/NoAccessIllustration';
import { useCallback, useMemo } from 'react';
import { NotFoundLayout } from '../Layouts/NotFoundLayout';
import { useNotFoundContentStyles } from '../../Hooks/styles/useNotFoundContentStyles';

export const OrganizationForbidden = ({
  organizationSlug,
  userEmail,
}: {
  organizationSlug: string;
  userEmail: string | undefined;
}) => {
  const navigate = useNavigate();
  const { logout } = useAuth0();
  const clientId = import.meta.env.VITE_AUTH0_CLIENT_ID!;

  const styles = useNotFoundContentStyles();


  const handleClickActionButton = useCallback(() => {
    navigate('/');
  }, [navigate]);

  return (
    <NotFoundLayout illustration={<NoAccessIllustration />}>
      <div className={styles.subtitleContainer}>
        <Text className="subtitle-item">Forbidden!</Text>
      </div>
      <div className={styles.descriptionContainer}>
        <Text className="description-item">
          You don't have access to
          that page in the <strong>{organizationSlug}</strong> workspace. Please contact the owner of the workspace or the
          person who shared this link with you for access.
        </Text>
      </div>
      <DealRoomPrimaryButton
        customClasses={styles.actionButton}
        onClick={handleClickActionButton}
      >
        Back to Main Page
      </DealRoomPrimaryButton>
      {userEmail ? (
        <Stack.Item style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', gap: '8px' }}>
            <Text className={styles.email}>
              {userEmail}
            </Text>
            <DefaultButton
              onClick={() => 
                logout({
                  clientId,
                  logoutParams: { returnTo: window.location.origin },
                })
              }
              styles={{
                root: {
                  fontSize: '0.875rem',
                  padding: '4px 12px',
                  minWidth: 'auto',
                },
              }}
            >
              Sign Out
            </DefaultButton>
        </Stack.Item> 
      ) : <></>}

    </NotFoundLayout>
  );
};

export default OrganizationForbidden;
