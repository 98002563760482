/**
 * DecisionSiteVideoTranscript is a sophisticated component that displays and manages
 * meeting transcripts with interactive features. It provides real-time transcript viewing,
 * search capabilities, speaker filtering, and timestamp-based navigation.
 *
 * Key Features:
 * - Real-time transcript display with auto-scroll
 * - Speaker-based filtering and search
 * - Interactive timestamp navigation
 * - Video player synchronization
 * - Sharing capabilities
 * - Dark/Light mode support
 * - Accessibility considerations
 */

import { mergeStyles } from '@fluentui/react';
import {
  Attendee,
  CallRecordingStatus,
  Contact,
  Utterance,
} from '@meetingflow/common/Api/data-contracts';
import { DeduplicateArray } from '@meetingflow/common/ArrayHelpers';
import { transcriptify } from '@meetingflow/common/TranscriptHelpers';
import { Truthy } from '@meetingflow/common/TypeHelpers';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import classNames from 'classnames';
import { Duration } from 'luxon';
import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import toast from 'react-hot-toast';
import { useLightOrDarkMode } from '../../../../Hooks/useLightOrDarkMode';
import { useOrganization } from '../../../../Hooks/useOrganization';
import {
  DEALROOMS_COLORS,
  MEETINGFLOW_COLORS,
} from '../../../../Themes/Themes';
import {
  Typography,
  IconButton,
  Menu,
  MenuItem,
  useTheme,
  Switch,
  FormControlLabel,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FastForwardIcon from '@mui/icons-material/FastForward';
import ShareIcon from '@mui/icons-material/Share';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { DSTextField } from '../../DS/DSTextField';
import { DSAutocomplete } from '../../DS/DSAutocomplete';
import { useMeetingflow } from './hooks/useMeetingflow';

/**
 * Extends the base Utterance type with additional fields for UI interaction
 * and timestamp management.
 * @property idx - Unique index for each utterance
 * @property text - Transcribed text content
 * @property startTime - Beginning timestamp of utterance
 * @property endTime - Ending timestamp of utterance
 */
interface ExtendedUtterance extends Utterance {
  idx: number;
  text: string;
  startTime: number;
  endTime: number;
}

/**
 * Defines the structure for speaker selection options
 * @property label - Display name for the speaker
 * @property value - Unique identifier for the speaker
 */
interface SpeakerOption {
  label: string;
  value: string;
}

/**
 * Formats seconds into human-readable timestamp (HH:MM:SS)
 * @param seconds - Number of seconds to format
 * @returns Formatted time string
 */
const formatSeconds = (seconds: number) =>
  Duration.fromObject({ seconds }).toFormat('h:mm:ss');

/**
 * Props interface for DecisionSiteVideoTranscript
 * @property playerTime - Current video player timestamp
 * @property meetingPlanId - Unique identifier for the meeting
 * @property onSeekTranscriptUtterance - Callback for transcript navigation
 * @property showCollabDialogForTimestamp - Callback for collaboration features
 * @property isFullscreen - Flag for fullscreen mode
 * @property onContactClick - Callback for contact interactions
 * @property isRealtime - Flag for real-time transcription mode
 * @property onScrollToBottom - Callback for scrolling to the bottom of the transcript
 * @property onScrollToTop - Callback for scrolling to the top of the transcript
 */
export type DecisionSiteVideoTranscriptProps = {
  playerTime?: number;
  meetingPlanId?: string;
  onSeekTranscriptUtterance?: (startTime: number) => void;
  showCollabDialogForTimestamp?: (timestamp: number) => void;
  isFullscreen?: boolean;
  onContactClick?: (c: Contact['id']) => void;
  isRealtime?: boolean;
  onScrollToBottom?: () => void;
  onScrollToTop?: () => void;
};

/**
 * Main transcript component that provides an interactive interface for viewing
 * and navigating meeting transcripts. Features include:
 * - Speaker-based filtering
 * - Text search
 * - Auto-scroll functionality
 * - Timestamp-based navigation
 * - Sharing capabilities
 * - Dark/Light mode support
 * - Accessibility considerations
 *
 * The component handles both real-time and recorded transcripts, with special
 * considerations for each mode. It integrates with the video player for
 * synchronized playback and navigation.
 */
export const DecisionSiteVideoTranscript = ({
  playerTime,
  meetingPlanId,
  onSeekTranscriptUtterance,
  showCollabDialogForTimestamp,
  isFullscreen,
  onContactClick,
  isRealtime,
  onScrollToBottom,
  onScrollToTop,
}: DecisionSiteVideoTranscriptProps) => {
  const { slug: organizationSlug } = useOrganization();
  const [autoScroll, setAutoScroll] = useState<boolean>(true);
  const { isDark } = useLightOrDarkMode();
  const appInsights = useAppInsightsContext();

  // Get data from useMeetingflow hook
  const {
    transcript: callTranscript,
    recorderStatus: botStatus,
    getColorForSpeaker,
    getContactForParticipant,
    speakerNames,
    isLoading,
  } = useMeetingflow(meetingPlanId);

  const utterances = useMemo(
    () =>
      transcriptify(callTranscript || []).map((utterance, idx) => ({
        idx,
        ...utterance,
      })) as ExtendedUtterance[],
    [callTranscript],
  );

  const [searchString, setSearchString] = useState<string>('');
  const [selectedSpeakers, setSelectedSpeakers] = useState<SpeakerOption[]>([]);

  const turns = useMemo(
    () => transcriptify(callTranscript || [], false),
    [callTranscript],
  );

  const speakers = useMemo(
    () =>
      DeduplicateArray(turns.map((t) => t.speaker))
        .filter(Truthy)
        .sort(),
    [turns],
  );

  const speakerOptions = useMemo(
    () => speakers.map((s) => ({ label: s, value: s })),
    [speakers],
  );

  const speakerContactMatches = useMemo(
    () =>
      speakers.reduce<Record<string, Contact>>((acc, speaker) => {
        const contact = getContactForParticipant(speaker);
        if (contact) {
          acc[speaker] = contact;
        }
        return acc;
      }, {}),
    [speakers, getContactForParticipant],
  );

  const filteredUtterances = useMemo(
    () =>
      utterances.filter((utterance) => {
        if (!utterance?.speaker) {
          return false;
        }

        if (
          selectedSpeakers.length > 0 &&
          !selectedSpeakers.some((s) => s.value === utterance.speaker)
        ) {
          return false;
        }

        if (searchString) {
          return utterance.text
            .toLowerCase()
            .includes(searchString.toLowerCase());
        }

        return true;
      }) as ExtendedUtterance[],
    [utterances, selectedSpeakers, searchString],
  );

  const activeIndex = useMemo(() => {
    if (!utterances.length) {
      return undefined;
    }

    // During recording, the last utterance is always active
    if (botStatus === 'in_call_recording') {
      return utterances.length - 1;
    }

    // There's no active utterance if the video is playing
    if (!playerTime) {
      return undefined;
    }

    const index = utterances.findIndex(
      (utterance) => utterance.endTime >= playerTime,
    );

    return index === -1 ? undefined : index;
  }, [botStatus, playerTime, utterances]);

  const transcriptStyles = mergeStyles({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    '.transcript-controls': {
      display: 'flex',
      columnGap: '.25rem',
      padding: '.25rem .5rem',
      position: 'sticky',
      top: '0',
      backgroundColor: DEALROOMS_COLORS.white,
      zIndex: 1000,
      '> div': {
        flex: '1 1 auto',
      },
    },
    '.transcript': {
      flex: 1,
      minHeight: 0,
      overflowY: 'auto',
      padding: '1rem 2rem 2rem 5rem',
      position: 'relative',
      '.utterance': {
        display: 'flex',
        flexDirection: 'column',
        lineHeight: '1.25rem',
        marginBottom: '0',
        boxSizing: 'content-box',
        transition: '.3s ease-in-out all',
        position: 'relative', // Ensure relative positioning
        containerType: 'inline-size',
        cursor: 'pointer',

        '.utterance-actions': {
          flex: '1 1 50px',
          padding: '0',
          transition: '.3s ease-in-out all',
        },

        '.utterance-time': {
          flex: '1 1 120px',
          marginLeft: '-5rem',
          padding: '0 .5rem 0 .5rem',
          transition: '.3s ease-in-out all',
          color: DEALROOMS_COLORS.cloudburst,
          marginRight: '.5rem',
          boxSizing: 'border-box',
          width: '58px',
          display: 'inline-block',
          textAlign: 'right',
        },

        '.utterance-text': {
          flex: '1 1 100%',
          lineHeight: '1.25rem',
          display: 'block',
          padding: '0 2.5rem .25rem .5rem',
          transition: '.3s ease-in-out all',
          borderLeft: `1px solid ${
            isDark ? MEETINGFLOW_COLORS.black : DEALROOMS_COLORS.plum25
          }`,

          ':hover': {
            padding: '.25rem 2.5rem .25rem .5rem',
            marginLeft: '.5rem',
            borderTopRightRadius: '.25rem',
            borderBottomRightRadius: '.25rem',
            backgroundColor: DEALROOMS_COLORS.financialLighterGray,
          },
        },

        '.speaker': {
          clear: 'both',
          display: 'inline-block',
          fontWeight: '500',
          width: 'auto',
          lineHeight: '1rem',
          padding: '.5rem .5rem 1rem .5rem',
          boxSizing: 'border-box',
          animationName: 'fadeInAnimation',
          animationDuration: '.3s',
          transitionTimingFunction: 'linear',
          animationIterationCount: '1',
          animationFillMode: 'forwards',
          zIndex: 10,
          color: isDark ? MEETINGFLOW_COLORS.white : DEALROOMS_COLORS.woodsmoke,
          marginTop: '.5rem',
          backgroundColor: 'transparent',
          // borderBottom: `1px solid ${
          //   isDark ? MEETINGFLOW_COLORS.black : DEALROOMS_COLORS.plum25
          // }`,
        },
      },

      '.utterance:first-child .speaker': {
        marginTop: 0,
      },

      ['.utterance-' + activeIndex + ' .utterance:']: {
        borderColor: 'transparent',
      },

      ['.utterance-' + activeIndex + ' .utterance-text']: {
        animationName: 'fadeInAnimation',
        animationDuration: '.3s',
        transitionTimingFunction: 'linear',
        animationIterationCount: '1',
        animationFillMode: 'forwards',
        borderRadius: '.25rem',
        borderLeft: 'none',
        marginLeft: '1.5rem !important',
        marginRight: '2rem !important',
        marginTop: '.5rem !important',
        marginBottom: '.5rem !important',
        lineHeight: '1rem',
        backgroundColor: DEALROOMS_COLORS.plum25,
        padding: '.5rem .5rem !important',
        color: isDark ? MEETINGFLOW_COLORS.white : MEETINGFLOW_COLORS.black,
        boxShadow: isDark
          ? '1px 1px 2px rgba(0, 0, 0, .5)'
          : '1px 1px 2px rgba(0, 0, 0, 0.025)',
        '*': {},

        ':hover': {
          color: isDark ? MEETINGFLOW_COLORS.white : MEETINGFLOW_COLORS.white,
          backgroundColor: DEALROOMS_COLORS.plum50,
        },

        '.utterance-actions': {
          padding: '0 !important',
          height: 0,
          width: 0,
          margin: 0,
        },

        '.utterance-time': {
          position: 'absolute',
          color: isDark ? MEETINGFLOW_COLORS.white : MEETINGFLOW_COLORS.black,
          textAlign: 'right',
        },
      },
    },

    '.no-data-message': {
      display: 'block',
      padding: '2rem',
      textAlign: 'center',
      color: DEALROOMS_COLORS.themePrimary,
    },
  });

  const transcriptRef = useRef<HTMLDivElement>(null);
  const isAutoScrollingRef = useRef(false);

  const handleScroll = useCallback(() => {
    if (!transcriptRef.current || isAutoScrollingRef.current) return;

    const { scrollTop, scrollHeight, clientHeight } = transcriptRef.current;

    // Check if scrolled to bottom
    const isAtBottom = Math.abs(scrollHeight - scrollTop - clientHeight) < 1;
    if (isAtBottom && onScrollToBottom) {
      onScrollToBottom();
    }

    // Check if scrolled to top
    const isAtTop = scrollTop === 0;
    if (isAtTop && onScrollToTop) {
      onScrollToTop();
    }
  }, [onScrollToBottom, onScrollToTop]);

  useEffect(() => {
    const transcriptElement = transcriptRef.current;
    if (!transcriptElement) return;

    transcriptElement.addEventListener('scroll', handleScroll);
    return () => {
      transcriptElement.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    if (autoScroll && activeIndex !== undefined) {
      const element = document.querySelector<HTMLDivElement>(
        `div.utterance.utterance-${activeIndex}`,
      );

      if (element) {
        isAutoScrollingRef.current = true;
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
        // Reset the flag after the smooth scroll animation completes
        setTimeout(() => {
          isAutoScrollingRef.current = false;
        }, 1000);
      }
    }
  }, [autoScroll, activeIndex]);

  const onSelectedSpeakersChanged = useCallback(
    (newValue: string[]) => {
      const newSelected = speakerOptions.filter((opt) =>
        newValue.includes(opt.label),
      );
      setSelectedSpeakers(newSelected);
    },
    [speakerOptions],
  );

  const onAutoscrollChanged = useCallback(
    (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      appInsights.trackEvent({
        name: 'CALL_RECORDING_TRANSCRIPT_TOGGLE_AUTO_SCROLL',
        properties: {
          meetingPlanId,
          autoScroll: checked,
        },
      });
      setAutoScroll(checked);
    },
    [appInsights, meetingPlanId],
  );

  const theme = useTheme();
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [activeUtterance, setActiveUtterance] =
    useState<ExtendedUtterance | null>(null);

  const handleMenuClick = (
    event: React.MouseEvent<HTMLElement>,
    utterance: ExtendedUtterance,
  ) => {
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
    setActiveUtterance(utterance);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
    setActiveUtterance(null);
  };

  const transcriptUtterances = useMemo(
    () =>
      filteredUtterances.map((utterance) => (
        <div
          key={`${utterance.idx}-${utterance.text}`}
          className={classNames('utterance', `utterance-${utterance.idx}`)}
          onClick={() =>
            onSeekTranscriptUtterance?.(Math.max(0, utterance.startTime - 0.5))
          }
          title={`Video Time: ${formatSeconds(utterance.startTime)}-${formatSeconds(
            utterance.endTime,
          )}`}
        >
          {utterance.idx === 0 ||
          utterance.speaker !==
            filteredUtterances[utterance.idx - 1]?.speaker ? (
            <Typography
              component="div"
              className="speaker"
              style={{
                cursor:
                  onContactClick &&
                  !!utterance.speaker &&
                  speakerContactMatches[utterance.speaker]
                    ? 'pointer'
                    : 'default',
                color: utterance?.speaker
                  ? getColorForSpeaker(utterance?.speaker)
                  : undefined,
                borderColor: utterance?.speaker
                  ? getColorForSpeaker(utterance?.speaker)
                  : undefined,
              }}
              onClick={
                onContactClick &&
                !!utterance.speaker &&
                speakerContactMatches[utterance.speaker]
                  ? (e) => {
                      e.stopPropagation();
                      if (utterance.speaker !== null) {
                        const contact =
                          speakerContactMatches[utterance.speaker];
                        if (contact && isFullscreen) {
                          window.open(
                            `/organization/${organizationSlug}/library/contact/${contact.id}`,
                            '_blank',
                          );
                        } else if (contact) {
                          onContactClick(contact.id);
                        }
                      }
                    }
                  : undefined
              }
            >
              {utterance.speaker}{' '}
              {!!utterance.speaker && speakerContactMatches[utterance.speaker]
                ? `(${speakerContactMatches[utterance.speaker].emailDomain})`
                : null}
            </Typography>
          ) : null}
          <Typography
            component="div"
            className="utterance-text"
            style={
              utterance?.speaker
                ? {
                    borderColor: getColorForSpeaker(utterance.speaker),
                  }
                : undefined
            }
            sx={{
              padding: '4px 40px 4px 8px !important',
              position: 'relative',
            }}
          >
            <span className="utterance-actions">
              <IconButton
                size="small"
                onClick={(e) => handleMenuClick(e, utterance)}
                sx={{
                  height: '24px',
                  width: '24px',
                  position: 'absolute',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  right: '8px',
                  color: isDark
                    ? theme.palette.grey[400]
                    : theme.palette.grey[700],
                }}
              >
                <MoreVertIcon fontSize="small" />
              </IconButton>
            </span>
            <Typography
              component="span"
              variant="body2"
              className="utterance-time"
              sx={{
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                width: '70px',
                textAlign: 'right',
                color: theme.palette.text.secondary,
                fontFamily: theme.typography.fontFamily,
              }}
            >
              {formatSeconds(utterance.startTime)}
            </Typography>
            {utterance.text}
          </Typography>
        </div>
      )),
    [
      filteredUtterances,
      getColorForSpeaker,
      isFullscreen,
      onContactClick,
      onSeekTranscriptUtterance,
      organizationSlug,
      speakerContactMatches,
      isDark,
      theme,
    ],
  );

  return (
    <div className={transcriptStyles}>
      <div className={'transcript-controls'}>
        <DSTextField
          size="small"
          placeholder="Search..."
          onChange={(e) => setSearchString(e.target.value)}
          sx={{
            width: '140px',
            '& .MuiInputBase-root': {
              height: '40px',
            },
          }}
        />
        <DSAutocomplete
          label=""
          placeholder="Select speakers..."
          autoCompleteProps={{
            multiple: true,
            options: speakerOptions.map((opt) => opt.label),
            value: selectedSpeakers.map((s) => s.label),
            onChange: (_, newValue) => {
              if (newValue) {
                onSelectedSpeakersChanged(newValue as string[]);
              }
            },
            renderInput: () => null,
            sx: {
              flex: 1,
              '& .MuiInputBase-root': {
                height: '40px',
                padding: '0 8px',
              },
              '& .MuiOutlinedInput-root': {
                padding: '0',
              },
              '& .MuiChip-root': {
                height: '24px',
                '& .MuiChip-label': {
                  fontSize: '12px',
                  padding: '0 6px',
                },
                '& .MuiChip-deleteIcon': {
                  fontSize: '16px',
                  margin: '0 4px',
                },
              },
            },
          }}
        />
        <FormControlLabel
          control={
            <Switch
              checked={autoScroll}
              onChange={onAutoscrollChanged}
              size="small"
            />
          }
          label={'Autoscroll'}
          sx={{
            marginLeft: '8px',
            '& .MuiFormControlLabel-label': {
              fontSize: '14px',
            },
          }}
        />
      </div>
      <div className="transcript" ref={transcriptRef}>
        {transcriptUtterances}
        {filteredUtterances.length === 0 && (
          <Typography
            variant="body1"
            className="no-data-message"
            sx={{
              color: DEALROOMS_COLORS.themePrimary,
              textAlign: 'center',
              padding: '2rem',
            }}
          >
            No results found.
            <br />
            Please try clearing your search and/or selecting more speakers.
          </Typography>
        )}
      </div>

      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleMenuClose}
        sx={{
          '& .MuiPaper-root': {
            maxHeight: '300px',
            overflow: 'auto',
          },
        }}
        style={{
          position: 'fixed',
        }}
      >
        <MenuItem
          onClick={() => {
            if (activeUtterance) {
              onSeekTranscriptUtterance?.(
                Math.max(0, activeUtterance.startTime - 0.5),
              );
              appInsights.trackEvent({
                name: 'CALL_RECORDING_TRANSCRIPT_MORE_MENU_SEEK_TO_TOPIC',
                properties: {
                  meetingPlanId,
                  recordingTimestamp: activeUtterance.startTime,
                },
              });
            }
            handleMenuClose();
          }}
        >
          <FastForwardIcon fontSize="small" sx={{ mr: 1 }} />
          Seek video to this timestamp
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (activeUtterance) {
              showCollabDialogForTimestamp?.(activeUtterance.startTime);
              appInsights.trackEvent({
                name: 'CALL_RECORDING_TRANSCRIPT_MORE_MENU_SHARE_TIMESTAMP',
                properties: {
                  meetingPlanId,
                  recordingTimestamp: activeUtterance.startTime,
                },
              });
            }
            handleMenuClose();
          }}
        >
          <ShareIcon fontSize="small" sx={{ mr: 1 }} />
          Share meeting at this timestamp
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            if (activeUtterance) {
              e?.preventDefault();
              const externalView = window.location.href.includes('/share/');
              const url = externalView
                ? `${window.location.href}?recordingTimestamp=${activeUtterance.startTime}`
                : `${window.location.origin}/organization/${organizationSlug}/plan/${meetingPlanId}/?recordingTimestamp=${activeUtterance.startTime}`;
              navigator.clipboard
                .writeText(url)
                .then(() =>
                  toast.success(
                    `Copied Meetingflow URL (with timestamp) to the clipboard.`,
                  ),
                );
              appInsights.trackEvent({
                name: 'CALL_RECORDING_TRANSCRIPT_MORE_MENU_COPY_LINK',
                properties: {
                  meetingPlanId,
                  recordingTimestamp: activeUtterance.startTime,
                },
              });
            }
            handleMenuClose();
          }}
        >
          <ContentCopyIcon fontSize="small" sx={{ mr: 1 }} />
          Copy link to meeting at this timestamp
        </MenuItem>
      </Menu>
    </div>
  );
};
