/**
 * DecisionSiteMeetingPanel is a comprehensive component for managing meeting interactions.
 *
 * Key features:
 * - Real-time meeting status and recording management
 * - Video playback with synchronized transcript
 * - Collaborative note-taking with cursor awareness
 * - WebSocket-based real-time updates
 * - Automatic reconnection handling
 * - Integration with call recording and transcription services
 */

import { useQuery, useQueryClient } from 'react-query';
import DecisionSiteMeetingPanelHeader from './DecisionSiteMeetingPanelHeader';
import {
  MeetingPlanCallRecorderStatusQuery,
  MeetingPlanQuery,
} from '../../../../QueryNames';
import { useOrganization } from '../../../../Hooks/useOrganization';
import { useAuth0 } from '@auth0/auth0-react';
import { MeetingflowsApiClient } from '../../../../Services/NetworkCommon';
import { useCallback, useEffect, useMemo, useState, useRef } from 'react';

import { DecisionSiteMeetingPanelTabs } from './DecisionSiteMeetingPanelTabs';
import {
  ContentWrapper,
  HeaderWrapper,
  MinimizeButtonWrapper,
  PanelWrapper,
  TabsWrapper,
  VideoPlayerWrapper,
} from './DecisionSiteMeetingPanel.styles';
import { Box, IconButton, CircularProgress, styled } from '@mui/material';
import { DecisionSiteMeetingflowCard } from '../../../MeetingPlans/DecisionSiteMeetingflowCard';
import { useCollabProvider } from '../../../../Hooks/useCollabProvider';
import * as Y from 'yjs';
import randomColor from 'randomcolor';
import { useUserProfile } from '../../../../Hooks/useProfile';
import { WS_MESSAGE } from '@meetingflow/common/Constants';
import { useDeferredPromise } from '../../../../Hooks/useDeferredPromise';
import { getYjsEditor } from '../../../Collab/Helpers/EditorHelpers';
import { useLinkDialog } from '../../../../Hooks/Modals/useLinkDialog';
import { useSearchParams } from 'react-router-dom';
import {
  VideoPlayerControls,
  VideoPlayerState,
} from '../../../../Components/Video/VideoPlayer';
import { DecisionSiteMeetingPanelVideoPlayer } from './DecisionSiteMeetingPanelVideoPlayer';
import { useMeetingflow } from './hooks/useMeetingflow';
import { YjsEditor } from '@slate-yjs/core';
import toast from 'react-hot-toast';
import {
  AspectRatioRounded,
  PictureInPictureAltRounded,
} from '@mui/icons-material';
import { DSTooltip } from '../../DS';
import { useDealRoom } from '../../../../Hooks/useDealRoom';
import { CallRecordingStatus } from '@meetingflow/common/Api/data-contracts';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';

/**
 * Styled container for loading spinner
 */
const LoadingContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  width: '100%',
});

/**
 * Props for the DecisionSiteMeetingPanel component
 * @property meetingPlanId - Unique identifier for the meeting session
 * @property onClickCloseButton - Handler for panel close action
 */
export const DecisionSiteMeetingPanel: React.FC<{
  meetingPlanId: string;
  onClickCloseButton: () => void;
}> = ({ meetingPlanId, onClickCloseButton }) => {
  // Authentication and organization context
  const { getAccessTokenSilently, user } = useAuth0();
  const { slug: organizationSlug, hasEntitlement } = useOrganization();
  const { dealRoomId } = useDealRoom();

  // User profile management
  const { name, email, picture } = user!;
  const { user: mfUser } = useUserProfile();

  // Query and routing state
  const client = useQueryClient();
  const [searchParams] = useSearchParams();

  const [videoIsPictureInPicture, setVideoIsPictureInPicture] = useState(false);

  /**
   * Video player state management
   * Controls playback, seeking, and player UI state
   */
  const [videoPlayerControls, setVideoPlayerControls] =
    useState<VideoPlayerControls | null>(null);
  const [videoPlayerState, setVideoPlayerState] = useState<
    VideoPlayerState | undefined
  >();

  /**
   * Initialize default video player state
   * Ensures consistent player behavior on mount
   */
  useEffect(() => {
    if (!videoPlayerState) {
      setVideoPlayerState({
        hasStarted: false,
        paused: true,
        duration: 0,
        currentTime: 0,
        seeking: false,
        seekingTime: 0,
        ended: false,
        muted: false,
        isFullscreen: false,
        videoHeight: 0,
        videoWidth: 0,
      });
    }
  }, [videoPlayerState]);

  /**
   * Parse timestamp from URL for video positioning
   * Enables deep linking to specific moments in recordings
   */
  const initialTimestamp =
    searchParams.has('recordingTimestamp') &&
    searchParams.get('recordingTimestamp') &&
    !isNaN(parseFloat(searchParams.get('recordingTimestamp')!))
      ? parseFloat(searchParams.get('recordingTimestamp')!)
      : undefined;

  // Provider reconnection state management
  const {
    createDeferred: createProviderReconnectDeferred,
    deferred: providerReconnectDeferred,
    resolve: resolveProviderReconnect,
  } = useDeferredPromise<boolean>();

  /**
   * Query hook for fetching meeting plan data and related states
   */
  const {
    meetingflow,
    meetingflowFetched,
    refetchMeetingflow,
    meetingflowLoading,
    recorderStatus,
    callRecorderStatus,
    recorderStatusLoading,
    refetchRecorderStatus,
    transcript,
    transcriptLoading,
    refetchTranscript,
    speakerNames,
    getContactForParticipant,
    getColorForSpeaker,
    scheduleCallRecording,
  } = useMeetingflow(meetingPlanId);

  const { createDeferred: createLinkDialogDeferred, dialog: linkDialog } =
    useLinkDialog();

  const color = useMemo(
    () =>
      randomColor({
        luminosity: 'dark',
        format: 'rgba',
        alpha: 1,
      }),
    [],
  );

  /**
   * WebSocket message handler for real-time updates
   * Manages different types of updates:
   * - Meeting plan changes
   * - Recording status updates
   * - Collaboration events
   * - Connection heartbeats
   */
  const onWSMessage = useCallback(
    (messageType: number) => {
      switch (messageType) {
        case WS_MESSAGE.REFRESH_PLAN: {
          refetchMeetingflow();
          return true;
        }
        // case WS_MESSAGE.REFRESH_ACTION_ITEMS: {
        //   refetchTasks();
        //   return true;
        // }
        case WS_MESSAGE.REFRESH_CALL_RECORDING_STATUS: {
          if (
            !!organizationSlug &&
            !!meetingPlanId &&
            hasEntitlement('CALL_RECORDING') &&
            !!meetingflow?.callRecording?.id
          ) {
            client.removeQueries(
              MeetingPlanCallRecorderStatusQuery(
                organizationSlug,
                meetingPlanId,
              ),
            );

            refetchRecorderStatus();
          }
          return true;
        }

        case WS_MESSAGE.HEARTBEAT:
        case WS_MESSAGE.RECREATE:
        case WS_MESSAGE.SAVE: {
          return true;
        }
        default: {
          return false;
        }
      }
    },
    [
      client,
      hasEntitlement,
      meetingPlanId,
      organizationSlug,
      meetingflow?.callRecording?.id,
      refetchRecorderStatus,
      refetchMeetingflow,
      // refetchTasks,
    ],
  );

  /**
   * Collaborative editing setup
   * - Initializes YJS document for shared editing
   * - Sets up awareness for cursor tracking
   * - Handles provider connection state
   */
  const ydoc = useMemo(() => {
    return new Y.Doc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationSlug, meetingPlanId]);

  /**
   * Collaborative notes array
   * Stores the shared document content using YJS XmlText
   */
  const notesYArray = useMemo(() => {
    return ydoc.get('notes', Y.XmlText) as Y.XmlText;
  }, [ydoc]);

  /**
   * Real-time collaboration provider setup
   * Manages:
   * - WebSocket connections
   * - User presence
   * - Document synchronization
   * - Error handling
   */
  const {
    provider,
    awarenessStates,
    hasConnected: collabHasConnected,
    isConnected: collabIsConnected,
    isSynced: collabIsSynced,
    isError: collabIsError,
    recreateProvider,
  } = useCollabProvider({
    providerName: 'MEETINGPLAN',
    documentName: `MeetingPlan__${organizationSlug}__${meetingPlanId}`,
    ydoc,
    connect: !!meetingPlanId, // Only connect when we have a valid meetingPlanId
    color,
    email: email!,
    name: mfUser?.name || user?.name,
    picture: mfUser?.avatarFileUrl || mfUser?.avatarUrl || user?.picture,
    onMessage: onWSMessage,
  });

  /**
   * Auto-reconnection handler
   * Shows toast notifications for connection state changes
   */
  useEffect(() => {
    if (
      !providerReconnectDeferred?.isPending &&
      collabHasConnected &&
      !collabIsConnected
    ) {
      toast.promise(createProviderReconnectDeferred().promise, {
        loading: 'Trying to reconnect...',
        success: 'Connected',
        error: 'Unable to reconnect',
      });
    } else if (!!providerReconnectDeferred?.isPending && collabIsConnected) {
      resolveProviderReconnect(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    providerReconnectDeferred?.isPending,
    collabHasConnected,
    collabIsConnected,
  ]);

  useEffect(() => {
    if (
      !!organizationSlug &&
      !!meetingPlanId &&
      !!meetingflow?.callRecording &&
      hasEntitlement('CALL_RECORDING') &&
      recorderStatus === 'in_call_recording'
    ) {
      refetchTranscript();
    }
  }, [
    hasEntitlement,
    meetingflow?.callRecording,
    meetingPlanId,
    organizationSlug,
    recorderStatus,
    refetchTranscript,
  ]);

  useEffect(() => {
    if (
      !!organizationSlug &&
      !!meetingPlanId &&
      !!meetingflow?.callRecording?.id &&
      hasEntitlement('CALL_RECORDING')
    ) {
      refetchRecorderStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    meetingflow?.callRecording?.id,
    meetingflow?.callRecording?.lastStatus,
    meetingPlanId,
    organizationSlug,
  ]);

  useEffect(
    () => {
      if (
        callRecorderStatus?.code &&
        (callRecorderStatus.code as CallRecordingStatus) !== recorderStatus
      ) {
        // setCallRecorderStatus(callRecorderStatus.code);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [callRecorderStatus?.code, recorderStatus],
  );

  /**
   * Collaborative editor initialization
   * Sets up:
   * - Cursor tracking
   * - User awareness
   * - Custom styling
   * - Link handling
   */
  const notesEditor = useMemo(() => {
    if (!provider) {
      return undefined;
    }
    return getYjsEditor(
      notesYArray,
      provider,
      {
        cursorStateField: 'notesCursorState',
        cursorDataField: 'notesCursorData',
        data: {
          alphaColor: color.slice(0, -2) + '0.2)',
          color,
          name,
          email: email!,
          picture,
        },
      },
      getAccessTokenSilently,
      organizationSlug!,
      createLinkDialogDeferred,
      meetingPlanId,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notesYArray, provider, meetingflow?.textSummary]);

  useEffect(() => {
    if (!notesEditor) {
      return;
    }
    YjsEditor.connect(notesEditor);
    return () => {
      YjsEditor.disconnect(notesEditor);
    };
  }, [notesEditor]);

  /**
   * Video player component with proper configuration
   * Memoized to prevent unnecessary re-renders
   */
  const Video = useMemo(
    () =>
      organizationSlug && meetingflow ? (
        <DecisionSiteMeetingPanelVideoPlayer
          meetingPlan={meetingflow}
          organizationSlug={organizationSlug}
          videoPlayerState={videoPlayerState}
          initialTimestamp={initialTimestamp}
          videoPlayerControls={videoPlayerControls}
          setVideoPlayerControls={setVideoPlayerControls}
          setVideoPlayerState={setVideoPlayerState}
          refetchMeetingflow={refetchMeetingflow}
          videoIsPictureInPicture={videoIsPictureInPicture}
          setVideoIsPictureInPicture={setVideoIsPictureInPicture}
        />
      ) : null,
    [
      meetingflow,
      organizationSlug,
      videoPlayerState,
      initialTimestamp,
      videoPlayerControls,
      setVideoPlayerControls,
      setVideoPlayerState,
      refetchMeetingflow,
      videoIsPictureInPicture,
    ],
  );

  // Determine if we're in real-time mode (live transcript without recording)
  const isRealtime =
    !meetingflow?.callRecording?.recordingFileName && !!transcript.length;

  const handleScheduleCallRecording = useCallback(
    (e: React.MouseEvent) => {
      scheduleCallRecording(e);
    },
    [scheduleCallRecording],
  );

  /**
   * Placeholder interaction handlers
   * TODO: Implement actual contact/company click behavior
   */

  const onContactClick = (contactId: number) => {
    // console.log('onContactClick', contactId);
  };

  const onCompanyClick = (companyId: number) => {
    // console.log('onCompanyClick', companyId);
  };

  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Handle click outside to close panel
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;

      // Material-UI renders Menus/Popovers/DatePickers in a Portal at the root of the document,
      // outside of our panel's DOM hierarchy. We need to check both:
      // 1. If the click is inside our panel's DOM tree
      // 2. If the click is inside any MUI portal component
      const isPortalClick =
        target.closest(
          '.MuiMenu-root, .MuiPopover-root, .MuiPickersPopper-root, .MuiMilestonePopper-root',
        ) !== null;
      const isPanelClick = wrapperRef.current?.contains(target);

      if (!isPanelClick && !isPortalClick) {
        onClickCloseButton();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClickCloseButton]);

  // Add highlight animation keyframes
  const highlightKeyframes = `
    @keyframes highlightButton {
      0% { 
        transform: scale(1); 
        box-shadow: 0 0 0 0 ${DEALROOMS_COLORS.plum100}40;
      }
      25% { 
        transform: scale(1.2); 
        box-shadow: 0 0 0 10px ${DEALROOMS_COLORS.plum100}00;
      }
      50% { 
        transform: scale(1.1); 
        box-shadow: 0 0 0 5px ${DEALROOMS_COLORS.plum100}30;
      }
      75% { 
        transform: scale(1.2); 
        box-shadow: 0 0 0 10px ${DEALROOMS_COLORS.plum100}00;
      }
      100% { 
        transform: scale(1); 
        box-shadow: 0 0 0 0 ${DEALROOMS_COLORS.plum100}00;
      }
    }
  `;

  // Add highlight prop and animation to MinimizeButton
  const MinimizeButton = styled(IconButton)(({ theme }) => ({
    width: '32px',
    height: '32px',
    padding: '6px',
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&[data-highlight="true"]': {
      animation: 'highlightButton 2s ease-in-out',
    },
  }));

  // Add state for highlight animation
  const [buttonHighlight, setButtonHighlight] = useState(false);

  // Handle highlight animation timing with cleanup
  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (buttonHighlight) {
      timeoutId = setTimeout(() => setButtonHighlight(false), 2000);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [buttonHighlight]);

  // Update scroll handlers to trigger highlight
  const handleScrollToBottom = useCallback(() => {
    setVideoIsPictureInPicture(true);
    setButtonHighlight(true);
  }, []);

  const handleScrollToTop = useCallback(() => {
    setVideoIsPictureInPicture(false);
    setButtonHighlight(true);
  }, []);

  // Add style tag for keyframes
  const GlobalStyle = styled('style')({});

  // Show loading state while any of the main queries are loading
  if (meetingflowLoading) {
    return (
      <PanelWrapper>
        <LoadingContainer>
          <CircularProgress />
        </LoadingContainer>
      </PanelWrapper>
    );
  }

  if (!meetingflow) {
    return null;
  }

  if (!organizationSlug || !meetingPlanId) {
    return null;
  }

  return (
    <PanelWrapper ref={wrapperRef}>
      <GlobalStyle>{highlightKeyframes}</GlobalStyle>
      <HeaderWrapper>
        <DecisionSiteMeetingPanelHeader
          meetingflowId={meetingPlanId}
          onClickCloseButton={onClickCloseButton}
        >
          {meetingflow?.callRecording?.recordingFileName ? (
            <MinimizeButtonWrapper>
              <DSTooltip
                title={
                  videoIsPictureInPicture
                    ? 'Embedded Video'
                    : 'Picture-in-Picture'
                }
              >
                <MinimizeButton
                  onClick={() =>
                    setVideoIsPictureInPicture(!videoIsPictureInPicture)
                  }
                  data-highlight={buttonHighlight}
                >
                  {videoIsPictureInPicture ? (
                    <AspectRatioRounded sx={{ fontSize: '18px' }} />
                  ) : (
                    <PictureInPictureAltRounded sx={{ fontSize: '18px' }} />
                  )}
                </MinimizeButton>
              </DSTooltip>
            </MinimizeButtonWrapper>
          ) : null}
          <DecisionSiteMeetingflowCard
            meetingflowId={meetingPlanId}
            hideCallRecordingButton={true}
            showContextualMenu={true}
            hideShowConferenceJoinButton={true}
            hideCRMIcon={true}
            hideSummaryIcon={true}
            hideAttendeesIcon={true}
            hideCallRecordingIcon={true}
            showAddToDecisionSiteButton={false}
            noTitleLink={true}
            miniConferenceJoinButton={false}
          />
        </DecisionSiteMeetingPanelHeader>
      </HeaderWrapper>
      <ContentWrapper contentMinimized={videoIsPictureInPicture}>
        {meetingPlanId && <VideoPlayerWrapper>{Video}</VideoPlayerWrapper>}
      </ContentWrapper>
      <TabsWrapper>
        {notesEditor && meetingflow && transcript && (
          <DecisionSiteMeetingPanelTabs
            meetingflow={meetingflow}
            notesEditor={notesEditor}
            readOnly={collabIsError}
            refetchMeetingflow={async () => {
              await refetchMeetingflow();
            }}
            videoPlayerState={videoPlayerState}
            videoPlayerControls={videoPlayerControls}
            callRecorderStatus={callRecorderStatus}
            scheduleCallRecording={handleScheduleCallRecording}
            onContactClick={onContactClick}
            onCompanyClick={onCompanyClick}
            isRealTime={isRealtime}
            transcript={transcript}
            onScrollToBottom={handleScrollToBottom}
            onScrollToTop={handleScrollToTop}
          />
        )}
      </TabsWrapper>
    </PanelWrapper>
  );
};
