import React from 'react';
import { Box } from '@mui/material';
import { NotificationsGroupHeader } from './NotificationsGroupHeader';
import { gridContainerStyles } from './NotificationGroup.styles';
import { AgGridReact } from 'ag-grid-react';
import { ColDef, GridOptions } from 'ag-grid-community';

interface NotificationDeliveryType {
  key: string;
  notifyFor: string;
  email: string;
  inApp: string;
}

interface NotificationGroupProps {
  icon: React.ReactNode;
  title: string;
  rowData: NotificationDeliveryType[];
  columnDefs: ColDef[];
}

const gridOptions: GridOptions = {
  domLayout: 'autoHeight',
  suppressMovableColumns: true, // Disable reordering for all columns
  suppressClickEdit: true, // Disable inline editing
  suppressCellFocus: true, // Disable cell focus
  suppressHeaderFocus: true, // Disable header focus
  suppressHorizontalScroll: true, // Disable horizontal scrolling
  readOnlyEdit: true, // Disable editing
  enableCellTextSelection: false, // Enable cell text selection
  defaultColDef: {
    resizable: false, // All columns are resizable by default
    sortable: false, // All columns are sortable by default
    filter: false, // All columns can be filtered by default
    flex: 1,
  },
  headerHeight: 48,
  rowHeight: 48,
};

export const NotificationsGroup: React.FC<NotificationGroupProps> = ({
  icon,
  title,
  rowData,
  columnDefs,
}) => {
  return (
    <Box display="flex" flexDirection="column" gap={0} overflow="hidden">
      <NotificationsGroupHeader icon={icon} title={title} />
      <Box display="flex" flexDirection="column" gap={1.5} overflow="hidden">
        <div className={gridContainerStyles}>
          {!!rowData.length && !!columnDefs.length && (
            <AgGridReact
              gridOptions={gridOptions}
              rowData={rowData}
              columnDefs={columnDefs}
            />
          )}
        </div>
      </Box>
    </Box>
  );
};
