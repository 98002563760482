import { useCallback, useEffect, useRef, useState } from 'react';
import {
  BigPlayButton,
  ClosedCaptionButton,
  ControlBar,
  CurrentTimeDisplay,
  ForwardControl,
  LoadingSpinner,
  PlaybackRateMenuButton,
  Player,
  PlayerReference,
  PlayerState,
  ReplayControl,
  StaticPlayerInstanceMethods,
  TimeDivider,
} from 'video-react';
import '../../Styles/VideoPlayer/scss/video-react.scss';
import DownloadButton from './DownloadButton';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';

const PLAYBACK_RATES = [3, 2.5, 2, 1.75, 1.5, 1.25, 1, 0.75, 0.5, 0.25];
const JUMP_FORWARD_INTERVAL = 10;
const JUMP_BACKWARD_INTERVAL = 10;

export type VideoPlayerState = Pick<
  PlayerState,
  | 'duration'
  | 'currentTime'
  | 'seekingTime'
  | 'seeking'
  | 'paused'
  | 'ended'
  | 'muted'
  | 'hasStarted'
  | 'isFullscreen'
  | 'videoHeight'
  | 'videoWidth'
>;

export type VideoPlayerControls = Pick<
  StaticPlayerInstanceMethods,
  'play' | 'pause' | 'seek' | 'forward' | 'replay' | 'toggleFullscreen'
>;

interface VideoPlayerProps {
  source: string;
  thumbnail?: string;
  transcript?: string;
  onPlayerStateChanged?: (state: VideoPlayerState) => void;
  onPlayerControls?: (controls: VideoPlayerControls) => void;
  onPlayerHeightChanged?: (height: number) => void;
  initialTimestamp?: number;
}

const VideoPlayer = ({
  source,
  thumbnail,
  transcript,
  onPlayerStateChanged,
  onPlayerControls,
  onPlayerHeightChanged,
  initialTimestamp,
}: VideoPlayerProps) => {
  const playerRef = useRef<PlayerReference>(null);
  const [playerState, setPlayerState] = useState<PlayerState | undefined>(
    undefined,
  );

  const appInsights = useAppInsightsContext();

  const play = useCallback(() => {
    playerRef.current?.play?.();
    appInsights.trackEvent({ name: 'CALL_RECORDING_VIDEO_PLAY' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const pause = useCallback(() => {
    playerRef.current?.pause?.();
    appInsights.trackEvent({ name: 'CALL_RECORDING_VIDEO_PAUSE' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const seek = useCallback((time: number) => {
    if (playerRef.current?.seek) {
      playerRef.current.seek(time);
      appInsights.trackEvent({ 
        name: 'CALL_RECORDING_VIDEO_SEEK',
        properties: { timestamp: time }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const seekTo = useCallback((time: number) => {
    if (playerRef.current?.seek) {
      playerRef.current.seek(time);
      appInsights.trackEvent({ 
        name: 'CALL_RECORDING_VIDEO_SEEK_TO',
        properties: { timestamp: time }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const forward = useCallback((seconds: number) => {
    playerRef.current?.forward?.(seconds);
    appInsights.trackEvent({ name: 'CALL_RECORDING_VIDEO_FORWARD' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const replay = useCallback((seconds: number) => {
    playerRef.current?.replay?.(seconds);
    appInsights.trackEvent({ name: 'CALL_RECORDING_VIDEO_REPLAY' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const toggleFullscreen = useCallback(() => {
    playerRef.current?.toggleFullscreen?.();
    appInsights.trackEvent({ name: 'CALL_RECORDING_VIDEO_TOGGLE_FULLSCREEN' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    playerRef.current?.subscribeToStateChange?.(setPlayerState);
  }, []);

  useEffect(() => {
    onPlayerControls?.({
      play,
      pause,
      seek,
      forward,
      replay,
      toggleFullscreen,
    });
  }, [forward, onPlayerControls, pause, play, replay, seek, toggleFullscreen]);

  useEffect(() => {
    if (playerState) {
      onPlayerStateChanged?.({
        duration: playerState.duration,
        currentTime: playerState.currentTime,
        seekingTime: playerState.seekingTime,
        seeking: playerState.seeking,
        paused: playerState.paused,
        ended: playerState.ended,
        muted: playerState.muted,
        hasStarted: playerState.hasStarted,
        isFullscreen: playerState.isFullscreen,
        videoHeight: playerState.videoHeight,
        videoWidth: playerState.videoWidth,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    onPlayerStateChanged,
    playerState?.duration,
    playerState?.currentTime,
    playerState?.seekingTime,
    playerState?.seeking,
    playerState?.paused,
    playerState?.ended,
    playerState?.muted,
    playerState?.hasStarted,
    playerState?.isFullscreen,
  ]);

  const videoHeight =
    // @ts-ignore
    playerRef?.current?.manager?.rootElement?.clientHeight || 0;

  useEffect(() => {
    onPlayerHeightChanged?.(videoHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoHeight]);

  return (
    <Player
      ref={playerRef}
      aspectRatio="16:9"
      preload="metadata"
      poster={thumbnail}
      startTime={initialTimestamp}
    >
      <source src={source} />

      {transcript ? (
        <track
          kind="captions"
          src={transcript}
          srcLang="en"
          label="English"
          default
        />
      ) : null}

      <BigPlayButton position="center" />
      <LoadingSpinner />
      <ControlBar autoHide={true}>
        <ReplayControl
          seconds={JUMP_BACKWARD_INTERVAL}
          // @ts-ignore Types def appears to be out of date and not have the 'order' prop
          order={1.1}
          className="extra-controls"
        />

        <ForwardControl
          seconds={JUMP_FORWARD_INTERVAL}
          // @ts-ignore Types def appears to be out of date and not have the 'order' prop
          order={1.2}
          className="extra-controls"
        />

        <CurrentTimeDisplay className="extra-controls" />
        <TimeDivider className="extra-controls" />

        <PlaybackRateMenuButton
          rates={PLAYBACK_RATES}
          // @ts-ignore Types def appears to be out of date and not have the 'order' prop
          order={7.1}
          className="extra-extra-controls"
        />

        <DownloadButton
          sourceURL={source}
          order={7.2}
          className="extra-controls"
        />

        {transcript ? <ClosedCaptionButton order={7.3} /> : null}
      </ControlBar>
    </Player>
  );
};

export default VideoPlayer;
