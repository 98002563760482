import { styled } from '@mui/material';

export const DealRoomBuyersJourneyTabContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  height: '100%',
  width: '100%',

  '.summary': {
    position: 'relative',
    '.MuiButton-root': {
      position: 'absolute',
      right: '1rem',
      bottom: '1rem',
    },
  },

  '& .content': {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    width: '100%',
    overflow: 'auto',
    gap: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },

    '& .column': {
      flex: '1',
      height: '100%',
      overflow: 'auto',

      '&:first-of-type': {
        flex: '2',
      },

      '&:last-of-type': {
        flex: '1',
      },
    },
  },
}));
