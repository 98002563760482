import {
  CalendarEvent,
  PostMeetingflowPayload,
  DealRoomArtifact,
  DetailedMeetingflow,
} from '@meetingflow/common/Api/data-contracts';
import {
  MeetingflowsApiClient,
  DealRoomsApiClient,
} from '../Services/NetworkCommon';
import { isAxiosErrorResponse } from '../Helpers/AxiosHelpers';
import { IAppInsights } from '@microsoft/applicationinsights-web';
import { QueryClient } from 'react-query';
import {
  OrganizationMeetingPlansQuery,
  OrganizationMeetingsHappeningSoon,
  OrganizationUpcomingMeetings,
  MeetingPlanQuery,
} from '../QueryNames';
import toast from 'react-hot-toast';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

const DEFAULT_PLANS_PER_PAGE = 30;

interface CreateMeetingflowOptions {
  event?: CalendarEvent;
  organizationSlug: string;
  scheduleCallRecording?: boolean;
  token: string;
  dealRoomId?: number;
  appInsights?: ReactPlugin;
  queryClient: QueryClient;
  onSuccess?: (meetingflowId: string) => void;
  skipQueryInvalidation?: boolean;
}

interface CreateMeetingflowResult {
  meetingflowId: string;
  status: number;
}

interface CreateMeetingflowAndDecisionSiteArtifactOptions
  extends CreateMeetingflowOptions {
  dealRoomId: number; // Make dealRoomId required for this operation
}

interface DeleteMeetingflowAndDecisionSiteArtifactOptions {
  organizationSlug: string;
  dealRoomId: number;
  meetingflowId: string;
  token: string;
  queryClient: QueryClient;
  appInsights?: ReactPlugin;
}

interface RemoveMeetingflowArtifactFromDecisionSiteOptions {
  organizationSlug: string;
  dealRoomId: number;
  meetingflowId: string;
  token: string;
  queryClient: QueryClient;
  appInsights?: ReactPlugin;
}

interface ToggleMeetingflowFeatureOptions {
  organizationSlug: string;
  dealRoomId: number;
  meetingflowId: string;
  token: string;
  queryClient: QueryClient;
  appInsights?: ReactPlugin;
}

export const invalidateMeetingQueries = async (
  queryClient: QueryClient,
  organizationSlug: string,
  dealRoomId: number,
) => {
  // Use a more targeted approach to avoid cascade of refetches
  const promises = [];

  // Only invalidate organization-level queries that are active
  promises.push(
    queryClient.invalidateQueries(['organization', organizationSlug], {
      exact: true,
      refetchActive: true,
    }),
  );

  // Only invalidate deal room artifacts if we have a dealRoomId
  if (dealRoomId) {
    promises.push(
      queryClient.invalidateQueries(['artifacts', dealRoomId], {
        exact: true,
        refetchActive: true,
      }),
    );
  }

  // Selectively invalidate meeting queries
  const happeningSoonKey = OrganizationMeetingsHappeningSoon(organizationSlug);
  const upcomingKey = OrganizationUpcomingMeetings(organizationSlug);
  const meetingPlansKey = OrganizationMeetingPlansQuery(
    organizationSlug,
    '',
    50,
    0,
    dealRoomId,
  );

  promises.push(
    // Invalidate all events queries with any query key that starts with the base key
    queryClient.invalidateQueries([upcomingKey], {
      exact: false,
      refetchActive: true,
      refetchInactive: true, // Force inactive queries to refetch too
    }),
    queryClient.invalidateQueries([happeningSoonKey], {
      exact: false,
      refetchActive: true,
      refetchInactive: true,
    }),
    queryClient.invalidateQueries(meetingPlansKey, {
      exact: false,
      refetchActive: true,
      refetchInactive: true,
    }),
    queryClient.invalidateQueries(['events', organizationSlug], {
      exact: false,
      refetchActive: true,
      refetchInactive: true,
    }),
    queryClient.invalidateQueries(['artifacts', dealRoomId], {
      exact: false,
      refetchActive: true,
      refetchInactive: true,
    }),
    queryClient.invalidateQueries(
      ['artifacts-calendar', dealRoomId, organizationSlug],
      {
        exact: true,
        refetchActive: true,
        refetchInactive: true,
      },
    ),
    queryClient.invalidateQueries(
      ['artifacts-analytics', dealRoomId, organizationSlug],
      {
        exact: true,
        refetchActive: true,
        refetchInactive: true,
      },
    ),
  );

  // Run all invalidations in parallel
  await Promise.all(promises);
};

export const createMeetingflow = async ({
  event,
  organizationSlug,
  scheduleCallRecording = false,
  token,
  dealRoomId,
  appInsights,
  queryClient,
  onSuccess,
  skipQueryInvalidation = false,
}: CreateMeetingflowOptions): Promise<CreateMeetingflowResult> => {
  let reqBody: PostMeetingflowPayload = { record: scheduleCallRecording };
  if (event) {
    reqBody = {
      source: event.source,
      eventId: event.externalId,
      eventTime: event.startTime,
      record: scheduleCallRecording,
    };
  }

  const result = await MeetingflowsApiClient.postMeetingflow(
    organizationSlug,
    reqBody,
    {
      headers: { Authorization: `Bearer ${token}` },
      validateStatus: (code) => code === 201 || code === 302,
    },
  );

  if (result.data) {
    queryClient.setQueryData(
      MeetingPlanQuery(organizationSlug, result.data.id),
      result,
    );
  }

  if (!skipQueryInvalidation) {
    if (dealRoomId) {
      await invalidateMeetingQueries(queryClient, organizationSlug, dealRoomId);
    } else {
      await invalidateMeetingQueries(queryClient, organizationSlug, 0);
    }
  }

  if (result.status === 201 && appInsights) {
    appInsights.trackEvent({
      name: `CREATE_PLAN${event ? '_FOR_EVENT' : '_ADHOC'}`,
      properties: {
        organizationSlug,
        source: event ? event?.source : '',
        eventId: event ? event?.externalId : '',
      },
    });
  }

  if (onSuccess) {
    onSuccess(result.data.id);
  }

  return {
    meetingflowId: result.data.id,
    status: result.status,
  };
};

export const createAdHocMeetingflow = async ({
  organizationSlug,
  scheduleCallRecording = false,
  token,
  dealRoomId,
  appInsights,
  queryClient,
  onSuccess,
  skipQueryInvalidation = false,
}: Omit<
  CreateMeetingflowOptions,
  'event'
>): Promise<CreateMeetingflowResult> => {
  return createMeetingflow({
    organizationSlug,
    scheduleCallRecording,
    token,
    dealRoomId,
    appInsights,
    queryClient,
    onSuccess,
    skipQueryInvalidation,
  });
};

export const createMeetingflowLinkArtifact = async (
  organizationSlug: string,
  dealRoomId: number,
  meetingflowId: string,
  queryClient: QueryClient,
  token: string,
  skipQueryInvalidation = false,
): Promise<void> => {
  await DealRoomsApiClient.createLinkArtifact(
    organizationSlug,
    dealRoomId,
    {
      type: 'MEETINGFLOW',
      meetingflowId: meetingflowId,
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    },
  );

  if (!skipQueryInvalidation) {
    await invalidateMeetingQueries(queryClient, organizationSlug, dealRoomId);
  }
};

export const createMeetingflowAndDecisionSiteArtifact = async ({
  event,
  organizationSlug,
  scheduleCallRecording = false,
  token,
  dealRoomId,
  appInsights,
  queryClient,
  onSuccess,
}: CreateMeetingflowAndDecisionSiteArtifactOptions): Promise<CreateMeetingflowResult> => {
  return await toast.promise(
    (async () => {
      const result = event
        ? await createMeetingflow({
            event,
            organizationSlug,
            scheduleCallRecording,
            token,
            dealRoomId,
            appInsights,
            queryClient,
          })
        : await createAdHocMeetingflow({
            organizationSlug,
            scheduleCallRecording,
            token,
            dealRoomId,
            appInsights,
            queryClient,
          });

      // Optimistically update the cache with the new meetingflow
      queryClient.setQueryData(
        ['organization', organizationSlug, 'meetingflow', result.meetingflowId],
        result,
      );

      // Create the artifact link
      await createMeetingflowLinkArtifact(
        organizationSlug,
        dealRoomId,
        result.meetingflowId,
        queryClient,
        token,
      );

      // Optimistically update artifacts cache
      const artifactsKey = ['artifacts', dealRoomId, organizationSlug];
      const existingArtifacts =
        queryClient.getQueryData<DealRoomArtifact[]>(artifactsKey) || [];
      if (Array.isArray(existingArtifacts)) {
        queryClient.setQueryData<DealRoomArtifact[]>(artifactsKey, [
          ...existingArtifacts,
          {
            type: 'MEETINGFLOW',
            meetingflowId: result.meetingflowId,
          } as DealRoomArtifact,
        ]);
      }

      // Do a single query invalidation at the end
      await invalidateMeetingQueries(queryClient, organizationSlug, dealRoomId);

      if (onSuccess) {
        onSuccess(result.meetingflowId);
      }

      return result;
    })(),
    {
      loading: 'Creating meeting...',
      success: () =>
        `Meeting ${event ? `for ${event?.title}` : ''} created and added to this Decision Site!`,
      error: (err) =>
        handleMeetingflowError(err, organizationSlug, event, appInsights),
    },
  );
};

export const deleteMeetingflowAndDecisionSiteArtifact = async ({
  organizationSlug,
  dealRoomId,
  meetingflowId,
  token,
  queryClient,
  appInsights,
}: DeleteMeetingflowAndDecisionSiteArtifactOptions): Promise<void> => {
  return await toast.promise(
    (async () => {
      // Get a list of artifacts
      const artifacts = await DealRoomsApiClient.listArtifacts(
        { organizationSlug, dealRoomId },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      // Find the meetingflow artifact
      const artifactId = artifacts.data.find(
        (artifact) =>
          artifact.type === 'MEETINGFLOW' &&
          artifact.meetingflowId === meetingflowId,
      )?.id;

      if (!artifactId) {
        throw new Error(
          'Could not find an artifact for this Meetingflow in this Decision Site to delete.',
        );
      }

      // First delete the link artifact
      await DealRoomsApiClient.deleteArtifact(
        {
          organizationSlug,
          dealRoomId,
          artifactId,
          soft: false,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      // Then delete the meetingflow itself — but only if there was an artifact for it.
      await MeetingflowsApiClient.deleteMeetingflow(
        organizationSlug,
        meetingflowId,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      // Invalidate relevant queries
      await invalidateMeetingQueries(queryClient, organizationSlug, dealRoomId);

      if (appInsights) {
        appInsights.trackEvent({
          name: 'DELETE_PLAN',
          properties: {
            organizationSlug,
            meetingflowId,
          },
        });
      }
    })(),
    {
      loading: 'Deleting Meetingflow...',
      success: 'Successfully deleted Meetingflow',
      error: (err) => {
        console.error('Error deleting meetingflow:', err);
        return 'Failed to delete Meetingflow';
      },
    },
  );
};

export const removeMeetingflowArtifactFromDecisionSite = async ({
  organizationSlug,
  dealRoomId,
  meetingflowId,
  token,
  queryClient,
  appInsights,
}: RemoveMeetingflowArtifactFromDecisionSiteOptions): Promise<void> => {
  return await toast.promise(
    (async () => {
      try {
        // Get the list of artifacts to find the one associated with this meetingflow
        const artifacts = await DealRoomsApiClient.listArtifacts(
          { organizationSlug, dealRoomId },
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );

        // Find the meetingflow artifact
        const artifact = artifacts.data.find(
          (artifact) =>
            artifact.type === 'MEETINGFLOW' &&
            artifact.meetingflowId === meetingflowId,
        );

        if (!artifact) {
          throw new Error(
            'Could not find an artifact for this Meetingflow in this Decision Site.',
          );
        }

        // Delete just the artifact
        await DealRoomsApiClient.deleteArtifact(
          {
            organizationSlug,
            dealRoomId,
            artifactId: artifact.id,
            soft: false,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );

        if (appInsights) {
          appInsights.trackEvent({
            name: 'REMOVE_MEETINGFLOW_FROM_DECISION_SITE',
            properties: {
              organizationSlug,
              dealRoomId,
              meetingflowId,
            },
          });
        }

        await invalidateMeetingQueries(
          queryClient,
          organizationSlug,
          dealRoomId,
        );
      } catch (error) {
        console.error('Error removing meetingflow from decision site:', error);
        throw error;
      }
    })(),
    {
      loading: 'Removing Meetingflow from Decision Site...',
      success: 'Successfully removed Meetingflow from Decision Site',
      error: 'Failed to remove Meetingflow from Decision Site',
    },
  );
};

export const toggleMeetingflowFeature = async ({
  organizationSlug,
  dealRoomId,
  meetingflowId,
  token,
  queryClient,
  appInsights,
}: ToggleMeetingflowFeatureOptions): Promise<boolean> => {
  return await toast.promise(
    (async () => {
      try {
        // Get the list of artifacts to find the one associated with this meetingflow
        const artifacts = await DealRoomsApiClient.listArtifacts(
          { organizationSlug, dealRoomId },
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );

        // Find the meetingflow artifact
        const artifact = artifacts.data.find(
          (artifact) =>
            artifact.type === 'MEETINGFLOW' &&
            artifact.meetingflowId === meetingflowId,
        );

        if (!artifact) {
          throw new Error(
            'Could not find an artifact for this Meetingflow in this Decision Site.',
          );
        }

        // Feature/unfeature the artifact
        if (artifact.featuredAt) {
          await DealRoomsApiClient.unfeatureArtifact(
            organizationSlug,
            dealRoomId,
            artifact.id,
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          );
        } else {
          await DealRoomsApiClient.featureArtifact(
            {
              organizationSlug,
              dealRoomId,
              artifactId: artifact.id,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          );
        }

        if (appInsights) {
          appInsights.trackEvent({
            name: artifact.featuredAt
              ? 'UNFEATURE_MEETINGFLOW'
              : 'FEATURE_MEETINGFLOW',
            properties: {
              organizationSlug,
              dealRoomId,
              meetingflowId,
            },
          });
        }

        await invalidateMeetingQueries(
          queryClient,
          organizationSlug,
          dealRoomId,
        );

        // Return the new featured state
        return !artifact.featuredAt;
      } catch (error) {
        console.error('Error toggling meeting feature state:', error);
        throw error;
      }
    })(),
    {
      loading: 'Updating Meeting...',
      success: (isFeatured) =>
        isFeatured
          ? 'Added this meeting to Featured Section'
          : 'Removed this meeting from Featured Section',
      error: 'Failed to update Meeting',
    },
  );
};

export const handleMeetingflowError = (
  error: unknown,
  organizationSlug: string,
  event: CalendarEvent | undefined,
  appInsights?: ReactPlugin,
): string => {
  if (error && isAxiosErrorResponse(error, 403)) {
    return `You don't have permission to create meetings in this workspace`;
  }

  if (appInsights) {
    appInsights.trackException({
      exception: error instanceof Error ? error : new Error(String(error)),
      properties: {
        organizationSlug,
        eventSource: event?.source,
        eventId: event?.externalId,
        status: isAxiosErrorResponse(error)
          ? error.response?.status
          : undefined,
        statusText: isAxiosErrorResponse(error)
          ? error.response?.statusText
          : undefined,
      },
    });

    appInsights.trackEvent({
      name: 'CREATE_PLAN_FAILED',
      properties: {
        organizationSlug,
        source: event?.source,
        eventId: event?.externalId,
        status: isAxiosErrorResponse(error)
          ? error.response?.status
          : undefined,
        statusText: isAxiosErrorResponse(error)
          ? error.response?.statusText
          : undefined,
      },
    });
  }

  return 'Something went wrong creating the Meetingflow, please try again';
};
