import { Box, Tab, Tabs, styled } from '@mui/material';
import { DSTabPanelProps } from './DSTabs';

export const StyledTabs = styled(Tabs)(({ theme }) => ({
  backgroundColor: 'transparent',
  minHeight: '2rem',

  '.MuiTabs-flexContainer': {
    gap: '0.8rem',
  },

  '.MuiTabs-indicator': {
    backgroundColor: theme.palette.primary.main,
  },

  '.MuiTabs-scroller': {
    overflow: 'auto !important',
  },
}));

export const StyledTab = styled(Tab, {
  shouldForwardProp: (prop) => prop !== 'selected',
})(({ theme }) => ({
  textTransform: 'none',
  fontSize: 14,
  fontWeight: 400,
  color: theme.palette.primary.main,
  transition: 'all 0.3s ease',
  padding: '.25rem .25rem .5rem .25rem',
  minHeight: '2rem',

  '&.Mui-selected': {
    fontWeight: '500',
  },

  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    color: theme.palette.primary.main,
  },
}));

export const TabLabelContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '.5rem',
});

interface TabBadgeProps {
  selected?: boolean;
}

export const TabBadge = styled('div')<TabBadgeProps>(({ theme, selected }) => ({
  backgroundColor: '#e8e8ec',
  color: selected ? theme.palette.primary.main : theme.palette.secondary.main,
  borderRadius: '50%',
  minWidth: '1.25rem',
  height: '1.25rem',
  lineHeight: '1.25rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '12px',
}));

export const TabPanel = styled(Box)<DSTabPanelProps>(({ theme, value, index }) => ({
  display: value === index ? 'flex' : 'none',
  flexDirection: 'column',
  flex: 1,
  minHeight: 0,
  height: '100%',
  backgroundColor: theme.palette.background.default,
}));
