/**
 * DecisionSiteMeetingPanelTasks is a component that displays action items
 * associated with a meeting. It shows both user-added tasks and AI-suggested
 * tasks derived from the meeting transcript.
 *
 * Features:
 * - Displays manually added action items
 * - Shows AI-suggested action items with context
 * - Groups tasks by source (user vs. AI)
 * - Provides speaker attribution for suggested items
 */

import React, { useMemo, useState } from 'react';
import { DetailedMeetingflow } from '@meetingflow/common/Api/data-contracts';
import { useDecisionSiteMeetingflowActionItems } from './hooks/useDecisionSiteMeetingflowActionItems';
import { MilestoneActionItem } from '../../Milestones/MilestoneActionItem';
import { useDealRoom } from '../../../../Hooks/useDealRoom';
import { useOrganization } from '../../../../Hooks/useOrganization';
import { Typography, Box } from '@mui/material';
import toast from 'react-hot-toast';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';
import {
  TaskSection,
  SectionTitle,
  TaskContainer,
  AddTaskContainer,
  AITaskSection,
  AIIndicator,
} from './DecisionSiteMeetingPanelTasks.styles';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { useMutualPlan } from '../../../../Hooks/useMutualPlan';

/**
 * Props interface for DecisionSiteMeetingPanelTasks
 * @property meetingflow - Detailed meeting information containing action items
 * @property refetchMeetingflow - Function to refetch meetingflow data
 */
interface DecisionSiteMeetingPanelTasksProps {
  meetingflow: DetailedMeetingflow;
  refetchMeetingflow: () => Promise<void>;
}

/**
 * Component that renders action items from both user input and AI suggestions
 * Uses the useDecisionSiteMeetingflowActionItems hook to fetch and manage tasks
 */
export const DecisionSiteMeetingPanelTasks: React.FC<
  DecisionSiteMeetingPanelTasksProps
> = ({ meetingflow, refetchMeetingflow }) => {
  const { dealRoomId, dealRoom } = useDealRoom();
  const { slug: organizationSlug } = useOrganization();

  const [taskIdForComments, setTaskIdForComments] = useState<number>(-1);

  /**
   * Hook to fetch and manage action items
   * - suggestedActionItems: Tasks suggested by AI based on transcript
   * - userAddedActionItems: Tasks manually added by users
   * - isLoading: Loading state for task data
   */
  const {
    suggestedActionItems,
    userAddedActionItems,
    isLoading,
    mutualPlanActionItems,
    mutualPlanTaskCount,
    userAddedTaskCount,
    aiSuggestedTaskCount,
    totalTaskCount,
    refetchTasks,
    deleteSuggestedActionItem,
  } = useDecisionSiteMeetingflowActionItems(meetingflow, refetchMeetingflow);

  const { refetch: refetchMutualPlan } = useMutualPlan(
    organizationSlug,
    dealRoomId,
  );

  return (
    <TaskContainer>
      {/* Add a new Action Item based on this meetingflow */}
      {organizationSlug && dealRoomId && (
        <TaskSection>
          <SectionTitle variant="subtitle1">
            Add a Task to Mutual Plan
          </SectionTitle>
          <AddTaskContainer>
            <MilestoneActionItem
              actionItem={{}}
              setTaskIdForComments={setTaskIdForComments}
              showMilestoneEditor
              fromMeetingflowId={meetingflow.id}
              allowInlineTitleEdit
              isLastItem
            />
          </AddTaskContainer>
        </TaskSection>
      )}

      {/* User-added action items section */}
      {organizationSlug && dealRoomId && userAddedActionItems?.length > 0 && (
        <TaskSection>
          <SectionTitle variant="subtitle1">
            Action Items added by Meetingflow users
          </SectionTitle>
          {userAddedActionItems?.map((item, index, arr) => (
            <React.Fragment key={index}>
              <MilestoneActionItem
                actionItem={{ ...item.actionItem, id: undefined }}
                setTaskIdForComments={setTaskIdForComments}
                isLastItem={index === arr.length - 1}
                fromMeetingflowId={meetingflow.id}
                showMilestoneEditor
                allowInlineTitleEdit
              />
            </React.Fragment>
          ))}
        </TaskSection>
      )}

      {/* AI-suggested action items section */}
      {organizationSlug && dealRoomId && suggestedActionItems?.length > 0 && (
        <AITaskSection>
          <SectionTitle variant="subtitle1">
            <AIIndicator>
              <AutoAwesomeIcon fontSize="small" />
            </AIIndicator>
            AI-Suggested Action Items
          </SectionTitle>
          {suggestedActionItems?.map((item, index) => (
            <Box key={index}>
              <MilestoneActionItem
                actionItem={item.actionItem}
                setTaskIdForComments={setTaskIdForComments}
                isLastItem={index === suggestedActionItems.length - 1}
                showDeleteButton={true}
                showMilestoneEditor
                fromMeetingflowId={meetingflow.id}
                isSuggestedItem={true}
                allowInlineTitleEdit
                onSuggestedItemAdded={async () => {
                  await deleteSuggestedActionItem(index);
                  await refetchMeetingflow();
                  await refetchMutualPlan();
                  toast.success('Action item added to Mutual Plan');
                }}
                onDelete={async () => {
                  await deleteSuggestedActionItem(index);
                  await refetchMeetingflow();
                  await refetchMutualPlan();
                  toast.success('Action item deleted');
                }}
              />
            </Box>
          ))}
        </AITaskSection>
      )}

      {organizationSlug &&
        dealRoomId &&
        mutualPlanActionItems &&
        mutualPlanActionItems.length > 0 && (
          <TaskSection>
            <SectionTitle variant="subtitle1">Mutual Plan Tasks</SectionTitle>
            {mutualPlanActionItems?.map((item, index, arr) => (
              <React.Fragment key={index}>
                <MilestoneActionItem
                  actionItem={item}
                  setTaskIdForComments={setTaskIdForComments}
                  isLastItem={index === arr.length - 1}
                  fromMeetingflowId={meetingflow.id}
                  allowInlineTitleEdit
                />
              </React.Fragment>
            ))}
          </TaskSection>
        )}
    </TaskContainer>
  );
};
