import {
  DealRoomNotificationStatusType,
  DealRoomNotification,
  DealRoomNotificationCategory,
} from '@meetingflow/common/Api/data-contracts';

const NotificationTypes = {
  CREATED: 'CREATED' as const,
  REMOVED: 'REMOVED' as const,
  UPDATED: 'UPDATED' as const,
  COMMENTED: 'COMMENTED' as const,
  UPVOTED: 'UPVOTED' as const,
  DOWNVOTED: 'DOWNVOTED' as const,
  UPLOADED: 'UPLOADED' as const,
  DOWNLOADED: 'DOWNLOADED' as const,
  CHANGED_STATUS: 'CHANGED_STATUS' as const,
  CHANGED_DUE_DATE: 'CHANGED_DUE_DATE' as const,
  ASSIGNED: 'ASSIGNED' as const,
  UNASSIGNED: 'UNASSIGNED' as const,
  CHANGED_MILESTONE: 'CHANGED_MILESTONE' as const,
  SCHEDULED_RECORDING: 'SCHEDULED_RECORDING' as const,
  SCHEDULED: 'SCHEDULED' as const,
  ADDED_TO_DECISION_SITE: 'ADDED_TO_DECISION_SITE' as const,
  INVITED_MEMBER: 'INVITED_MEMBER' as const,
  MENTIONED: 'MENTIONED' as const,
} as const;

export const getNotificationCategoryDisplayName = (
  notificationCategory: DealRoomNotificationCategory,
) => {
  switch (notificationCategory) {
    case 'ARTIFACT':
      return 'Artifact';
    case 'ACTION_ITEM':
      return 'Task';
    case 'DECISION_SITE':
      return 'Decision Site';
    case 'COMMENT':
      return 'Comment';
    case 'MEETING':
      return 'Meeting';
    default:
      return 'Unknown';
  }
};

export const getNotificationCategoryDisplayArticle = (
  notificationCategory: DealRoomNotificationCategory,
) => {
  switch (notificationCategory) {
    case 'ARTIFACT':
      return 'an';
    case 'ACTION_ITEM':
      return 'a';
    case 'DECISION_SITE':
      return 'a';
    case 'COMMENT':
      return 'a';
    case 'MEETING':
      return 'a';
    default:
      return 'a';
  }
};

export const getNotificationTitle = (notification: DealRoomNotification) => {
  // return notification.title;

  const triggeredBy = notification.triggeredBy?.name || 'Someone';

  // Handle shared action types
  switch (notification.notificationType) {
    case NotificationTypes.CREATED:
      return `${triggeredBy} created ${getNotificationCategoryDisplayArticle(notification.notificationCategory)} ${getNotificationCategoryDisplayName(notification.notificationCategory).toLowerCase()}`;
    case NotificationTypes.REMOVED:
      return `${triggeredBy} removed ${getNotificationCategoryDisplayArticle(notification.notificationCategory)} ${getNotificationCategoryDisplayName(notification.notificationCategory).toLowerCase()}`;
    case NotificationTypes.UPDATED:
      return `${triggeredBy} updated ${getNotificationCategoryDisplayArticle(notification.notificationCategory)} ${getNotificationCategoryDisplayName(notification.notificationCategory).toLowerCase()}`;
    case NotificationTypes.COMMENTED:
      return `${triggeredBy} commented on ${getNotificationCategoryDisplayArticle(notification.notificationCategory)} ${getNotificationCategoryDisplayName(notification.notificationCategory).toLowerCase()}`;
    case NotificationTypes.UPVOTED:
      return `${triggeredBy} upvoted ${getNotificationCategoryDisplayArticle(notification.notificationCategory)} ${getNotificationCategoryDisplayName(notification.notificationCategory).toLowerCase()}`;
    case NotificationTypes.DOWNVOTED:
      return `${triggeredBy} downvoted ${getNotificationCategoryDisplayArticle(notification.notificationCategory)} ${getNotificationCategoryDisplayName(notification.notificationCategory).toLowerCase()}`;
    case NotificationTypes.UPLOADED:
      return `${triggeredBy} uploaded ${getNotificationCategoryDisplayArticle(notification.notificationCategory)} ${getNotificationCategoryDisplayName('ARTIFACT').toLowerCase()}`;
    case NotificationTypes.DOWNLOADED:
      return `${triggeredBy} downloaded ${getNotificationCategoryDisplayArticle(notification.notificationCategory)} ${getNotificationCategoryDisplayName('ARTIFACT').toLowerCase()}`;
    case NotificationTypes.CHANGED_STATUS:
      return `${triggeredBy} changed ${getNotificationCategoryDisplayArticle(notification.notificationCategory)} ${getNotificationCategoryDisplayName('ACTION_ITEM').toLowerCase()} status`;
    case NotificationTypes.CHANGED_DUE_DATE:
      return `${triggeredBy} updated ${getNotificationCategoryDisplayArticle(notification.notificationCategory)} ${getNotificationCategoryDisplayName('ACTION_ITEM').toLowerCase()} due date`;
    case NotificationTypes.ASSIGNED:
      return `${triggeredBy} assigned ${getNotificationCategoryDisplayArticle(notification.notificationCategory)} ${getNotificationCategoryDisplayName('ACTION_ITEM').toLowerCase()}`;
    case NotificationTypes.UNASSIGNED:
      return `${triggeredBy} unassigned ${getNotificationCategoryDisplayArticle(notification.notificationCategory)} ${getNotificationCategoryDisplayName('ACTION_ITEM').toLowerCase()}`;
    case NotificationTypes.CHANGED_MILESTONE:
      return `${triggeredBy} changed ${getNotificationCategoryDisplayArticle(notification.notificationCategory)} ${getNotificationCategoryDisplayName('ACTION_ITEM').toLowerCase()} milestone`;
    case NotificationTypes.ADDED_TO_DECISION_SITE:
      return `${triggeredBy} added ${getNotificationCategoryDisplayArticle(notification.notificationCategory)} ${getNotificationCategoryDisplayName('MEETING').toLowerCase()} to the ${getNotificationCategoryDisplayName('DECISION_SITE')}`;
    case NotificationTypes.SCHEDULED_RECORDING:
      return `${triggeredBy} scheduled a recording`;
    case NotificationTypes.INVITED_MEMBER:
      return `${triggeredBy} invited a new member`;
    default:
      return 'New notification';
  }
};

export const isNotificationUnread = (status: DealRoomNotificationStatusType) =>
  status === 'UNREAD';
