/**
 * DSArtifactPanelHeader is a reusable component that provides
 * a consistent header layout for the artifact panel. It includes a content area
 * for custom elements and a close button.
 */

import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ReactNode } from 'react';
import {
  HeaderContainer,
  ContentWrapper,
  StyledIconButton,
} from './DSArtifactPanelHeader.styles';

/**
 * Props interface for DSArtifactPanelHeader
 * @property artifactId - Unique identifier for the artifact
 * @property onClickCloseButton - Callback function to handle panel closure
 * @property children - Optional content to be rendered in the header
 */
type DSArtifactPanelHeaderProps = {
  artifactId: number;
  onClickCloseButton: () => void;
  children?: ReactNode;
};

/**
 * Header component that provides a consistent layout for the artifact panel
 * Includes a flexible content area and a close button
 */
export const DSArtifactPanelHeader = ({
  artifactId,
  onClickCloseButton,
  children,
}: DSArtifactPanelHeaderProps) => {
  return (
    <HeaderContainer>
      <ContentWrapper>{children}</ContentWrapper>
      <IconButton
        component={StyledIconButton}
        onClick={onClickCloseButton}
      >
        <CloseIcon />
      </IconButton>
    </HeaderContainer>
  );
};

export default DSArtifactPanelHeader;
