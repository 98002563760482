import { mergeStyleSets } from '@fluentui/react';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';

export const decisionSiteJourneySummaryPanelStyles = () =>
  mergeStyleSets({
    container: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      backgroundColor: DEALROOMS_COLORS.financialLighterGray,
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '1rem',
      backgroundColor: 'white',
    },
    title: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
      fontWeight: '500',
    },
    divider: {
      height: '1px',
      backgroundColor: DEALROOMS_COLORS.financialLightGray,
    },
    content: {
      flex: 1,
      minHeight: 0,
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: DEALROOMS_COLORS.financialLighterGray,
      overflow: 'auto',
    },
    contentInner: {
      height: '100%',
      // padding: '1.5rem',
    },
  });

export const drawerStyles = {
  '& .MuiDrawer-paper': {
    width: '70%',
    minWidth: '500px',
    maxWidth: '800px',
    boxSizing: 'border-box',
    padding: 0,
    '@media (max-width: 600px)': {
      minWidth: '250px',
      width: '90%',
    },
  },
};
