import React from 'react';
import { Box, Typography } from '@mui/material';
import { DealRoomArtifact } from '@meetingflow/common/Api/data-contracts';
import { DSTextField } from '../../DS/DSTextField';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';
import { DSTooltip } from '../../DS';

export interface ArtifactDescriptionProps {
  description: string | undefined;
  isEditingDescription: boolean;
  onStartEditDescription: () => void;
  onBlurDescription: () => Promise<void>;
  onChangeDescription: (value: string) => void;
  artifact: DealRoomArtifact;
}

/**
 * ArtifactDescription Component
 *
 * Displays and handles editing of the artifact description using MUI components.
 * Supports both view and edit modes with proper keyboard interactions.
 */
export const ArtifactDescription: React.FC<ArtifactDescriptionProps> = ({
  description,
  isEditingDescription,
  onStartEditDescription,
  onBlurDescription,
  onChangeDescription,
  artifact,
}) => {
  return (
    <Box
      onClick={!isEditingDescription ? onStartEditDescription : undefined}
      sx={{
        width: 'calc(100% - 2rem)',
        padding: '1rem',
        backgroundColor: DEALROOMS_COLORS.financialLighterGray,
        borderRadius: '.25rem',
        cursor: 'pointer',
        transition: '.3s ease-in-out all',
        maxHeight: 'calc(100% - 2rem)',
        overflow: 'hidden',
        overflowY: 'auto',
        ':hover': {
          backgroundColor: DEALROOMS_COLORS.financialLightGray,
        },
      }}
    >
      {isEditingDescription ? (
        <DSTextField
          value={description}
          onBlur={onBlurDescription}
          onChange={(e) => onChangeDescription(e.target.value)}
          onKeyDown={async (e) => {
            if (e.key === 'Enter') {
              await onBlurDescription();
            }
          }}
          autoFocus
          fullWidth
          size="small"
          multiline
          rows={4}
          minRows={4}
          sx={{
            backgroundColor: DEALROOMS_COLORS.white,
          }}
        />
      ) : (
        <DSTooltip
          title={
            artifact.description ? 'Edit description...' : 'Add a description'
          }
        >
          <Typography variant="body1">
            {artifact.description || 'Enter description here...'}
          </Typography>
        </DSTooltip>
      )}
    </Box>
  );
};
