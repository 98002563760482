import React from 'react';
import { Box, Typography } from '@mui/material';
import { DEALROOMS_COLORS } from '../../../../../Themes/Themes';

interface NotificationGroupHeaderProps {
  icon: React.ReactNode;
  title: string;
}

export const NotificationsGroupHeader: React.FC<
  NotificationGroupHeaderProps
> = ({ icon, title }) => {
  return (
    <Box display="flex" alignItems="center" gap={1}>
      {icon}
      <Typography
        variant="subtitle1"
        sx={{
          color: DEALROOMS_COLORS.themePrimary,
          fontWeight: 500,
          fontSize: '1.125rem',
          lineHeight: '1.5rem',
        }}
      >
        {title}
      </Typography>
    </Box>
  );
};
