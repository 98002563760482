import { IconButton } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { DealRoomsApiClient } from '../../../Services/NetworkCommon';
import { useMutualPlan } from '../../../Hooks/useMutualPlan';
import { useMilestonesSummary } from '../../../Hooks/useMilestonesSummary';
import { DSMenu, DSMenuItem } from '../DS';
import toast from 'react-hot-toast';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';

type MilestoneOptionsProps = {
  milestoneId: number;
  organizationSlug: string;
  dealRoomId: number;
  onRequestArtifact?: () => Promise<void>;
  isMobile?: boolean;
  onEditDate?: () => void;
};

export const MilestoneMoreOptions = ({
  milestoneId,
  organizationSlug,
  dealRoomId,
  onRequestArtifact,
  isMobile,
  onEditDate,
}: MilestoneOptionsProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { getAccessTokenSilently } = useAuth0();
  const { mutualPlan, refetch: refetchMutualPlan } = useMutualPlan(
    organizationSlug,
    dealRoomId,
  );
  const { refetch: refetchMilestonesSummary } = useMilestonesSummary(
    organizationSlug,
    dealRoomId,
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleHideMilestone = async () => {
    if (!Array.isArray(mutualPlan?.milestones)) return;

    const updatedMilestones = mutualPlan?.milestones.map((milestone) => ({
      id: milestone.id,
      title: milestone.title || "",
      visible: milestone.id === milestoneId
        ? !milestone.visible
        : milestone.visible,
      dueDate: milestone.dueDate,
    }));

    const token = await getAccessTokenSilently();
    await toast.promise(
      DealRoomsApiClient.saveMilestones(
        organizationSlug,
        dealRoomId,
        updatedMilestones,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      ),
      {
        loading: 'Updating milestones',
        success: () => {
          Promise.all([refetchMilestonesSummary(), refetchMutualPlan()]);
          return 'Successfully updated the milestones list';
        },
        error: () => 'Something went wrong updating the milestones list',
      },
    );

    setAnchorEl(null);
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <IconButton
        onClick={handleClick}
        size="small"
        sx={{
          color: DEALROOMS_COLORS.cloudburst,
        }}
      >
        <MoreHorizIcon fontSize="small" />
      </IconButton>
      <DSMenu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={(e) => e.stopPropagation()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {isMobile && onEditDate && (
          <DSMenuItem
            onClick={() => {
              handleClose();
              onEditDate();
            }}
            icon={<CalendarTodayIcon fontSize="small" />}
            primaryText="Edit Due Date"
          />
        )}
        {isMobile && onRequestArtifact && (
          <DSMenuItem
            onClick={() => {
              handleClose();
              onRequestArtifact();
            }}
            icon={<MoveToInboxIcon fontSize="small" />}
            primaryText="Request Artifact"
          />
        )}
        <DSMenuItem
          onClick={handleHideMilestone}
          icon={<VisibilityOffIcon fontSize="small" />}
          primaryText="Hide Milestone"
        />
      </DSMenu>
    </div>
  );
};
