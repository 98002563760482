/**
 * Styled components for the DSArtifactPanelHeader
 * Provides consistent styling and layout for the header section
 */

import { styled } from '@mui/material';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';

/**
 * Main container for the header
 * Uses flexbox for layout with proper spacing and alignment
 */
export const HeaderContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '.5rem',
  padding: '1rem 0 1rem 0',
  width: '100%',
});

/**
 * Wrapper for the main content area
 * Uses flex-grow to take up available space while preventing overflow
 */
export const ContentWrapper = styled('div')({
  flex: '1 1 auto',
  minWidth: 0, // This prevents flex items from overflowing
});

/**
 * Styled button component for the close icon
 * Maintains consistent spacing and icon styling
 */
export const StyledIconButton = styled('button')({
  marginLeft: '.5rem',
  '& i': {
    fontSize: '1.25rem',
    color: DEALROOMS_COLORS.themePrimary,
  },
});
