import React, { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useDealRoomId } from '../../../../Hooks/useDealRoomId';
import { useOrganizationSlug } from '../../../../Hooks/useOrganizationSlug';
import { OrganizationDealRoomArtifactsQuery } from '../../../../QueryNames';
import { useAuth0 } from '@auth0/auth0-react';
import { DealRoomsApiClient } from '../../../../Services/NetworkCommon';
import {
  isBadRequest,
  isForbiddenError,
} from '../../../../Helpers/AxiosHelpers';
import { useMeetingflow } from '../Journey/hooks/useMeetingflow';
import { useDecisionSiteMeetingflowActionItems } from '../Journey/hooks/useDecisionSiteMeetingflowActionItems';
import { MilestoneActionItem } from '../../Milestones/MilestoneActionItem';
import { DateTime } from 'luxon';
import toast from 'react-hot-toast';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';
import SuggestedTasksHeader from './SuggestedTasksHeader';
import {
  SuggestedTasksContainer,
  MeetingInfoContainer,
  MeetingInfoText,
  MeetingTitle,
  MeetingDateContainer,
  ActionItemContainer,
  MeetingTitleContainer,
} from './SuggestedTasks.styles';
import { useMutualPlan } from '../../../../Hooks/useMutualPlan';
import { Divider } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useNavigate } from '../../../../Hooks/useNavigate';

interface SuggestedTasksFromMeetingflowProps {
  meetingflowId: string;
  onClose?: () => void;
}

const SuggestedTasksFromMeetingflow = ({
  meetingflowId,
  onClose,
}: SuggestedTasksFromMeetingflowProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const organizationSlug = useOrganizationSlug();
  const dealRoomId = useDealRoomId();
  const navigate = useNavigate();

  const {
    meetingflow,
    meetingflowLoading,
    meetingflowError,
    refetchMeetingflow,
  } = useMeetingflow(meetingflowId);

  const { refetch: refetchMutualPlan } = useMutualPlan(
    organizationSlug,
    dealRoomId,
  );

  const { suggestedActionItems, isLoading, deleteSuggestedActionItem } =
    useDecisionSiteMeetingflowActionItems(meetingflow, refetchMeetingflow);

  const handleClickMeetingTitle = useCallback(() => {
    if (!meetingflow?.id) return;

    navigate(
      `/organization/${organizationSlug}/decisionsite/${dealRoomId}/journey?meeting=${meetingflow.id}`,
      { preserveQuery: true },
    );
  }, [dealRoomId, meetingflow?.id, navigate, organizationSlug]);

  // Don't render anything while loading or if there are no tasks
  if (isLoading || !suggestedActionItems?.length) {
    return null;
  }

  return (
    <SuggestedTasksContainer>
      <MeetingInfoContainer>
        <MeetingInfoText variant="body1">
          <MeetingTitleContainer onClick={handleClickMeetingTitle}>
            <CalendarMonthIcon sx={{ color: DEALROOMS_COLORS.cloudburst }} />
            <MeetingTitle>{meetingflow?.title || 'Meeting'}</MeetingTitle>
          </MeetingTitleContainer>
          {meetingflow?.startTime && (
            <MeetingDateContainer>
              {DateTime.fromISO(meetingflow.startTime).toFormat('LLL d, yyyy')}
            </MeetingDateContainer>
          )}
        </MeetingInfoText>
      </MeetingInfoContainer>
      <Divider
        sx={{
          backgroundColor: DEALROOMS_COLORS.financialLightGray,
          margin: '.25rem 0 .5rem 0',
        }}
      />
      {suggestedActionItems.map((item, index) => (
        <ActionItemContainer key={index}>
          <MilestoneActionItem
            actionItem={item.actionItem}
            setTaskIdForComments={() => {
              -1;
            }}
            isLastItem={index === suggestedActionItems.length - 1}
            showDeleteButton={true}
            showMilestoneEditor
            fromMeetingflowId={meetingflow?.id}
            isSuggestedItem={true}
            allowInlineTitleEdit
            onSuggestedItemAdded={async () => {
              await deleteSuggestedActionItem(index);
              await refetchMeetingflow();
              await refetchMutualPlan();
              toast.success('Action item added to Mutual Plan');
            }}
            onDelete={async () => {
              await deleteSuggestedActionItem(index);
              await refetchMeetingflow();
              await refetchMutualPlan();
              toast.success('Action item deleted');
            }}
          />
        </ActionItemContainer>
      ))}
    </SuggestedTasksContainer>
  );
};

interface SuggestedTaskListProps {
  onClose?: () => void;
}

const SuggestedTaskList = ({ onClose }: SuggestedTaskListProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const organizationSlug = useOrganizationSlug();
  const dealRoomId = useDealRoomId();

  const {
    data: dealRoomArtifacts,
    isLoading: artifactsLoading,
    refetch: refetchArtifacts,
  } = useQuery(
    OrganizationDealRoomArtifactsQuery(organizationSlug, dealRoomId),
    async () => {
      if (!organizationSlug || !dealRoomId) {
        throw new Error('Organization slug and deal room ID are required');
      }
      const token = await getAccessTokenSilently();
      const artifacts = await DealRoomsApiClient.listArtifacts(
        { organizationSlug, dealRoomId, includeDeleted: true },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      return artifacts;
    },
    {
      enabled: !!organizationSlug && !!dealRoomId,
      retry: (failureCount, error) => {
        if (isForbiddenError(error)) {
          return false;
        }

        if (isBadRequest(error)) {
          return false;
        }

        return failureCount < 3;
      },
    },
  );

  const meetingflowArtifacts = useMemo(
    () =>
      dealRoomArtifacts?.data?.filter((a) => a.type === 'MEETINGFLOW') || [],
    [dealRoomArtifacts],
  );

  const meetingflowArtifactsIds = useMemo(
    () => [...new Set(meetingflowArtifacts.map((a) => a.meetingflowId))],
    [meetingflowArtifacts],
  );

  if (!dealRoomId || !organizationSlug) {
    return null;
  }

  return (
    <>
      {meetingflowArtifactsIds.map((id: string) => (
        <SuggestedTasksFromMeetingflow
          key={id}
          meetingflowId={id}
          onClose={onClose}
        />
      ))}
    </>
  );
};

export default SuggestedTaskList;
