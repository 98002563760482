import React from 'react';
import { DSMenuHeaderRoot } from './DSMenuHeader.styles';

export interface DSMenuHeaderProps {
  children: React.ReactNode;
}

const DSMenuHeader: React.FC<DSMenuHeaderProps> = ({ children }) => {
  return <DSMenuHeaderRoot>{children}</DSMenuHeaderRoot>;
};

export default DSMenuHeader;
