import { useAuth0 } from '@auth0/auth0-react';
import { Spinner } from '@fluentui/react';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router';
import {
  isAxiosErrorResponse,
  isForbiddenError,
} from '../../Helpers/AxiosHelpers';
import { MeetingPlanQuery } from '../../QueryNames';
import { MeetingflowsApiClient } from '../../Services/NetworkCommon';
import { OrganizationSlugRouteParams } from '../../types/RouteParams';
import OrganizationForbidden from '../Organization/OrganizationForbidden';
import { ResourceNotFound } from '../ResourceNotFound';
import { MeetingPlanPage } from './MeetingPlan';

export type MeetingPlanRootProps = {};
export const MeetingPlanRoot = () => {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  const { organizationSlug, meetingPlanId } = useParams<
    OrganizationSlugRouteParams & {
      meetingPlanId: string;
    }
  >();

  const { isLoading: meetingPlanDataLoading, error: meetingPlanError } =
    useQuery(
      MeetingPlanQuery(organizationSlug!, meetingPlanId!),
      async () => {
        const token = await getAccessTokenSilently();
        return MeetingflowsApiClient.getMeetingflow(
          organizationSlug!,
          meetingPlanId!,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
      },
      {
        enabled: !!organizationSlug && !!meetingPlanId,
      },
    );

  useEffect(() => {
    if (!organizationSlug) {
      navigate(`/`);
      return;
    }
    if (!meetingPlanId) {
      navigate(`/organization/${organizationSlug}`);
      return;
    }
  }, [meetingPlanId, navigate, organizationSlug]);

  if (!organizationSlug || !meetingPlanId) {
    return null;
  }

  if (meetingPlanDataLoading) {
    return <Spinner />;
  }

  if (isForbiddenError(meetingPlanError)) {
    return <OrganizationForbidden organizationSlug={organizationSlug} userEmail={undefined} />;
  }

  if (isAxiosErrorResponse(meetingPlanError)) {
    return <ResourceNotFound resourceType="Meetingflow" />;
  }

  return (
    <MeetingPlanPage
      key={`${organizationSlug}__${meetingPlanId}`}
      organizationSlug={organizationSlug!}
      meetingPlanId={meetingPlanId!}
    />
  );
};
