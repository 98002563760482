/**
 * Styled components for the SuggestedTasksHeader
 * Provides consistent styling and layout for the header section
 */

import { styled } from '@mui/material';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';

/**
 * Main container for the header
 * Uses flexbox for layout with proper spacing and alignment
 */
export const HeaderContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '.5rem',
  padding: '1rem',
  width: 'calc(100% - 2rem)',
  borderBottom: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
});

/**
 * Wrapper for the main content area
 * Uses flex-grow to take up available space while preventing overflow
 */
export const ContentWrapper = styled('div')({
  flex: '1 1 auto',
  minWidth: 0, // This prevents flex items from overflowing
});

/**
 * Title text styling
 */
export const Title = styled('h2')({
  margin: 0,
  fontSize: '1rem',
  fontWeight: 500,
  color: DEALROOMS_COLORS.woodsmoke,
});

/**
 * Subtitle text styling
 */
export const Subtitle = styled('div')({
  margin: '0.25rem 0 0 0',
  fontSize: '.875rem',
  fontWeight: 400,
  color: DEALROOMS_COLORS.cloudburst,
});

/**
 * Styled button component for the close icon
 * Maintains consistent spacing and icon styling
 */
export const StyledIconButton = styled('button')({
  marginLeft: '.5rem',
  '& svg': {
    fontSize: '1.25rem',
    color: DEALROOMS_COLORS.themePrimary,
  },
});
