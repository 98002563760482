/**
 * Decision Site Inbox Component
 *
 * Manages the state and display of notifications in a tabbed interface with a master-detail view.
 *
 * State Management:
 * - Active Notification: Single notification displayed in the detail view, controlled via URL
 * - Selected Notifications: Multiple notifications that can be selected for bulk operations
 * - Tab State: Current active tab filtering the notifications
 *
 * Features:
 * - Tabbed filtering of notifications by category
 * - Master-detail view with URL-based navigation
 * - Multi-select functionality for bulk operations:
 *   - Archive notifications (in non-archived tabs)
 *   - Recover notifications (in archived tab)
 *   - Mark as read
 * - Responsive layout that adapts when a notification is active
 */
import { useState, useMemo, useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { DSInboxNotificationListDetail } from './Inbox/NotificationListDetail';
import { DSInboxRoot, DSInboxHeader, DSInboxContent } from './Inbox/styles';
import { DSTab, DSTabs } from '../DS';
import { useOrganizationSlug } from '../../../Hooks/useOrganizationSlug';
import { useNotificationData } from '../../../Hooks/useNotificationData';
import { useDealRooms } from '../../../Hooks/useDealRooms';
import { StyledSpinner } from '../../../Components/StyledSpinner';
import {
  DealRoomNotificationStatusType,
  DealRoomNotification,
} from '@meetingflow/common/Api/data-contracts';
import { useDealRoom } from '../../../Hooks/useDealRoom';

/**
 * Configuration interface for inbox tabs
 */
interface InboxTabConfig {
  id: string;
  label: string;
  filter: (notifications: DealRoomNotification[]) => DealRoomNotification[];
  emptyState: {
    title: string;
    subtitle: string;
  };
  isArchive?: boolean;
}

/**
 * Centralized configuration for all inbox tabs
 */
const INBOX_TABS: InboxTabConfig[] = [
  {
    id: 'all',
    label: 'All',
    filter: (notifications) =>
      [...notifications].filter((n) => n.status !== 'ARCHIVED'),
    emptyState: {
      title: 'No notifications',
      subtitle: 'When you receive notifications, they will appear here',
    },
  },
  {
    id: 'unread',
    label: 'Unread',
    filter: (notifications) =>
      [...notifications].filter((n) => n.status === 'UNREAD'),
    emptyState: {
      title: 'No unread notifications',
      subtitle: 'When you receive notifications, they will appear here',
    },
  },
  {
    id: 'archived',
    label: 'Archived',
    filter: (notifications) =>
      [...notifications].filter((n) => n.status === 'ARCHIVED'),
    emptyState: {
      title: 'No archived notifications',
      subtitle: 'Archived notifications will appear here',
    },
    isArchive: true,
  },
];

/**
 * DSInbox Component
 *
 * Main component for displaying the Decision Site inbox interface.
 * Features:
 * - Tabbed navigation (All, Mentions, Following, Archived)
 * - Notification list with filtering
 * - URL-based notification selection (?notification=id)
 * - Responsive detail view based on URL state
 */
const DSInbox = ({
  dealRoomId,
  showToastsForNewNotifications = false,
}: {
  dealRoomId?: number | string;
  showToastsForNewNotifications?: boolean;
}) => {
  const orgSlug = useOrganizationSlug();
  const { dealRoom } = useDealRoom();

  // Use the provided dealRoomId or fall back to the current one from context
  const effectiveDealRoomId = dealRoomId ?? dealRoom?.id;
  const isAllDealRooms = effectiveDealRoomId === 'all';

  // Use search params for URL-based notification selection
  // Manages active notification (for list/detail view)
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  const {
    notificationData: notifications,
    notificationDataLoading,
    refetchNotificationData,
    bulkUpdateNotifications,
  } = useNotificationData(
    orgSlug,
    isAllDealRooms ? undefined : (effectiveDealRoomId as number),
    showToastsForNewNotifications ? true : false, // BuyersSidebar shows the toasts inside a Decision Site, most other uses of this hook should not or we'll get duplicate toasts
  );

  const {
    allNotifications,
    totalUnreadCount,
    isLoading: dealRoomsLoading,
  } = useDealRooms(orgSlug!);

  const displayedNotifications = isAllDealRooms
    ? allNotifications
    : notifications;
  const isLoading = isAllDealRooms ? dealRoomsLoading : notificationDataLoading;

  // Manages the actively-selected tab
  const [activeTab, setActiveTab] = useState<string | number>('all');

  // Manages the activley selected notifications (for bulk operations)
  const [selectedNotifications, setSelectedNotifications] = useState<
    Set<string>
  >(new Set());

  const clearSelectedNotifications = useCallback(() => {
    setSelectedNotifications(() => new Set());
  }, []);

  /**
   * Handles bulk deletion of selected notifications
   * Only available in non-archived tabs
   */
  const handleDeleteSelectedNotifications = useCallback(() => {
    if (selectedNotifications.size === 0) return;
    const notificationIds = Array.from(selectedNotifications).map((id) => id.toString());
    bulkUpdateNotifications(
      notificationIds,
      'ARCHIVED' as DealRoomNotificationStatusType,
    );
    setSelectedNotifications(() => new Set());
  }, [selectedNotifications, bulkUpdateNotifications]);

  /**
   * Handles bulk recovery of archived notifications
   * Only available in the archived tab
   */
  const handleUndeleteSelectedNotifications = useCallback(() => {
    if (selectedNotifications.size === 0) return;
    const notificationIds = Array.from(selectedNotifications).map((id) => id.toString());
    bulkUpdateNotifications(
      notificationIds,
      'UNREAD' as DealRoomNotificationStatusType,
    );
    setSelectedNotifications(() => new Set());
  }, [selectedNotifications, bulkUpdateNotifications]);

  // Memoized counts for each tab
  // Updates only when notifications change
  const counts = useMemo(
    () => ({
      all: displayedNotifications.length,
      unread: INBOX_TABS.find((tab) => tab.id === 'unread')?.filter(
        displayedNotifications ?? [],
      ).length,
      archived: INBOX_TABS.find((tab) => tab.id === 'archived')?.filter(
        displayedNotifications ?? [],
      ).length,
    }),
    [displayedNotifications],
  );

  // Get the current tab configuration
  const currentTabConfig = useMemo(
    () => INBOX_TABS.find((tab) => tab.id === activeTab) ?? INBOX_TABS[0],
    [activeTab],
  );

  // Filter notifications based on current tab
  const filteredNotifications = useMemo(
    () => currentTabConfig.filter(displayedNotifications ?? []),
    [currentTabConfig, displayedNotifications],
  );

  const handleUpdateSelectedNotificationsStatus = useCallback(
    (status: DealRoomNotificationStatusType) => {
      if (selectedNotifications.size === 0) return;
      const notificationIds = Array.from(selectedNotifications).map((id) => id.toString());
      bulkUpdateNotifications(notificationIds, status);
      setSelectedNotifications(() => new Set());
    },
    [selectedNotifications, bulkUpdateNotifications],
  );

  const handleMarkSelectedNotificationsAsRead = useCallback(() => {
    handleUpdateSelectedNotificationsStatus('READ');
  }, [handleUpdateSelectedNotificationsStatus]);

  const handleMarkSelectedNotificationsAsUnread = useCallback(() => {
    handleUpdateSelectedNotificationsStatus('UNREAD');
  }, [handleUpdateSelectedNotificationsStatus]);

  const handleMarkNotificationsAsRead = useCallback(
    (notificationIds: string[]) => {
      bulkUpdateNotifications(notificationIds, 'READ');
    },
    [bulkUpdateNotifications],
  );

  const handleSelectAllNotifications = useCallback(() => {
    setSelectedNotifications(new Set(filteredNotifications.map((n) => n.id.toString())));
  }, [filteredNotifications]);

  const handleClearSelectedNotifications = useCallback(() => {
    setSelectedNotifications(new Set());
  }, []);

  /**
   * Derives the selected notification from the URL's notification parameter
   * Returns undefined if no notification ID is present or if the ID doesn't match any notification
   */
  const activeNotification = useMemo(() => {
    const notificationId = searchParams.get('notification');
    if (!notificationId) return undefined;
    return displayedNotifications.find((n) => n.id.toString() === notificationId);
  }, [searchParams, displayedNotifications]);

  /**
   * Gets the navigation path for a notification based on its category and associated IDs
   */
  const getNotificationNavigationPath = useCallback(
    (notification: DealRoomNotification): string | null => {
      const basePath = `/organization/${orgSlug}/decisionsite/${effectiveDealRoomId}`;

      switch (notification.notificationCategory) {
        case 'ARTIFACT':
          if (notification.artifactId) {
            return `${basePath}/artifact/${notification.artifactId}`;
          }
          return `${basePath}/artifacts`; // Fallback to artifacts list

        case 'ACTION_ITEM':
          // @ts-ignore because actionItemId exists in the data but not in the type
          if (notification.actionItemId) {
            // @ts-ignore because actionItemId exists in the data but not in the type
            return `${basePath}/mutual-action-plan?task=${notification.actionItemId}`;
          }
          return `${basePath}/mutual-action-plan`; // Fallback to tasks list

        case 'MEETING':
          if (notification.meetingId) {
            return `${basePath}/journey?meeting=${notification.meetingId}`;
          }
          return `${basePath}/journey`; // Fallback to journey view

        case 'COMMENT':
          // If comment is on an artifact, navigate to artifact with comment context
          if (notification.artifactId && notification.commentId) {
            return `${basePath}/artifact/${notification.artifactId}?commentId=${notification.commentId}`;
          }
          return null;

        case 'DECISION_SITE':
          // General decision site notifications just go to the main view
          return basePath;

        default:
          return null;
      }
    },
    [orgSlug, effectiveDealRoomId],
  );

  /**
   * Handler for notification clicks
   * - Updates the URL with the selected notification's ID
   * - Clicking an already selected notification removes it from the URL
   * - This triggers a re-render through the activeNotification memo
   */
  const handleNotificationClick = (notification: DealRoomNotification) => {
    setSelectedNotifications((prev) => {
      const next = new Set(prev);
      next.clear();
      return next;
    });

    handleMarkNotificationsAsRead([notification.id.toString()]);
    refetchNotificationData();

    const navigationPath = getNotificationNavigationPath(notification);
    if (navigationPath) {
      // Preserve existing search params except 'notification'
      const currentParams = new URLSearchParams(searchParams);
      currentParams.delete('notification');
      const queryString = currentParams.toString();

      navigate(
        navigationPath +
          (queryString
            ? `${navigationPath.includes('?') ? '&' : '?'}${queryString}`
            : ''),
      );
    }
  };

  /**
   * Handles toggling a notification's selected state for multi-select operations
   */
  const handleNotificationSelect = (notification: DealRoomNotification) => {
    setSelectedNotifications((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(notification.id.toString())) {
        newSet.delete(notification.id.toString());
      } else {
        newSet.add(notification.id.toString());
      }
      return newSet;
    });
  };

  // Early return if no organization slug
  if (!orgSlug) {
    return <StyledSpinner />;
  }

  if (isLoading) {
    return <StyledSpinner />;
  }

  return (
    <DSInboxRoot>
      <DSInboxHeader>
        <DSTabs value={activeTab} onChange={(_, value) => setActiveTab(value)}>
          {INBOX_TABS.map((tab) => (
            <DSTab
              key={tab.id}
              value={tab.id}
              label={tab.label}
              count={tab.filter(displayedNotifications ?? []).length}
            />
          ))}
        </DSTabs>
      </DSInboxHeader>
      <DSInboxContent>
        {isLoading ? (
          <StyledSpinner />
        ) : (
          <DSInboxNotificationListDetail
            listViewOnly
            notifications={filteredNotifications}
            activeNotification={activeNotification}
            selectedNotifications={selectedNotifications}
            noNotificationsMessageTitle={currentTabConfig.emptyState.title}
            noNotificationsMessageSubtitle={
              currentTabConfig.emptyState.subtitle
            }
            onNotificationClick={handleNotificationClick}
            onNotificationSelect={handleNotificationSelect}
            onClearSelectedNotifications={clearSelectedNotifications}
            onDeleteSelectedNotifications={
              currentTabConfig?.isArchive
                ? undefined
                : handleDeleteSelectedNotifications
            }
            onUndeleteSelectedNotifications={
              currentTabConfig?.isArchive
                ? handleUndeleteSelectedNotifications
                : undefined
            }
            onMarkSelectedNotificationsAsRead={
              handleMarkSelectedNotificationsAsRead
            }
            onMarkSelectedNotificationsAsUnread={
              handleMarkSelectedNotificationsAsUnread
            }
            onSelectAllNotifications={handleSelectAllNotifications}
            onDeselectAllNotifications={handleClearSelectedNotifications}
          />
        )}
      </DSInboxContent>
    </DSInboxRoot>
  );
};

export default DSInbox;
