import React, { useEffect, useMemo, useState } from 'react';
import {
  Autocomplete,
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
} from '@mui/material';
import { useDealRoom } from '../../../Hooks/useDealRoom';
import { UserActivity } from '@meetingflow/common/Api/data-contracts';
import { humanizeDateTime } from '@meetingflow/common/DateHelpers';
import { InfoSection } from '../Components/InfoSection/InfoSection';
import { useQuery } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { useOrganization } from '../../../Hooks/useOrganization';
import { DealRoomsApiClient } from '../../../Services/NetworkCommon';
import { Stairs } from '@mui/icons-material';
import { Container } from './DSAnalyticsActivityTable.styles';
import { ANALYTICS_TYPES } from './DSAnalyticsTypes';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';
import { uuidv4 } from 'lib0/random';

type Order = 'asc' | 'desc';
type OrderBy = 'user' | 'time';

interface Contact {
  id: string;
  name: string;
  email: string;
}

function getComparator(order: Order, orderBy: OrderBy) {
  return order === 'desc'
    ? (a: UserActivity, b: UserActivity) => descendingComparator(a, b, orderBy)
    : (a: UserActivity, b: UserActivity) =>
        -descendingComparator(a, b, orderBy);
}

function descendingComparator(
  a: UserActivity,
  b: UserActivity,
  orderBy: OrderBy,
) {
  if (orderBy === 'time') {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  }

  const aName = a.user?.name || a.user?.email || 'Unknown User';
  const bName = b.user?.name || b.user?.email || 'Unknown User';
  return bName.localeCompare(aName);
}

export const DSAnalyticsActivityTable = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { slug: organizationSlug } = useOrganization();
  const {
    dealRoomId,
    dealRoom,
    refetch: refetchDealRoom,
    isLoading: isDealRoomLoading,
  } = useDealRoom();
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<OrderBy>('time');
  const [selectedContacts, setSelectedContacts] = useState<Contact[]>([]);

  // Get all artifacts so that we can render their details
  const {
    data: allArtifactsData,
    isLoading: isArtifactsLoading,
    refetch: refetchArtifacts,
  } = useQuery(
    ['artifacts-analytics', dealRoomId, organizationSlug],
    async () => {
      const token = await getAccessTokenSilently();
      const slug = organizationSlug!;
      const id = dealRoomId!;

      return DealRoomsApiClient.listArtifacts(
        {
          organizationSlug: slug,
          dealRoomId: id,
          includeDeleted: true,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      enabled: Boolean(organizationSlug) && Boolean(dealRoomId),
    },
  );

  useEffect(() => {
    refetchArtifacts();
  }, [refetchArtifacts]);

  useEffect(() => {
    refetchDealRoom();
  }, [refetchDealRoom]);

  // Transform deal room contacts into the format needed for the Autocomplete
  const contacts = useMemo(() => {
    if (!dealRoom?.contacts) return [];

    return dealRoom.contacts.map((contact) => ({
      id: contact.id.toString(),
      name: contact.name || '',
      email: contact.email || '',
    }));
  }, [dealRoom?.contacts]);

  // get the activity from the deal room
  const activities = useMemo(() => {
    return dealRoom?.activity;
  }, [dealRoom?.activity]);

  // filter and sort activities
  const sortedActivities = useMemo(() => {
    if (!activities) return [];
    let filtered = activities.filter(
      (activity) => activity.type in ANALYTICS_TYPES,
    );

    // Filter by selected contacts if any are selected
    if (selectedContacts.length > 0) {
      const selectedContactEmails = selectedContacts.map((contact) =>
        contact.email.toLowerCase(),
      );
      filtered = filtered.filter(
        (activity) =>
          activity.user?.email &&
          selectedContactEmails.includes(activity.user.email.toLowerCase()),
      );
    }

    return filtered.sort(getComparator(order, orderBy));
  }, [activities, order, orderBy, selectedContacts]);

  const handleRequestSort = (property: OrderBy) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // Combine loading states from both queries
  const isLoading = isArtifactsLoading || isDealRoomLoading;

  if (!sortedActivities) {
    return null;
  }

  return (
    <Container>
      <InfoSection
        title="Activity"
        icon={<Stairs fontSize="medium" color="secondary" />}
      >
        {isLoading ? (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              padding: '2rem',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Autocomplete
                sx={{ width: 300 }}
                multiple
                options={contacts}
                value={selectedContacts}
                onChange={(_, newValue) => setSelectedContacts(newValue)}
                getOptionLabel={(option) => option.name || option.email}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Filter by People"
                    size="small"
                  />
                )}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />
            </Box>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'time'}
                      direction={orderBy === 'time' ? order : 'asc'}
                      onClick={() => handleRequestSort('time')}
                    >
                      Time
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={orderBy === 'user'}
                      direction={orderBy === 'user' ? order : 'asc'}
                      onClick={() => handleRequestSort('user')}
                    >
                      User
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>Activity</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!isLoading &&
                  sortedActivities.map((activity) => {
                    const activityType =
                      ANALYTICS_TYPES[
                        activity.type as keyof typeof ANALYTICS_TYPES
                      ];

                    return (
                      <TableRow
                        key={`${activity.userId}-${activity.createdAt}-${activity.type}-${uuidv4()}`}
                      >
                        <TableCell
                          style={{ color: DEALROOMS_COLORS.themeSecondary }}
                        >
                          {humanizeDateTime(activity.createdAt, {
                            displayComponents: ['date', 'time'],
                            dateFormatOptions: {
                              day: 'numeric',
                              month: 'short',
                              year: 'numeric',
                            },
                            timeFormatOptions: {
                              hour: 'numeric',
                              minute: 'numeric',
                              timeZoneName: 'short',
                            },
                          })}
                        </TableCell>
                        <TableCell>
                          {activity.user?.name ||
                            activity.user?.email ||
                            'Unknown User'}
                        </TableCell>
                        <TableCell>
                          {dealRoom?.id &&
                            activityType?.renderer &&
                            activityType.renderer({
                              activity,
                              organizationSlug,
                              dealRoomId: dealRoom.id.toString(),
                              allArtifacts: allArtifactsData?.data || [],
                            })}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </Box>
        )}
      </InfoSection>
    </Container>
  );
};
