/**
 * Notification List Component
 *
 * Displays a list of notifications with both single-selection (for detail view) and
 * multi-selection (for bulk operations) capabilities.
 *
 * Features:
 * - Checkbox-based multi-select for bulk operations
 * - Action toolbar appears when notifications are selected
 * - Bulk actions:
 *   - Archive/Recover (mutually exclusive based on context)
 *   - Mark as Read
 *   - Clear Selection
 * - Visual indication of active notification (for detail view)
 * - Responsive width based on detail view state
 *
 * @property {DealRoomNotification[]} notifications - Array of notifications to display
 * @property {DealRoomNotification} activeNotification - Currently active notification shown in detail view
 * @property {Set<string>} selectedNotifications - Set of notification IDs selected for bulk operations
 * @property {Function} onNotificationClick - Callback when a notification is clicked to view details
 * @property {Function} onNotificationSelect - Callback when a notification checkbox is toggled
 * @property {Function} onClearSelectedNotifications - Callback to clear all selected notifications
 * @property {Function} onDeleteSelectedNotifications - Callback to archive selected notifications (mutually exclusive with onUndeleteSelectedNotifications)
 * @property {Function} onUndeleteSelectedNotifications - Callback to recover archived notifications (mutually exclusive with onDeleteSelectedNotifications)
 * @property {Function} onMarkSelectedNotificationsAsRead - Callback to mark selected notifications as read
 * @property {Function} onSelectAllNotifications - Optional callback to select all notifications
 * @property {Function} onDeselectAllNotifications - Optional callback to deselect all notifications
 */
import {
  DSInboxNotificationsList,
  DSInboxNotificationListItem,
  DSInboxNotificationsListToolbar,
  DSInboxNotificationCheckbox,
  DSInboxNotificationsListWrapper,
  DSInboxEmptyStateWrapper,
  DSInboxEmptyStateTitle,
  DSInboxEmptyStateSubtitle,
  DSInboxEmptyStateIcon,
} from './styles';
import { DSInboxNotificationPreview } from './NotificationPreview';
import {
  IconButton,
  Slide,
  Stack,
  FormControlLabel,
  Box,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import {
  DoneAll,
  Clear,
  Archive,
  Restore,
  Markunread,
  NotificationsOff,
} from '@mui/icons-material';
import { DSButton } from '../../DS';
import { DealRoomNotification } from '@meetingflow/common/Api/data-contracts';

/**
 * Props interface for the DSInboxNotificationList component
 * @property {DealRoomNotification[]} notifications - Array of notifications to display in the list
 * @property {DealRoomNotification} activeNotification - Optional currently selected notification
 * @property {Set<string>} selectedNotifications - Set of notification IDs that are currently selected
 * @property {Function} onNotificationClick - Optional callback function triggered when a notification is clicked
 * @property {Function} onNotificationSelect - Optional callback function triggered when a notification checkbox is toggled
 * @property {Function} onClearSelectedNotifications - Optional callback to clear all selected notifications
 * @property {Function} onDeleteSelectedNotifications - Optional callback to delete selected notifications
 * @property {Function} onUndeleteSelectedNotifications - Optional callback to undelete selected notifications
 * @property {Function} onMarkSelectedNotificationsAsRead - Optional callback to mark selected notifications as read
 * @property {Function} onMarkSelectedNotificationsAsUnread - Optional callback to mark selected notifications as unread
 * @property {Function} onSelectAllNotifications - Optional callback to select all notifications
 * @property {Function} onDeselectAllNotifications - Optional callback to deselect all notifications
 * @property {string} noNotificationsMessageTitle - Optional title to display when there are no notifications
 * @property {string} noNotificationsMessageSubtitle - Optional subtitle to display when there are no notifications
 */
interface DSInboxNotificationListProps {
  notifications: DealRoomNotification[];
  activeNotification?: DealRoomNotification;
  selectedNotifications: Set<string>;
  noNotificationsMessageTitle?: string;
  noNotificationsMessageSubtitle?: string;
  onNotificationClick?: (notification: DealRoomNotification) => void;
  onNotificationSelect?: (notification: DealRoomNotification) => void;
  onClearSelectedNotifications?: () => void;
  onDeleteSelectedNotifications?: () => void;
  onUndeleteSelectedNotifications?: () => void;
  onMarkSelectedNotificationsAsRead?: () => void;
  onMarkSelectedNotificationsAsUnread?: () => void;
  onSelectAllNotifications?: () => void;
  onDeselectAllNotifications?: () => void;
}

/**
 * Renders a list of notification previews with selection and click handling capabilities.
 * Each notification is rendered as a DSInboxNotificationPreview component with its own
 * selection state and click handler.
 *
 * @param {DSInboxNotificationListProps} props - Component props
 * @returns {JSX.Element} A styled list container with notification preview items
 */
export const DSInboxNotificationList = ({
  notifications,
  activeNotification,
  selectedNotifications,
  noNotificationsMessageTitle = 'No new notifications',
  noNotificationsMessageSubtitle = 'When you receive notifications, they will appear here',
  onNotificationClick,
  onNotificationSelect,
  onClearSelectedNotifications,
  onDeleteSelectedNotifications,
  onUndeleteSelectedNotifications,
  onMarkSelectedNotificationsAsRead,
  onMarkSelectedNotificationsAsUnread,
  onSelectAllNotifications,
  onDeselectAllNotifications,
}: DSInboxNotificationListProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <DSInboxNotificationsList hasActiveNotification={!!activeNotification}>
      <Slide
        direction="down"
        in={selectedNotifications.size > 0}
        mountOnEnter
        unmountOnExit
        timeout={400}
      >
        <div>
          <DSInboxNotificationsListToolbar>
            <FormControlLabel
              control={
                <DSInboxNotificationCheckbox
                  size="small"
                  checked={selectedNotifications.size === notifications.length}
                  indeterminate={
                    selectedNotifications.size > 0 &&
                    selectedNotifications.size < notifications.length
                  }
                  onChange={(e) => {
                    e.stopPropagation();
                    if (selectedNotifications.size === notifications.length) {
                      onDeselectAllNotifications?.();
                    } else {
                      onSelectAllNotifications?.();
                    }
                  }}
                />
              }
              label={isMobile ? '' : 'Select All'}
              sx={{
                '& .MuiFormControlLabel-label': {
                  fontSize: '0.875rem',
                  color: (theme) => theme.palette.text.primary,
                },
              }}
            />
            <DSButton
              variant="text"
              size="small"
              startIcon={<Clear />}
              onClick={(e) => {
                e.stopPropagation();
                onClearSelectedNotifications?.();
              }}
              iconOnly={isMobile}
            >
              Clear
            </DSButton>
            <Stack direction="row" spacing={1} sx={{ marginLeft: 'auto' }}>
              {onMarkSelectedNotificationsAsRead && (
                <DSButton
                  color="primary"
                  variant="contained"
                  size="small"
                  startIcon={<DoneAll />}
                  onClick={(e) => {
                    e.stopPropagation();
                    onMarkSelectedNotificationsAsRead?.();
                  }}
                  iconOnly={isMobile}
                >
                  Mark Read
                </DSButton>
              )}
              {onMarkSelectedNotificationsAsUnread && (
                <DSButton
                  color="primary"
                  variant="contained"
                  size="small"
                  startIcon={<Markunread />}
                  onClick={(e) => {
                    e.stopPropagation();
                    onMarkSelectedNotificationsAsUnread?.();
                  }}
                  iconOnly={isMobile}
                >
                  Mark Unread
                </DSButton>
              )}
              <DSButton
                color="secondary"
                variant="outlined"
                size="small"
                startIcon={
                  onUndeleteSelectedNotifications ? <Restore /> : <Archive />
                }
                onClick={(e) => {
                  e.stopPropagation();
                  if (onUndeleteSelectedNotifications) {
                    onUndeleteSelectedNotifications();
                  } else {
                    onDeleteSelectedNotifications?.();
                  }
                }}
                iconOnly={isMobile}
              >
                {onUndeleteSelectedNotifications ? 'Recover' : 'Archive'}
              </DSButton>
            </Stack>
          </DSInboxNotificationsListToolbar>
        </div>
      </Slide>
      {/* Map through notifications array to render individual preview components */}
      <DSInboxNotificationsListWrapper>
        {notifications.length === 0 ? (
          <DSInboxEmptyStateWrapper>
            <DSInboxEmptyStateIcon />
            <DSInboxEmptyStateTitle>
              {noNotificationsMessageTitle}
            </DSInboxEmptyStateTitle>
            <DSInboxEmptyStateSubtitle>
              {noNotificationsMessageSubtitle}
            </DSInboxEmptyStateSubtitle>
          </DSInboxEmptyStateWrapper>
        ) : (
          notifications.map((notification) => (
            <DSInboxNotificationListItem
              key={notification.id}
              active={notification.id === activeNotification?.id}
            >
              <DSInboxNotificationCheckbox
                size="small"
                checked={selectedNotifications.has(notification.id)}
                onChange={(e) => {
                  e.stopPropagation(); // Prevent triggering the notification click
                  onNotificationSelect?.(notification);
                }}
              />
              <DSInboxNotificationPreview
                key={notification.id}
                notification={notification}
                selected={notification.id === activeNotification?.id} // Determine if this notification is currently selected
                onClick={() => onNotificationClick?.(notification)} // Optional chaining ensures safe callback execution
              />
            </DSInboxNotificationListItem>
          ))
        )}
      </DSInboxNotificationsListWrapper>
    </DSInboxNotificationsList>
  );
};
