/**
 * SuggestedTasksHeader is a reusable component that provides
 * a consistent header layout for the suggested tasks panel. It includes a title
 * and a close button.
 */

import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  HeaderContainer,
  ContentWrapper,
  StyledIconButton,
  Title,
  Subtitle,
} from './SuggestedTasksHeader.styles';

/**
 * Props interface for SuggestedTasksHeader
 * @property onClose - Callback function to handle panel closure
 */
type SuggestedTasksHeaderProps = {
  onClose: () => void;
};

/**
 * Header component that provides a consistent layout for the suggested tasks panel
 * Includes a title and a close button
 */
export const SuggestedTasksHeader = ({
  onClose,
}: SuggestedTasksHeaderProps) => {
  return (
    <HeaderContainer>
      <ContentWrapper>
        <Title>AI Suggested Tasks</Title>
        <Subtitle>From your meetings</Subtitle>
      </ContentWrapper>
      <IconButton component={StyledIconButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </HeaderContainer>
  );
};

export default SuggestedTasksHeader;
