import { DealRoomAudioArtifact } from '@meetingflow/common/Api/data-contracts';
import { ArtifactIconPreview } from './ArtifactIconPreview';
import { BaseArtifactCard, BaseArtifactCardProps } from './BaseArtifactCard';
import { ImageWithFallback } from '../../Common/ImageWithFallback';

export type AudioArtifactCardProps = BaseArtifactCardProps &
  Pick<DealRoomAudioArtifact, 'fileName' | 'thumbnailFileName' | 'thumbnailUrl' | 'mimeType'>;

export const AudioArtifactCard = ({
  fileName,
  orgSlug,
  dealRoomId,
  thumbnailFileName,
  thumbnailUrl,
  mimeType,
  ...rest
}: AudioArtifactCardProps) => {
  return (
    <BaseArtifactCard
      className="audio-artifact"
      orgSlug={orgSlug}
      dealRoomId={dealRoomId}
      mimeType={mimeType}
      {...rest}
    >
      <ImageWithFallback
        src={thumbnailUrl || undefined}
        fallback={<ArtifactIconPreview
          type="AUDIO"
          fileExtension={fileName.split('.').pop()}
          mimeType={mimeType}
        />}
      />
      
    </BaseArtifactCard>
  );
};
