import { useAuth0 } from '@auth0/auth0-react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  DealRoomArtifact,
  DetailedDealRoom,
} from '@meetingflow/common/Api/data-contracts';
import { DeduplicateArray } from '@meetingflow/common/ArrayHelpers';
import classNames from 'classnames';
import { useCallback, useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { isBadRequest, isForbiddenError } from '../../../Helpers/AxiosHelpers';
import { useNavigate } from '../../../Hooks/useNavigate';
import { useTitle } from '../../../Hooks/useTitle';
import { OrganizationDealRoomArtifactsQuery } from '../../../QueryNames';
import { DealRoomsApiClient } from '../../../Services/NetworkCommon';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';
import { AudioArtifactCard } from '../Artifacts/AudioArtifactCard';
import { DocumentArtifactCard } from '../Artifacts/DocumentArtifactCard';
import { FileArtifactCard } from '../Artifacts/FileArtifactCard';
import { ImageArtifactCard } from '../Artifacts/ImageArtifactCard';
import { LinkArtifactCard } from '../Artifacts/LinkArtifactCard';
import { MeetingflowArtifactCard } from '../Artifacts/MeetingflowArtifactCard';
import { VideoArtifactCard } from '../Artifacts/VideoArtifactCard';
import useDeviceType from '../../../Hooks/deviceDetection';
import { getArtifactLink } from '../utils/artifactUtils';

type DealRoomArtifactsProps = {
  className?: string;
  artifactContainerClassName?: string;
  organizationSlug: string;
  dealRoomId: number;
  activity?: DetailedDealRoom['activity'];
  artifacts?: DetailedDealRoom['artifacts'];
  refreshDealRoom: () => Promise<unknown>;
  onClick?: (id: DealRoomArtifact['id']) => unknown;
  onDelete?: (id: DealRoomArtifact['id']) => Promise<unknown>;
};

export const DSFeaturedArtifacts = ({
  className,
  artifactContainerClassName,
  organizationSlug,
  dealRoomId,
  activity,
  artifacts,
  onClick,
  onDelete,
}: DealRoomArtifactsProps) => {
  const { isMobile } = useDeviceType();

  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  useTitle('Artifacts');

  const {
    data: dealRoomArtifacts,
    isLoading: artifactsLoading,
    refetch: refetchArtifacts,
  } = useQuery(
    OrganizationDealRoomArtifactsQuery(
      organizationSlug,
      dealRoomId,
      true, // featured
      false, // includeDeleted
    ),
    async () => {
      const token = await getAccessTokenSilently();
      return DealRoomsApiClient.listArtifacts(
        { organizationSlug, dealRoomId, featured: true, includeDeleted: false },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      enabled: !artifacts && !!organizationSlug && !!dealRoomId,
      retry: (failureCount, error) => {
        if (isForbiddenError(error)) {
          return false;
        }
        if (isBadRequest(error)) {
          return false;
        }

        return failureCount < 3;
      },
    },
  );

  useEffect(() => {
    refetchArtifacts();
  }, [refetchArtifacts]);

  const drArtifacts = useMemo(() => {
    if (artifacts) {
      return artifacts
        .filter((a) => a.featuredAt)
        .sort((a, b) => {
          if (a.placement === 'WELCOME' && b.placement !== 'WELCOME') return -1;
          if (b.placement === 'WELCOME' && a.placement !== 'WELCOME') return 1;
          return (
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          );
        });
    }

    return dealRoomArtifacts?.data || [];
  }, [artifacts, dealRoomArtifacts?.data]);

  const refetch = useCallback(async () => {
    await Promise.all([refetchArtifacts()]);
  }, [refetchArtifacts]);

  const handleOnClick = useCallback(
    (id: DealRoomArtifact['id']) => {
      if (onClick) {
        onClick(id);
      }

      const artifact = drArtifacts.find((a) => a.id === id);

      if (!artifact) {
        return;
      }

      navigate(getArtifactLink(organizationSlug, dealRoomId, artifact), {
        preserveQuery: true,
      });
    },
    [dealRoomId, drArtifacts, navigate, onClick, organizationSlug],
  );

  const handleOnDelete = useCallback(
    async (id: DealRoomArtifact['id']) => {
      await refetch();
      await onDelete?.(id);
    },
    [onDelete, refetch],
  );

  const Container = styled(Box)(({ theme }) => ({
    height: '100%',
    backgroundColor: DEALROOMS_COLORS.neutralGray,
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    gap: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    border: 'none',
    width: isMobile ? '100%' : 'auto',
  }));

  const NoArtifacts = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    minHeight: '10rem',
  });

  const ArtifactScroll = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    padding: theme.spacing(2),
    overflowX: 'auto',
    overflowY: 'hidden',
    '& > *': {
      flex: '0 0 300px', // Fixed width for each card
      maxWidth: '300px',
    },
    // Hide scrollbar in Firefox
    scrollbarWidth: 'none',
    // Hide scrollbar in Chrome/Safari
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    // Ensure smooth scrolling
    scrollBehavior: 'smooth',
  }));

  return (
    <Container id="dealroom-artifacts" className={className}>
      {drArtifacts?.length ? (
        <ArtifactScroll className={artifactContainerClassName}>
          {drArtifacts.map((a) => {
            const viewCount =
              DeduplicateArray(
                activity
                  ?.filter(
                    (activity) =>
                      activity.type === 'VIEW_ARTIFACT' &&
                      activity.additionalDetails.artifactId === a.id,
                  )
                  .map((a) => a.userId) ?? [],
              ).length ?? 0;
            switch (a.type) {
              case 'MEETINGFLOW':
                return (
                  <MeetingflowArtifactCard
                    key={a.id}
                    {...a}
                    viewCount={viewCount}
                    orgSlug={organizationSlug}
                    dealRoomId={dealRoomId}
                    refreshDealRoom={refetch}
                    onClick={handleOnClick}
                    onDelete={handleOnDelete}
                  />
                );

              case 'AUDIO':
                return (
                  <AudioArtifactCard
                    key={a.id}
                    {...a}
                    viewCount={viewCount}
                    orgSlug={organizationSlug}
                    dealRoomId={dealRoomId}
                    refreshDealRoom={refetch}
                    onClick={handleOnClick}
                    onDelete={handleOnDelete}
                  />
                );

              case 'DOCUMENT':
                return (
                  <DocumentArtifactCard
                    key={a.id}
                    {...a}
                    viewCount={viewCount}
                    orgSlug={organizationSlug}
                    dealRoomId={dealRoomId}
                    refreshDealRoom={refetch}
                    onClick={handleOnClick}
                    onDelete={handleOnDelete}
                  />
                );

              case 'FILE':
                return (
                  <FileArtifactCard
                    key={a.id}
                    {...a}
                    viewCount={viewCount}
                    orgSlug={organizationSlug}
                    dealRoomId={dealRoomId}
                    refreshDealRoom={refetch}
                    onClick={handleOnClick}
                    onDelete={handleOnDelete}
                  />
                );

              case 'IMAGE':
                return (
                  <ImageArtifactCard
                    key={a.id}
                    {...a}
                    orgSlug={organizationSlug}
                    dealRoomId={dealRoomId}
                    refreshDealRoom={refetch}
                    onClick={handleOnClick}
                    onDelete={handleOnDelete}
                  />
                );

              case 'LINK':
                return (
                  <LinkArtifactCard
                    key={a.id}
                    {...a}
                    viewCount={viewCount}
                    orgSlug={organizationSlug}
                    dealRoomId={dealRoomId}
                    refreshDealRoom={refetch}
                    onClick={handleOnClick}
                    onDelete={handleOnDelete}
                  />
                );

              case 'VIDEO':
                return (
                  <VideoArtifactCard
                    key={a.id}
                    {...a}
                    viewCount={viewCount}
                    orgSlug={organizationSlug}
                    dealRoomId={dealRoomId}
                    refreshDealRoom={refetch}
                    onClick={handleOnClick}
                    onDelete={handleOnDelete}
                  />
                );
            }
          })}
        </ArtifactScroll>
      ) : (
        <NoArtifacts>
          {!artifactsLoading ? (
            <Typography>No Featured Artifacts</Typography>
          ) : (
            <CircularProgress />
          )}
        </NoArtifacts>
      )}
    </Container>
  );
};
