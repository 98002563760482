/**
 * NotificationDetail.tsx
 * A React component that displays detailed information about a single notification in the Decision Site inbox.
 * It shows the triggering user's avatar, notification message, and relative timestamp.
 */

import { formatDistanceToNow } from 'date-fns';
import { DSContactAvatar } from '../../DS';
import {
  DSInboxNotificationDetailContainer,
  DSInboxNotificationDetailHeader,
  DSInboxNotificationDetailContent,
  DSInboxNotificationDetailTitle,
  DSInboxNotificationDetailTimestamp,
} from './styles';
import { getNotificationTitle } from './utils';
import { DealRoomNotification } from '@meetingflow/common/Api/data-contracts';

/**
 * Props interface for the DSInboxNotificationDetailView component
 * @property {DealRoomNotification} notification - Optional notification object containing details about the notification
 */
interface DSInboxNotificationDetailViewProps {
  notification?: DealRoomNotification;
}

/**
 * Renders a detailed view of a single notification, including the triggering user's avatar,
 * a formatted notification message, and a relative timestamp.
 *
 * @param {DSInboxNotificationDetailViewProps} props - Component props
 * @returns {JSX.Element | null} Returns null if no notification is provided, otherwise returns the notification detail view
 */
export const DSInboxNotificationDetailView = ({
  notification,
}: DSInboxNotificationDetailViewProps) => {
  // Early return if no notification is provided
  if (!notification) return null;

  return (
    <DSInboxNotificationDetailContainer>
      <DSInboxNotificationDetailHeader>
        {/* Render avatar only if we have complete user information */}
        {notification?.triggeredBy &&
        notification.triggeredBy.id &&
        notification.triggeredBy.email ? (
          <DSContactAvatar
            contact={{
              id: notification.triggeredBy.id,
              name: notification.triggeredBy.name,
              email: notification.triggeredBy.email,
              avatarUrl: notification.triggeredBy.avatarUrl || undefined,
            }}
            size={40}
          />
        ) : null}
        <DSInboxNotificationDetailContent>
          {/* Display the formatted notification title */}
          <DSInboxNotificationDetailTitle>
            {getNotificationTitle(notification)}
          </DSInboxNotificationDetailTitle>
          {/* Show relative timestamp (e.g., "2 hours ago") */}
          <DSInboxNotificationDetailTimestamp>
            {formatDistanceToNow(new Date(notification.triggeredAt))} ago
          </DSInboxNotificationDetailTimestamp>
        </DSInboxNotificationDetailContent>
      </DSInboxNotificationDetailHeader>
    </DSInboxNotificationDetailContainer>
  );
};
