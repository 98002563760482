import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { DEALROOMS_COLORS } from '../../../../../Themes/Themes';

interface InviteContainerProps {
  isMobile: boolean;
}

export const InviteContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isMobile',
})<InviteContainerProps>(({ isMobile }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: isMobile ? '0 0 1rem' : '0',
  flex: '1',
}));

export const InviteContent = styled(Box)({
  flex: '1',
  display: 'flex',
  flexDirection: 'column',
  gap: '1.5rem',
});

export const InviteHeaderContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

export const InviteLabel = styled(Box)({
  fontSize: '1rem',
  paddingBottom: '0.5rem',
});

export const InviteSubtitle = styled(Box)({
  fontSize: '0.85rem',
  color: DEALROOMS_COLORS.themeSecondary,
});
