import { DateTime } from 'luxon';
import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import {
  DealRoomActionItem,
  DetailedMeetingflow,
  Task,
} from '@meetingflow/common/Api/data-contracts';
import { MeetingPlanTasksQuery } from '../../../../../QueryNames';
import { tryMatchAttendeesToParticipants } from '@meetingflow/common/TranscriptHelpers';
import {
  CallRecordingsApiClient,
  TasksApiClient,
} from '../../../../../Services/NetworkCommon';
import { useDealRoom } from '../../../../../Hooks/useDealRoom';
import { useOrganization } from '../../../../../Hooks/useOrganization';

/**
 * Organizations that are allowed to use AI-suggested action items
 */
const ALLOWED_ORG_SLUGS = ['augment-ai', 'augment-demo'];

/**
 * Represents an action item that can come from either user input or AI suggestions.
 * Contains the action item details, its associated meetingflow, and optionally the speaker
 * who mentioned it (for AI-suggested items).
 */
type MeetingFlowActionItem = {
  actionItem: Partial<DealRoomActionItem>;
  meetingflowId: string;
  speaker?: string;
};

/**
 * Hook to manage action items associated with a meetingflow, including both user-added
 * and AI-suggested items. This hook is designed to work even when meetingflow is undefined
 * to maintain React hook call order consistency.
 *
 * @param meetingflow - The meetingflow to get action items for. Can be undefined during initial render
 * @param refetchMeetingflow - Function to refresh the meetingflow data
 *
 * @returns Object containing:
 * - suggestedActionItems: Action items suggested by AI from the transcript
 * - userAddedActionItems: Action items manually added by users
 * - isLoading: Loading state for tasks
 * - Various task counts and utility functions
 */
export const useDecisionSiteMeetingflowActionItems = (
  meetingflow: DetailedMeetingflow | undefined,
  refetchMeetingflow: () => Promise<unknown>,
) => {
  // Auth and context hooks
  const { getAccessTokenSilently } = useAuth0();
  const { dealRoom, dealRoomId } = useDealRoom();
  const { slug: organizationSlug, internalDomains } = useOrganization();

  // Check if the organization is allowed to use AI features
  const isAllowedOrg =
    organizationSlug && ALLOWED_ORG_SLUGS.includes(organizationSlug);

  /**
   * Fetch tasks associated with this meetingflow.
   * Note: We handle undefined meetingflow by returning empty data,
   * which maintains React hook call order while preventing API calls
   * with invalid IDs.
   */
  const {
    data: tasksData,
    isLoading: tasksLoading,
    refetch: refetchTasks,
  } = useQuery(
    MeetingPlanTasksQuery(organizationSlug!, meetingflow?.id ?? ''),
    async () => {
      if (!meetingflow?.id) {
        return {
          data: {
            items: [],
            next: null,
            previous: null,
            total: 0,
          },
        };
      }
      const token = await getAccessTokenSilently();
      return TasksApiClient.listTasks(
        {
          organizationSlug: organizationSlug!,
          meetingPlanId: meetingflow?.id ?? '',
          sortBy: 'createdAt',
          sortOrder: 'asc',
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      refetchOnReconnect: true,
    },
  );

  /**
   * Extract and transform AI-suggested action items from the meeting transcript.
   * Handles filtering based on internal participants and transforms the items
   * into a consistent format.
   */
  const extractSuggestedActionItemsFromMeetingFlow = useCallback(() => {
    // Early return if no transcript action items exist
    if (!meetingflow?.callRecording?.transcriptActionItems) {
      return [];
    }

    if (!meetingflow.callRecording || !meetingflow.attendees) return [];

    const suggestedActionItems =
      meetingflow.callRecording.transcriptActionItems;

    let recordingActionItems = [...suggestedActionItems];

    const participantNames =
      meetingflow.callRecording.participants?.map((p) => p.name) ?? [];

    const matches = tryMatchAttendeesToParticipants(
      participantNames,
      meetingflow.attendees,
    );

    const internalParticipants = participantNames.filter((p) => {
      const matchedAttendee = meetingflow.attendees?.find(
        (a) => a.id === matches[p]?.id,
      );
      const emailDomain = matchedAttendee?.emailDomain || '';
      return internalDomains.includes(emailDomain);
    });

    // Only filter by internal participants if we have any
    if (internalParticipants.length > 0) {
      recordingActionItems = recordingActionItems.filter((item) => {
        const isInternalAssignee = item.assignee
          ? internalParticipants.some((p) =>
              item.assignee!.toLowerCase().includes(p.toLowerCase()),
            )
          : false;

        const isInternalSpeaker = internalParticipants.some((p) =>
          item.speaker.toLowerCase().includes(p.toLowerCase()),
        );
        return isInternalAssignee || isInternalSpeaker;
      });
    }

    const transformedActionItems = recordingActionItems.map(
      (t): MeetingFlowActionItem => ({
        actionItem: {
          actionItem: t.text,
          dueDate: t.deadline || DateTime.now().plus({ days: 7 }).toISO(),
          status: 'NOT_STARTED',
          assignee: null,
          id: undefined,
        },
        meetingflowId: meetingflow.id,
        speaker: t.speaker,
      }),
    );
    return transformedActionItems;
  }, [meetingflow, internalDomains]);

  const extractUserAddedActionItemsFromMeetingFlow = useCallback(() => {
    const userAddedActionItems = Array.isArray(tasksData?.data)
      ? tasksData.data
      : [];
    const transformedActionItems = userAddedActionItems.map(
      (t: Task): MeetingFlowActionItem => ({
        actionItem: {
          actionItem: t.text,
          dueDate: DateTime.now().plus({ days: 7 }).toISO(), // Default due date for all tasks
          status: 'NOT_STARTED',
          assignee: t.assignee
            ? {
                ...t.assignee,
                timezone: 'UTC', // Default timezone since BasicContact doesn't have it
              }
            : null,
          id: -1, // Temporary ID for new items
        },
        meetingflowId: meetingflow?.id || '',
      }),
    );
    return transformedActionItems;
  }, [tasksData, meetingflow]);

  // Extract both AI-suggested and user-added action items
  const suggestedActionItems = extractSuggestedActionItemsFromMeetingFlow();
  const userAddedActionItems = extractUserAddedActionItemsFromMeetingFlow();

  /**
   * Find the artifact ID for this meetingflow in the deal room.
   * This is used to track relationships between tasks and meetingflows.
   */
  const meetingflowArtifactId = useMemo(() => {
    return (
      dealRoom?.artifacts.find(
        (artifact) =>
          artifact.type === 'MEETINGFLOW' &&
          artifact.meetingflowId === meetingflow?.id,
      )?.id ?? null
    );
  }, [meetingflow?.id, dealRoom?.artifacts]);

  // Filter mutual plan action items to only those related to this meetingflow
  const mppActionItemsForMeetingflow = dealRoom?.mutualActionItems?.filter(
    (item) =>
      item.artifacts?.some(
        (artifact) =>
          artifact.type === 'MEETINGFLOW' &&
          artifact.id === meetingflowArtifactId,
      ),
  );

  // Calculate various task counts for UI display
  const mppTaskCount = mppActionItemsForMeetingflow?.length ?? 0;
  const userAddedTaskCount = userAddedActionItems?.length ?? 0;
  const aiSuggestedTaskCount = suggestedActionItems?.length ?? 0;
  const totalTaskCount =
    mppTaskCount + userAddedTaskCount + aiSuggestedTaskCount;

  /**
   * Delete an AI-suggested action item from the transcript.
   * This removes it from consideration but doesn't affect any tasks
   * that were already created from it.
   */
  const deleteSuggestedActionItem = useCallback(
    async (index: number) => {
      if (!organizationSlug || !meetingflow?.id) return;

      try {
        const token = await getAccessTokenSilently();
        await CallRecordingsApiClient.deleteSuggestedActionItem(
          {
            organizationSlug,
            meetingPlanId: meetingflow.id,
            index,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
        // Wait for both refetches to complete
        await Promise.all([refetchMeetingflow(), refetchTasks()]);
      } catch (error) {
        console.error('Error deleting suggested action item:', error);
      }
    },
    [
      getAccessTokenSilently,
      meetingflow?.id,
      organizationSlug,
      refetchMeetingflow,
      refetchTasks,
    ],
  );

  return {
    userAddedActionItems,
    suggestedActionItems,
    isLoading: false,
    mutualPlanActionItems: mppActionItemsForMeetingflow,
    mutualPlanTaskCount: mppTaskCount,
    userAddedTaskCount,
    aiSuggestedTaskCount,
    totalTaskCount,
    refetchTasks,
    deleteSuggestedActionItem,
  };
};
