import React, { useRef } from 'react';
import { AvatarProps } from '@mui/material/Avatar';
import { useUserProfile } from '../../../Hooks/useProfile';
import { useOrganization } from '../../../Hooks/useOrganization';
import { useDealRoom } from '../../../Hooks/useDealRoom';
import { useNavigate } from 'react-router';
import { useAuth0 } from '@auth0/auth0-react';
import { StyledAvatar } from './DSUserAvatar.styles';
import { stringToColor } from '@meetingflow/common/StringHelpers';

// Creates MUI avatar props (background color, initials) based on a string name
export const avatarPropsFromString = (
  name: string,
): { sx: { bgcolor: string }; children: string } => {
  const splitName = name.split(' ');
  const firstWord = splitName[0];
  const secondWord = splitName.length > 1 ? splitName[1] : undefined;
  const firstInitial = (firstWord ? firstWord[0] : '') || '';
  const secondInitial = (secondWord ? secondWord[0] : '') || '';

  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${firstInitial}${secondInitial}`,
  };
};

interface DSUserAvatarProps extends AvatarProps {}

const DSUserAvatar: React.FC<DSUserAvatarProps> = ({ ...props }) => {
  const userRef = useRef(null);
  const { user: profileData } = useUserProfile();

  const userName = profileData?.name || 'User';

  const avatarUrl =
    profileData?.avatarFileUrl || profileData?.avatarUrl || undefined;

  return (
    <StyledAvatar
      {...avatarPropsFromString(userName)}
      src={avatarUrl}
      ref={userRef}
      {...props}
    />
  );
};

export default DSUserAvatar;
