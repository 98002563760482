import { Box, Button, Typography, styled } from '@mui/material';
import { DEALROOMS_COLORS } from '../../../../../Themes/Themes';

interface ContentContainerProps {
  isMobile: boolean;
}

export const ContentContainer = styled(Box)<ContentContainerProps>(
  ({ isMobile }) => ({
    padding: isMobile ? '0 0 1.5rem 0' : '1.5rem',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  }),
);

export const PageTitle = styled(Typography)(() => ({
  padding: 0,
  margin: 0,
}));

export const DangerBox = styled(Box)(() => ({
  backgroundColor: '#fff8f8',
  border: `1px solid ${DEALROOMS_COLORS.crimson}`,
  borderRadius: '.25rem',
  padding: '1rem',
}));

export const DangerHeader = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: 8,
}));

export const DangerIcon = styled(Box)(() => ({
  color: DEALROOMS_COLORS.crimson,
}));

export const DangerTitle = styled(Typography)(() => ({
  color: DEALROOMS_COLORS.crimson,
  fontSize: '1rem',
}));

export const DangerText = styled(Typography)(() => ({
  color: DEALROOMS_COLORS.neutralSecondary,
  fontSize: '14px',
  lineHeight: '1.25rem',
}));

export const Section = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const SectionTitle = styled(Typography)(() => ({
  marginBottom: '.5rem',
}));

export const HelperText = styled(Typography)(() => ({
  color: DEALROOMS_COLORS.cloudburst,
  fontSize: '.875rem',
  marginBottom: '1rem',
}));

export const ContactAutocomplete = styled(Box)(() => ({
  marginBottom: 8,
}));

export const TransferButton = styled(Button)(() => ({
  backgroundColor: DEALROOMS_COLORS.crimson,
  color: 'white',
  '&:hover': {
    backgroundColor: DEALROOMS_COLORS.crimson,
  },
  '&:disabled': {
    backgroundColor: DEALROOMS_COLORS.neutralLight,
  },
}));
