import { useTheme, useMediaQuery } from '@mui/material';
import { formatDistanceToNow } from 'date-fns';
import { DSContactAvatar } from '../../DS';
import {
  DSInboxNotificationPreviewWrapper,
  DSInboxNotificationPreviewContainer,
  DSInboxNotificationContent,
  DSInboxNotificationTitle,
  DSInboxNotificationTimestamp,
  DSInboxNotificationStatusDot,
} from './styles';
import { getNotificationTitle, isNotificationUnread } from './utils';
import { DealRoomNotification } from '@meetingflow/common/Api/data-contracts';
import { useMemo } from 'react';

/**
 * Notification Preview Component
 *
 * Renders a preview of a single notification in the list view.
 * Can be in one of two states:
 * - Active: Currently being viewed in the detail panel (highlighted)
 * - Inactive: Just showing in the list
 *
 * Note: Selection state (checkbox) is handled by the parent component
 * and rendered separately from this preview.
 *
 * @property {DealRoomNotification} notification - The notification to display
 * @property {boolean} selected - Whether this notification is currently active in the detail view
 * @property {Function} onClick - Callback when the preview is clicked
 */
interface DSInboxNotificationPreviewProps {
  notification: DealRoomNotification;
  selected?: boolean;
  onClick?: () => void;
}

export const DSInboxNotificationPreview = ({
  notification,
  selected,
  onClick,
}: DSInboxNotificationPreviewProps) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const currentAvatarUrl = useMemo(() => {
    if (
      notification &&
      notification.triggeredBy &&
      'avatarFileUrl' in notification.triggeredBy &&
      notification.triggeredBy.avatarFileUrl
    ) {
      return notification.triggeredBy.avatarFileUrl as string;
    }

    return notification?.triggeredBy?.avatarUrl || undefined;
  }, [notification]);

  return (
    <DSInboxNotificationPreviewWrapper selected={selected} onClick={onClick}>
      <DSInboxNotificationPreviewContainer>
        {!isSmallScreen &&
          notification?.triggeredBy &&
          notification.triggeredBy.id &&
          notification.triggeredBy.email && (
            <DSContactAvatar
              contact={{
                id: notification.triggeredBy.id,
                name: notification.triggeredBy.name,
                email: notification.triggeredBy.email,
                avatarUrl: currentAvatarUrl,
              }}
              size={32}
              sx={{
                opacity: isNotificationUnread(notification.status) ? 1 : 0.5,
              }}
            />
          )}
        <DSInboxNotificationContent
          isNew={isNotificationUnread(notification.status)}
        >
          <DSInboxNotificationTitle
            isNew={isNotificationUnread(notification.status)}
          >
            <DSInboxNotificationStatusDot
              isNew={isNotificationUnread(notification.status)}
            />
            {getNotificationTitle(notification)}
          </DSInboxNotificationTitle>
          <DSInboxNotificationTimestamp
            isNew={isNotificationUnread(notification.status)}
          >
            {formatDistanceToNow(new Date(notification.triggeredAt))} ago
          </DSInboxNotificationTimestamp>
        </DSInboxNotificationContent>
      </DSInboxNotificationPreviewContainer>
    </DSInboxNotificationPreviewWrapper>
  );
};
