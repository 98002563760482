/**
 * NotificationListDetail.tsx
 * A React component that implements an adaptive master-detail view pattern for notifications.
 *
 * The component has two display modes:
 * 1. List-only mode (when activeNotification is undefined):
 *    - Displays a full-width list of notifications
 *    - Optimal for browsing multiple notifications
 *
 * 2. Split-view mode (when activeNotification is provided):
 *    - Shows a two-panel layout with the list on the left (45% width)
 *    - Shows the selected notification's details on the right
 *    - Maintains context while viewing details
 *
 * Selection Modes:
 * - Active: Single notification for detailed view (controlled by URL)
 * - Multi-select: Multiple notifications can be selected via checkboxes for bulk operations
 *
 * Bulk Operations:
 * - Delete/Undelete: Archive or recover notifications (mutually exclusive operations)
 * - Mark as Read: Update notification status
 * - Clear Selection: Deselect all notifications
 *
 * @property {DealRoomNotification[]} notifications - Array of notifications to display
 * @property {DealRoomNotification} activeNotification - Currently active notification shown in detail view
 * @property {Set<string>} selectedNotifications - Set of notification IDs selected for bulk operations
 * @property {Function} onNotificationClick - Callback when a notification is clicked to view details
 * @property {Function} onNotificationSelect - Callback when a notification checkbox is toggled
 * @property {Function} onClearSelectedNotifications - Callback to clear all selected notifications
 * @property {Function} onDeleteSelectedNotifications - Callback to delete selected notifications (mutually exclusive with onUndeleteSelectedNotifications)
 * @property {Function} onUndeleteSelectedNotifications - Callback to recover deleted notifications (mutually exclusive with onDeleteSelectedNotifications)
 * @property {Function} onMarkSelectedNotificationsAsRead - Callback to mark selected notifications as read
 * @property {Function} onMarkSelectedNotificationsAsUnread - Callback to mark selected notifications as unread
 */
import {
  DSInboxNotificationsListDetailRoot,
  DSInboxNotificationDetailWrapper,
} from './styles';
import { DSInboxNotificationList } from './NotificationList';
import { DSInboxNotificationDetailView } from './NotificationDetail';
import { DealRoomNotification } from '@meetingflow/common/Api/data-contracts';

/**
 * Props interface for the DSInboxNotificationListDetail component
 * @property {DealRoomNotification[]} notifications - Array of notifications to display in the list
 * @property {DealRoomNotification} activeNotification - Optional notification to display in detail view.
 *                                                           When undefined, renders in list-only mode.
 * @property {Set<string>} selectedNotifications - Set of notification IDs that are currently selected
 * @property {Function} onNotificationClick - Optional callback function triggered when a notification is selected
 * @property {Function} onNotificationSelect - Optional callback function triggered when a notification checkbox is toggled
 * @property {Function} onClearSelectedNotifications - Optional callback to clear all selected notifications
 * @property {Function} onDeleteSelectedNotifications - Optional callback to delete selected notifications
 * @property {Function} onUndeleteSelectedNotifications - Optional callback to undelete selected notifications
 * @property {Function} onMarkSelectedNotificationsAsRead - Optional callback to mark selected notifications as read
 * @property {Function} onMarkSelectedNotificationsAsUnread - Optional callback to mark selected notifications as unread
 * @property {Function} onSelectAllNotifications - Optional callback to select all notifications
 * @property {Function} onDeselectAllNotifications - Optional callback to deselect all notifications
 * @property {boolean} listViewOnly - Optional flag to force list-only mode
 * @property {string} noNotificationsMessageTitle - Optional title to display when there are no notifications
 * @property {string} noNotificationsMessageSubtitle - Optional subtitle to display when there are no notifications
 */
interface DSInboxNotificationListDetailProps {
  notifications: DealRoomNotification[];
  activeNotification?: DealRoomNotification;
  selectedNotifications: Set<string>;
  onNotificationClick?: (notification: DealRoomNotification) => void;
  onNotificationSelect?: (notification: DealRoomNotification) => void;
  onClearSelectedNotifications?: () => void;
  onDeleteSelectedNotifications?: () => void;
  onUndeleteSelectedNotifications?: () => void;
  onMarkSelectedNotificationsAsRead?: () => void;
  onMarkSelectedNotificationsAsUnread?: () => void;
  onSelectAllNotifications?: () => void;
  onDeselectAllNotifications?: () => void;
  listViewOnly?: boolean;
  noNotificationsMessageTitle?: string;
  noNotificationsMessageSubtitle?: string;
}

/**
 * Renders an adaptive notification view that switches between list-only and split-view modes
 * based on whether a notification is selected.
 *
 * @param {DSInboxNotificationListDetailProps} props - Component props
 * @returns {JSX.Element} A responsive view that adapts between list-only and split-view layouts
 */
export const DSInboxNotificationListDetail = ({
  notifications,
  activeNotification,
  selectedNotifications,
  onNotificationClick,
  onNotificationSelect,
  onClearSelectedNotifications,
  onDeleteSelectedNotifications,
  onUndeleteSelectedNotifications,
  onMarkSelectedNotificationsAsRead,
  onMarkSelectedNotificationsAsUnread,
  onSelectAllNotifications,
  onDeselectAllNotifications,
  listViewOnly = false,
  noNotificationsMessageTitle,
  noNotificationsMessageSubtitle,
}: DSInboxNotificationListDetailProps) => {
  return (
    <DSInboxNotificationsListDetailRoot>
      {/* List component adapts its width based on whether a notification is selected */}
      <DSInboxNotificationList
        notifications={notifications}
        activeNotification={
          activeNotification && !listViewOnly ? activeNotification : undefined
        }
        selectedNotifications={selectedNotifications}
        onNotificationClick={onNotificationClick}
        onNotificationSelect={onNotificationSelect}
        onClearSelectedNotifications={onClearSelectedNotifications}
        onDeleteSelectedNotifications={onDeleteSelectedNotifications}
        onUndeleteSelectedNotifications={onUndeleteSelectedNotifications}
        onMarkSelectedNotificationsAsRead={onMarkSelectedNotificationsAsRead}
        onMarkSelectedNotificationsAsUnread={
          onMarkSelectedNotificationsAsUnread
        }
        onSelectAllNotifications={onSelectAllNotifications}
        onDeselectAllNotifications={onDeselectAllNotifications}
        noNotificationsMessageTitle={noNotificationsMessageTitle}
        noNotificationsMessageSubtitle={noNotificationsMessageSubtitle}
      />
      {/* Detail panel only renders when a notification is selected */}
      {activeNotification && !listViewOnly && (
        <DSInboxNotificationDetailWrapper>
          <DSInboxNotificationDetailView notification={activeNotification} />
        </DSInboxNotificationDetailWrapper>
      )}
    </DSInboxNotificationsListDetailRoot>
  );
};
