import { DEALROOMS_COLORS } from '../../../Themes/Themes';
import {
  Dropdown,
  IconButton,
  mergeStyleSets,
  Modal,
  Stack,
  Text,
} from '@fluentui/react';
import {
  CheckMarkIconProps,
  ChromeCloseIconProps,
} from '../../../utils/iconProps';
import {
  DealRoomAsyncCommandBarButton,
  DealRoomAsyncPrimaryButton,
  DealRoomCommandBarButton,
} from '../Components/DealRoomButton';
import { DraggableFileUploaderContainer } from '../DraggableFileUploaderContainer';
import { useCallback, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { pickFile } from '../../../Helpers/FileHelpers';
import { useAuth0 } from '@auth0/auth0-react';
import { DealRoomsApiClient } from '../../../Services/NetworkCommon';
import { useMutualPlan } from '../../../Hooks/useMutualPlan';
import {
  getFileWithMimeType,
  hasValidFileExtension,
} from '../../../utils/buyerDecisionSiteUtils';

type FulfillRequestAttachmentDialogProps = {
  organizationSlug: string;
  dealRoomId: number;
  onResolve: (value: unknown) => void;
  onDismiss: () => void;
  actionItemId: number;
  ownerName: string;
  description: string;
};

export const FulfillRequestAttachmentDialog = ({
  organizationSlug,
  dealRoomId,
  onResolve,
  onDismiss,
  actionItemId,
  ownerName,
  description,
}: FulfillRequestAttachmentDialogProps) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [selectedActionItemId, setSelectedActionItemId] = useState<
    number | null
  >(null);

  useEffect(() => {
    setSelectedActionItemId(actionItemId);
  }, [actionItemId]);

  const { getAccessTokenSilently } = useAuth0();

  const { mutualPlan, refetch: refetchMutualPlan } = useMutualPlan(
    organizationSlug,
    dealRoomId,
  );
  const taskOptionsList = useMemo(() => {
    if (!mutualPlan?.milestones) return [];

    return mutualPlan.milestones
      .flatMap((milestone) => milestone.actionItems || [])
      .filter((actionItem) => actionItem.status === 'DOC_REQUESTED')
      .map((actionItem) => ({
        key: actionItem?.id,
        text: actionItem?.actionItem,
      }));
  }, [mutualPlan?.milestones]);

  const handleDrop = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    const droppedFiles = event?.dataTransfer?.files;
    if (droppedFiles && droppedFiles.length > 0) {
      const currentFile = Array.from(droppedFiles)[0];

      if (!currentFile) {
        toast.error('The uploaded file is not valid');
        return;
      }

      if (!hasValidFileExtension(currentFile)) {
        toast.error(
          'Please select a file with an extension, like .txt or .pdf',
        );
        return;
      }

      const fileCopyWithType = getFileWithMimeType(currentFile);

      setSelectedFile(fileCopyWithType);
    }
  }, []);

  const handleSelectFile = useCallback(async () => {
    try {
      const file = await pickFile({});

      if (!file) {
        toast.error('The uploaded file is not valid');
        return;
      }

      if (!hasValidFileExtension(file)) {
        toast.error(
          'Please select a file with an extension, like .txt or .pdf',
        );
        return;
      }

      const fileCopyWithType = getFileWithMimeType(file);

      setSelectedFile(fileCopyWithType);
    } catch (err) {}
  }, []);

  const handleInitialUpload = useCallback(async () => {
    if (!selectedFile || !selectedActionItemId) return;
    try {
      const token = await getAccessTokenSilently();
      return await DealRoomsApiClient.uploadFileArtifact(
        organizationSlug,
        dealRoomId,
        {
          file: selectedFile,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    } catch (err) {
      toast.error(
        'Something went wrong uploading file, please try again later',
      );
      onDismiss();
      return err;
    }
  }, [
    dealRoomId,
    getAccessTokenSilently,
    onDismiss,
    organizationSlug,
    selectedActionItemId,
    selectedFile,
  ]);

  const handleUploadAttachment = useCallback(async () => {
    const response = await handleInitialUpload();
    if (selectedActionItemId === null) return;
    if (
      response &&
      typeof response === 'object' &&
      'data' in response &&
      response.data &&
      typeof response.data === 'object' &&
      'id' in response.data &&
      typeof response.data.id === 'number'
    ) {
      try {
        const token = await getAccessTokenSilently();
        await toast.promise(
          DealRoomsApiClient.putActionItemArtifact(
            organizationSlug,
            dealRoomId,
            selectedActionItemId,
            response.data.id,
            {
              headers: { Authorization: `Bearer ${token}` },
            },
          ),
          {
            loading: 'Uploading Artifact',
            success: () => {
              onResolve(null);
              refetchMutualPlan().then();
              return 'Successfully uploaded artifact';
            },
            error: () => {
              onDismiss();
              return 'Failed to upload artifact';
            },
          },
        );
      } catch (err) {
        onDismiss();
      }
    }
  }, [
    dealRoomId,
    getAccessTokenSilently,
    handleInitialUpload,
    onDismiss,
    onResolve,
    organizationSlug,
    refetchMutualPlan,
    selectedActionItemId,
  ]);

  const styles = mergeStyleSets({
    modalWrapper: {
      '& > .ms-Overlay': {
        backdropFilter: 'blur(3px)',
      },
      '.ms-Dialog-main': {
        minHeight: 'fit-content',
        backgroundColor: 'transparent',
      },
    },
    contentContainer: {
      width: 'calc(100vw - 3rem)',
      padding: '1rem 1.5rem 1.5rem',
      maxWidth: '538px',
      backgroundColor: DEALROOMS_COLORS.white,
      borderRadius: '.5rem',
      boxShadow: '0px 8px 16px 0px #00000024',
      gap: '1.5rem',
    },
    headerContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },
    title: {
      color: DEALROOMS_COLORS.themePrimary,
      fontSize: '1.5rem',
      lineHeight: '2.5rem',
      fontWeight: '400',
    },
    closeButton: {
      i: {
        fontSize: '.75rem',
        color: DEALROOMS_COLORS.themePrimary,
      },
    },
    content: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
    },
    footerContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: '1rem',
      flexWrap: 'wrap',
      '> .cancel-button': {
        padding: '0.5rem 1rem',
        justifyContent: 'center',
        '.ms-Button-label': {
          fontSize: '0.9375rem',
          lineHeight: '1.5rem',
          fontWeight: '400',
          color: DEALROOMS_COLORS.darkerGray,
        },
      },
      '> .save-button': {
        padding: '0.5rem 1rem',
        '.ms-Button-label': {
          fontSize: '0.9375rem',
          lineHeight: '1.5rem',
          fontWeight: '400',
          color: DEALROOMS_COLORS.white,
        },
      },
    },
    infos: {
      display: 'flex',
      flexDirection: 'column',
      gap: '.5rem',
    },
    infosTitle: {
      color: DEALROOMS_COLORS.themeSecondary,
      fontSize: '1.125rem',
      textAlign: 'center',
      lineHeight: '1.5rem',
      fontWeight: '400',
    },
    infosDescription: {
      color: DEALROOMS_COLORS.userSurveyPrimary,
      fontSize: '1.125rem',
      lineHeight: '1.5rem',
      fontWeight: '500',
      fontStyle: 'italic',
      textAlign: 'center',
    },
    selectFileButton: {
      padding: '.375rem .75rem',
    },
    dropContent: {
      display: 'flex',
      flexDirection: 'column',
    },
    dragMaxWidth: {
      maxWidth: '240px',
    },
    uploadSectionMessage: {
      fontSize: '0.9375rem',
      lineHeight: '1.5rem',
      fontWeight: '500',
      color: DEALROOMS_COLORS.neutralDark,
      wordBreak: 'break-word',
      textAlign: 'center',
    },
    uploadSectionFileName: {
      fontSize: '0.9375rem',
      lineHeight: '1.5rem',
      fontWeight: '500',
      color: DEALROOMS_COLORS.themePrimary,
      wordBreak: 'break-word',
      textAlign: 'center',
    },
    dropdown: {
      '.ms-Label': {
        fontSize: '0.875rem',
        lineHeight: '1.25rem',
        fontWeight: '400',
        color: DEALROOMS_COLORS.darkerGray,
      },
    },
  });

  return (
    <Modal className={styles.modalWrapper} isOpen onDismiss={onDismiss}>
      <Stack className={styles.contentContainer}>
        <Stack.Item className={styles.headerContainer}>
          <Text className={styles.title}>Fulfill Request for Artifact</Text>
          <IconButton
            className={styles.closeButton}
            ariaLabel="Close"
            iconProps={ChromeCloseIconProps}
            onClick={onDismiss}
            alt="Close image"
          />
        </Stack.Item>
        <Stack.Item className={styles.content}>
          <div className={styles.infos}>
            <Text className={styles.infosTitle}>
              {ownerName} requested PDF file from you.
            </Text>
            <Text className={styles.infosDescription}>{description}</Text>
          </div>
          <DraggableFileUploaderContainer
            handleOnDrop={handleDrop}
            className={styles.dragMaxWidth}
          >
            <DealRoomAsyncCommandBarButton
              customClasses={styles.selectFileButton}
              onClick={handleSelectFile}
              text="Select File"
              buttonStyleType="DEAL_ROOM"
              iconProps={{ iconName: 'AddArtifact' }}
            />
            <div className={styles.dropContent}>
              <Text className={styles.uploadSectionMessage}>
                Select or drop a file to upload.
              </Text>
              {selectedFile && (
                <Text className={styles.uploadSectionFileName}>
                  {selectedFile.name || 'N/A'}
                </Text>
              )}
            </div>
          </DraggableFileUploaderContainer>
        </Stack.Item>
        <Dropdown
          options={taskOptionsList}
          label="Task"
          placeholder="Select a task"
          className={styles.dropdown}
          onChange={(ev, currentOption) => {
            currentOption?.key && setSelectedActionItemId(+currentOption.key);
          }}
          selectedKey={selectedActionItemId}
        />
        <Stack.Item className={styles.footerContainer}>
          <DealRoomCommandBarButton
            customClasses="cancel-button"
            buttonStyleType="COMMAND_BAR"
            text="Cancel"
            onClick={onDismiss}
          />
          <DealRoomAsyncPrimaryButton
            customClasses="save-button"
            disabled={!selectedFile}
            iconProps={CheckMarkIconProps}
            onClick={handleUploadAttachment}
            text="Upload Artifact"
          />
        </Stack.Item>
      </Stack>
    </Modal>
  );
};
