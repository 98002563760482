import { useAuth0 } from '@auth0/auth0-react';
import { useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useDealRoom } from '../../../../../Hooks/useDealRoom';
import { useOrganization } from '../../../../../Hooks/useOrganization';
import { useUserProfile } from '../../../../../Hooks/useProfile';
import {
  DealRoomContactsQuery,
  OrganizationUpcomingMeetings,
} from '../../../../../QueryNames';
import {
  DealRoomsApiClient,
  EventsApiClient,
  MeetingflowsApiClient,
} from '../../../../../Services/NetworkCommon';
import {
  CalendarEvent,
  DealRoomMeetingflowArtifact,
  ListDealRoomContactsData,
  ListEventsData,
  ListPlansData,
} from '@meetingflow/common/Api/data-contracts';
import { DateTime, Duration } from 'luxon';
import { AxiosResponse } from 'axios';

interface UseUserCalendarProps {
  searchString: string;
  selectedDate?: DateTime;
  minDate?: DateTime;
  maxDate?: DateTime;
  showInternal: boolean;
  hasMeetingflow?: boolean;
  refreshKey?: string;
  queryKey?: (organizationSlug: string) => string;
}

type UseUserCalendarReturnType = {
  // Data
  events: CalendarEvent[];
  eventsData: AxiosResponse<ListEventsData> | undefined;
  dealRoomContactsData: AxiosResponse<ListDealRoomContactsData> | undefined;
  ownedMeetingflowsData: AxiosResponse<ListPlansData> | undefined;

  // Loading states
  loading: boolean;
  isLoading: boolean;
  isFetching: boolean;
  isRefetching: boolean;
  isError: boolean;

  // Errors
  eventsError: unknown;
  dealRoomContactsError: unknown;

  // Actions
  refetchEvents: ReturnType<typeof useQuery>['refetch'];
  refetchContacts: ReturnType<typeof useQuery>['refetch'];
  getAccessTokenWithPopup: ReturnType<
    typeof useAuth0
  >['getAccessTokenWithPopup'];
  isEventInDecisionSite: (event: CalendarEvent) => boolean;

  meetingflowArtifacts: DealRoomMeetingflowArtifact[];
};

export const useUserCalendar = ({
  searchString,
  selectedDate,
  minDate,
  maxDate,
  showInternal,
  hasMeetingflow,
  refreshKey,
  queryKey = OrganizationUpcomingMeetings,
}: UseUserCalendarProps): UseUserCalendarReturnType => {
  // Authentication and organization context hooks
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();
  const {
    slug: organizationSlug,
    internalDomains,
    isLoading: orgIsLoading,
  } = useOrganization();
  const { dealRoom, isLoading: dealRoomIsLoading } = useDealRoom();
  const { userId } = useUserProfile();

  // Data fetching hooks - Deal Room Contacts
  const {
    data: dealRoomContactsData,
    isLoading: dealRoomContactsLoading,
    error: dealRoomContactsError,
    refetch: refetchContacts,
  } = useQuery(
    [DealRoomContactsQuery, dealRoom?.id],
    async () => {
      if (!dealRoom?.id || !organizationSlug) {
        return undefined;
      }
      const token = await getAccessTokenSilently();
      return DealRoomsApiClient.listDealRoomContacts(
        organizationSlug,
        dealRoom.id,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      enabled: Boolean(organizationSlug),
    },
  );

  // Fetch owned meetingflows within date range
  const effectiveMinDate =
    minDate || (selectedDate ? selectedDate.startOf('week') : undefined);
  const effectiveMaxDate =
    maxDate || (selectedDate ? selectedDate.endOf('week') : undefined);

  const { data: ownedMeetingflowsData } = useQuery(
    [
      'ownedMeetingflows',
      organizationSlug,
      effectiveMinDate?.toISO(),
      effectiveMaxDate?.toISO(),
    ],
    async () => {
      if (!organizationSlug) {
        throw new Error('Organization slug is required');
      }
      const token = await getAccessTokenSilently();
      return MeetingflowsApiClient.listPlans(
        {
          organizationSlug,
          minDate: effectiveMinDate?.toISO() || undefined,
          maxDate: effectiveMaxDate?.toISO() || undefined,
          owner: userId,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      enabled:
        !!organizationSlug &&
        !!userId &&
        (!!effectiveMinDate || !!effectiveMaxDate),
    },
  );

  // Data fetching hooks - Upcoming Events
  const {
    data: eventsData,
    isLoading: eventsLoading,
    refetch: refetchEvents,
    isError: eventsErrored,
    isFetching: eventsFetching,
    isRefetching: eventsRefetching,
    error: eventsError,
  } = useQuery(
    [
      queryKey(organizationSlug ?? ''),
      refreshKey,
      searchString,
      selectedDate?.startOf('day').toISO(),
      minDate?.startOf('day').toISO(),
      maxDate?.endOf('day').toISO(),
    ],
    async () => {
      if (!organizationSlug) {
        throw new Error('No organization slug available');
      }
      const token = await getAccessTokenSilently();

      // Determine date range based on provided parameters
      let minDateISO: string | undefined;
      let maxDateISO: string | undefined;

      if (selectedDate) {
        // Use week-based range if selectedDate is provided
        minDateISO =
          selectedDate.startOf('week').startOf('day').toISO() || undefined;
        maxDateISO =
          selectedDate.plus({ days: 7 }).endOf('day').toISO() || undefined;
      } else if (minDate || maxDate) {
        // Use explicit date range if provided
        minDateISO = minDate?.startOf('day').toISO() || undefined;
        maxDateISO = maxDate?.endOf('day').toISO() || undefined;
      }

      return EventsApiClient.listEvents(
        {
          organizationSlug,
          q: searchString || undefined,
          minDate: minDateISO,
          maxDate: maxDateISO,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
    },
    {
      refetchInterval: Duration.fromObject({ minutes: 15 }).as('milliseconds'),
      staleTime: 0, // Consider data immediately stale
      cacheTime: Duration.fromObject({ minutes: 5 }).as('milliseconds'),
      refetchOnMount: 'always', // Always refetch on mount
      refetchOnWindowFocus: true,
      retry: 1,
      enabled: Boolean(organizationSlug),
    },
  );

  // We need to query for artifacts so that we can check if an event already has a related meetingflow in the decision site.
  const { data: allArtifactsData } = useQuery(
    ['artifacts-calendar', dealRoom?.id, organizationSlug],
    async () => {
      const token = await getAccessTokenSilently();
      if (!organizationSlug || !dealRoom?.id) {
        return { data: [] };
      }
      const slug = organizationSlug;
      const id = dealRoom.id;

      return DealRoomsApiClient.listArtifacts(
        {
          organizationSlug: slug,
          dealRoomId: id,
          includeDeleted: true,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      enabled: Boolean(organizationSlug),
      staleTime: 0, // Consider data immediately stale
      cacheTime: Duration.fromObject({ minutes: 5 }).as('milliseconds'),
      refetchOnMount: 'always', // Always refetch on mount
      refetchOnWindowFocus: true,
      retry: 1,
    },
  );

  const meetingflowArtifacts = useMemo(() => {
    const artifacts = allArtifactsData?.data ?? [];
    return artifacts.filter((artifact) => artifact.type === 'MEETINGFLOW');
  }, [allArtifactsData]);

  // Check if a calendar event has a related meetingflow in the decision site
  const isEventInDecisionSite = (event: CalendarEvent) => {
    return meetingflowArtifacts.some((artifact) => {
      return artifact.meetingflowId === event.meetingplanId;
    });
  };

  // Memoized data transformations
  const events: CalendarEvent[] = useMemo(() => {
    if (!eventsData?.data) {
      return [];
    }

    let filteredEvents: CalendarEvent[] = eventsData.data;

    // Add owned meetingflows that aren't already in calendar events
    if (ownedMeetingflowsData?.data) {
      const existingMeetingflowIds = new Set(
        filteredEvents.map((event) => event.meetingplanId).filter(Boolean),
      );

      const ownedMeetingflowEvents = ownedMeetingflowsData.data
        .filter((meetingflow) => !existingMeetingflowIds.has(meetingflow.id))
        .map((meetingflow) => ({
          attendees: meetingflow.attendees || [],
          companies: meetingflow.companies || [],
          conferenceInfo: meetingflow.conferenceInfo || null,
          creator: meetingflow.creator || null,
          description: meetingflow.eventDescription || null,
          endTime: meetingflow.endTime,
          eventAttachments: null,
          externalId: meetingflow.id,
          externalSeriesId: meetingflow.externalSeriesId,
          iCalUid: meetingflow.id,
          isAllDay: false,
          isCancelled: false,
          isPrivate: false,
          location: meetingflow.location || null,
          meetingplanId: meetingflow.id,
          organizer: meetingflow.organizer || null,
          source: 'GOOGLE' as const,
          startTime: meetingflow.startTime,
          title: meetingflow.title,
        }));

      filteredEvents = [...filteredEvents, ...ownedMeetingflowEvents];
    }

    // Only filter by selectedDate if it's provided
    if (selectedDate) {
      filteredEvents = filteredEvents.filter(
        (event) =>
          DateTime.fromISO(event?.startTime).startOf('day') >=
          selectedDate.startOf('day'),
      );
    }

    // Filter for events with meetingflow if requested
    if (hasMeetingflow) {
      filteredEvents = filteredEvents.filter((event) =>
        Boolean(event.meetingplanId),
      );
    }

    if (!showInternal) {
      filteredEvents = filteredEvents.filter((event) => {
        const attendeeEmails = event.attendees.map(
          (attendee) => attendee.email,
        );
        return !attendeeEmails.every((email) =>
          internalDomains.some((domain) => email.endsWith(domain)),
        );
      });
    }

    return filteredEvents;
  }, [
    eventsData?.data,
    selectedDate,
    showInternal,
    internalDomains,
    hasMeetingflow,
    ownedMeetingflowsData?.data,
  ]);

  return {
    // Data
    events,
    eventsData,
    dealRoomContactsData: dealRoom?.id ? dealRoomContactsData : undefined,
    ownedMeetingflowsData,

    // Loading states
    loading:
      !events.length && (eventsLoading || eventsFetching || orgIsLoading),
    isLoading: eventsLoading || eventsFetching || orgIsLoading,
    isFetching: eventsFetching,
    isRefetching: eventsRefetching,
    isError: eventsErrored,

    // Errors
    eventsError,
    dealRoomContactsError: dealRoom?.id ? dealRoomContactsError : undefined,

    // Actions
    refetchEvents,
    refetchContacts,
    getAccessTokenWithPopup,
    isEventInDecisionSite,

    // MeetingflowArtifacts
    meetingflowArtifacts,
  };
};

export default useUserCalendar;
