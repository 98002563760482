import React, { useCallback, useMemo } from 'react';
import { Box } from '@mui/material';
import { NotificationsGroup } from './NotificationsGroup';
import MessageIcon from '@mui/icons-material/Message';
import ArticleIcon from '@mui/icons-material/Article';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { FrequencyCheckbox } from './FrequencyCheckbox';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import {
  ListUserNotificationPreferencesData,
  DealRoomNotificationCategory,
} from '@meetingflow/common/Api/data-contracts';
import { DEALROOMS_COLORS } from '../../../../../Themes/Themes';

interface UserSettingsNotificationsProps {
  isSaving: boolean;
  setIsSaving: (value: boolean) => void;
  organizationSlug: string;
  dealRoomId: number;
  userNotificationsSettings: ListUserNotificationPreferencesData | [];
}

const groupIconStyles = {
  color: DEALROOMS_COLORS.themeSecondary,
  width: '1.25rem',
  height: '1.25rem',
};

export const UserSettingsNotifications: React.FC<
  UserSettingsNotificationsProps
> = ({ userNotificationsSettings }) => {
  // Transform user notification settings into a structured format for ag-grid table
  const notificationsCategoriesData = useMemo(() => {
    return userNotificationsSettings.map((item) => ({
      // Preserve category information
      notificationSectionCategory: item.notificationCategory,
      notificationSectionDescription: item.notificationCategoryDescription,
      // Map each notification type to a more readable format
      notificationTypes: item.notificationTypes.map((type) => ({
        key: type.notificationType,
        notifyFor: type.notificationTypeDescription,
        // Assume index 0 is for email, index 1 for in-app
        email: type.delivery[0].timingType,
        inApp: type.delivery[1].timingType,
        notificationSectionCategory: item.notificationCategory,
      })),
    }));
  }, [userNotificationsSettings]); // Recalculate when settings change

  const columnDefs = useMemo(
    () =>
      [
        {
          field: 'notifyFor',
          headerName: 'Notify me when someone',
          flex: 2,
          minWidth: 150,
          cellRenderer: (params: ICellRendererParams) => (
            <div
              className="notification-cell"
              style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                width: '100%',
              }}
            >
              {params.value}
            </div>
          ),
        },
        {
          field: 'email',
          headerName: 'Email',
          cellRenderer: FrequencyCheckbox,
          minWidth: 75,
          maxWidth: 75,
        },
        {
          field: 'inApp',
          headerName: 'In-App',
          cellRenderer: FrequencyCheckbox,
          minWidth: 80,
          maxWidth: 80,
        },
      ] as ColDef[],
    [],
  );

  // Map notification category to icon
  // if DealRoomNotificationCategory type changes, this function will need to be updated
  const getGroupIcon = useCallback(
    (groupType: DealRoomNotificationCategory) => {
      switch (groupType) {
        case 'ARTIFACT':
          return <MessageIcon sx={groupIconStyles} />;
        case 'ACTION_ITEM':
          return <ArticleIcon sx={groupIconStyles} />;
        // case 'CONTACT':
        //   return <ContactPageIcon sx={groupIconStyles} />;
        default:
          return <NotificationsIcon sx={groupIconStyles} />;
      }
    },
    [],
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={2.5}
      sx={{ padding: '1rem' }}
    >
      {notificationsCategoriesData.map((category) => (
        <NotificationsGroup
          key={category.notificationSectionCategory}
          icon={getGroupIcon(category.notificationSectionCategory)}
          title={category.notificationSectionDescription}
          rowData={category.notificationTypes}
          columnDefs={columnDefs}
        />
      ))}
    </Box>
  );
};
