import React, { useMemo, useCallback } from 'react';
import useStore from '../../../../Stores/userSettingsStore';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';
import {
  TextField,
  Button,
  Avatar,
  Typography,
  Box,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Chip,
  styled,
} from '@mui/material';
import { DetailedUser } from '@meetingflow/common/Api/data-contracts';
import {
  IANA_TIMEZONES,
  isTimezone,
  Timezone,
} from '@meetingflow/common/Timezones';
import { TimeZonesIllustrations } from './UserSettingsIllustrations';
import { pickFile } from '../../../../Helpers/FileHelpers';
import toast from 'react-hot-toast';
import { useUserProfile } from '../../../../Hooks/useProfile';
import useDeviceType from '../../../../Hooks/deviceDetection';
import DSButton from '../../DS/DSButton';
import DSTextField from '../../DS/DSTextField';
import {
  linkedInUrlRegex,
  scheduleMeetingUrlRegex,
  getFileWithMimeType,
  hasValidFileExtension,
  validUploadImageTypes,
} from '../../../../utils/buyerDecisionSiteUtils';
import { DecisionSitePhoneNumberField } from '../DecisionSitePhoneNumberField';

interface UserSettingsProfileProps {
  orgRole: string | undefined;
  profileData: DetailedUser | undefined;
  setSelectedAvatar: (arg0: File | null) => void;
  isSaving: boolean;
  setUploadedImgAsPath: (arg0: string | null) => void;
  userProfileDataState: { [key: string]: string | null };
}

const timezones = [...IANA_TIMEZONES];

const StyledSection = styled(Box)(({ theme }) => ({
  // width: '100%',
  border: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
  borderRadius: '0.25rem',
  backgroundColor: DEALROOMS_COLORS.neutralGray,
  padding: '1rem',
  marginBottom: '1.5rem',
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  color: DEALROOMS_COLORS.themePrimary,
  marginBottom: '1rem',
}));

export const UserSettingsProfile = ({
  orgRole,
  profileData,
  setSelectedAvatar,
  isSaving,
  setUploadedImgAsPath,
  userProfileDataState,
}: UserSettingsProfileProps) => {
  const { isMobile } = useDeviceType();
  const { user } = useUserProfile();
  const email = user?.email || '';

  const profilePhoneCountryCode = useStore(
    (state) => state.profilePhoneCountryCode,
  );
  const setProfilePhoneCountryCode = useStore(
    (state) => state.setProfilePhoneCountryCode,
  );

  const defaultTz = useMemo(() => {
    const browserTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return (timezones as string[]).includes(browserTz)
      ? (browserTz as Timezone)
      : undefined;
  }, []);

  const startAvatarChange = useCallback(async () => {
    const file = await pickFile({
      excludeAcceptAll: true,
      fileTypes: 'image',
    });

    if (!file) {
      setSelectedAvatar(null);
      setUploadedImgAsPath(null);
      toast.error('The uploaded file is not a valid image.');
      return;
    }

    if (!hasValidFileExtension(file)) {
      setSelectedAvatar(null);
      setUploadedImgAsPath(null);
      toast.error('Please select a file with an extension, like .jpg or .png');
      return;
    }

    if (file.size > 2 * 1024 * 1024) {
      setSelectedAvatar(null);
      setUploadedImgAsPath(null);
      toast.error('The uploaded image size exceeds the 2MB limit.');
      return;
    }

    const fileCopyWithType = getFileWithMimeType(file);

    if (!validUploadImageTypes.includes(fileCopyWithType.type)) {
      setSelectedAvatar(null);
      setUploadedImgAsPath(null);
      toast.error('The uploaded file is not a valid image.');
      return;
    }

    setSelectedAvatar(fileCopyWithType);

    const reader = new FileReader();
    reader.onloadend = () => {
      setUploadedImgAsPath(reader.result as string);
    };
    reader.readAsDataURL(fileCopyWithType);
  }, [setSelectedAvatar, setUploadedImgAsPath]);

  const setUserProfileDataState = useStore((state) => state.setUserProfileData);
  const uploadedImgAsPath = useStore((state) => state.uploadedImgAsPath);

  const handleInputChange = useCallback(
    (field: string) =>
      (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setUserProfileDataState(field, event.target.value);
      },
    [setUserProfileDataState],
  );

  return (
    <Box sx={{ width: '100%' }}>
      <StyledSection>
        <StyledTitle>Avatar</StyledTitle>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Avatar
              src={
                uploadedImgAsPath ||
                profileData?.avatarFileUrl ||
                profileData?.avatarUrl ||
                ''
              }
              alt={profileData?.name || 'User'}
              sx={{ width: isMobile ? 72 : 100, height: isMobile ? 72 : 100 }}
            />
          </Grid>
          <Grid item xs>
            <DSButton onClick={startAvatarChange} disabled={isSaving}>
              Change Picture
            </DSButton>
            <Typography variant="caption" sx={{ display: 'block', mt: 1 }}>
              Square picture, jpeg, gif or png format, max 2MB
            </Typography>
          </Grid>
        </Grid>
      </StyledSection>

      <StyledSection>
        <StyledTitle>Name</StyledTitle>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DSTextField
              fullWidth
              label="First Name"
              defaultValue={
                userProfileDataState.firstName || profileData?.firstName
              }
              onChange={handleInputChange('firstName')}
              disabled={isSaving}
              variant="outlined"
              sx={{ backgroundColor: DEALROOMS_COLORS.inputLightGray }}
            />
          </Grid>
          <Grid item xs={12}>
            <DSTextField
              fullWidth
              label="Last Name"
              defaultValue={
                userProfileDataState.lastName || profileData?.lastName
              }
              onChange={handleInputChange('lastName')}
              disabled={isSaving}
              variant="outlined"
              sx={{ backgroundColor: DEALROOMS_COLORS.inputLightGray }}
            />
          </Grid>
          <Grid item xs={12}>
            <DSTextField
              fullWidth
              label="Email"
              type="email"
              defaultValue={email}
              disabled={true}
              variant="outlined"
              sx={{ backgroundColor: DEALROOMS_COLORS.inputLightGray }}
            />
          </Grid>
        </Grid>
      </StyledSection>

      <StyledSection>
        <StyledTitle>My Information</StyledTitle>
        <Grid container spacing={2}>
          {/* eslint-disable no-useless-escape */}
          <Grid item xs={12}>
            <DecisionSitePhoneNumberField
              value={
                userProfileDataState.phoneNumber ??
                (profileData?.phoneNumber || '')
              }
              onChange={(newPhoneNumber) => {
                setUserProfileDataState('phoneNumber', newPhoneNumber);
              }}
              country={profilePhoneCountryCode}
              setCountry={setProfilePhoneCountryCode}
              label="Phone Number"
              containerSx={{
                backgroundColor: DEALROOMS_COLORS.inputLightGray,
                height: '3.5rem',
              }}
              inputSx={{
                backgroundColor: DEALROOMS_COLORS.inputLightGray,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <DSTextField
              fullWidth
              label="Scheduling Link"
              defaultValue={
                userProfileDataState.schedulingUrl || profileData?.schedulingUrl
              }
              onChange={handleInputChange('schedulingUrl')}
              disabled={isSaving}
              placeholder="Enter the URL where others can schedule meetings with you"
              variant="outlined"
              error={Boolean(
                userProfileDataState.schedulingUrl &&
                  !userProfileDataState.schedulingUrl.match(
                    scheduleMeetingUrlRegex,
                  ),
              )}
              helperText={
                userProfileDataState.schedulingUrl &&
                !userProfileDataState.schedulingUrl.match(
                  scheduleMeetingUrlRegex,
                )
                  ? 'Please enter a valid URL'
                  : ''
              }
              sx={{ backgroundColor: DEALROOMS_COLORS.inputLightGray }}
            />
          </Grid>
          <Grid item xs={12}>
            <DSTextField
              fullWidth
              label="LinkedIn"
              defaultValue={
                userProfileDataState.linkedInUrl || profileData?.linkedInUrl
              }
              onChange={handleInputChange('linkedInUrl')}
              disabled={isSaving}
              variant="outlined"
              error={Boolean(
                userProfileDataState.linkedInUrl &&
                  !userProfileDataState.linkedInUrl.match(linkedInUrlRegex),
              )}
              helperText={
                userProfileDataState.linkedInUrl &&
                !userProfileDataState.linkedInUrl.match(linkedInUrlRegex)
                  ? 'Please enter a valid LinkedIn profile URL'
                  : ''
              }
              sx={{ backgroundColor: DEALROOMS_COLORS.inputLightGray }}
            />
          </Grid>
          {/* eslint-enable no-useless-escape */}
        </Grid>
      </StyledSection>

      <StyledSection>
        <StyledTitle>Timezone</StyledTitle>
        <FormControl fullWidth>
          <Select
            value={
              isTimezone(userProfileDataState.timezone)
                ? userProfileDataState.timezone
                : isTimezone(profileData?.timezone)
                  ? profileData?.timezone
                  : defaultTz
            }
            onChange={(e) =>
              setUserProfileDataState('timezone', e.target.value as Timezone)
            }
            disabled={isSaving}
            renderValue={(value) => (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <TimeZonesIllustrations />
                <Typography sx={{ ml: 1 }}>{value}</Typography>
              </Box>
            )}
            sx={{ backgroundColor: DEALROOMS_COLORS.inputLightGray }}
          >
            {timezones.map((timezone) => (
              <MenuItem key={timezone} value={timezone}>
                {timezone}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </StyledSection>
    </Box>
  );
};
