import { useTaskComments } from '../../../../Hooks/useTaskComments';
import { useMemo, useCallback } from 'react';
import { Badge, IconButton, Tooltip, SxProps } from '@mui/material';
import { Message, ChatOutlined } from '@mui/icons-material';

import { DealRoomCommandBarButton } from '../../Components/DealRoomButton';
import { useCommentsButtonStyles } from './useCommentsButtonStyles';
import { DSButton } from '../../DS';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';

type CommentsContextualMenuProps = {
  organizationSlug: string;
  dealRoomId: number;
  taskId: number;
  setTaskIdForComments: (taskId: number) => void;
  sx?: SxProps;
};

export const OpenCommentsButton = ({
  organizationSlug,
  dealRoomId,
  taskId,
  setTaskIdForComments,
  sx,
}: CommentsContextualMenuProps) => {
  const { taskComments } = useTaskComments(
    organizationSlug,
    dealRoomId,
    taskId,
  );

  const styles = useCommentsButtonStyles();

  const count = useMemo(() => {
    return taskComments?.length ? `${taskComments.length}` : '0';
  }, [taskComments]);

  return (
    <DSButton
      variant="outlined"
      onClick={(e) => {
        e.stopPropagation();
        setTaskIdForComments(taskId);
      }}
      sx={{
        height: '32px',
        minWidth: '32px',
        padding: '0 !important',
        textTransform: 'none',
        border: 'none',
        backgroundColor: 'transparent !important',
        color: DEALROOMS_COLORS.cloudburst,
        position: 'relative',
        top: '-.25rem',
        '& .MuiSvgIcon-root': {
          fontSize: '1.2rem',
          color: DEALROOMS_COLORS.cloudburst,
        },
        '& .MuiBadge-badge': {
          border: `1px solid ${DEALROOMS_COLORS.cloudburst}`,
          backgroundColor: DEALROOMS_COLORS.white,
          color: DEALROOMS_COLORS.cloudburst,
          fontSize: '0.5rem',
          minWidth: '16px',
          width: 'auto',
          height: '16px',
          lineHeight: '16px',
          padding: '0 2px 0 3px',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          display: 'block',
        },
        '&:hover': {
          border: 'none',
          backgroundColor: DEALROOMS_COLORS.neutralLight,
        },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Badge badgeContent={count} max={99}>
        <Message />
      </Badge>
    </DSButton>
  );
};
