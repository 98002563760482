export const getIdentityProvider = (
  sub: string | undefined,
): 'GOOGLE' | 'M365' => {
  if (!sub) return 'GOOGLE'; // Default to Google for backward compatibility
  const provider = sub.split('|')[0];
  return provider === 'google-oauth2' ? 'GOOGLE' : 'M365';
};

export const getDisplayProvider = (sub: string | undefined): string => {
  if (!sub) return 'Google'; // Default to Google for backward compatibility
  const provider = sub.split('|')[0];
  return provider === 'google-oauth2'
    ? 'Google'
    : provider === 'waad'
      ? 'Microsoft'
      : provider;
};

export const isMicrosoftUser = (sub: string) => sub.startsWith('waad|');
export const isGoogleUser = (sub: string) => sub.startsWith('google-oauth2|');
