import { styled } from '@mui/material/styles';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';
import { Box, Card, Typography } from '@mui/material';

export const StyledCard = styled(Card)(({ theme }) => ({
  border: `1px solid ${DEALROOMS_COLORS.financialLightGray}`,
  borderRadius: '0.25rem',
  transition: '.3s ease-in-out all',
  padding: '.5rem 1rem .5rem 1rem',
  display: 'flex',
  flexDirection: 'column',
  gap: '.25rem',
  position: 'relative',
  cursor: 'pointer',
  boxShadow: 'none',
  '&:hover': {
    boxShadow: `0 0 0 1px ${DEALROOMS_COLORS.financialLightGray}`,
  },
}));

export const Header = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '.5rem',
  height: 'auto',
  position: 'relative',
  marginBottom: '.5rem',
});

export const TitleContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '.5rem',
  flex: 1,
  minWidth: 0,
  paddingRight: '1.5rem',
});

export const ActionsButton = styled(Box)({
  display: 'flex',
  gap: '.5rem',
  position: 'absolute',
  right: '-.5rem',
  top: 0,
});

export const Title = styled(Typography)({
  fontSize: '1rem',
  margin: 0,
  padding: '.25rem 0 0 0',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  fontWeight: 400,
});

export const Preview = styled(Box)({
  width: '100%',
  aspectRatio: '16/9',
  overflow: 'hidden',
  backgroundColor: DEALROOMS_COLORS.financialLighterGray,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& > *': {
    width: '100%',
    height: '100%',
  },
});

export const Activity = styled(Box)({
  padding: '.5rem .5rem',
  display: 'flex',
  alignItems: 'flex-start',
  gap: '0.5rem',
});
