/**
 * DSArtifactPanel is a component for displaying and managing individual artifacts within a DealRoom.
 *
 * Key features:
 * - Displays artifact metadata, preview, and actions
 * - Supports artifact title editing
 * - Handles artifact voting (upvote/downvote)
 * - Manages artifact downloads
 * - Provides artifact featuring functionality
 * - Implements click-outside behavior for panel closure
 */

import { useQuery, useQueryClient } from 'react-query';
import { useOrganization } from '../../../Hooks/useOrganization';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useMemo, useRef, useCallback, useState } from 'react';
import { Box, CircularProgress, styled } from '@mui/material';
import { KeyboardArrowUp } from '@mui/icons-material';
import {
  ContentWrapper,
  HeaderWrapper,
  PanelWrapper,
  TabsWrapper,
  CollapseButton,
} from './DSArtifactPanel.styles';
import { DSArtifactPanelHeader } from './DSArtifactPanelHeader';
import { DSArtifactPanelTabs } from './DSArtifactPanelTabs';

import { useUserProfile } from '../../../Hooks/useProfile';
import { useSearchParams } from 'react-router-dom';
import { useDealRoom } from '../../../Hooks/useDealRoom';
import {
  OrganizationDealRoomArtifactQuery,
  OrganizationDealRoomArtifactsQuery,
} from '../../../QueryNames';
import { DealRoomsApiClient } from '../../../Services/NetworkCommon';
import {
  ArtifactActions,
  ArtifactMetadata,
  ArtifactPreview,
  ArtifactTitle,
} from './ArtifactDetailsViewComponents';
import toast from 'react-hot-toast';
import { useHandleOnFeature } from './useArtifactsOnFeature';
import saveAs from 'file-saver';

/**
 * A centered container for displaying the loading spinner while artifact data is being fetched
 */
const LoadingContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  width: '100%',
});

/**
 * Props for the DSArtifactPanel component
 * @property artifactId - Numeric identifier for the artifact to be displayed
 * @property onClickCloseButton - Callback function triggered when the panel should be closed
 */
export const DSArtifactPanel: React.FC<{
  artifactId: number;
  onClickCloseButton: () => void;
}> = ({ artifactId, onClickCloseButton }) => {
  const [isTabsCollapsed, setIsTabsCollapsed] = useState(true);
  const [selectedTab, setSelectedTab] = useState<string | number>(
    'description',
  );

  const handleTabChange = (newTab: string | number) => {
    if (newTab === selectedTab) {
      // Toggle collapse when clicking the selected tab
      setIsTabsCollapsed(!isTabsCollapsed);
    } else {
      // Expand when switching tabs
      setIsTabsCollapsed(false);
      setSelectedTab(newTab);
    }
  };
  // Authentication and organization context
  const { getAccessTokenSilently, user } = useAuth0();
  const { slug: organizationSlug, hasEntitlement } = useOrganization();
  const { dealRoomId, refetch: refetchDealRoom, dealRoomRole } = useDealRoom();

  // User profile management
  const { name, email, picture } = user!;
  const { user: mfUser } = useUserProfile();

  // Query and routing state
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();

  const wrapperRef = useRef<HTMLDivElement>(null);

  // Fetch artifact data
  const {
    data: artifactResponse,
    isLoading: artifactLoading,
    refetch: refetchArtifact,
  } = useQuery(
    OrganizationDealRoomArtifactQuery(
      organizationSlug!,
      dealRoomId!,
      artifactId!,
    ),
    async () => {
      const token = await getAccessTokenSilently();
      return DealRoomsApiClient.getArtifact(
        organizationSlug!,
        dealRoomId!,
        artifactId!,
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
    },
    {
      enabled: !!organizationSlug && !!dealRoomId && !!artifactId,
    },
  );

  const artifact = useMemo(() => artifactResponse?.data, [artifactResponse]);

  // Function to handle viewing artifact
  const handleViewArtifact = useCallback(async () => {
    if (!artifactId || !organizationSlug || !dealRoomId) return;

    const token = await getAccessTokenSilently();
    await DealRoomsApiClient.viewArtifact(
      organizationSlug,
      dealRoomId,
      artifactId,
      {
        headers: { Authorization: `Bearer ${token}` },
      },
    );
    await refetchArtifact();
  }, [
    artifactId,
    dealRoomId,
    getAccessTokenSilently,
    organizationSlug,
    refetchArtifact,
  ]);

  // Track artifact view on mount
  useEffect(() => {
    handleViewArtifact();
  }, [handleViewArtifact]);

  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [title, setTitle] = useState<string | undefined>(
    artifact?.label || artifact?.name,
  );

  useEffect(() => {
    // Handle click outside to close panel
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;

      // Material-UI renders Menus/Popovers/DatePickers in a Portal at the root of the document,
      // outside of our panel's DOM hierarchy. We need to check both:
      // 1. If the click is inside our panel's DOM tree
      // 2. If the click is inside any MUI portal component
      const isPortalClick =
        target.closest(
          '.MuiMenu-root, .MuiPopover-root, .MuiPickersPopper-root, .MuiMilestonePopper-root',
        ) !== null;
      const isPanelClick = wrapperRef.current?.contains(target);

      if (!isPanelClick && !isPortalClick) {
        onClickCloseButton();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClickCloseButton]);

  const previewArtifact = useMemo(() => {
    if (!artifact || !organizationSlug || !dealRoomId) return null;

    return (
      <ArtifactPreview
        artifact={artifact}
        organizationSlug={organizationSlug}
        dealRoomId={dealRoomId}
      />
    );
  }, [artifact, organizationSlug, dealRoomId]);

  // Handle title change
  const handleOnBlurName = useCallback(async () => {
    try {
      if (
        !organizationSlug ||
        !dealRoomId ||
        !artifact?.id ||
        !title ||
        title === artifact?.name ||
        title === artifact?.label
      ) {
        setIsEditingTitle(false);
        return;
      }

      let dataToSend: {};
      if (artifact?.label) {
        dataToSend = { label: title };
      } else {
        dataToSend = { name: title };
      }

      const token = await getAccessTokenSilently();
      await DealRoomsApiClient.updateArtifact(
        organizationSlug,
        dealRoomId,
        artifact?.id,
        {
          ...dataToSend,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );

      // Invalidate queries to ensure all components get updated data
      queryClient.invalidateQueries(
        OrganizationDealRoomArtifactQuery(
          organizationSlug,
          dealRoomId,
          artifact.id,
        ),
      );
      queryClient.invalidateQueries(
        OrganizationDealRoomArtifactsQuery(organizationSlug, dealRoomId),
      );

      // Refetch to update local state
      await Promise.all([refetchArtifact(), refetchDealRoom()]);
      setIsEditingTitle(false);
      toast.success('Artifact title updated successfully');
    } catch (err: unknown) {
      toast.error('Failed to update artifact title');
      setIsEditingTitle(false);
    }
  }, [
    organizationSlug,
    dealRoomId,
    artifact?.id,
    getAccessTokenSilently,
    title,
    artifact?.label,
    artifact?.name,
    refetchArtifact,
    refetchDealRoom,
    queryClient,
  ]);

  // Handle feature action
  const { handleOnFeature } = useHandleOnFeature({
    organizationSlug,
    dealRoomId,
    refetchArtifacts: refetchArtifact,
  });

  // Handle download action
  const handleOnDownloadArtifact = useCallback(async () => {
    try {
      if (artifact && 'fileUrl' in artifact) {
        // Get filename from either name, label, or use a default name
        const filename = artifact.name || artifact.label || 'downloaded-file';

        // Fetch the artifact file from the provided URL
        const fileResponse = await fetch(artifact.fileUrl);

        // Convert the response to a Blob object
        const fileBlob = await fileResponse.blob();

        // Create a new File object with the Blob data and appropriate metadata
        const downloadableFile = new File([fileBlob], filename, {
          type: fileBlob.type,
        });

        // Initiate the file download
        saveAs(downloadableFile);
      }
    } catch (err: unknown) {}
  }, [artifact]);

  // Handle upvote/downvote action
  const handleOnVote = useCallback(
    async (upVote?: boolean) => {
      try {
        if (
          !artifact ||
          typeof organizationSlug !== 'string' ||
          typeof dealRoomId !== 'number' ||
          typeof artifact.id !== 'number' ||
          !getAccessTokenSilently ||
          !refetchArtifact
        ) {
          return;
        }

        const token = await getAccessTokenSilently();
        await (
          upVote
            ? artifact.upvoted
              ? DealRoomsApiClient.removeArtifactUpvote
              : DealRoomsApiClient.upvoteArtifact
            : artifact.downvoted
              ? DealRoomsApiClient.removeArtifactDownvote
              : DealRoomsApiClient.downvoteArtifact
        )(organizationSlug, dealRoomId, artifact.id, {
          headers: { Authorization: `Bearer ${token}` },
        });
        await refetchArtifact();
      } catch (err: unknown) {}
    },
    [
      artifact,
      organizationSlug,
      dealRoomId,
      getAccessTokenSilently,
      refetchArtifact,
    ],
  );

  // Show loading state while any of the main queries are loading
  if (artifactLoading) {
    return (
      <PanelWrapper>
        <LoadingContainer>
          <CircularProgress />
        </LoadingContainer>
      </PanelWrapper>
    );
  }

  if (!artifact) {
    return null;
  }

  if (!organizationSlug || !artifact) {
    return null;
  }

  return (
    <PanelWrapper ref={wrapperRef}>
      <HeaderWrapper>
        <DSArtifactPanelHeader
          artifactId={artifactId}
          onClickCloseButton={onClickCloseButton}
        >
          <ArtifactTitle
            title={title}
            isEditingTitle={isEditingTitle}
            onStartEditTitle={() => setIsEditingTitle(true)}
            onBlurTitle={handleOnBlurName}
            onChangeTitle={setTitle}
            artifact={artifact}
          />
        </DSArtifactPanelHeader>
      </HeaderWrapper>
      <ContentWrapper>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '.5rem 1rem 0 1rem',
          }}
        >
          <ArtifactMetadata artifact={artifact} />
          <ArtifactActions
            artifact={artifact}
            dealRoomRole={dealRoomRole}
            onFeature={handleOnFeature}
            onDownload={handleOnDownloadArtifact}
            // onShare={handleOnShare}
            onUpvote={() => handleOnVote(true)}
            onDownvote={() => handleOnVote(false)}
          />
        </Box>
        {previewArtifact}
      </ContentWrapper>
      <TabsWrapper isCollapsed={isTabsCollapsed}>
        <CollapseButton
          onClick={() => setIsTabsCollapsed(!isTabsCollapsed)}
          size="small"
          sx={{
            '& svg': {
              transform: isTabsCollapsed ? 'rotate(0deg)' : 'rotate(180deg)',
            },
          }}
        >
          <KeyboardArrowUp />
        </CollapseButton>
        <DSArtifactPanelTabs
          artifactId={artifactId}
          onTabChange={handleTabChange}
        />
      </TabsWrapper>
    </PanelWrapper>
  );
};
