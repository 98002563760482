import { create } from 'zustand';
import { Country } from 'react-phone-number-input';

type UserConfigurationsStore = {
  surveyPhoneCountryCode: Country;
  setSurveyPhoneCountryCode: (countryCode: Country) => void;
};

// Define the initial state
const initialState = {
  surveyPhoneCountryCode: 'US' as Country,
};

// Create the store with Zustand
const useUserConfigurationsStore = create<UserConfigurationsStore>((set) => ({
  ...initialState,
  setSurveyPhoneCountryCode: (countryCode: Country) =>
    set((state) => ({
      surveyPhoneCountryCode: countryCode,
    })),
}));

export default useUserConfigurationsStore;
