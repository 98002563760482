import { styled } from '@mui/material/styles';
import { Chip, Button, TableCell, Typography, Paper } from '@mui/material';

export const StyledUserCalendarRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  backgroundColor: theme.palette.background.paper,
  borderRadius: '.25rem',
  border: `1px solid ${theme.palette.divider}`,
  overflow: 'hidden', // Add this to prevent child elements from breaking border radius
}));

export const StyledContactsContainer = styled('div')`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 16px 16px 16px;
  background-color: #fcfcfd;
`;

export const StyledContactChipContainer = styled('div')<{
  isSelected: boolean;
}>`
  display: flex;
  align-items: center;
  background-color: ${({ theme, isSelected }) =>
    isSelected ? theme.palette.grey[200] : 'transparent'};
  border-radius: 24px;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transform: ${({ isSelected }) => (isSelected ? 'scale(1.02)' : 'scale(1)')};
  cursor: pointer;
`;

export const StyledContactChip = styled(Chip)`
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  &:hover {
    transform: scale(1.02);
  }

  & .MuiChip-label {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 0.75rem;
  }
`;

export const StyledSearchContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  padding: '0 16px 0 16px',
  flexGrow: 1,
}));

export const StyledSearchAndToggleContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
  background-color: #fcfcfd;
`;

export const StyledToggleContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  white-space: nowrap;
  padding: 1rem;
`;

export const StyledToggleLabel = styled('div')`
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 4px;
`;

export const StyledUserCalendarEventsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0;
  overflow: hidden;
  container-type: inline-size;
  width: 100%;

  .MuiTableContainer-root {
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
    border-radius: 0;

    &.MuiPaper-root {
      border-radius: 0;
    }
  }

  .MuiPaper-root {
    border-radius: 0;
  }

  table {
    width: 100%;
    table-layout: fixed;

    tr:first-of-type td {
      border-radius: 0;
    }

    @container (max-width: 40rem) {
      td.organizer,
      th.organizer {
        display: none;
      }
    }

    @container (max-width: 25rem) {
      td.attendees,
      th.attendees {
        display: none;
      }
    }

    .mf-creator-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 8px;
      container-type: inline-size;

      @container (max-width: 10rem) {
        span {
          display: none;
        }
      }
    }
  }
`;

export const StyledWeekNavigationContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '12px 16px',
  borderBottom: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.paper,
  flexWrap: 'wrap',
  gap: '8px',

  '& .MuiTypography-subtitle1': {
    fontSize: '0.875rem',
    fontWeight: 500,
    flex: 1,
    textAlign: 'left',
    minWidth: '150px',
    color: theme.palette.text.primary,
  },

  '& .nav-buttons': {
    display: 'flex',
    gap: '4px',
    marginLeft: 'auto',
  },

  '@media (max-width: 400px)': {
    flexDirection: 'column',
    alignItems: 'stretch',

    '& .MuiTypography-subtitle1': {
      order: -1,
      marginBottom: '8px',
    },

    '& .nav-buttons': {
      justifyContent: 'space-between',
      marginLeft: 0,
    },
  },
}));

export const StyledNavigationButton = styled(Button)(({ theme }) => ({
  minWidth: '32px',
  padding: '4px 8px',
  color: theme.palette.text.secondary,
  fontSize: '0.75rem',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

export const StyledTableHeader = styled(TableCell)(({ theme }) => ({
  fontWeight: 600,
  backgroundColor: theme.palette.background.default,
  padding: '12px 16px',
  borderRadius: '0 !important',
  '&&': {
    borderRadius: '0 !important',
  },
  '&.MuiTableCell-head': {
    borderRadius: '0 !important',
  },
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '.5rem',
  borderBottom: `1px solid ${theme.palette.grey[200]}`,
  borderRadius: '0 !important',

  '&.MuiTableCell-root': {
    borderRadius: '0 !important',
  },

  '&.mf-creator-container': {
    width: '80px',
    whiteSpace: 'nowrap',
  },
}));

export const StyledPaper = styled(Paper)({
  borderRadius: 0,
  '&&': {
    borderRadius: 0,
  }
});

export const SetupContainer = styled('div')(({ theme }) => ({
  backgroundColor: 'white',
  padding: '1rem',
  borderRadius: '.25rem',
  lineHeight: '1.25rem',
}));

export const StyledLoadingContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  padding: '2rem',
});

export const StyledEmptyStateContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '2rem',
  color: 'rgba(0, 0, 0, 0.6)',
});

export const StyledEmptyStateText = styled(Typography)({
  marginTop: '0.5rem',
});

export const StyledClickableText = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: 'underline',
  cursor: 'pointer',
}));

export const StyledFilterSection = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

export const StyledFilterHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  cursor: 'pointer',
  padding: '0',
  height: 0,
  paddingRight: '1rem',
  opacity: 1,

  '&:hover': {
    backgroundColor: theme.palette.cloudburst.main,
  },

  button: {
    height: '1.5rem',
    width: '1.5rem',
    backgroundColor: theme.palette.cloudburst.main,
    color: 'white',
    opacity: 1,

    '&:hover': {
      backgroundColor: theme.palette.cloudburst.main,
    },
  },
}));
