import { useDeferredPromise } from '../../../Hooks/useDeferredPromise';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { v4 } from 'uuid';
import {
  Dialog,
  Pivot,
  PivotItem,
  useTheme,
  Text,
  NeutralColors,
  FontWeights,
  Dropdown,
  FontSizes,
  IPivotStyles,
} from '@fluentui/react';
import ShareDialogInviteShareSelector, {
  useShareDialogInviteShareSelectorState,
} from './ShareDialogInviteShareSelector';
import ShareDialogCalendarShareSelector, {
  useShadowEventAttendeesDialogState,
} from './ShareDialogCalendarShareSelector';
import { useLightOrDarkMode } from '../../../Hooks/useLightOrDarkMode';
import ShareDialogAccessControl, {
  useShareDialogAccessControlState,
} from './ShareDialogAccessControl';
import { useOrganization } from '../../../Hooks/useOrganization';
import useBreakpoints from '../../../Hooks/useBreakpoints';
import {
  MeetingPlanQuery,
  MeetingPlanSharesQuery,
  OrganizationMeetingPlansQuery,
  OrganizationMeetingsHappeningSoon,
  OrganizationUpcomingMeetings,
} from '../../../QueryNames';
import { useQueryClient } from 'react-query';
import {
  Contact,
  DetailedMeetingflow,
  MeetingflowVisibility,
  RecordingShareType,
  Task,
} from '@meetingflow/common/Api/data-contracts';
import * as Y from 'yjs';
import {
  VISIBILITY_OPTIONS,
  RECORDING_VISIBILITY_OPTIONS,
} from '../../../Constants';
import {
  ApiClient,
  MeetingflowsApiClient,
} from '../../../Services/NetworkCommon';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocalStorageState } from '../../../Hooks/useLocalStorageState';
import { BaseModal } from './BaseModal';
import { MEETINGFLOW_COLORS } from '../../../Themes/Themes';
import toast from 'react-hot-toast';
import { useSearchParams } from 'react-router-dom';

type CollaborateDialogPivot =
  | 'InviteShare'
  | 'EmailShare'
  | 'PrepMeeting'
  | 'ShareAccess'
  | 'RecordingAccess';

interface CollaborateDialogContextInterface {
  type: CollaborateDialogPivot;
}
interface CollaborateInviteShareContext
  extends CollaborateDialogContextInterface {
  type: 'InviteShare';
  contact?: Contact;
  recordingTimestamp?: number;
}

interface CollaborateEmailShareContext
  extends CollaborateDialogContextInterface {
  type: 'EmailShare';
}
interface CollaboratePrepMeetingContext
  extends CollaborateDialogContextInterface {
  type: 'PrepMeeting';
}

interface CollaborateShareAccessContext
  extends CollaborateDialogContextInterface {
  type: 'ShareAccess';
}

export type CollaborateDefaultContext =
  | CollaborateInviteShareContext
  | CollaborateEmailShareContext
  | CollaboratePrepMeetingContext
  | CollaborateShareAccessContext;

type UseCollaborateDialogOptions = {
  organizationSlug: string;
  meetingPlanId: string;
  internalDomains: string[];
  meetingPlan?: Pick<
    DetailedMeetingflow,
    | 'id'
    | 'visibility'
    | 'attendees'
    | 'companies'
    | 'textSummary'
    | 'callRecording'
    | 'recordingShareAdditionalDomains'
    | 'recordingShareType'
  >;
  notesYArray: Y.XmlText;
  tasks: Task[];
};
export const useCollaborateDialog = ({
  organizationSlug,
  meetingPlanId,
  internalDomains,
  meetingPlan,
  notesYArray,
  tasks,
}: UseCollaborateDialogOptions) => {
  const { createDeferred, reject, resolve, deferred } = useDeferredPromise<
    boolean,
    CollaborateDefaultContext | undefined
  >();

  const client = useQueryClient();

  useEffect(() => {
    if (deferred?.isPending && !meetingPlan) {
      reject();
    }
  }, [deferred?.isPending, meetingPlan, reject]);

  const dialog = useMemo(() => {
    if (!deferred || !deferred.isPending || !meetingPlan) {
      return null;
    }

    return (
      <CollaborateDialog
        key={v4()}
        context={deferred?.context}
        organizationSlug={organizationSlug}
        meetingPlanId={meetingPlanId}
        internalDomains={internalDomains}
        meetingPlan={meetingPlan}
        notesYArray={notesYArray}
        tasks={tasks}
        onDismiss={() => resolve(false)}
        onDone={() => {
          client.invalidateQueries([
            MeetingPlanSharesQuery(organizationSlug, meetingPlanId, true),
            MeetingPlanSharesQuery(organizationSlug, meetingPlanId, false),
          ]);

          resolve(true);
        }}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    deferred,
    deferred?.isPending,
    meetingPlan,
    organizationSlug,
    meetingPlanId,
    internalDomains,
    notesYArray,
    resolve,
    client,
  ]);

  return { createDeferred, reject, resolve, deferred, dialog };
};

type CollaborateDialogProps = {
  organizationSlug: string;
  meetingPlanId: string;
  internalDomains: string[];
  meetingPlan: Pick<
    DetailedMeetingflow,
    | 'id'
    | 'visibility'
    | 'attendees'
    | 'companies'
    | 'textSummary'
    | 'callRecording'
    | 'recordingShareAdditionalDomains'
    | 'recordingShareType'
  >;
  context?: CollaborateDefaultContext;
  notesYArray: Y.XmlText;
  tasks: Task[];
  onDismiss: () => void;
  onDone: () => void;
};

const CollaborateDialogModalProps = {
  isBlocking: true,
  isClickableOutsideFocusTrap: true,
};

const CollaborateDialogContentProps = {
  title: 'Collaborate',
};

export const CollaborateDialog = ({
  organizationSlug,
  meetingPlanId,
  meetingPlan,
  internalDomains,
  context,
  notesYArray,
  tasks,
  onDismiss,
  onDone,
}: CollaborateDialogProps) => {
  const theme = useTheme();
  const { isDark } = useLightOrDarkMode();
  const breakpoints = useBreakpoints();
  const { getAccessTokenSilently } = useAuth0();
  const client = useQueryClient();

  const [lastPivot, setLastPivot] =
    useLocalStorageState<CollaborateDialogPivot>(
      `LastCollabPivot`,
      'InviteShare',
    );

  const [activePivot, setActivePivot] = useState<CollaborateDialogPivot>(
    context?.type || lastPivot,
  );

  const storageKey = `recording-share-type-${meetingPlanId}`;
  const [localRecordingShareType, setLocalRecordingShareType] = useLocalStorageState<RecordingShareType>(
    storageKey,
    meetingPlan.recordingShareType
  );

  useEffect(() => {
    const storedValue = localStorage.getItem(storageKey);
    if (!storedValue) {
      setLocalRecordingShareType(meetingPlan.recordingShareType);
    }
  }, [meetingPlan.recordingShareType, setLocalRecordingShareType, storageKey]);

  useEffect(() => {
    if (activePivot) {
      setLastPivot(activePivot);
    }
  }, [activePivot, setLastPivot]);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get('accessTab')) {
      setActivePivot(searchParams.get('accessTab') as CollaborateDialogPivot);
    }
  }, [searchParams, setActivePivot]);

  const { organization } = useOrganization(organizationSlug);

  const invitePivotState = useShareDialogInviteShareSelectorState({
    organizationSlug,
    meetingPlanId,
    meetingPlan,
    internalDomains,
    hideExternalAttendees: true,
    defaultSelectedContactEmail:
      context?.type === 'InviteShare' ? context.contact?.email : undefined,
  });
  const prepMeetingPivotState = useShadowEventAttendeesDialogState({
    organizationSlug,
    meetingPlanId,
    meetingPlan,
    internalDomains,
  });
  const accessPivotState = useShareDialogAccessControlState({
    organizationSlug,
    meetingPlanId,
  });

  const onLinkClick = useCallback(
    (pivotItem: PivotItem | undefined) =>
      setActivePivot(
        (pivotItem?.props?.itemKey || 'InviteShare') as CollaborateDialogPivot,
      ),
    [],
  );

  const pivotStyles = {
    root: {
      height: '36px',
      padding: '.5rem .5rem 0 .5rem',
    },
    link: {
      marginTop: '.25rem',
      fontSize: FontSizes.small,
      height: '1.5rem',
      borderTopLeftRadius: '3px',
      borderTopRightRadius: '3px',
      color: NeutralColors.gray120,
      selectors: {
        ':hover': {
          backgroundColor: isDark
            ? NeutralColors.gray150
            : MEETINGFLOW_COLORS.purpleLighter,
        },
        ':active': {
          backgroundColor: isDark
            ? NeutralColors.gray150
            : MEETINGFLOW_COLORS.purpleLighter,
        },
      },
    },
    linkContent: {
      height: '32px',
    },
    text: {
      lineHeight: '28px',
    },
    linkIsSelected: {
      backgroundColor: isDark
        ? NeutralColors.gray150
        : MEETINGFLOW_COLORS.purpleLighter,
      color: isDark ? NeutralColors.gray90 : NeutralColors.gray140,
      fontWeight: FontWeights.semibold,
      '::before': {
        display: 'none',
      },
    },
    itemContainer: {
      position: 'relative',
      height: '100%',
      maxHeight: '100%',
      padding: '1rem',
      '> div:last-child': {
        height: '100%',
        maxHeight: '100%',
      },
      borderTop: `1px solid ${
        isDark ? NeutralColors.gray150 : MEETINGFLOW_COLORS.purpleGrey
      }`,
    },
  } as Partial<IPivotStyles>;

  return (
    <BaseModal
      isOpen={true}
      title="Meetingflow Access & Privacy"
      onDismiss={onDismiss}
      styles={{
        main: {
          width: '80%',
          height: '400px',
          maxWidth: '800px',
          maxHeight: '500px',
          backgroundColor: isDark
            ? NeutralColors.black
            : MEETINGFLOW_COLORS.white,
        },
      }}
      contentPadding="0"
      headerPadding=".25rem 1rem"
      headerBackgroundColor={
        isDark
          ? MEETINGFLOW_COLORS.purpleMedium
          : MEETINGFLOW_COLORS.purpleMediumDark
      }
    >
      <Pivot
        selectedKey={activePivot}
        onLinkClick={onLinkClick}
        styles={pivotStyles}
      >
        {/* <PivotItem
          key="InviteShare"
          itemKey="InviteShare"
          headerText="Invite and notify"
          title="Invite and notify"
          itemIcon="PeopleAdd"
        >
          <Text
            block
            style={{ margin: '.5rem 0', color: NeutralColors.gray110 }}
          >
            Invite others to join your workspace and notify them about this
            Meetingflow.{' '}
          </Text>
          <ShareDialogInviteShareSelector
            key="InviteShare"
            organizationSlug={organizationSlug}
            meetingPlanId={meetingPlanId}
            meetingPlan={meetingPlan}
            internalDomains={internalDomains}
            onConfirm={onDone}
            onCancel={onDismiss}
            notesYArray={notesYArray}
            tasks={tasks}
            recordingTimestamp={
              context?.type === 'InviteShare'
                ? context.recordingTimestamp
                : undefined
            }
            defaultSelectedContactEmail={
              context?.type === 'InviteShare'
                ? context.contact?.email
                : undefined
            }
            {...invitePivotState}
          />
        </PivotItem> */}
        {/* <PivotItem
          key="PrepMeeting"
          itemKey="PrepMeeting"
          headerText="Create prep meeting"
          title="Create prep meeting"
          itemIcon="calendar"
        >
          <Text
            block
            style={{ margin: '.5rem 0', color: NeutralColors.gray110 }}
          >
            To create a prep meeting, invite teammates below.{' '}
            <div style={{ fontWeight: FontWeights.semibold }}>
              Note: An event will be added to their calendar with a link to this
              plan.
            </div>
          </Text>
          <ShareDialogCalendarShareSelector
            key="PrepMeeting"
            organizationSlug={organizationSlug}
            meetingPlanId={meetingPlanId}
            meetingPlan={meetingPlan}
            internalDomains={internalDomains}
            onConfirm={onDone}
            onCancel={onDismiss}
            {...prepMeetingPivotState}
          />
        </PivotItem> */}

        <PivotItem
          key="ShareAccess"
          itemKey="ShareAccess"
          headerText="Meetingflow Access"
          title="Meetingflow Access"
        >
          <Text
            block
            style={{
              fontSize: FontSizes.size14,
              fontWeight: FontWeights.semibold,
              backgroundColor: isDark
                ? NeutralColors.gray190
                : MEETINGFLOW_COLORS.purpleSecondary,
              padding: '.25rem .5rem',
              color: MEETINGFLOW_COLORS.white,
              borderTopRightRadius: '.25rem',
              borderTopLeftRadius: '.25rem',
            }}
          >
            Who can view this Meetingflow?
          </Text>

          <div
            style={{
              backgroundColor: isDark
                ? MEETINGFLOW_COLORS.darkModeMeetingflowBackgroundGrey
                : MEETINGFLOW_COLORS.purpleLighter,
              padding: '1rem',
              borderBottomRightRadius: '.25rem',
              borderBottomLeftRadius: '.25rem',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                columnGap: '.5rem',
                alignItems: 'baseline ',
              }}
            >
              <Text style={{ fontWeight: FontWeights.semibold }}>
                Meetingflow Visibility
              </Text>
              <Dropdown
                options={VISIBILITY_OPTIONS}
                selectedKey={meetingPlan.visibility}
                style={{ minWidth: '200px' }}
                onChange={(_evt, option, _index) => {
                  if (option?.key) {
                    getAccessTokenSilently().then(async (token) => {
                      const result =
                        await MeetingflowsApiClient.patchMeetingflow(
                          organizationSlug,
                          meetingPlanId,
                          { visibility: option.key as MeetingflowVisibility },
                          {
                            headers: { Authorization: `Bearer ${token}` },
                          },
                        );

                      if (result.status === 200) {
                        client.invalidateQueries(
                          OrganizationMeetingPlansQuery(organizationSlug),
                        );
                        client.invalidateQueries(
                          OrganizationMeetingsHappeningSoon(organizationSlug),
                        );
                        client.invalidateQueries(
                          OrganizationUpcomingMeetings(organizationSlug),
                        );
                        client.setQueryData(
                          MeetingPlanQuery(organizationSlug, result.data.id),
                          result,
                        );
                      }
                    });
                  }
                }}
              />
            </div>
            {meetingPlan.visibility === 'ORGANIZATION' ? (
              <Text
                block
                style={{
                  margin: '.5rem 0',
                  color: NeutralColors.gray110,
                }}
              >
                All Admins, Creators, and Collaborators in the{' '}
                {`${organization?.name}`} workspace have access to view and edit
                this Meetingflow.
              </Text>
            ) : (
              <Text
                block
                style={{ margin: '.5rem 0', color: NeutralColors.gray110 }}
              >
                All <strong>Admins</strong> in the {`${organization?.name}`}{' '}
                workspace have access to view and edit this Meetingflow. The{' '}
                <strong>Creator</strong> who created the Meetingflow, as well as
                any <strong>Collaborators</strong> who are meeting attendees
                also have access. Finally, anyone with whom this Meetingflow was
                explicitly shared (shown below) can access this Meetingflow.
              </Text>
            )}
          </div>

          <ShareDialogAccessControl
            key="ShareAccess"
            organizationSlug={organizationSlug}
            meetingPlan={meetingPlan}
            internalDomains={internalDomains}
            onlyGuests={meetingPlan.visibility === 'ORGANIZATION'}
            {...accessPivotState}
          />
        </PivotItem>

        <PivotItem
          key="RecordingAccess"
          itemKey="RecordingAccess"
          headerText="Recording Access"
          title="Recording Access"
        >
          <Text
            block
            style={{
              fontSize: FontSizes.size14,
              fontWeight: FontWeights.semibold,
              backgroundColor: isDark
                ? NeutralColors.gray190
                : MEETINGFLOW_COLORS.purpleSecondary,
              padding: '.25rem .5rem',
              color: MEETINGFLOW_COLORS.white,
              borderTopRightRadius: '.25rem',
              borderTopLeftRadius: '.25rem',
            }}
          >
            Who can view the call recording?
          </Text>

          <div
            style={{
              backgroundColor: isDark
                ? MEETINGFLOW_COLORS.darkModeMeetingflowBackgroundGrey
                : MEETINGFLOW_COLORS.purpleLighter,
              padding: '1rem',
              borderBottomRightRadius: '.25rem',
              borderBottomLeftRadius: '.25rem',
            }}
          >
            <Text block style={{ marginBottom: '.5rem' }}>
              The call recording is also available at a seaprate URL, intended
              for sharing externally to your organization. Determine who can
              view the call recording URL (
              <strong style={{ fontWeight: FontWeights.semibold }}>
                provided they have the URL
              </strong>
              ).
            </Text>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                columnGap: '.5rem',
                alignItems: 'center',
              }}
            >
              <Text style={{ fontWeight: FontWeights.semibold }}>
                Call Recording Visibility
              </Text>
              <Dropdown
                options={RECORDING_VISIBILITY_OPTIONS}
                selectedKey={localRecordingShareType}
                style={{ minWidth: '400px' }}
                onChange={(_evt, option, _index) => {
                  if (option?.key) {
                    getAccessTokenSilently().then(async (token) => {
                      const result = await ApiClient.patch(
                        `/organization/${organizationSlug}/plan/${meetingPlanId}/recordingShare`,
                        {
                          recordingShareType: option.key as RecordingShareType,
                        },
                        {
                          headers: {
                            Authorization: `Bearer ${token}`,
                          },
                        },
                      );

                      if (result.status === 200) {
                        const updatedType = result.data?.recordingShareType || option.key as RecordingShareType;
                        setLocalRecordingShareType(updatedType);
                        
                        client.invalidateQueries(
                          OrganizationMeetingPlansQuery(organizationSlug),
                        );
                        client.invalidateQueries(
                          OrganizationMeetingsHappeningSoon(organizationSlug),
                        );
                        client.invalidateQueries(
                          OrganizationUpcomingMeetings(organizationSlug),
                        );
                        client.setQueryData(
                          MeetingPlanQuery(organizationSlug, result.data.id),
                          result,
                        );

                        toast.success('Call recording visibility updated.');
                      }
                    });
                  }
                }}
              />
            </div>
            {meetingPlan.recordingShareType === 'ATTENDEES_ONLY' ? (
              <Text
                block
                style={{ margin: '.5rem 0', color: NeutralColors.gray110 }}
              >
                All attendees of this meeting can access the call recording,
                provided they have the link.
              </Text>
            ) : (
              <Text
                block
                style={{ margin: '.5rem 0', color: NeutralColors.gray110 }}
              >
                Anyone with the link can access the call recording, provided
                their email address domain is the same as any attendee of this
                meeting.
              </Text>
            )}
          </div>
        </PivotItem>
      </Pivot>
    </BaseModal>
  );
};
