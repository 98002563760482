import { DealRoomMilestone } from '@meetingflow/common/Api/data-contracts';
import { mergeStyleSets } from '@fluentui/react';
import DealRoomAsyncCommandBarButton, {
  DealRoomCommandBarButton,
} from '../Components/DealRoomButton';
import { MilestoneMoreOptions } from './MilestoneMoreOptions';
import { DSButton, DSTooltip, DSDatePicker } from '../DS';
import { MoveToInbox, AddTask } from '@mui/icons-material';
import { DateTime } from 'luxon';
import {
  HeaderContainer,
  Title,
  ButtonsContainer,
  datePickerStyles,
} from './MilestoneHeader.styles';
import { DEALROOMS_COLORS } from '../../../Themes/Themes';
import { FieldChangeHandlerContext } from '@mui/x-date-pickers/internals/hooks/useField';
import { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';

type MilestoneHeaderProps = {
  milestone: DealRoomMilestone;
  organizationSlug: string;
  dealRoomId: number;
  onDueDateChange: (
    newDate: DateTime | null,
    context: FieldChangeHandlerContext<string | null>,
  ) => void;
  onRequestArtifact: () => Promise<void>;
  onAddTask: () => void;
  milestoneDueDate: string | null;
};

export const MilestoneHeader = ({
  milestone,
  organizationSlug,
  dealRoomId,
  onDueDateChange,
  onRequestArtifact,
  onAddTask,
  milestoneDueDate,
}: MilestoneHeaderProps) => {
  const [isMobile, setIsMobile] = useState(false);
  const [datePickerOpen, setDatePickerOpen] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    setIsMobile(mediaQuery.matches);

    const handler = (e: MediaQueryListEvent) => setIsMobile(e.matches);
    mediaQuery.addEventListener('change', handler);
    return () => mediaQuery.removeEventListener('change', handler);
  }, []);

  const handleDueDateChange = (
    newDate: DateTime | null,
    context: FieldChangeHandlerContext<string | null>,
  ) => {
    onDueDateChange(newDate, context);
    setDatePickerOpen(false);
  };

  return (
    <>
      <HeaderContainer>
        <Title>{milestone.title}</Title>
        {!isMobile && (
          <DSDatePicker
            value={milestoneDueDate ? DateTime.fromISO(milestoneDueDate) : null}
            onChange={handleDueDateChange}
            size="xsmall"
            sx={datePickerStyles}
          />
        )}
        <ButtonsContainer>
          {!isMobile && (
            <DSTooltip title="Request Artifact">
              <span>
                <DSButton
                  onClick={onRequestArtifact}
                  startIcon={<MoveToInbox />}
                  iconOnly
                  variant="text"
                  sx={{
                    '& .MuiSvgIcon-root': {
                      color: DEALROOMS_COLORS.cloudburst,
                    },
                  }}
                >
                  Request Artifact
                </DSButton>
              </span>
            </DSTooltip>
          )}

          <span>
            <DSButton
              onClick={onAddTask}
              startIcon={<AddTask />}
              variant="text"
              iconOnly={isMobile}
              sx={{
                '& .MuiSvgIcon-root': { color: DEALROOMS_COLORS.cloudburst },
              }}
            >
              Add Task
            </DSButton>
          </span>

          <MilestoneMoreOptions
            organizationSlug={organizationSlug}
            dealRoomId={dealRoomId}
            milestoneId={milestone.id}
            onRequestArtifact={isMobile ? onRequestArtifact : undefined}
            onEditDate={isMobile ? () => setDatePickerOpen(true) : undefined}
            isMobile={isMobile}
          />
        </ButtonsContainer>
      </HeaderContainer>

      {isMobile && (
        <Dialog
          open={datePickerOpen}
          onClose={() => setDatePickerOpen(false)}
          maxWidth="xs"
          fullWidth
        >
          <DialogTitle>Edit Due Date</DialogTitle>
          <DialogContent>
            <DSDatePicker
              value={
                milestoneDueDate ? DateTime.fromISO(milestoneDueDate) : null
              }
              onChange={handleDueDateChange}
              size="medium"
              sx={{ width: '100%', marginTop: 2 }}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};
