import { useAuth0 } from '@auth0/auth0-react';
import {
  BaseButton,
  Button,
  FontIcon,
  FontSizes,
  FontWeights,
  NeutralColors,
  PersonaSize,
  mergeStyles,
} from '@fluentui/react';
import {
  Company,
  Contact,
  DetailedMeetingflow,
  Meetingflow,
  ListArtifactsData,
  DealRoomArtifact,
  DealRoomMeetingflowArtifact,
} from '@meetingflow/common/Api/data-contracts';
import { GroupBy } from '@meetingflow/common/ArrayHelpers';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import { useOrganization } from '../../Hooks/useOrganization';
import { useUserProfile } from '../../Hooks/useProfile';
import { useMeetingflow } from '../DealRoom/Components/Journey/hooks/useMeetingflow';
import {
  DealRoomsApiClient,
  MeetingflowsApiClient,
} from '../../Services/NetworkCommon';
import { DEALROOMS_COLORS, MEETINGFLOW_COLORS } from '../../Themes/Themes';
import { ModifierAwareLink } from '../Common/ModifierAwareLink';
import StyledDateTime from '../StyledDateTime';
import {
  MeetingflowCardContainer,
  MeetingflowIconContainer,
  MeetingflowIcon,
  AttendeesList,
  AttendeesListSubheader,
  AttendeesListItem,
  AttendeesTypography,
  AttendeesAvatar,
  AttendeesBadge,
  MeetingflowCardStack,
  CompanyLogoListContainer,
  DateTimeContainer,
  CompletedEventIcon,
  StyledAvatarGroup,
  JoinConferenceButton,
} from './DecisionSiteMeetingflowCard.styles';
import {
  Menu,
  MenuItem,
  IconButton,
  Box,
  Typography,
  Avatar,
  CircularProgress,
} from '@mui/material';
import {
  createMeetingflowLinkArtifact,
  deleteMeetingflowAndDecisionSiteArtifact,
  removeMeetingflowArtifactFromDecisionSite,
  toggleMeetingflowFeature,
} from '../../utils/DecisionSiteMeetingflowHelpers';
import {
  AssignmentInd,
  Star,
  StarOutlined,
  Videocam,
  Summarize as SummarizeIcon,
  MoreVert as MoreVertIcon,
  Delete as DeleteIcon,
  StarRounded,
  Summarize,
  Storage,
} from '@mui/icons-material';
import { useDealRoom } from '../../Hooks/useDealRoom';
import { useLightOrDarkMode } from '../../Hooks/useLightOrDarkMode';
import { CompanyCard } from './MeetingPlanAttendees';
import { AsyncSpan } from '../HOC/AsyncIntrinsicElement';
import { useModifierAwareNavigate } from '../../Hooks/useModifierAwareNavigate';
import { DSTooltip } from '../DealRoom/DS/DSTooltip';
import randomColor from 'randomcolor';
import { Y } from '@syncedstore/core';
import { sendWSMessage } from '../../Helpers/WSHelpers';
import { WS_MESSAGE } from '@meetingflow/common/Constants';
import { useCollabProvider } from '../../Hooks/useCollabProvider';
import DecisionSiteMeetingflowRecordButton from '../DealRoom/Components/Journey/DecisionSiteMeetingflowRecordButton';
import { MeetingPlanCallRecorderStatusQuery } from '../../QueryNames';
import { DecisionSiteMeetingflowCardSummaryIcon } from './DecisionSiteMeetingflowCard/DecisionSiteMeetingflowCardSummaryIcon';
import { DecisionSiteMeetingflowCardLastLoggedIcon } from './DecisionSiteMeetingflowCard/DecisionSiteMeetingflowCardLastLoggedIcon';
import { DecisionSiteMeetingflowCardJoinConferenceIcon } from './DecisionSiteMeetingflowCard/DecisionSiteMeetingflowCardJoinConferenceIcon';
import { DecisionSiteMeetingflowCardAttendeesIcon } from './DecisionSiteMeetingflowCard/DecisionSiteMeetingflowCardAttendeesIcon';
import { DecisionSiteMeetingflowCardContextMenu } from './DecisionSiteMeetingflowCard/DecisionSiteMeetingflowCardContextMenu';

const MAX_NUM_COMPANY_LOGOS_INLINE = 5;

export type MeetingFlowCardMeetingflow = Pick<
  Meetingflow,
  | 'id'
  | 'title'
  | 'startTime'
  | 'endTime'
  | 'externalId'
  | 'externalSeriesId'
  | 'conferenceInfo'
  | 'textSummary'
  | 'lastLoggedToCrm'
  | 'location'
  | 'callRecording'
  | 'owner'
  | 'organizer'
  | 'creator'
  | 'attendees'
  | 'companies'
>;

export type DecisionSiteMeetingflowCardProps = {
  meetingflowId: string;
  onClick?: (meetingflowId: DetailedMeetingflow['id']) => void;
  onTextClick?: () => void;
  onContactClick?: (c: Contact['id']) => void;
  onCompanyClick?: (c: Company['id']) => void;
  hideShowConferenceJoinButton?: boolean;
  hideCRMIcon?: boolean;
  hideSummaryIcon?: boolean;
  hideAttendeesIcon?: boolean;
  hideCallRecordingIcon?: boolean;
  hideCallRecordingButton?: boolean;
  noTitleLink?: boolean;
  showTimesOnly?: boolean;
  showEndTime?: boolean;
  showContextualMenu?: boolean;
  showAddToDecisionSiteButton?: boolean;
  miniConferenceJoinButton?: boolean;
};

export const DecisionSiteMeetingflowCard: React.FC<
  DecisionSiteMeetingflowCardProps
> = ({
  meetingflowId,
  onTextClick,
  onClick,
  onContactClick,
  onCompanyClick,
  hideCRMIcon = false,
  hideSummaryIcon = false,
  hideAttendeesIcon = false,
  hideCallRecordingIcon = false,
  noTitleLink = false,
  hideCallRecordingButton = false,
  showTimesOnly = false,
  showEndTime = false,
  showContextualMenu = false,
  showAddToDecisionSiteButton = false,
  miniConferenceJoinButton = true,
}) => {
  const findCompanyByDomain = (
    companies: Company[] | undefined,
    domain: string,
  ) => {
    return companies?.find((c) => c.domains?.some((d) => d.domain === domain));
  };

  const { user, getAccessTokenSilently } = useAuth0();
  const userEmail = user?.email || '';
  const {
    slug: organizationSlug,
    role,
    organization,
    canCreatePlans,
    hasEntitlement,
    internalDomains,
  } = useOrganization();
  const { dealRoom, dealRoomRole } = useDealRoom();
  const dealRoomId = dealRoom?.id;

  const {
    userId,
    user: mfUser,
    workspacePreferences,
    updateWorkspacePreferencesAsync,
  } = useUserProfile();

  const { isDark } = useLightOrDarkMode();
  const navigate = useNavigate();
  const appInsights = useAppInsightsContext();
  const queryClient = useQueryClient();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const modifierAwareNavigate = useModifierAwareNavigate();

  const color = useMemo(
    () =>
      randomColor({
        luminosity: 'dark',
        format: 'rgba',
        alpha: 1,
      }),
    [],
  );

  const ydoc = useMemo(() => {
    return new Y.Doc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationSlug, meetingflowId]);

  const client = useQueryClient();

  const handleDelete = async (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);

    if (!dealRoomId || !meetingflowId || !organizationSlug) return;

    const token = await getAccessTokenSilently();

    await deleteMeetingflowAndDecisionSiteArtifact({
      organizationSlug,
      dealRoomId,
      meetingflowId,
      token,
      queryClient,
      appInsights,
    });
  };

  const handleRemoveFromDecisionSite = async (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    event.stopPropagation();
    setAnchorEl(null);

    if (!dealRoomId || !meetingflowId || !organizationSlug) return;

    const token = await getAccessTokenSilently();

    try {
      await removeMeetingflowArtifactFromDecisionSite({
        organizationSlug,
        dealRoomId,
        meetingflowId,
        token,
        queryClient,
        appInsights,
      });
    } catch (err: unknown) {
      console.error('Error removing meetingflow from decision site:', err);
    }
  };

  const [isArtifactFeatured, setIsArtifactFeatured] = useState<boolean>(false);
  const [artifactId, setArtifactId] = useState<number | undefined>();

  const {
    meetingflow,
    meetingflowFetched,
    refetchMeetingflow,
    recorderStatus,
    isLoading,
    refetchRecorderStatus,
    withDealRoomContacts,
    sortedAttendeesByDomain = {} as Record<string, Contact[]>,
    companies = [],
  } = useMeetingflow(meetingflowId);

  const { data: artifactsData } = useQuery<ListArtifactsData>(
    ['artifacts', dealRoomId, organizationSlug, meetingflowId],
    async () => {
      if (!dealRoomId || !organizationSlug || !meetingflowId) {
        throw new Error('Missing required parameters');
      }
      const token = await getAccessTokenSilently();
      const response = await DealRoomsApiClient.listArtifacts(
        { organizationSlug, dealRoomId },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
      return response.data;
    },
    {
      enabled: !!dealRoomId && !!organizationSlug && !!meetingflowId,
    },
  );

  useEffect(() => {
    if (artifactsData) {
      const artifact = artifactsData.find(
        (a: DealRoomArtifact): a is DealRoomMeetingflowArtifact =>
          a.type === 'MEETINGFLOW' &&
          'meetingflowId' in a &&
          a.meetingflowId === meetingflowId,
      );
      setIsArtifactFeatured(!!artifact?.featuredAt);
      setArtifactId(artifact?.id);
    }
  }, [artifactsData, meetingflowId]);

  const handleFeature = async (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);

    if (!dealRoomId || !meetingflowId || !organizationSlug) return;

    const token = await getAccessTokenSilently();

    try {
      const newFeaturedState = await toggleMeetingflowFeature({
        organizationSlug,
        dealRoomId,
        meetingflowId,
        token,
        queryClient,
        appInsights,
      });
      setIsArtifactFeatured(newFeaturedState);
    } catch (err: unknown) {
      console.error('Error featuring meetingflow:', err);
    }
  };

  useEffect(() => {
    if (meetingflowId) {
      refetchMeetingflow();
    }
  }, [meetingflowId, refetchMeetingflow]);

  const isAssociatedWithEvent = !!meetingflow?.externalId;

  const peopleByDomain = useMemo(
    () => GroupBy(meetingflow?.attendees || [], (a) => a?.emailDomain || ''),
    [meetingflow?.attendees],
  );

  const attendeesWithoutCompany = useMemo(() => {
    const companyDomains = meetingflow?.companies
      ?.map((c) => c.domains?.map((d) => d?.domain || '') || [])
      .flat();
    return (
      Object.keys(peopleByDomain || {})
        .filter((key) => !companyDomains?.includes(key))
        .flatMap((key) => peopleByDomain[key] || []) ?? []
    );
  }, [meetingflow?.companies, peopleByDomain]);

  const externalCompanies = useMemo(
    () => meetingflow?.companies?.filter((c) => !c?.isInternal) || [],
    [meetingflow?.companies],
  );

  const badgeWidth = 2.125;
  const iconWidth = 1.466;

  const eventIsInPast = useMemo(() => {
    if (!meetingflow?.endTime) return false;
    const now = DateTime.now();
    const endTime = DateTime.fromISO(meetingflow?.endTime);
    return now > endTime;
  }, [meetingflow?.endTime]);

  const isNowOrSoon = meetingflow
    ? meetingflow?.conferenceInfo &&
      DateTime.fromISO(meetingflow?.startTime).diffNow('minutes').minutes <
        60 &&
      DateTime.fromISO(meetingflow?.endTime).diffNow('minutes').minutes > -10
    : false;

  const beforeStart = meetingflow
    ? DateTime.fromISO(meetingflow.startTime).diffNow().milliseconds > 0
    : true;
  const beforeEnd = meetingflow
    ? DateTime.fromISO(meetingflow.endTime).diffNow().milliseconds > 0
    : true;

  const companyClass = mergeStyles({
    display: 'block',
    marginBottom: '.5rem',
  });

  const companyNameClass = mergeStyles({
    transition: '.3s ease-in-out all',
    color: DEALROOMS_COLORS.cloudburst,
    cursor: 'pointer',
  });

  const onCompanyCardClick = useCallback(
    (id: number, e?: React.MouseEvent<HTMLElement, MouseEvent>) => {
      e?.stopPropagation();
      navigate(`/organization/${organizationSlug}/library/companies/${id}`);
    },
    [navigate, organizationSlug],
  );

  const onConferenceIconClick = useCallback(() => {
    window.open(meetingflow?.conferenceInfo?.joinUri, '_blank');
    appInsights.trackEvent({
      name: 'JOIN_CONFERENCE_CALL',
      properties: {
        organizationId: organization?.id,
        type: meetingflow?.conferenceInfo?.type,
      },
    });
  }, [
    appInsights,
    meetingflow?.conferenceInfo?.joinUri,
    meetingflow?.conferenceInfo?.type,
    organization?.id,
  ]);

  const onWSMessage = useCallback(
    (messageType: (typeof WS_MESSAGE)[keyof typeof WS_MESSAGE]) => {
      switch (messageType) {
        case WS_MESSAGE.REFRESH_PLAN: {
          refetchMeetingflow();
          return true;
        }
        case WS_MESSAGE.REFRESH_CALL_RECORDING_STATUS: {
          if (
            !!organizationSlug &&
            !!meetingflowId &&
            hasEntitlement('CALL_RECORDING') &&
            !!meetingflow?.callRecording?.id
          ) {
            client.removeQueries(
              MeetingPlanCallRecorderStatusQuery(
                organizationSlug,
                meetingflowId,
              ),
            );

            refetchRecorderStatus();
          }
          return true;
        }

        case WS_MESSAGE.HEARTBEAT:
        case WS_MESSAGE.RECREATE:
        case WS_MESSAGE.SAVE: {
          return true;
        }
        default: {
          return false;
        }
      }
    },
    [
      client,
      hasEntitlement,
      meetingflowId,
      organizationSlug,
      meetingflow?.callRecording?.id,
      refetchRecorderStatus,
      refetchMeetingflow,
    ],
  );

  const { provider } = useCollabProvider({
    providerName: 'MEETINGPLAN',
    documentName: `MeetingPlan__${organizationSlug || ''}__${meetingflowId || ''}`,
    ydoc,
    color,
    email: userEmail || '',
    name: mfUser?.name || user?.name || '',
    picture: mfUser?.avatarFileUrl || mfUser?.avatarUrl || user?.picture || '',
    onMessage: onWSMessage,
  });
  const addLinkClass = mergeStyles({
    marginRight: '0',
    color: MEETINGFLOW_COLORS.white,
    cursor: 'pointer',
    fontSize: FontSizes.mini,
    fontWeight: FontWeights.semibold,
    backgroundColor: DEALROOMS_COLORS.themeSecondary,
    borderRadius: '.5rem',
    padding: '0 .5rem .0 .5rem',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    justifyItems: 'center',
    maxHeight: '1rem',
    textTransform: 'uppercase',
    transition: 'all .3s ease-in-out',
    lineHeight: '18px',
    position: 'relative',
    top: '0',

    '&:hover': {
      backgroundColor: DEALROOMS_COLORS.themePrimary,
    },

    i: {
      marginRight: '.25rem',
      fontWeight: FontWeights.regular,
      color: 'white',
    },
  });

  const addMeetingflowToDecisionSite = async (
    e: React.MouseEvent<
      | HTMLAnchorElement
      | HTMLButtonElement
      | HTMLDivElement
      | BaseButton
      | Button
      | HTMLSpanElement
    >,
    scheduleCallRecording = false,
  ): Promise<unknown> => {
    e.stopPropagation();
    const token = await getAccessTokenSilently();

    try {
      if (!dealRoomId) {
        throw new Error('No dealRoomId provided');
      }

      return await createMeetingflowLinkArtifact(
        organizationSlug!,
        dealRoomId,
        meetingflowId,
        queryClient,
        token,
      );
    } catch (error) {
      toast.error('Failed to add meetingflow to Decision Site');
      return error;
    }
  };

  const mfIconSx = useMemo(
    () => ({
      color: DEALROOMS_COLORS.cloudburst,
      cursor: 'pointer',
      height: '16px',
      width: '16px',
      borderRadius: '50%',
    }),
    [],
  );

  const mfIconContainerSx = useMemo(
    () => ({
      width: '26px',
      height: '26px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '50%',
    }),
    [],
  );

  const addToDecisionSiteButton = useMemo(() => {
    if (!meetingflow || dealRoomRole !== 'SELLER') {
      return null;
    }

    return (
      <AsyncSpan
        className={classNames(addLinkClass, '')}
        onClick={addMeetingflowToDecisionSite}
      >
        Add
      </AsyncSpan>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addLinkClass, addMeetingflowToDecisionSite]);

  const conferenceIcon = useMemo(() => {
    if (meetingflow && meetingflow?.conferenceInfo) {
      return (
        <DecisionSiteMeetingflowCardJoinConferenceIcon
          meetingflow={meetingflow}
          onConferenceIconClick={onConferenceIconClick}
          mini={miniConferenceJoinButton}
        />
      );
    }
    return null;
  }, [meetingflow, onConferenceIconClick, miniConferenceJoinButton]);

  const summaryIcon = useMemo(() => {
    if (meetingflow && meetingflow?.textSummary && !hideSummaryIcon) {
      return (
        <DecisionSiteMeetingflowCardSummaryIcon
          meetingflow={meetingflow}
          hideSummaryIcon={hideSummaryIcon}
        />
      );
    }
    return null;
  }, [meetingflow, hideSummaryIcon]);

  const attendeesIcon = useMemo(() => {
    if (meetingflow?.attendees && sortedAttendeesByDomain && !hideAttendeesIcon && Object.keys(sortedAttendeesByDomain).length > 0) {
      return (
        <DecisionSiteMeetingflowCardAttendeesIcon
          meetingflow={meetingflow}
          hideAttendeesIcon={hideAttendeesIcon}
          sortedAttendeesByDomain={sortedAttendeesByDomain}
          companies={companies || []}
          withDealRoomContacts={withDealRoomContacts}
          mfIconSx={mfIconSx}
          findCompanyByDomain={findCompanyByDomain}
        />
      );
    }
    return null;
  }, [
    meetingflow,
    hideAttendeesIcon,
    sortedAttendeesByDomain,
    companies,
    mfIconSx,
    withDealRoomContacts,
  ]);

  const lastLoggedIcon = useMemo(() => {
    if (!!meetingflow?.lastLoggedToCrm?.loggedAt && !hideCRMIcon) {
      return (
        <DecisionSiteMeetingflowCardLastLoggedIcon
          meetingflow={meetingflow}
          hideCRMIcon={hideCRMIcon}
        />
      );
    }
    return null;
  }, [meetingflow, hideCRMIcon]);

  const completedLinkClass = mergeStyles({
    marginRight: '.5rem',
    color: isDark ? DEALROOMS_COLORS.themePrimary : DEALROOMS_COLORS.woodsmoke,
    cursor: 'default',
    fontSize: FontSizes.mini,
    fontWeight: FontWeights.regular,
    borderRadius: '8px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    justifyItems: 'center',
    width: '16px',
    height: '16px',
    transition: 'all .3s ease-in-out',
    backgroundColor: isDark ? NeutralColors.gray160 : NeutralColors.gray30,

    i: {
      margin: 0,
      fontSize: '10px',
    },
  });

  const handleStatusChange = useCallback(() => {
    refetchMeetingflow();
    refetchRecorderStatus();
  }, [refetchMeetingflow, refetchRecorderStatus]);

  const card = (
    <MeetingflowCardStack
      direction="row"
      onClick={() => onClick && onClick(meetingflowId)}
    >
      <div className="mf-details">
        <Typography
          className="meetingflow-title"
          onClick={() => onClick && onClick(meetingflowId)}
          aria-label={meetingflow?.title}
          title={meetingflow?.title}
          noWrap
        >
          {showTimesOnly && meetingflow?.startTime ? (
            <>
              <CompletedEventIcon>
                {eventIsInPast && !showTimesOnly && (
                  <DSTooltip
                    title={`This event was in the past and can no longer be recorded. ${showAddToDecisionSiteButton ? 'You can still add to your Decision Site.' : ''}`}
                    placement="top"
                  >
                    <span className={completedLinkClass}>
                      <FontIcon iconName="CheckMark" />
                    </span>
                  </DSTooltip>
                )}
              </CompletedEventIcon>
              <span className="short-time">
                <StyledDateTime
                  useRelativeDates
                  dateTime={meetingflow?.startTime}
                  displayComponents={
                    showTimesOnly ? ['time'] : ['date', 'time']
                  }
                  shortMonths
                />
              </span>
            </>
          ) : null}{' '}
          {noTitleLink ? (
            meetingflow?.title
          ) : (
            <ModifierAwareLink
              defaultToNewTab={false}
              href={`/organization/${organizationSlug}/decisionsite/${dealRoomId}/journey?meeting=${meetingflowId}`}
              as={'a'}
            >
              {meetingflow?.title}
            </ModifierAwareLink>
          )}
        </Typography>
        <Typography
          className="mf-datetime"
          onClick={() => onClick && onClick(meetingflowId)}
          noWrap
        >
          {meetingflow?.startTime ||
          (meetingflow?.location && !meetingflow.conferenceInfo?.joinUri) ? (
            <DateTimeContainer>
              {!showTimesOnly && (
                <>
                  {eventIsInPast && (
                    <DSTooltip
                      title={`This event was in the past and can no longer be recorded. ${showAddToDecisionSiteButton ? 'You can still add to your Decision Site.' : ''}`}
                      placement="top"
                    >
                      <span className={completedLinkClass}>
                        <FontIcon iconName="CheckMark" />
                      </span>
                    </DSTooltip>
                  )}
                  <StyledDateTime
                    useRelativeDates
                    dateTime={meetingflow?.startTime}
                    displayComponents={
                      showTimesOnly ? ['time'] : ['date', 'time']
                    }
                    shortMonths
                  />
                  {meetingflow?.endTime && showEndTime && (
                    <>
                      {'–'}{' '}
                      <StyledDateTime
                        dateTime={meetingflow?.endTime}
                        displayComponents={['time']}
                        includeTimeZone
                      />
                    </>
                  )}
                </>
              )}
            </DateTimeContainer>
          ) : null}
        </Typography>
      </div>
    </MeetingflowCardStack>
  );

  if (!meetingflow || !meetingflowId || !organizationSlug) {
    return null;
  }

  if (isLoading) {
    return (
      <MeetingflowCardContainer
        sx={{
          justifyContent: 'center',
          minHeight: '40px',
        }}
      >
        <CircularProgress size={24} />
      </MeetingflowCardContainer>
    );
  }

  return (
    <MeetingflowCardContainer>
      <div className="meetingflow-details">{card}</div>
      <div
        className="mf-icons"
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '4px',
          lineHeight: '12px',
        }}
      >
        {summaryIcon}
        {lastLoggedIcon}
        {attendeesIcon}
        {!hideCallRecordingButton && (
          <DecisionSiteMeetingflowRecordButton
            meetingflowId={meetingflowId!}
            onStatusChange={handleStatusChange}
            mini
            iconOnly={'when-inactionable'}
          />
        )}
        {conferenceIcon}
        {showAddToDecisionSiteButton && dealRoomRole === 'SELLER'
          ? addToDecisionSiteButton
          : null}
      </div>
      {showContextualMenu && organizationSlug ? (
        <DecisionSiteMeetingflowCardContextMenu
          meetingflow={meetingflow}
          dealRoomRole={dealRoomRole}
          organizationSlug={organizationSlug}
          meetingflowId={meetingflowId}
          isArtifactFeatured={isArtifactFeatured}
          hasEntitlement={hasEntitlement}
          getAccessTokenSilently={getAccessTokenSilently}
          handleFeature={handleFeature}
          handleRemoveFromDecisionSite={handleRemoveFromDecisionSite}
          handleDelete={handleDelete}
          provider={provider}
          client={client}
          refetchRecorderStatus={refetchRecorderStatus}
          refetchMeetingflow={refetchMeetingflow}
        />
      ) : null}
    </MeetingflowCardContainer>
  );
};
