import {
  buyerOrientationSurveyOptions,
  optionValuesThatNeedPhoneNumber,
} from '../buyerOrientationSurveyUtils';
import { Icon, mergeStyles, Stack, TextField } from '@fluentui/react';
import { DEALROOMS_COLORS } from '../../../../../Themes/Themes';
import { SurveyOptionCard } from '../SurveyOptionCard';
import {
  ActionType,
  BuyerOrientationStepProps,
} from '../../../../../types/BuyerOrientationSurveyTypes';
import { useCallback } from 'react';
import { DecisionSitePhoneNumberField } from '../../DecisionSitePhoneNumberField';
import useUserConfigurationsStore from '../../../../../Stores/userConfigurationsStore';

export const BuyerOrientationCommunication = ({
  orientationForm,
  updateOrientationForm,
}: BuyerOrientationStepProps) => {
  const stepContainerStyles = mergeStyles({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',

    '.buyer-orientation-step-title': {
      color: DEALROOMS_COLORS.themePrimary,
      fontWeight: '400',
      fontSize: '1.5rem',
      lineHeight: '2.5rem',
      textAlign: 'center',
    },

    '.buyer-orientation-step-cards': {
      display: 'flex',
      alignItems: 'stretch',
      justifyContent: 'space-between',
      width: '100%',
      flexWrap: 'wrap',

      '@media(max-width: 720px)': {
        flexDirection: 'column',
        gap: '1.5rem',
      },

      '.card': {
        flexBasis: '23%',
      },
    },

    '.communication-phone-main-container': {
      position: 'relative',

      '.communication-phone-icon': {
        color: DEALROOMS_COLORS.themeSecondary,
        position: 'absolute',
        top: '2.5rem',
        left: '.75rem',
        zIndex: '1',
      },

      '.communication-phone-icon-container': {
        position: 'absolute',
        top: '2.125rem',
        left: '.75rem',
        zIndex: '1',
      },

      '.communication-phone-text-field': {
        '.ms-Label': {
          fontWeight: '400',
          color: DEALROOMS_COLORS.themePrimary,
        },
        '.ms-TextField-fieldGroup': {
          border: `1px solid ${DEALROOMS_COLORS.neutralLight}`,
          borderRadius: '4px',
          height: 'auto',
        },
        input: {
          minHeight: '2.5rem',
          fontSize: '1rem',
          fontWeight: '400',
          '::placeholder': {
            color: DEALROOMS_COLORS.userSurveyInputPlaceholder,
          },
          paddingLeft: '2.375rem',
        },
      },
    },
  });

  // Get the phone country code from the store
  const surveyPhoneCountryCode = useUserConfigurationsStore(
    (state) => state.surveyPhoneCountryCode,
  );
  // Update the phone country code in the store
  const setSurveyPhoneCountryCode = useUserConfigurationsStore(
    (state) => state.setSurveyPhoneCountryCode,
  );

  const handleSelectOption = useCallback(
    (newValue: string) => {
      updateOrientationForm({
        type: ActionType.UPDATE_COMMUNICATION_PREFERENCE,
        payload: newValue,
      });
    },
    [updateOrientationForm],
  );

  return (
    <div className={stepContainerStyles}>
      <Stack horizontal className="buyer-orientation-step-cards">
        {buyerOrientationSurveyOptions.communicationPreference.options.map(
          (option) => (
            <SurveyOptionCard
              className="card"
              key={option.optionId}
              option={option}
              isSelected={
                orientationForm.communicationPreference === option.value
              }
              handleSelectOption={handleSelectOption}
            />
          ),
        )}
      </Stack>
      {optionValuesThatNeedPhoneNumber.includes(
        orientationForm.communicationPreference || '',
      ) ? (
        <div className="communication-phone-main-container">
          <DecisionSitePhoneNumberField
            value={orientationForm.phoneNumber || ''}
            onChange={(newValue) => {
              updateOrientationForm({
                type: ActionType.UPDATE_PHONE_NUMBER,
                payload: newValue || '',
              });
            }}
            country={surveyPhoneCountryCode}
            setCountry={setSurveyPhoneCountryCode}
            label="Phone Number"
            containerSx={{
              backgroundColor: DEALROOMS_COLORS.white,
              height: '3rem',
            }}
            inputSx={{
              backgroundColor: DEALROOMS_COLORS.white,
            }}
          />
        </div>
      ) : null}
    </div>
  );
};
