import { styled, Box, Typography } from '@mui/material';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';
import { keyframes } from '@mui/system';

const pulse = keyframes`
  0%, 100% {
    opacity: 0.5;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.1);
  }
`;

export const TaskContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  overflowY: 'auto',
  paddingBottom: '5rem',
  maxWidth: '100%',
});

export const SectionTitle = styled(Typography)({
  marginBottom: '.5rem',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  fontWeight: '500',
});

export const AIIndicator = styled('span')({
  color: DEALROOMS_COLORS.peacock,
  animation: `${pulse} 2s ease-in-out infinite`,
  display: 'inline-flex',
  alignItems: 'center',
  marginRight: '4px',
  '& svg': {
    fontSize: '16px',
  },
});

export const TaskSection = styled(Box)({
  marginBottom: '1.5rem',
});

export const AITaskSection = styled(Box)({
  marginBottom: '1.5rem',
});

export const AddTaskContainer = styled(Box)({
  padding: '.2rem .5rem',
  backgroundColor: DEALROOMS_COLORS.neutralLighter,
  borderRadius: '4px',

  '& .MuiFormControl-root ': {
    margin: 0,
  },

  '& input': {
    backgroundColor: DEALROOMS_COLORS.white,
  },
});
