import { TextField, styled } from '@mui/material';
import { DEALROOMS_COLORS } from '../../../../../Themes/Themes';

export const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    padding: 0,
    borderRadius: '.25rem',
    transition: 'all 0.2s ease-in-out',
    backgroundColor: DEALROOMS_COLORS.white,
    '& input': {
      padding: '0.25rem 0.75rem',
      backgroundColor: DEALROOMS_COLORS.white,
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: `1px solid ${DEALROOMS_COLORS.lightGray}`,
    transition: 'all 0.2s ease-in-out',
  },
}));