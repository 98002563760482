import React from 'react';
import { Box, Typography } from '@mui/material';
import { DealRoomArtifact } from '@meetingflow/common/Api/data-contracts';
import { DSTextField } from '../../DS/DSTextField';
import { DSTooltip } from '../../DS';
import { DEALROOMS_COLORS } from '../../../../Themes/Themes';

export interface ArtifactTitleProps {
  title: string | undefined;
  isEditingTitle: boolean;
  onStartEditTitle: () => void;
  onBlurTitle: () => Promise<void>;
  onChangeTitle: (value: string) => void;
  artifact: DealRoomArtifact;
}

/**
 * ArtifactTitle Component
 *
 * Displays and handles editing of the artifact title and description using MUI components.
 * Supports both view and edit modes with proper keyboard interactions.
 */
export const ArtifactTitle: React.FC<ArtifactTitleProps> = ({
  title,
  isEditingTitle,
  onStartEditTitle,
  onBlurTitle,
  onChangeTitle,
  artifact,
}) => {
  return (
    <Box
      onClick={!isEditingTitle ? onStartEditTitle : undefined}
      sx={{
        width: 'calc(100% - 1rem)',
        padding: '.5rem',
        backgroundColor: DEALROOMS_COLORS.financialLighterGray,
        borderRadius: '.25rem',
        cursor: 'pointer',
        transition: '.3s ease-in-out all',
        ':hover': {
          backgroundColor: DEALROOMS_COLORS.financialLightGray,
        },
      }}
    >
      {isEditingTitle ? (
        <DSTextField
          value={title}
          onBlur={onBlurTitle}
          onChange={(e) => onChangeTitle(e.target.value)}
          onKeyDown={async (e) => {
            if (e.key === 'Enter') {
              await onBlurTitle();
            }
          }}
          autoFocus
          fullWidth
          size="small"
          sx={{
            backgroundColor: DEALROOMS_COLORS.white,
          }}
        />
      ) : (
        <DSTooltip title={artifact.name ? 'Edit name...' : 'Add a name'}>
          <Typography>{artifact.label || artifact.name}</Typography>
        </DSTooltip>
      )}
    </Box>
  );
};
