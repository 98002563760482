/**
 * DecisionSiteMeetingPanelVideoPlayer is a component that manages the video playback
 * experience for recorded meetings. It handles video loading, playback controls,
 * and recording status display.
 *
 * Key Features:
 * - Video playback with adaptive streaming
 * - Thumbnail generation and display
 * - Subtitle/transcript synchronization
 * - Recording status monitoring
 * - Timeline visualization
 * - Speaker tracking and identification
 * - Responsive 16:9 video container
 * - Meeting state management
 */

import { useMemo, useState, useRef, useEffect, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useQueryClient } from 'react-query';
import VideoPlayer, {
  VideoPlayerControls,
  VideoPlayerState,
} from '../../../../Components/Video/VideoPlayer';
import { toContentPath } from '../../../../Helpers/FileHelpers';
import { DetailedMeetingflow } from '@meetingflow/common/Api/data-contracts';
import { DecisionSiteRecordingStatusBar } from './DecisionSiteRecordingStatusBar';
import CallRecordingTimeline from '../../../../Components/MeetingPlans/CallRecordingTimeline';
import {
  VideoPlayerContainer,
  VideoPlayerContent,
  VideoPlayerOverlay
} from './DecisionSiteMeetingPanel.styles';
import { useDealRoom } from '../../../..//Hooks/useDealRoom';
import { useMeetingflow } from './hooks/useMeetingflow';
import { FontIcon } from '@fluentui/react';
/**
 * Props interface for DecisionSiteMeetingPanelVideoPlayer
 * @property meetingPlan - Detailed meeting information including recording details
 * @property organizationSlug - Organization identifier for content path resolution
 * @property videoPlayerState - Current state of video playback (time, playing status)
 * @property initialTimestamp - Starting timestamp for video playback
 * @property videoPlayerControls - Player control functions (play, pause, seek)
 * @property setVideoPlayerControls - Callback to update player controls
 * @property setVideoPlayerState - Callback to update player state
 * @property refetchMeetingflow - Function to refresh meeting data
 * @property isCurrentlyHappening - Flag indicating if meeting is in progress
 * @property isMinimized - Flag indicating if video player is minimized
 */
interface DecisionSiteMeetingPanelVideoPlayerProps {
  meetingPlan: DetailedMeetingflow;
  organizationSlug: string;
  videoPlayerState?: VideoPlayerState;
  initialTimestamp?: number;
  videoPlayerControls?: VideoPlayerControls | null;
  setVideoPlayerControls: (controls: VideoPlayerControls | null) => void;
  setVideoPlayerState: (state: VideoPlayerState) => void;
  refetchMeetingflow?: () => Promise<unknown>;
  videoIsPictureInPicture?: boolean;
  setVideoIsPictureInPicture?: (isPictureInPicture: boolean) => void;
}

/**
 * Video player component with integrated meeting controls and status display
 * Provides:
 * - Video playback interface
 * - Timeline visualization
 * - Speaker identification
 * - Recording status management
 * - Meeting state tracking
 */
export const DecisionSiteMeetingPanelVideoPlayer: React.FC<
  DecisionSiteMeetingPanelVideoPlayerProps
> = ({
  meetingPlan,
  organizationSlug,
  videoPlayerState,
  initialTimestamp,
  videoPlayerControls,
  setVideoPlayerControls,
  setVideoPlayerState,
  refetchMeetingflow,
  videoIsPictureInPicture = false,
  setVideoIsPictureInPicture,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();
  const { dealRoomId } = useDealRoom();

  /**
   * Custom hook for comprehensive meeting flow management
   * Provides:
   * - Recording status updates
   * - Speaker identification
   * - Participant information
   * - Transcript data
   * - Call statistics
   * - Speaker turn tracking
   */
  const {
    recorderStatus,
    speakerNames,
    getContactForParticipant,
    getColorForSpeaker,
    transcript,
    callStats,
    turns,
    isInFuture,
    isVideoMeeting,
    isInPast,
    isCurrentlyHappening,
  } = useMeetingflow(meetingPlan.id);

  /**
   * Recording availability check
   * Requires:
   * - Valid organization context
   * - Recording file reference
   */
  const hasRecording = Boolean(
    organizationSlug && meetingPlan?.callRecording?.recordingFileName,
  );

  // Dragging state
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const dragRef = useRef<{
    startX: number;
    startY: number;
    dragStartX: number;
    dragStartY: number;
  }>();

  // Handle drag start
  const handleMouseDown = useCallback((e: React.MouseEvent) => {
    if (!videoIsPictureInPicture) return;

    setIsDragging(true);
    dragRef.current = {
      startX: e.clientX - position.x,
      startY: e.clientY - position.y,
      dragStartX: e.clientX,
      dragStartY: e.clientY,
    };

    // Prevent text selection while dragging
    e.preventDefault();
  }, [videoIsPictureInPicture, position.x, position.y]);

  // Handle dragging
  useEffect(() => {
    if (!videoIsPictureInPicture) return;

    const handleMouseMove = (e: MouseEvent) => {
      if (!isDragging || !dragRef.current) return;

      setPosition({
        x: e.clientX - dragRef.current.startX,
        y: e.clientY - dragRef.current.startY,
      });
    };

    const handleMouseUp = () => {
      setIsDragging(false);
      dragRef.current = undefined;
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, videoIsPictureInPicture]);

  const memoizedVideoPlayer = useMemo(
    () => (
      <div>
        {hasRecording && (
          <VideoPlayerContainer
            isMinimized={videoIsPictureInPicture}
            onMouseDown={handleMouseDown}
            style={
              videoIsPictureInPicture
                ? {
                    transform: `translate(${position.x}px, ${position.y}px)`,
                    cursor: isDragging ? 'grabbing' : 'grab',
                  }
                : undefined
            }
          >
            <VideoPlayerContent>
              <VideoPlayer
                source={
                  toContentPath(
                    meetingPlan.callRecording!.recordingFileName,
                    organizationSlug,
                    meetingPlan.id,
                  )!
                }
                thumbnail={toContentPath(
                  meetingPlan?.callRecording?.thumbnailFileName,
                  organizationSlug,
                  meetingPlan.id,
                )}
                transcript={toContentPath(
                  meetingPlan?.callRecording?.subtitleFileName,
                  organizationSlug,
                  meetingPlan.id,
                )}
                initialTimestamp={initialTimestamp}
                onPlayerStateChanged={setVideoPlayerState}
                onPlayerControls={setVideoPlayerControls}
              />
              {videoIsPictureInPicture && setVideoIsPictureInPicture && (
                <VideoPlayerOverlay
                  onClick={() => setVideoIsPictureInPicture(false)}
                >
                  <FontIcon 
                    iconName="FullScreen" 
                    className="text-white cursor-pointer"
                  />
                </VideoPlayerOverlay>
              )}
            </VideoPlayerContent>
          </VideoPlayerContainer>
        )}

        {hasRecording && !videoIsPictureInPicture && (
          <div
            className="transcript-timeline"
            style={{
              height: `${speakerNames.length / 2 + 2}rem`,
            }}
          >
            <CallRecordingTimeline
              speakers={speakerNames}
              turns={turns}
              callStats={callStats}
              meetingPlanId={meetingPlan.id}
              getColorForSpeaker={getColorForSpeaker}
              videoPlayerControls={videoPlayerControls}
            />
          </div>
        )}

        {organizationSlug && (
          <DecisionSiteRecordingStatusBar
            hasRecording={hasRecording}
            recorderStatus={recorderStatus}
            callRecording={meetingPlan.callRecording}
            meetingflowId={meetingPlan.id}
            refetchMeetingflow={refetchMeetingflow}
          />
        )}
      </div>
    ),
    [
      hasRecording,
      organizationSlug,
      initialTimestamp,
      videoPlayerControls,
      speakerNames,
      meetingPlan.id,
      meetingPlan.callRecording,
      callStats,
      getColorForSpeaker,
      recorderStatus,
      refetchMeetingflow,
      setVideoPlayerControls,
      setVideoPlayerState,
      turns,
      videoIsPictureInPicture,
      position,
      isDragging,
      handleMouseDown,
      setVideoIsPictureInPicture,
    ],
  );

  return memoizedVideoPlayer;
};
